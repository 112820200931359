import React, { useMemo } from 'react';
import { ColorMapper } from 'utils/SemesterCalendar/colorMapper';
import Avatar, { AvatarProps } from '@mui/material/Avatar';

function stringAvatar(name: string) {
  const bgColor = ColorMapper.stringToColor(name);
  const textColor = ColorMapper.getContrastYIQ(bgColor);
  return {
    sx: {
      bgcolor: bgColor,
      color: textColor,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

interface BackgroundLetterAvatarProps {
  name: string;
}

type Props = BackgroundLetterAvatarProps & AvatarProps;

const BackgroundLetterAvatar = ({ name, ...props }: Props) => {
  const avatarProps = useMemo(() => stringAvatar(name), [name]);
  const sx = useMemo(() => ({ ...avatarProps.sx, ...props.sx }), [avatarProps.sx, props.sx]);

  return <Avatar {...avatarProps} {...props} sx={sx} />;
};

export default BackgroundLetterAvatar;

import React from 'react';
import { ProfileHomeProps } from 'types';
import FullBleedCTA from 'components/organisms/FullBleedCTA/FullBleedCTA';
import HeaderAndContent from 'components/templates/HeaderAndContent/HeaderAndContent';
import { useParams } from 'react-router-dom';
import useQueryUpcomingAppointments from 'hooks/queries/useQueryUpcomingAppointments/useQueryUpcomingAppointments';
import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';
import StudentInfoCard from 'components/molecules/StudentInfoCard/StudentInfoCard';
import { useAccountContext } from 'contexts/AccountContext/AccountContext';
import useQueryGetCallToActions from 'hooks/queries/useQueryGetCallToActions/useQueryGetCallToActions';
import { CallToActionPositions, InstructorProfile } from 'generated/graphql';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';

function getInstructorName(instructorProfile: InstructorProfile | undefined) {
  return instructorProfile?.user ? `${instructorProfile.user.firstName} ${instructorProfile.user.lastName}` : '';
}

const StudentHome = ({ user }: ProfileHomeProps) => {
  const { id = '-1' } = useParams();
  const studentId = parseInt(id, 10);
  const { data, loading } = useQueryUpcomingAppointments({ variables: { studentId } });
  const { students } = useAccountContext();
  const { data: callToActionData } = useQueryGetCallToActions();

  const student = students?.find((student) => student.id === studentId);
  const firstName = student?.user?.firstName || user.firstName;

  return (
    <HeaderAndContent text={`Student Home - ${firstName}`}>
      <>
        {callToActionData?.callToActions
          ?.filter((cta) => cta.position === CallToActionPositions.Top)
          .map((cta) => (
            <React.Fragment key={`cta-${cta.id}`}>
              <FullBleedCTA callToAction={cta} />
            </React.Fragment>
          ))}
        {loading && <LoadingIndicator />}
        {data &&
          data.getAppointmentsByStudentId?.map((appointment) => {
            const instructorName = appointment?.substituteProfile
              ? getInstructorName(appointment.substituteProfile as InstructorProfile)
              : getInstructorName(appointment.instructorProfile as InstructorProfile);

            return (
              <StudentInfoCard
                key={appointment.id}
                firstName={`Lesson from ${DateTime.fromISO(
                  appointment.startDate
                ).toLocaleString()} to ${DateTime.fromISO(appointment.endDate).toLocaleString()}`}
                lastName={`with Instructor ${instructorName}`}
              />
            );
          })}
        <Box sx={{ minHeight: '20rem' }}>
          Welcome to your dashboard. In the future, this is where you will see upcoming lessons and your lesson notes.
        </Box>
        {callToActionData?.callToActions
          ?.filter((cta) => cta.position === CallToActionPositions.Bottom)
          .map((cta) => (
            <React.Fragment key={`cta-${cta.id}`}>
              <FullBleedCTA callToAction={cta} onBottom />
            </React.Fragment>
          ))}
      </>
    </HeaderAndContent>
  );
};

export default StudentHome;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { EnrollmentFromQuery } from 'types';
import CheckboxList from 'components/molecules/CheckboxList/CheckboxList';
import UseMutationSendEnrollmentConfirmations from 'hooks/mutations/useMutationSendEnrollmentConfirmations/useMutationSendEnrollmentConfirmations';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

function sortEnrollmentsByAccount(enrollments: EnrollmentFromQuery[]): EnrollmentFromQuery[] {
  const sorted = enrollments.slice();
  sorted.sort((a, b) => {
    const accountA = a.studentProfile.account?.accountName || '';
    const accountB = b.studentProfile.account?.accountName || '';

    if (accountA !== accountB) {
      return accountA < accountB ? -1 : 1;
    }

    const nameA = a.studentProfile.user?.firstName ?? '';
    const nameB = b.studentProfile.user?.firstName ?? '';

    if (nameA !== nameB) {
      return nameA < nameB ? -1 : 1;
    }

    return a.id - b.id;
  });

  return sorted;
}

function labelAccessor(enrollment: EnrollmentFromQuery): string {
  return `${enrollment.studentProfile.user?.firstName} ${enrollment.studentProfile.user?.lastName} - ${enrollment.skill?.skillName}`;
}

function idAccessor(enrollment: EnrollmentFromQuery): number {
  return enrollment.id;
}

interface EnrollmentConfirmationsDialogProps {
  enrollments: EnrollmentFromQuery[];
  open: boolean;
  onClose?: () => void;
}

type Props = EnrollmentConfirmationsDialogProps;

const EnrollmentConfirmationsDialog = ({ open, onClose, enrollments }: Props) => {
  const [selectedEnrollments, setSelectedEnrollments] = useState<EnrollmentFromQuery[]>([]);
  const [sendEnrollmentConfirmations, { loading }] = UseMutationSendEnrollmentConfirmations();

  const sortedEnrollments = useMemo(() => sortEnrollmentsByAccount(enrollments), [enrollments]);

  const handleSubmit = useCallback(async () => {
    await sendEnrollmentConfirmations({
      variables: {
        input: {
          enrollmentIds: selectedEnrollments.map((x) => x.id),
        },
      },
    });
    onClose && onClose();
  }, [onClose, selectedEnrollments, sendEnrollmentConfirmations]);

  useEffect(() => {
    // Filter selectedEnrollments based on available options.
    setSelectedEnrollments((prev) => prev.filter((selected) => sortedEnrollments.some((x) => x.id === selected.id)));
  }, [sortedEnrollments]);

  const allChecked = useMemo(
    () => selectedEnrollments.length === sortedEnrollments.length,
    [sortedEnrollments.length, selectedEnrollments.length]
  );

  const handleCheckAllToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedEnrollments(sortedEnrollments);
      } else {
        setSelectedEnrollments([]);
      }
    },
    [sortedEnrollments]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle id='scroll-dialog-title'>Send Schedule Confirmations</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
          Select the scheduled lessons that you&apos;d like to send confirmation emails for:
        </DialogContentText>
        <FormGroup>
          <FormControlLabel
            label='Check/Uncheck All'
            control={<Checkbox checked={allChecked} onChange={handleCheckAllToggle} />}
          />
        </FormGroup>
        <CheckboxList<EnrollmentFromQuery, number>
          value={selectedEnrollments}
          onChange={setSelectedEnrollments}
          options={sortedEnrollments}
          labelAccessor={labelAccessor}
          idAccessor={idAccessor}
          keyAccessor={(enrollment) => enrollment.id}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} size='small' color='secondary' disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} size='small' color='secondary' disabled={loading}>
          {loading ? 'Sending...' : 'Send Confirmations'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EnrollmentConfirmationsDialog;

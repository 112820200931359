import { Account, Role, User } from '../../generated/graphql';

export enum ProfileType {
  AccountHolder = 'account_holder',
  Instructor = 'instructor',
  Manager = 'manager',
  Student = 'student',
}

export class UserRoleUtils {
  static getRoleProfile<T extends Role>(user: User, profileType: ProfileType): T | undefined {
    return user.roles?.find((role): role is T => role.profileType === profileType);
  }

  static isAccountHolderAndStudent<T extends Role>(user: User): boolean {
    return !!(
      user.roles?.find((role): role is T => role.profileType === ProfileType.Student) &&
      user.roles?.find((role): role is T => role.profileType === ProfileType.AccountHolder)
    );
  }

  static getFullName(user?: Pick<User, 'firstName' | 'lastName'> | null) {
    if (!user) {
      return '';
    }

    return `${user.firstName} ${user.lastName}`.trim();
  }

  static getAccountHolderRoles(account?: Account | null) {
    if (!account?.roles?.length) {
      return [];
    }

    return account.roles.filter((x) => x.profileType === ProfileType.AccountHolder);
  }
}

import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { dayOfWeekIndexToStringMap, sortAvailabilityItems } from 'utils/availability/availabilityUtils';
import MenuItem from '@mui/material/MenuItem';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import { getDateTime } from 'utils/dates';
import { EnrollmentFromQuery, PartialBy } from 'types';
import useQueryInstructorProfiles from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import { DateTime } from 'luxon';
import {
  AvailabilityItem,
  AvailabilityItemInput,
  EnrollmentStatus,
  LessonType,
  PaymentType,
  SchedulingPreference,
  SchedulingPriority,
  SiblingSchedulePreference,
  UpdateEnrollmentDocument,
  UpdateEnrollmentMutation,
  UpdateEnrollmentMutationVariables,
} from 'generated/graphql';
import useMutationScheduleEnrollment from 'hooks/useMutationScheduleEnrollment/useMutationScheduleEnrollment';
import useQueryGetLocations from 'hooks/queries/useQueryGetLocations/useQueryGetLocations';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import theme from 'theme';
import { useMutation } from '@apollo/client';
import AvailabilityInput from 'components/organisms/AvailabilityInput/AvailabilityInput';
import useQueryGetPackages, { PackageData } from 'hooks/queries/useQueryGetPackages/useQueryGetPackages';

interface EnrollmentDrawerEditProps {
  enrollment: EnrollmentFromQuery;
  onSubmit?: () => void;
  onCancel?: () => void;
}

type Props = EnrollmentDrawerEditProps;

const StyledRadio = styled(Radio)`
  color: ${theme.colors.enrollText};

  &.Mui-checked {
    color: ${theme.colors.cobalt};
  }
`;

const EnrollmentDrawerEdit = ({ enrollment, onCancel, onSubmit }: Props) => {
  const instructors = useQueryInstructorProfiles();
  const { data } = useQueryGetLocations();
  const { data: packageData } = useQueryGetPackages();
  const instructorsData = instructors.data?.instructorProfiles;
  const startTime = enrollment.startTime ? DateTime.fromFormat(enrollment.startTime, 'TT').toJSDate() : null;
  const date = enrollment.dayOfWeek || null;
  const instructor = enrollment.scheduledInstructorId;
  const initialStatus = enrollment.schedulingStatus || EnrollmentStatus.New;
  const initialLessonType = enrollment.lessonType || LessonType.InPerson;
  const initialNotes = enrollment?.notes || '';
  const initialPaymentPreference = enrollment?.paymentTypePreference || PaymentType.PayInFull;
  const initialSchedulingPriority = SchedulingPriority.NoPreference;
  const initialSiblingConsideration = SiblingSchedulePreference.NoPreference;
  const initialSchedulingConsideration = SchedulingPreference.NoPreference;
  const initialPackage = packageData?.getPackages?.find((pk) => pk.id === enrollment.packageId) || null;

  const [studentAvailability, setStudentAvailability] = useState<PartialBy<AvailabilityItem, 'id'>[]>(
    sortAvailabilityItems(enrollment.availability?.availabilityItems || [])
  );
  const [lessonStartTime, setLessonStartTime] = useState<Date | null>(startTime);
  const [dayOfWeek] = useState<number | null>(date);
  const [status] = useState<EnrollmentStatus>(initialStatus);
  const [lessonType, setLessonType] = useState<LessonType>(initialLessonType);
  const [notes] = useState<string | null>(initialNotes);
  const [packages, setPackages] = useState<PackageData | null>(initialPackage);
  const [paymentType, setPaymentType] = useState<PaymentType>(initialPaymentPreference);
  const [schedulingPriority, setSchedulingPriority] = useState<SchedulingPriority>(initialSchedulingPriority);
  const [siblingConsideration, setSiblingConsideration] =
    useState<SiblingSchedulePreference>(initialSiblingConsideration);
  const [schedulingConsideration, setSchedulingConsideration] =
    useState<SchedulingPreference>(initialSchedulingConsideration);
  const [isReturningStudent, setIsReturningStudent] = useState<boolean>(enrollment.isReturningStudent);
  const [enrollmentStartDate] = useState<string | null>('');
  const [pricePerItemInCents] = useState<number>(enrollment?.pricePerItemInCents);
  const [initialFeeInCents] = useState<number>(enrollment?.initialFeeInCents);
  const [scheduleEnrollment] = useMutationScheduleEnrollment();
  const [updateEnrollment] = useMutation<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables>(
    UpdateEnrollmentDocument
  );

  const allLocations = data?.getLocations || [];

  const formik = useFormik({
    initialValues: {
      lessonStartTime,
      dayOfWeek,
      instructor,
      status,
      lessonType,
      notes,
      paymentType,
      preferredInstructorId: enrollment.preferredInstructorId,
      siblingConsideration,
      schedulingConsideration,
      schedulingPriority,
      phoneNumber: enrollment.phoneNumber,
      billingStatus: enrollment.billingStatus,
      preferredSiteIds: enrollment.preferredSites?.map((x) => x.id),
      scheduledLocationId: enrollment.scheduledLocationId,
      packageId: enrollment.packageId,
      isReturningStudent: enrollment?.isReturningStudent,
      userDeclineReason: '',
      enrollmentStartDate: enrollmentStartDate,
      pricePerItemInCents: pricePerItemInCents,
      initialFeeInCents: initialFeeInCents,
    },
    onSubmit: async (values) => {
      if (!enrollment) {
        return null;
      }

      let scheduledLocationId;
      if (lessonStartTime && values.dayOfWeek != null && values.instructor) {
        const updatedSchedule = await scheduleEnrollment({
          variables: {
            input: {
              id: enrollment.id,
              dayOfWeek: dayOfWeekIndexToStringMap[values.dayOfWeek],
              startTime: getDateTime(lessonStartTime).toFormat('TT'),
              instructorId: values.instructor,
            },
          },
        });
        if (updatedSchedule.data?.scheduleEnrollment.scheduledLocationId) {
          scheduledLocationId = updatedSchedule.data?.scheduleEnrollment.scheduledLocationId;
        }
      }

      await updateEnrollment({
        variables: {
          updateEnrollmentInput: {
            id: enrollment.id,
            enrollment: {
              preferredInstructorId: values.preferredInstructorId
                ? parseInt(values.preferredInstructorId.toString(), 10)
                : null,
              lessonType: lessonType,
              siblingConsideration: siblingConsideration,
              schedulingConsideration: schedulingConsideration,
              schedulingPriority: schedulingPriority,
              notes: values.notes,
              phoneNumber: values.phoneNumber,
              billingStatus: values.billingStatus,
              paymentTypePreference: paymentType,
              availabilityItems: studentAvailability
                .map<AvailabilityItemInput | undefined>(({ id, dayOfWeek, startTime, endTime }) =>
                  startTime && endTime
                    ? {
                        id,
                        dayOfWeek,
                        startTime,
                        endTime,
                      }
                    : undefined
                )
                .filter((x): x is AvailabilityItemInput => !!x),
              scheduledLocationId: values.scheduledLocationId ?? scheduledLocationId,
              packageId: packages?.id,
            },
          },
        },
      });

      onSubmit && onSubmit();
    },
  });

  const handleCancelClick = () => {
    onCancel && onCancel();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={2} direction='column'>
          <Box>
            <InputLabel>Day of Week</InputLabel>
            <Select
              sx={{ textTransform: 'capitalize' }}
              fullWidth
              value={formik.values.dayOfWeek}
              id='dayOfWeek'
              name='dayOfWeek'
              onChange={formik.handleChange}
              size='small'
            >
              {Object.entries(dayOfWeekIndexToStringMap).map(([dayIndex, dayOfWeek]) => {
                return (
                  <MenuItem key={dayIndex} value={dayIndex} sx={{ textTransform: 'capitalize' }}>
                    {dayOfWeek.toLowerCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <TimePicker
            label='Lesson Start Time'
            value={lessonStartTime}
            onChange={(value) => setLessonStartTime(value)}
            slotProps={{ textField: { size: 'small' } }}
            minutesStep={15}
            skipDisabled
          ></TimePicker>
        </Stack>
      </LocalizationProvider>
      <Box mt={2}>
        <InputLabel>Scheduled Teacher</InputLabel>
        <Select
          fullWidth
          value={formik.values.instructor}
          id='instructor'
          name='instructor'
          onChange={formik.handleChange}
          size='small'
        >
          {instructorsData?.map((instructor) => {
            return (
              <MenuItem key={instructor.profileId} value={instructor.profileId}>
                {instructor.user?.firstName} {instructor.user?.lastName}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box mt={2}>
        <InputLabel>Package</InputLabel>
        <Select
          fullWidth
          value={packages?.id}
          id='package'
          name='package'
          onChange={(event) => {
            const value = event.target.value;
            const selectedPackage = packageData?.getPackages?.find((pk) => pk.id === value) || null;

            setPackages(selectedPackage);
            return event;
          }}
          size='small'
        >
          {packageData?.getPackages?.map((pk) => {
            return (
              <MenuItem key={pk.id} value={pk.id}>
                {pk.name}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box mt={2}>
        <InputLabel>Lesson Type</InputLabel>
        <Select
          fullWidth
          value={lessonType}
          id='lessonType'
          name='lessonType'
          onChange={(event) => {
            const value = event.target.value;
            const lsType =
              LessonType.InPerson === value
                ? LessonType.InPerson
                : LessonType.Hybrid === value
                ? LessonType.Hybrid
                : LessonType.Virtual;

            setLessonType(lsType);
            return event;
          }}
          size='small'
        >
          <MenuItem key={LessonType.Hybrid} value={LessonType.Hybrid}>
            {LessonType.Hybrid}
          </MenuItem>
          <MenuItem key={LessonType.Virtual} value={LessonType.Virtual}>
            {LessonType.Virtual}
          </MenuItem>
          <MenuItem key={LessonType.InPerson} value={LessonType.InPerson}>
            {LessonType.InPerson}
          </MenuItem>
        </Select>
      </Box>
      <Box mt={2}>
        <Checkbox checked={isReturningStudent} onChange={({ target: { checked } }) => setIsReturningStudent(checked)} />
        Is Returning Student
      </Box>
      <Box mt={2}>
        <InputLabel>User Decline Reason</InputLabel>
        <TextField
          sx={{ my: 2 }}
          fullWidth
          value={formik.values.userDeclineReason}
          id='userDeclineReason'
          name='userDeclineReason'
          onChange={formik.handleChange}
        />
      </Box>
      <Box mt={2}>
        <InputLabel>Price Per Item In Cents</InputLabel>
        <TextField
          sx={{ my: 2 }}
          fullWidth
          value={formik.values.pricePerItemInCents}
          id='pricePerItemInCents'
          name='pricePerItemInCents'
          onChange={formik.handleChange}
        />
      </Box>
      <Box mt={2}>
        <InputLabel>Initial Fee In Cents</InputLabel>
        <TextField
          sx={{ my: 2 }}
          fullWidth
          value={formik.values.initialFeeInCents}
          id='initialFeeInCents'
          name='initialFeeInCents'
          onChange={formik.handleChange}
        />
      </Box>
      {/*<Box mt={2}>*/}
      {/*  <DatePicker*/}
      {/*    label='Enrollment Start Date'*/}
      {/*    onChange={(value) => setEnrollmentStartDate(value)}*/}
      {/*    value={formik.values.enrollmentStartDate}*/}
      {/*  />*/}
      {/*</Box>*/}
      <Box mt={2}>
        <InputLabel>Location</InputLabel>
        <Select
          fullWidth
          value={formik.values.scheduledLocationId}
          id='scheduledLocationId'
          name='scheduledLocationId'
          onChange={formik.handleChange}
          size='small'
        >
          {allLocations?.map((location) => {
            return (
              <MenuItem key={location.id} value={location.id}>
                {location.address || location.locationIdentifier} -{' '}
                {location?.site?.siteName || location?.site?.siteIdentifier}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box mt={2}>
        <InputLabel>Preferred Teacher</InputLabel>
        <Select
          fullWidth
          value={formik.values.preferredInstructorId}
          id='preferredInstructorId'
          name='preferredInstructorId'
          onChange={formik.handleChange}
          size='small'
        >
          {instructorsData?.map((instructor) => {
            return (
              <MenuItem key={instructor.profileId} value={instructor.profileId}>
                {instructor.user?.firstName} {instructor.user?.lastName}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <Box mt={2}>
        <InputLabel>Student Scheduling Preference</InputLabel>
        <Select
          fullWidth
          id='StudentScheudlingPreference'
          name='StudentScheudlingPreference'
          value={schedulingConsideration}
          onChange={(event) => {
            const value = event.target.value as SchedulingPreference;
            setSchedulingConsideration(value);
            return event;
          }}
          onClick={(event) => {
            if ((event.target as HTMLOptionElement).selected === true) {
              setSchedulingConsideration(SchedulingPreference.NoPreference);
            }
          }}
          size='small'
        >
          <MenuItem key={SchedulingPreference.ScheduleTogether} value={SchedulingPreference.ScheduleTogether}>
            Scheduled back-to-back, when possible
          </MenuItem>
          <MenuItem key={SchedulingPreference.ScheduleApart} value={SchedulingPreference.ScheduleApart}>
            Scheduled on separate days, when possible
          </MenuItem>
        </Select>
      </Box>
      <Box mt={2}>
        <InputLabel>Schedule with siblings/family?</InputLabel>
        <RadioGroup
          row
          aria-labelledby='schedule-sibling-together-label'
          name='schedule-sibling'
          onChange={(e) => {
            const value =
              e.target.value === SiblingSchedulePreference.ScheduleTogether
                ? SiblingSchedulePreference.ScheduleTogether
                : SiblingSchedulePreference.NoPreference;
            setSiblingConsideration(value);
            return e;
          }}
          value={siblingConsideration || SiblingSchedulePreference.NoPreference}
          defaultChecked={false}
          defaultValue={SiblingSchedulePreference.NoPreference}
        >
          <FormControlLabel value={SiblingSchedulePreference.NoPreference} control={<StyledRadio />} label={'No'} />
          <FormControlLabel
            value={SiblingSchedulePreference.ScheduleTogether}
            control={<StyledRadio />}
            label={'Yes'}
          />
        </RadioGroup>
      </Box>
      <Box mt={2}>
        <InputLabel>What&apos;s the higher priority?</InputLabel>
        <Select
          fullWidth
          id='schedulePriorityLabel'
          name='schedulePriorityLabel'
          value={schedulingPriority}
          onChange={(event) => {
            const value = event.target.value as SchedulingPriority;
            setSchedulingPriority(value);
            return event;
          }}
          onClick={(event) => {
            if ((event.target as HTMLOptionElement).selected === true) {
              setSchedulingPriority(SchedulingPriority.NoPreference);
            }
          }}
          size='small'
        >
          <MenuItem key={SchedulingPriority.WithFamily} value={SchedulingPriority.WithFamily}>
            Scheduling with siblings/family
          </MenuItem>
          <MenuItem key={SchedulingPriority.WithPreferredInstructor} value={SchedulingPriority.WithPreferredInstructor}>
            Staying with the indicated teacher
          </MenuItem>
        </Select>
      </Box>
      <Box mt={2}>
        <InputLabel>Payment Preference</InputLabel>
        <Select
          fullWidth
          id='paymentPreference'
          name='paymentPreference'
          value={paymentType}
          onChange={(event) => {
            const val = event?.target.value;
            const paymentType =
              val === PaymentType.PayInFull
                ? PaymentType.PayInFull
                : val === PaymentType.Monthly
                ? PaymentType.Monthly
                : val === PaymentType.Zelle
                ? PaymentType.Zelle
                : PaymentType.PayByCheck;
            setPaymentType(paymentType);

            return event;
          }}
          size='small'
        >
          <MenuItem key={PaymentType.PayInFull} value={PaymentType.PayInFull}>
            Pay-in-full with card (3% discount)
          </MenuItem>
          <MenuItem key={PaymentType.Monthly} value={PaymentType.Monthly}>
            Monthly payments with card
          </MenuItem>
          <MenuItem key={PaymentType.Zelle} value={PaymentType.Zelle}>
            I prefer to pay with Zelle (pay-in-full only)
          </MenuItem>
          <MenuItem key={PaymentType.PayByCheck} value={PaymentType.PayByCheck}>
            Pay by Check (pay-in-full only)
          </MenuItem>
        </Select>
      </Box>
      <Box mt={2}>
        <InputLabel>Notes</InputLabel>
        <TextField
          sx={{ my: 2 }}
          fullWidth
          value={formik.values.notes}
          id='notes'
          name='notes'
          onChange={formik.handleChange}
          multiline
        />
      </Box>
      <Box mt={2}>
        <InputLabel sx={{ mb: 2 }}>Student Availability</InputLabel>
        <AvailabilityInput value={studentAvailability} onChange={setStudentAvailability} />
      </Box>
      <Stack spacing={2} direction='row' justifyContent='center' mt={3}>
        <Button color='secondary' variant='outlined' size='medium' onClick={handleCancelClick} fullWidth>
          Cancel
        </Button>
        <Button
          color='secondary'
          type='submit'
          startIcon={formik.isSubmitting && <CircularProgress color='inherit' size='1em' />}
          variant='contained'
          size='medium'
          disabled={formik.isSubmitting}
          fullWidth
        >
          Save
        </Button>
      </Stack>
    </form>
  );
};

export default EnrollmentDrawerEdit;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useAccountContext } from 'contexts/AccountContext/AccountContext';

const EnrollButton = styled(Button)`
  margin: 0 1rem;
`;

const EnrollUserButton = () => {
  const navigate = useNavigate();
  const { students } = useAccountContext();
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const handleEnrollClick = () => navigate(`/enroll`);
  // check if it is homepage or student profile page
  const isHomepageOrStudentProfilePage =
    location.pathname && (location.pathname === '/' || location.pathname.includes('student-home'));

  const handleClose = () => setOpen(false);

  useEffect(() => {
    setOpen(true);
  }, [students]);

  return isHomepageOrStudentProfilePage ? (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle fontSize='1.3rem'>{'Hello Ready to Enroll?'}</DialogTitle>
        <DialogActions sx={{ justifyContent: 'space-evenly' }}>
          <Button color='inherit' onClick={handleClose}>
            Not yet
          </Button>
          <Button color='inherit' variant='outlined' onClick={() => handleEnrollClick()}>
            Enroll
          </Button>
        </DialogActions>
      </Dialog>
      <EnrollButton color='inherit' variant='outlined' onClick={handleEnrollClick}>
        Enroll
      </EnrollButton>
    </>
  ) : null;
};

export default EnrollUserButton;

import { HardSoftScore } from 'generated/graphql';

const SCORE_PATTERN = /(-?\d+)(init|hard|soft|medium)\/?/gi;

enum ScoreType {
  init = 'init',
  hard = 'hard',
  soft = 'soft',
}

// const ScoreType = 'init' | 'hard' | 'soft';

const scoreTypePropertyMap: Record<string, 'initScore' | 'hardScore' | 'softScore'> = {
  [ScoreType.init]: 'initScore',
  [ScoreType.hard]: 'hardScore',
  [ScoreType.soft]: 'softScore',
};

export function parseSolverScoreText(scoreText?: string | null): HardSoftScore | undefined {
  if (!scoreText) {
    return;
  }

  const matches = Array.from(scoreText.matchAll(SCORE_PATTERN));

  if (matches.length === 0) {
    throw new Error(`Failed to parse score text: "${scoreText}"`);
  }

  const score: HardSoftScore = {
    hardScore: 0,
    softScore: 0,
  };

  for (const match of matches) {
    const [, valueRaw, type] = match;

    const propertyName = scoreTypePropertyMap[type];
    if (!propertyName) {
      throw new Error(`Unsupported score type: ${type}`);
    }

    score[propertyName] = parseInt(valueRaw, 10);
  }

  return score;
}

import { useSchedulingConsiderationsContext } from 'contexts/SchedulingConsiderationsContext/SchedulingConsiderationsContext';
import { useMemo } from 'react';

function useMultipleEnrollmentsDetail(studentProfileId?: number) {
  const { multipleEnrollmentsMap } = useSchedulingConsiderationsContext();

  return useMemo(
    () => (studentProfileId ? multipleEnrollmentsMap?.[studentProfileId] : undefined),
    [multipleEnrollmentsMap, studentProfileId]
  );
}

export default useMultipleEnrollmentsDetail;

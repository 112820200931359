import React, { useEffect, useState } from 'react';
import { EventProps } from 'react-big-calendar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { ScheduleEvent } from 'components/organisms/SemesterCalendar/SemesterCalendar';
import { AppointmentEvent } from 'types';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { AppointmentDrawerView } from 'pages/Admin/ManagerCalendar/components/AppointmentDrawerView/AppointmentDrawerView';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import useQueryInstructorProfiles from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import * as yup from 'yup';
import useMutationUpdateAppointment from 'hooks/mutations/useMutationUpdateAppointment/useMutationUpdateAppointment';
import { AppointmentState, LessonType } from 'generated/graphql';

const EDIT = 'EDIT';
const VIEW = 'VIEW';

function isAppointmentEvent(event: ScheduleEvent | AppointmentEvent): event is AppointmentEvent {
  return 'appointment' in event;
}

type Props = EventProps<ScheduleEvent>;

const AppointmentEventCard = ({ title, event }: Props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mode, setMode] = useState(VIEW);

  const appointment = isAppointmentEvent(event) ? event.appointment : undefined;
  const instructors = useQueryInstructorProfiles();
  const instructorsData = instructors.data?.instructorProfiles;
  const startTime = appointment?.startDate || '';
  const endTime = appointment?.endDate || '';
  const date = appointment?.startDate || '';
  const instructor = appointment?.instructorProfileId;
  const substitute = appointment?.substituteProfileId;
  const initialStatus = appointment?.status || AppointmentState.Unconfirmed;
  const initialLessonType = appointment?.lessonType || LessonType.Hybrid;

  const [lessonStartTime, setLessonStartTime] = useState<string | null>(startTime);
  const [lessonEndTime, setLessonEndTime] = useState<string | null>(endTime);
  const [appointmentDate, setAppointmentDate] = useState<string | null>(date);
  const [status, setStatus] = useState<AppointmentState>(initialStatus);
  const [lessonType, setLessonType] = useState<LessonType>(initialLessonType);
  const [updateAppointment] = useMutationUpdateAppointment();

  useEffect(() => {
    setLessonStartTime(startTime);
    setLessonEndTime(endTime);
    setAppointmentDate(date);
    setStatus(initialStatus);
    setLessonType(initialLessonType);
  }, [date, endTime, startTime, initialLessonType, initialStatus]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);

    if (!drawerOpen) {
      setMode(VIEW);
    }
  };

  const handleCancelClick = () => {
    setMode(VIEW);
  };

  const validationSchema = yup.object({
    lessonStartTime: yup.string().required('Start Time is required'),
    lessonEndTime: yup.string().required('End Time is required'),
    appointmentDate: yup.string().required('Appointment Date is required'),
    instructor: yup.number().required('Instructor is required'),
  });

  const formik = useFormik({
    initialValues: {
      lessonStartTime,
      lessonEndTime,
      appointmentDate,
      instructor,
      substitute,
      status,
      lessonType,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!lessonStartTime || !lessonEndTime || !appointmentDate) {
        return null;
      }

      const updatedStartTime = new Date(lessonStartTime);
      const updatedEndTime = new Date(lessonEndTime);
      const startDate = new Date(appointmentDate);
      const endDate = new Date(appointmentDate);

      startDate.setHours(updatedStartTime.getHours());
      startDate.setMinutes(updatedStartTime.getMinutes());
      startDate.setSeconds(0);

      endDate.setHours(updatedEndTime.getHours());
      endDate.setMinutes(updatedEndTime.getMinutes());
      endDate.setSeconds(0);

      if (!appointment) {
        return null;
      }

      await updateAppointment({
        variables: {
          input: {
            id: appointment.id,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            instructorProfileId: values.instructor,
            substituteProfileId: values.substitute,
            status: values.status,
            lessonType: values.lessonType,
          },
        },
      });

      toggleDrawer();
    },
  });

  const appointmentStates = [
    AppointmentState.Canceled,
    AppointmentState.Missed,
    AppointmentState.Confirmed,
    AppointmentState.Unconfirmed,
    AppointmentState.Fulfilled,
  ];

  const lessonTypes = [LessonType.Hybrid, LessonType.InPerson, LessonType.Virtual];
  const lessonTypeLabels = {
    [LessonType.Hybrid]: 'Hybrid',
    [LessonType.InPerson]: 'In Person',
    [LessonType.Virtual]: 'Virtual',
  };

  return (
    <>
      <Box role='button' tabIndex={0} onClick={() => toggleDrawer()} sx={{ height: '100%' }}>
        <Stack direction='column' sx={{ height: '100%' }}>
          <Typography variant='body2' fontWeight='bold' noWrap>
            {title}
          </Typography>
        </Stack>
      </Box>
      <Drawer
        anchor={'right'}
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          zIndex: 1202,
        }}
      >
        <Box m={3} sx={{ maxWidth: '25vw' }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant='h1'>
                {appointment?.studentProfile?.user?.firstName} {appointment?.studentProfile?.user?.lastName}
              </Typography>
              <Typography variant='h5'>
                {appointment?.appointmentType?.name}: {appointment?.enrollment?.skill?.skillName}
              </Typography>
            </Grid>
            <Grid item xs={4} textAlign='right'>
              {mode === VIEW && (
                <Button color='primary' variant='contained' size='small' onClick={() => setMode(EDIT)}>
                  Edit
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider light sx={{ mt: 1 }} />
            </Grid>
          </Grid>
          {mode === VIEW && <AppointmentDrawerView appointment={appointment} />}
          {mode === EDIT && (
            <form onSubmit={formik.handleSubmit}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={2} direction='column'>
                  <TimePicker
                    label='Lesson Start Time'
                    value={lessonStartTime}
                    onChange={(value) => setLessonStartTime(value)}
                  ></TimePicker>
                  <TimePicker
                    label='Lesson End Time'
                    value={lessonEndTime}
                    onChange={(value) => setLessonEndTime(value)}
                  ></TimePicker>
                  <DatePicker
                    label='Appointment Date'
                    onChange={(value) => setAppointmentDate(value)}
                    value={appointmentDate}
                  ></DatePicker>
                </Stack>
              </LocalizationProvider>
              <Box mt={2}>
                <InputLabel>Teacher</InputLabel>
                <Select
                  sx={{ my: 2 }}
                  fullWidth
                  value={formik.values.instructor}
                  id='instructor'
                  name='instructor'
                  onChange={formik.handleChange}
                >
                  {instructorsData?.map((instructor) => {
                    return (
                      <MenuItem key={instructor.profileId} value={instructor.profileId}>
                        {instructor.user?.firstName} {instructor.user?.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box mt={2}>
                <InputLabel>Substitute</InputLabel>
                <Select
                  sx={{ my: 2 }}
                  fullWidth
                  value={formik.values.substitute}
                  id='substitute'
                  name='substitute'
                  onChange={formik.handleChange}
                >
                  {instructorsData?.map((instructor) => {
                    return (
                      <MenuItem key={instructor.profileId} value={instructor.profileId}>
                        {instructor.user?.firstName} {instructor.user?.lastName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box mt={2}>
                <InputLabel>Status</InputLabel>
                <Select
                  sx={{ my: 2, textTransform: 'capitalize' }}
                  fullWidth
                  value={formik.values.status}
                  id='status'
                  name='status'
                  onChange={formik.handleChange}
                >
                  {appointmentStates.map((state) => {
                    return (
                      <MenuItem key={state} value={state} sx={{ textTransform: 'capitalize' }}>
                        {state.toLowerCase()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box mt={2}>
                <InputLabel>Lesson Type</InputLabel>
                <Select
                  fullWidth
                  value={formik.values.lessonType}
                  id='lessonType'
                  name='lessonType'
                  onChange={formik.handleChange}
                >
                  {lessonTypes.map((lessonType) => {
                    return (
                      <MenuItem key={lessonType} value={lessonType}>
                        {lessonTypeLabels[lessonType]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Stack spacing={2} direction='row' justifyContent='center' mt={2}>
                <Button
                  color='primary'
                  type='submit'
                  startIcon={formik.isSubmitting && <CircularProgress color='inherit' size='1em' />}
                  variant='contained'
                  size='medium'
                  disabled={formik.isSubmitting}
                  fullWidth
                >
                  Save
                </Button>
                <Button color='secondary' variant='contained' size='medium' onClick={handleCancelClick} fullWidth>
                  Cancel
                </Button>
              </Stack>
            </form>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default React.memo(AppointmentEventCard);

import React, { useCallback } from 'react';
import { EnrollmentFromQuery } from 'types';
import { createMachine } from 'xstate';
import { useMachine } from '@xstate/react';
import { EnrollmentStatus } from 'generated/graphql';
import Stack, { StackProps } from '@mui/material/Stack';
import Button, { ButtonProps } from '@mui/material/Button';
import useMutationUpdateEnrollmentStatus from 'hooks/mutations/useMutationUpdateEnrollmentStatus/useMutationUpdateEnrollmentStatus';
import Typography from '@mui/material/Typography';

enum StateEvent {
  SCHEDULE = 'SCHEDULE',
  AUTO_SCHEDULE = 'AUTO_SCHEDULE',
  DEQUEUE_CONFIRMATION = 'DEQUEUE_CONFIRMATION',
  QUEUE_CONFIRMATION = 'QUEUE_CONFIRMATION',
  SEND_CONFIRMATION = 'SEND_CONFIRMATION',
  REACTIVATE = 'REACTIVATE',
  CONFIRM = 'CONFIRM',
  DECLINE = 'DECLINE',
  APPROVE = 'APPROVE',
  DEACTIVATE = 'DEACTIVATE',
  EXPIRE = 'EXPIRE',
}

const eventLabels: Record<StateEvent, React.ReactNode> = {
  [StateEvent.SCHEDULE]: 'Schedule',
  [StateEvent.AUTO_SCHEDULE]: 'Auto-Schedule',
  [StateEvent.DEQUEUE_CONFIRMATION]: 'Remove from Confirmation Queue',
  [StateEvent.QUEUE_CONFIRMATION]: 'Queue for Confirmation',
  [StateEvent.SEND_CONFIRMATION]: 'Send Confirmation',
  [StateEvent.REACTIVATE]: 'Reactivate',
  [StateEvent.CONFIRM]: 'Confirm on behalf of user',
  [StateEvent.DECLINE]: 'Decline on behalf of user',
  [StateEvent.APPROVE]: 'Approve and finalize',
  [StateEvent.DEACTIVATE]: 'Deactivate',
  [StateEvent.EXPIRE]: 'Expire',
};

const createStateMachine = (initial: string | number | symbol | undefined) => {
  return createMachine<unknown, { type: string; status?: EnrollmentStatus }>({
    predictableActionArguments: true,
    id: 'enrollmentStatus',
    initial,
    states: {
      [EnrollmentStatus.New]: {
        on: {
          SCHEDULE: { target: EnrollmentStatus.Scheduled },
          AUTO_SCHEDULE: { target: EnrollmentStatus.AutoScheduled },
        },
      },
      [EnrollmentStatus.AutoScheduled]: {
        on: {
          SCHEDULE: { target: EnrollmentStatus.Scheduled },
          QUEUE_CONFIRMATION: { target: EnrollmentStatus.ReadyForConfirmation },
        },
      },
      [EnrollmentStatus.Scheduled]: {
        on: {
          QUEUE_CONFIRMATION: { target: EnrollmentStatus.ReadyForConfirmation },
        },
      },
      [EnrollmentStatus.Expired]: {},
      [EnrollmentStatus.Inactive]: {
        on: {
          REACTIVATE: { target: EnrollmentStatus.New },
        },
      },
      [EnrollmentStatus.ReadyForConfirmation]: {
        on: {
          DEQUEUE_CONFIRMATION: { target: EnrollmentStatus.Scheduled },
          SEND_CONFIRMATION: { target: EnrollmentStatus.PendingConfirmation },
        },
      },
      [EnrollmentStatus.PendingConfirmation]: {
        on: {
          CONFIRM: { target: EnrollmentStatus.UserConfirmed },
          DECLINE: { target: EnrollmentStatus.UserDeclined },
        },
      },
      [EnrollmentStatus.UserConfirmed]: {
        on: {
          APPROVE: { target: EnrollmentStatus.Approved },
          QUEUE_CONFIRMATION: { target: EnrollmentStatus.ReadyForConfirmation },
        },
      },
      [EnrollmentStatus.UserDeclined]: {
        on: {
          QUEUE_CONFIRMATION: { target: EnrollmentStatus.ReadyForConfirmation },
        },
      },
      [EnrollmentStatus.Approved]: {
        on: {
          DEACTIVATE: undefined,
        },
      },
    },
    on: {
      EXPIRE: { target: EnrollmentStatus.Expired },
      DEACTIVATE: { target: EnrollmentStatus.Inactive },
    },
  });
};

const stateButtons = [
  StateEvent.SCHEDULE,
  StateEvent.DEQUEUE_CONFIRMATION,
  StateEvent.QUEUE_CONFIRMATION,
  StateEvent.SEND_CONFIRMATION,
  StateEvent.CONFIRM,
  StateEvent.DECLINE,
  StateEvent.APPROVE,
];

const eventsToUpdateStatus = [
  StateEvent.SCHEDULE,
  StateEvent.DEQUEUE_CONFIRMATION,
  StateEvent.QUEUE_CONFIRMATION,
  StateEvent.DECLINE,
  StateEvent.APPROVE,
];

interface EnrollmentStatusControlProps {
  enrollment: EnrollmentFromQuery;
  stackProps?: StackProps;
  buttonProps?: ButtonProps;
  label?: React.ReactNode;
}

type Props = EnrollmentStatusControlProps;

const EnrollmentStatusControl = ({ enrollment, buttonProps, stackProps, label }: Props) => {
  const [current, send] = useMachine(() => createStateMachine(enrollment.schedulingStatus));
  const [updateEnrollmentStatus, { loading }] = useMutationUpdateEnrollmentStatus();

  const handleEventClick = useCallback(
    (event: StateEvent) => {
      const result = send(event);

      // TODO: Implement logic for sending confirmations,
      // and confirming / declining on behalf of users,
      // and final approval.

      // Check if this event falls within the list of events that we
      // allow updating the status of.
      if (eventsToUpdateStatus.includes(event)) {
        return updateEnrollmentStatus({
          variables: {
            input: {
              id: enrollment.id,
              status: result.value as EnrollmentStatus,
            },
          },
        });
      }
    },
    [enrollment.id, send, updateEnrollmentStatus]
  );

  return (
    <Stack direction='row' spacing={2} alignItems='center' my={1} {...stackProps}>
      {label && <Typography variant='overline'>{label}</Typography>}
      {stateButtons
        .filter((x) => current.can({ type: x }))
        .map((event) => (
          <Button
            key={event}
            onClick={() => handleEventClick(event)}
            size='small'
            variant='outlined'
            disabled={loading}
            {...buttonProps}
          >
            {eventLabels[event]}
          </Button>
        ))}
    </Stack>
  );
};

export default EnrollmentStatusControl;

import { User } from '@auth0/auth0-react';

export enum AuthRole {
  LyriqAdmin = 'Lyriq-Admin',
}

const ROLE_CLAIM_NAME = 'https://lyriqmusic.com/roles';

export class AuthUtils {
  static getAuthRoles(user: User): string[] {
    return user[ROLE_CLAIM_NAME] || [];
  }

  static hasAuthRole(user: User, role: AuthRole): boolean {
    return AuthUtils.getAuthRoles(user).includes(role);
  }
}

import React from 'react';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import Typography from '@mui/material/Typography';

const Container = styled('div')`
  height: 8rem;
  background-color: ${theme.colors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
`;

type Props = {
  text: string;
};

const ContentHeader: React.FC<Props> = ({ text }) => {
  return (
    <Container>
      <Typography variant={'h1'}>{text}</Typography>
    </Container>
  );
};

export default ContentHeader;

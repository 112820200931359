import { MutationTuple, useMutation } from '@apollo/client';
import {
  UnscheduleEnrollmentDocument,
  UnscheduleEnrollmentMutation,
  UnscheduleEnrollmentMutationVariables,
} from 'generated/graphql';

function useMutationUncheduleEnrollment(): MutationTuple<
  UnscheduleEnrollmentMutation,
  UnscheduleEnrollmentMutationVariables
> {
  return useMutation<UnscheduleEnrollmentMutation, UnscheduleEnrollmentMutationVariables>(UnscheduleEnrollmentDocument);
}

export default useMutationUncheduleEnrollment;

import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Enrollment from 'components/templates/Enrollment/Enrollment';
import ContentHeaderWrapper from 'components/atoms/ContentHeaderWrapper/ContentHeaderWrapper';
import Typography from '@mui/material/Typography';
import { useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import ContentBodyWrapper from 'components/atoms/ContentBodyWrapper/ContentBodyWrapper';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import { LessonFormat, SignupType } from 'utils/signupDetails';
import MenuItem from '@mui/material/MenuItem';
import useQueryGetLocations from 'hooks/queries/useQueryGetLocations/useQueryGetLocations';
import EnrollmentHeader from 'pages/Enrollment/components/EnrollmentHeader/EnrollmentHeader';
import { Site } from 'generated/graphql';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const DEFAULT_LESSON_LENGTH = 30;
const LESSON_LENGTH_OPTIONS = [30, 45, 60];

const StyledFormControl = styled(FormControl)`
  margin: 0.75rem 0.5rem;
`;

const StyledRadio = styled(Radio)`
  color: ${theme.colors.enrollText};

  &.Mui-checked {
    color: ${theme.colors.cobalt};
  }
`;

const Line = styled('hr')`
  background: linear-gradient(99deg, #e499ff 0%, #8572bb 100%);
  height: 0.188rem;
  flex-shrink: 0;
`;

const SignDetailContainer = styled('div')`
  margin-bottom: 2rem;
`;

const SignUpDetails = () => {
  const navigate = useNavigate();
  const { scheduleId, studentId } = useParams();
  const { selectLessonSignupDetails, studentEnrollments } = useEnrollmentContext();
  const studentID = (studentId && parseInt(studentId, 10)) || 0;
  const { data } = useQueryGetLocations();
  const locations = data?.getLocations?.filter((loc) => loc.site !== null).map((loc) => loc.site) || [];
  const sitesAvailable = locations.filter(
    (ele, ind): ele is Site => !!ele && ind === locations.findIndex((elem) => elem?.id === ele?.id)
  );

  const enrollment = useMemo(
    () => studentEnrollments.find((x) => x.student.id === studentID),
    [studentID, studentEnrollments]
  );
  const { selectedLessonsSignupDetails, selectedLessons } = enrollment || {};

  const isInPerson = (lessonId: number) => {
    const lessonDetails = selectedLessonsSignupDetails?.find((sd) => sd.id === lessonId);

    return lessonDetails && lessonDetails.lessonFormat === LessonFormat.InPerson;
  };

  useEffect(() => {
    if (!studentId) {
      navigate(`/enroll/${scheduleId}`);
    }
  }, [studentId, scheduleId, navigate]);

  useEffect(() => {
    if (!selectedLessons?.length) {
      navigate(`/enroll/${scheduleId}/student/${studentId}/lesson-selection`);
    }
  }, [navigate, scheduleId, selectedLessons?.length, studentId]);

  const handleSignTypeChange = useCallback(
    (lessonId: number, signupType: string, lessonFormat: string, sessionLength: string, sites?: Site[]) => {
      const selectedDetails = selectedLessonsSignupDetails?.find((sd) => sd.id === lessonId);
      const sType = signupType !== '' ? signupType : selectedDetails?.signupType || SignupType.FullSemester;
      const sFormat = lessonFormat !== '' ? lessonFormat : selectedDetails?.lessonFormat || LessonFormat.InPerson;
      const sSession =
        sessionLength !== '' ? Number(sessionLength) : selectedDetails?.sessionLength || DEFAULT_LESSON_LENGTH;
      const sSites = sites != null ? sites : selectedDetails?.sites || sitesAvailable;
      selectLessonSignupDetails(studentID, {
        id: lessonId,
        signupType: sType,
        lessonFormat: sFormat,
        sessionLength: sSession,
        sites: sSites,
      });
    },
    [selectLessonSignupDetails, selectedLessonsSignupDetails, sitesAvailable, studentID]
  );

  // Set default values if lesson details doesn't exist yet.
  useEffect(() => {
    selectedLessons?.forEach((lesson) => {
      const selectedLessonDetails = selectedLessonsSignupDetails?.find((ss) => ss.id === lesson.id);
      if (!selectedLessonDetails) {
        handleSignTypeChange(lesson.id, '', '', '', sitesAvailable);
      }
    });
  }, [handleSignTypeChange, selectedLessons, selectedLessonsSignupDetails, sitesAvailable]);

  const handleLocationChange = (siteId: number, lessonId: number) => {
    const selectedLessonDetails = selectedLessonsSignupDetails?.find((ss) => ss.id === lessonId);
    const selectedSites = selectedLessonDetails?.sites?.map((x) => x.id) || [];
    const siteIds = new Set(selectedSites);
    if (siteIds.has(siteId)) {
      siteIds.delete(siteId);
    } else {
      siteIds.add(siteId);
    }
    const sites = sitesAvailable.filter((x) => siteIds.has(x.id));
    handleSignTypeChange(lessonId, '', '', '', sites);
  };

  // If sites is empty for lessons, default it to the sites available.
  useEffect(() => {
    if (selectedLessonsSignupDetails && sitesAvailable.length) {
      selectedLessonsSignupDetails.forEach((lessonDetails) => {
        if (!lessonDetails.sites?.length) {
          handleSignTypeChange(lessonDetails.id, '', '', '', sitesAvailable);
        }
      });
    }
  }, [handleSignTypeChange, selectedLessonsSignupDetails, sitesAvailable]);

  const getSiteLabel = (site?: Site) => {
    if (!site) {
      return '';
    }

    return `${site.siteName} ${site.addressDisplayText ? `(${site.addressDisplayText})` : ''}`.trim();
  };

  return (
    <Enrollment
      back={{ path: `/enroll/${scheduleId}/student/${studentId}/lesson-selection`, description: 'Lesson Selection' }}
      next={{ path: `/enroll/${scheduleId}/student/${studentId}/teacher-selection`, description: 'Teacher Selection' }}
    >
      <EnrollmentHeader currentStudent={enrollment?.student} />
      <ContentHeaderWrapper>
        <Typography variant={'h1'}>Sign-up details</Typography>
      </ContentHeaderWrapper>
      <ContentBodyWrapper>
        {selectedLessons?.map((lesson) => {
          const selectedLessonDetails = selectedLessonsSignupDetails?.find((ss) => ss.id === lesson.id);
          const selectedSites = selectedLessonDetails?.sites?.map((x) => x.id) || [];
          return (
            <SignDetailContainer key={lesson.id}>
              <Typography key={lesson.id} variant={'h5'}>
                {lesson.skillName} {lesson.skillCategory}
              </Typography>
              <Line />
              <StyledFormControl>
                <Typography id='signup-type-group-label'>Enrollment Duration</Typography>
                <RadioGroup
                  row
                  aria-labelledby='signup-type-group-label'
                  name='signup-type'
                  onChange={(e) => {
                    handleSignTypeChange(lesson.id, e.target.value, '', '');
                    return e;
                  }}
                  defaultChecked={true}
                  defaultValue={selectedLessonDetails?.signupType || SignupType.FullSemester}
                >
                  <FormControlLabel
                    value={SignupType.FullSemester}
                    control={<StyledRadio />}
                    label={SignupType.FullSemester}
                  />
                  <FormControlLabel
                    value={SignupType.The4WeekTrial}
                    control={<StyledRadio />}
                    label={SignupType.The4WeekTrial}
                  />
                </RadioGroup>
              </StyledFormControl>
              <StyledFormControl>
                <Typography id='lesson-type-group-label'>Lesson Type</Typography>
                <RadioGroup
                  row
                  aria-labelledby='lesson-type-group-label'
                  name='lesson-type'
                  onChange={(e) => {
                    handleSignTypeChange(lesson.id, '', e.target.value, '');
                    return e;
                  }}
                  defaultChecked={true}
                  defaultValue={selectedLessonDetails?.lessonFormat || LessonFormat.InPerson}
                >
                  <FormControlLabel
                    value={LessonFormat.InPerson}
                    control={<StyledRadio />}
                    label={LessonFormat.InPerson}
                  />
                  <FormControlLabel
                    value={LessonFormat.Virtual}
                    control={<StyledRadio />}
                    label={LessonFormat.Virtual}
                  />
                </RadioGroup>
              </StyledFormControl>
              <StyledFormControl>
                <Typography id='lesson-length-group-label'>Lesson Length</Typography>
                <RadioGroup
                  row
                  aria-labelledby='lesson-length-group-label'
                  name='lesson-length'
                  onChange={(e) => {
                    handleSignTypeChange(lesson.id, '', '', e.target.value);
                    return e;
                  }}
                  defaultChecked={true}
                  defaultValue={(selectedLessonDetails?.sessionLength || DEFAULT_LESSON_LENGTH).toString()}
                >
                  {LESSON_LENGTH_OPTIONS.map((lessonLength) => (
                    <FormControlLabel
                      key={`lesson-length-${lessonLength}`}
                      value={lessonLength.toString()}
                      control={<StyledRadio />}
                      label={`${lessonLength} min`}
                    />
                  ))}
                </RadioGroup>
              </StyledFormControl>
              {sitesAvailable.length > 0 && isInPerson(lesson.id) && (
                <StyledFormControl>
                  <Typography>Locations: </Typography>
                  {sitesAvailable.map((site) => (
                    <MenuItem
                      key={site.id}
                      value={site.id}
                      sx={{ paddingLeft: 0 }}
                      onClick={() => handleLocationChange(site.id, lesson.id)}
                    >
                      <Checkbox checked={selectedSites.indexOf(site.id) > -1} />
                      <ListItemText primary={getSiteLabel(site)} />
                    </MenuItem>
                  ))}
                </StyledFormControl>
              )}
            </SignDetailContainer>
          );
        })}
      </ContentBodyWrapper>
    </Enrollment>
  );
};

export default SignUpDetails;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, Typography } from '@mui/material';
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';

type NavigationButton = {
  label?: string;
  path: string;
  description?: string;
  disabled?: boolean;
};

interface NavigationProps {
  back?: NavigationButton;
  next?: NavigationButton;
}

export type Props = NavigationProps;

type NavigationStyleProps = {
  nextOnly: boolean;
};

type ButtonReverseStyleProps = {
  reverse: boolean;
};

type ButtonCursorStyleProps = {
  disabled: boolean;
};

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content: ${({ nextOnly }: NavigationStyleProps) => (nextOnly ? 'flex-end' : 'space-between')};
`;

const NavigationButtonWrapper = styled('div')`
  display: flex;
  ${({ reverse }: ButtonReverseStyleProps) => reverse && 'flex-direction: row-reverse;'}
  min-height: 3.1rem;
  cursor: ${({ disabled }: ButtonCursorStyleProps) => (disabled ? 'not-allowed' : 'pointer')};
  margin: 0 0.5rem;
`;

const ButtonLabelWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: ${({ reverse }: ButtonReverseStyleProps) => (reverse ? 'baseline' : 'end')};
`;

const Navigation = ({ back, next }: Props) => {
  const navigate = useNavigate();

  const NavigationButton = ({ path, label, description, disabled }: NavigationButton, reverse?: boolean) => (
    <NavigationButtonWrapper reverse={reverse} disabled={disabled} onClick={() => !disabled && navigate(path)}>
      <ButtonLabelWrapper reverse={reverse}>
        <Typography
          variant='h3'
          fontSize='1.2rem'
          color={disabled ? 'text.secondary' : ''}
          sx={{ minWidth: 0, padding: 0, marginTop: 0.2, transition: 'color 0.5s' }}
        >
          {label}
        </Typography>
        {!!description && (
          <Typography
            color={disabled ? 'text.secondary' : 'primary'}
            fontSize='0.7rem'
            sx={{ transition: 'color 0.5s' }}
          >
            {description}
          </Typography>
        )}
      </ButtonLabelWrapper>
      {reverse ? (
        <ArrowBackIosIcon
          color={disabled ? 'disabled' : 'secondary'}
          sx={{ marginTop: 0.3, marginRight: -0.4, fontSize: 24, transition: 'color 0.5s' }}
        />
      ) : (
        <ArrowForwardIosIcon
          color={disabled ? 'disabled' : 'secondary'}
          sx={{ marginTop: 0.3, marginLeft: 0.3, fontSize: 24, transition: 'color 0.5s' }}
        />
      )}
    </NavigationButtonWrapper>
  );

  return (
    <NavigationWrapper nextOnly={!!next && !back}>
      {!!back && NavigationButton({ label: 'Back', ...back }, true)}
      {!!next && NavigationButton({ label: 'Next', ...next })}
    </NavigationWrapper>
  );
};

export default Navigation;

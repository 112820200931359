import React from 'react';
import Typography from '@mui/material/Typography';
import ContentHeaderWrapper from 'components/atoms/ContentHeaderWrapper/ContentHeaderWrapper';
import ContentBodyWrapper from 'components/atoms/ContentBodyWrapper/ContentBodyWrapper';
import Enrollment from 'components/templates/Enrollment/Enrollment';

const Confirmation = () => {
  return (
    <Enrollment>
      <ContentHeaderWrapper>
        <Typography variant='h1'>Thank you for registering!</Typography>
      </ContentHeaderWrapper>
      <ContentBodyWrapper>
        <Typography>
          A confirmation email will be sent within the next few minutes with your registration details.
        </Typography>
        <Typography>&nbsp;</Typography>
        <Typography>
          Lyriq will do all it can to accommodate your preferences and will contact you with your students’ personalized
          semester. At that point you can confirm your registration.
        </Typography>
      </ContentBodyWrapper>
    </Enrollment>
  );
};

export default Confirmation;

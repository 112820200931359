import { LessonType, Site } from 'generated/graphql';

export enum LessonFormat {
  Hybrid = 'Hybrid',
  InPerson = 'In-Person',
  Virtual = 'Virtual',
}

export enum SignupType {
  FullSemester = 'Full Semester',
  The4WeekTrial = '4-Week Trial',
}

export type SkillSignupDetails = {
  id: number;
  lessonFormat: string;
  sessionLength?: number;
  signupType: string;
  location?: string;
  sites?: Site[];
};

export const lessonFormatMap: Record<LessonFormat, LessonType> = {
  [LessonFormat.Hybrid]: LessonType.Hybrid,
  [LessonFormat.InPerson]: LessonType.InPerson,
  [LessonFormat.Virtual]: LessonType.Virtual,
};

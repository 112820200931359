import { DateTime, DateTimeOptions } from 'luxon';

export function getFirstDayOfWeek(d: Date) {
  // 👇️ clone date object, so we don't mutate it
  const date = new Date(d);
  const day = date.getDay(); // 👉️ get day of week

  // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
  const diff = date.getDate() - day;

  return new Date(date.setDate(diff));
}

export type DateValue = DateTime | Date | string | number;

export function getDateTime(date?: DateValue, opts?: DateTimeOptions): DateTime {
  if (date instanceof DateTime) {
    return date;
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date, opts);
  }

  if (typeof date === 'number') {
    return DateTime.fromMillis(date, opts);
  }

  if (date) {
    return DateTime.fromISO(date, opts);
  }

  return DateTime.now();
}

export function parseTimeString(time: string, format = 'TT') {
  return DateTime.fromFormat(time, format);
}

export function fixRRuleDates(dates: Date[]): Date[] {
  return dates.map(
    (d) =>
      new Date(
        d.getUTCFullYear(),
        d.getUTCMonth(),
        d.getUTCDate(),
        d.getUTCHours(),
        d.getUTCMinutes(),
        d.getUTCSeconds(),
        d.getUTCMilliseconds()
      )
  );
}

import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';

const BoxShadowContainer = styled(Container)`
  border-radius: 1.25rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0rem 0.3rem 0.9rem;
  background-color: #fff;
  padding: 2rem;
  margin-bottom: 1rem;
`;

export default BoxShadowContainer;

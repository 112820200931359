export type StudentColor = { primary: string; secondary: string };

export type StudentColorName = 'gold' | 'green' | 'blue' | 'purple' | 'red';

export const STUDENT_COLORS: Record<StudentColorName, StudentColor> = {
  gold: {
    primary: '#ffa23f',
    secondary: '#e3843f',
  },
  green: {
    primary: '#70ea92',
    secondary: '#1ecc92',
  },
  blue: {
    primary: '#70cdea',
    secondary: '#40afea',
  },
  purple: {
    primary: '#e499ff',
    secondary: '#b469ff',
  },
  red: {
    primary: '#ff7e7e',
    secondary: '#cf607e',
  },
};

export function getPrimaryStudentColors() {
  return Object.entries(STUDENT_COLORS).map(([, colorSet]) => colorSet.primary);
}

export function getRandomStudentColor(): StudentColor {
  const colors = Object.keys(STUDENT_COLORS) as StudentColorName[];
  return STUDENT_COLORS[colors[Math.floor(Math.random() * colors.length)]];
}

export function getSecondaryColor(primary: string) {
  const colorEntry = Object.entries(STUDENT_COLORS).find(([, colors]) => colors.primary === primary.toLowerCase());
  if (colorEntry) {
    return colorEntry[1].secondary;
  }

  return '#b499ff';
}

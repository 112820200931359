import React, { useState } from 'react';
import { DateEnums, Dates, useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import { StudentProfile } from 'generated/graphql';
// @ts-ignore
import BaseTimeRangeSlider from 'react-time-range-slider';
import theme from 'theme';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getDateTime } from 'utils/dates';
import { DateTime } from 'luxon';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const OptionButtons = styled(Box)`
  display: flex;
  justify-content: end;
`;

const weekdayMinMax = { start: '2:00 PM', end: '10:00 PM' };
const weekendMinMax = { start: '9:00 AM', end: '4:00 PM' };

const weekdayDefaultValue = { start: '2:00 PM', end: '10:00 PM' };
const weekendDefaultValue = { start: '9:00 AM', end: '4:00 PM' };

const defaultValueForDay: Record<DateEnums, { start: string; end: string }> = {
  Monday: weekdayDefaultValue,
  Tuesday: weekdayDefaultValue,
  Wednesday: weekdayDefaultValue,
  Thursday: weekdayDefaultValue,
  Friday: weekdayDefaultValue,
  Saturday: weekendDefaultValue,
  Sunday: weekendDefaultValue,
};

const minMaxForDay: Record<DateEnums, { start: string; end: string }> = {
  Monday: weekdayMinMax,
  Tuesday: weekdayMinMax,
  Wednesday: weekdayMinMax,
  Thursday: weekdayMinMax,
  Friday: weekdayMinMax,
  Saturday: weekendMinMax,
  Sunday: weekendMinMax,
};

interface TimeRangeSliderProps {
  selectedAvailabilities: Dates;
  date: DateEnums;
  currentStudent?: StudentProfile;
  id: string;
}

type Props = TimeRangeSliderProps;

const TimeRangeSlider = ({ currentStudent, date, id, selectedAvailabilities }: Props) => {
  const { selectAvailability, removeAvailability } = useEnrollmentContext();
  const selectedTime = selectedAvailabilities[date] || [];
  const currentAvailability = selectedTime.find((x) => x.id === id);
  const currentStartTime =
    currentAvailability && currentAvailability.start?.time ? currentAvailability.start.time : undefined;
  const currentEndTime =
    currentAvailability && currentAvailability.end?.time ? currentAvailability.end.time : undefined;

  const [timeValue, setTimeValue] = useState(() => {
    if (currentStartTime && currentEndTime) {
      return {
        start: getDateTime(currentStartTime).toFormat('h:mm a'),
        end: getDateTime(currentEndTime).toFormat('h:mm a'),
      };
    }

    return defaultValueForDay[date];
  });

  const timeChangeHandler = (time: { start: string; end: string }) => {
    setTimeValue(time);
  };

  const changeCompleteHandler = () => {
    selectAvailability(
      Number(currentStudent?.id),
      date,
      {
        ...currentAvailability,
        id,
        start: {
          time: DateTime.fromFormat(timeValue.start, 'h:mm a').toJSDate(),
          editable: false,
        },
        end: {
          time: DateTime.fromFormat(timeValue.end, 'h:mm a').toJSDate(),
          editable: false,
        },
      },
      id
    );
  };

  const minMax = minMaxForDay[date];

  return (
    <Box px={2}>
      <Typography variant='h3' color={theme.colors.rackley} textAlign='center'>
        {timeValue.start} - {timeValue.end}
      </Typography>
      <BaseTimeRangeSlider
        disabled={false}
        draggableTrack={false}
        format={12}
        minValue={minMax.start}
        maxValue={minMax.end}
        name={'time_range'}
        onChangeComplete={changeCompleteHandler}
        onChange={timeChangeHandler}
        step={15}
        value={timeValue}
      />
      {id != null && (
        <OptionButtons>
          <Button color='error' size='small' onClick={() => removeAvailability(Number(currentStudent?.id), date, id)}>
            Remove
          </Button>
        </OptionButtons>
      )}
    </Box>
  );
};

export default TimeRangeSlider;

import React, { useEffect, useMemo } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useAdminContext } from 'contexts/AdminContext/AdminContext';
import useQueryAdminSchedules from 'hooks/queries/useQueryAdminSchedules/useQueryAdminSchedules';
import { ScheduleState } from 'generated/graphql';

const ACTIVE_UPCOMING_SCHEDULE_STATUSES = [
  ScheduleState.Active,
  ScheduleState.Scheduling,
  ScheduleState.Open,
  ScheduleState.Closed,
];

const UPCOMING_STATUSES = [ScheduleState.Scheduling, ScheduleState.Open, ScheduleState.Closed];

interface ScheduleSelectFieldProps {
  id?: string;
  label?: string;
  placeholder?: string;
}

type Props = ScheduleSelectFieldProps;

const ScheduleSelectField = ({ id = 'schedule-select', label = 'Change schedule', placeholder }: Props) => {
  const { selectedSchedule, setSelectedSchedule } = useAdminContext();

  const { data } = useQueryAdminSchedules({
    variables: {
      input: {
        filters: {
          status: ACTIVE_UPCOMING_SCHEDULE_STATUSES,
        },
      },
    },
  });

  useEffect(() => {
    if (!selectedSchedule && data?.adminSchedules?.results && data.adminSchedules.results.length > 0) {
      // Find first upcoming schedule, fallback to first.
      const schedules = data.adminSchedules.results;
      const firstUpcoming = schedules.find((schedule) => UPCOMING_STATUSES.includes(schedule.status));
      setSelectedSchedule(firstUpcoming || schedules[0]);
    }
  }, [data?.adminSchedules?.results, selectedSchedule, setSelectedSchedule]);

  const schedules = useMemo(() => data?.adminSchedules?.results, [data?.adminSchedules?.results]);

  const handleScheduleChange = (event: SelectChangeEvent) => {
    const schedule = schedules?.find((schedule) => schedule.id.toString() === event.target.value.toString());
    if (schedule) {
      setSelectedSchedule(schedule);
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`${id}-label`}>{label || 'Select schedule'}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={selectedSchedule?.id.toString() || ''}
        label={label || 'Select schedule'}
        onChange={handleScheduleChange}
        placeholder={placeholder}
      >
        {schedules &&
          schedules.map((schedule) => (
            <MenuItem key={schedule.id} value={schedule.id}>
              {schedule.name} ({schedule.status})
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ScheduleSelectField;

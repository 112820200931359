import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetScheduleSolverStatusDocument,
  GetScheduleSolverStatusQuery,
  GetScheduleSolverStatusQueryVariables,
} from 'generated/graphql';

type Data = GetScheduleSolverStatusQuery;
type Variables = GetScheduleSolverStatusQueryVariables;

function useQueryScheduleSolverStatus(
  input?: Variables['input'],
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(GetScheduleSolverStatusDocument, {
    ...options,
    skip: !input,
    variables: input
      ? {
          input,
        }
      : undefined,
  });
}

export default useQueryScheduleSolverStatus;

import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { EnrollmentFromQuery } from 'types';
import { Box } from '@mui/material';

interface EnrollmentSearchProps {
  enrollments: EnrollmentFromQuery[];
  onChange?: (newValue?: EnrollmentFromQuery | null) => void;
  selectedEnrollment?: EnrollmentFromQuery;
}

type Props = EnrollmentSearchProps;

const EnrollmentSearch = ({ enrollments, selectedEnrollment, onChange }: Props) => {
  const [enrollmentDrawer, setEnrollmentDrawer] = useState<EnrollmentFromQuery | undefined>(selectedEnrollment);

  useEffect(() => {
    setEnrollmentDrawer(selectedEnrollment);
  }, [selectedEnrollment]);

  return (
    <Autocomplete
      id='enrollmentSearch'
      options={enrollments}
      getOptionLabel={(enrollment) =>
        `${enrollment.studentProfile.user?.firstName ? `${enrollment.studentProfile.user?.firstName} ` : ''}${
          enrollment.studentProfile.user?.lastName
        }`
      }
      onChange={(event, value) => {
        onChange && onChange(value);
      }}
      renderOption={(props, enrollment) => (
        <Box component='li' {...props} key={`${enrollment.id}`}>
          {`${enrollment.studentProfile.user?.firstName} ${enrollment.studentProfile.user?.lastName} - ${
            enrollment.skill?.skillName || enrollment.package?.name
          }${enrollment.skill?.skillName ? ` - ${enrollment.package?.appointmentType?.name}` : ''}`}
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label='Student search' placeholder='' />}
      value={enrollmentDrawer}
      sx={{ backgroundColor: 'white' }}
    />
  );
};

export default EnrollmentSearch;

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { EnrollmentFromQuery } from 'types';
import { BillingState, EnrollmentStatus } from 'generated/graphql';
import { EnrollmentDrawerView } from './EnrollmentDrawerView/EnrollmentDrawerView';
import useMutationUnscheduleEnrollment from 'hooks/useMutationUnscheduleEnrollment/useMutationUnscheduleEnrollment';
import EnrollmentLockToggle from 'components/molecules/EnrollmentLockToggle/EnrollmentLockToggle';
import EnrollmentStatusControl from 'components/molecules/EnrollmentStatusControl/EnrollmentStatusControl';
import EnrollmentDrawerEdit from 'pages/Admin/NewScheduler/components/EnrollmentDrawer/EnrollmentDrawerEdit/EnrollmentDrawerEdit';
import env from 'config/env';

const EDIT = 'EDIT';
const VIEW = 'VIEW';

function canBeUnscheduled(enrollment: EnrollmentFromQuery) {
  return (
    ![EnrollmentStatus.PendingConfirmation, EnrollmentStatus.UserConfirmed, EnrollmentStatus.Approved].includes(
      enrollment.schedulingStatus
    ) &&
    enrollment.billingStatus !== BillingState.Paid &&
    enrollment.dayOfWeek != null &&
    enrollment.startTime != null &&
    !!enrollment.scheduledInstructorId
  );
}

function isLocked(enrollment: EnrollmentFromQuery) {
  const lockedStatuses = [
    EnrollmentStatus.PendingConfirmation,
    EnrollmentStatus.UserConfirmed,
    EnrollmentStatus.Approved,
  ];

  return lockedStatuses.includes(enrollment.schedulingStatus);
}

interface EnrollmentDrawerProps {
  drawerOpen: boolean;
  onClose: () => void;
  enrollment: EnrollmentFromQuery;
  switchEnrollment?: (enrollment: EnrollmentFromQuery) => void;
}

type Props = EnrollmentDrawerProps;

const EnrollmentDrawer = ({ drawerOpen, onClose, enrollment, switchEnrollment }: Props) => {
  const [mode, setMode] = useState(VIEW);

  const [unscheduleEnrollment] = useMutationUnscheduleEnrollment();
  const handleUnscheduleClick = async () =>
    await unscheduleEnrollment({
      variables: {
        input: {
          id: enrollment.id,
        },
      },
    });

  const enrollmentCanBeUnscheduled = canBeUnscheduled(enrollment);
  const editDisabled = isLocked(enrollment) || enrollment.scheduleLocked;

  const handleCancelClick = () => {
    setMode(VIEW);
  };

  const handleSubmit = () => {
    setMode(VIEW);
  };

  return (
    <Drawer
      anchor={'right'}
      open={drawerOpen}
      onClose={onClose}
      sx={{
        zIndex: 1202,
      }}
    >
      <Box m={3} sx={{ maxWidth: '25vw' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h1'>
              {enrollment.studentProfile?.user?.firstName} {enrollment?.studentProfile?.user?.lastName}
            </Typography>
            <Typography variant='h5'>
              {enrollment.package?.name}: {enrollment?.skill?.skillName}
            </Typography>
          </Grid>
        </Grid>
        <Divider light sx={{ my: 2 }} />
        <Stack direction='row' spacing={1.3}>
          {mode === VIEW && (
            <Button color='info' variant='outlined' size='small' onClick={() => setMode(EDIT)} disabled={editDisabled}>
              Edit
            </Button>
          )}
          {enrollmentCanBeUnscheduled && (
            <Button
              color='info'
              variant='outlined'
              size='small'
              onClick={handleUnscheduleClick}
              disabled={editDisabled}
            >
              Unschedule
            </Button>
          )}
          <Box sx={{ minWidth: '7rem' }}>
            <EnrollmentLockToggle enrollment={enrollment} buttonProps={{ fullWidth: true, color: 'info' }} />
          </Box>
          <Button
            color='info'
            variant='outlined'
            size='small'
            href={`${env.admin.baseUrl}/resources/Enrollment/records/${enrollment.id}/edit`}
            target='_blank'
          >
            Open in Admin
          </Button>
        </Stack>
        {enrollment.schedulingStatus !== EnrollmentStatus.New && (
          <>
            <Box sx={{ mt: 1.5 }}>
              <Typography variant='overline'>Actions</Typography>
            </Box>
            <EnrollmentStatusControl
              enrollment={enrollment}
              buttonProps={{ color: 'info' }}
              stackProps={{ spacing: 1.3, my: 0 }}
            />
          </>
        )}
        <Divider light sx={{ my: 2 }} />
        {mode === VIEW && <EnrollmentDrawerView enrollment={enrollment} switchEnrollment={switchEnrollment} />}
        {mode === EDIT && (
          <EnrollmentDrawerEdit enrollment={enrollment} onCancel={handleCancelClick} onSubmit={handleSubmit} />
        )}
      </Box>
    </Drawer>
  );
};

export default EnrollmentDrawer;

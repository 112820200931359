import { ScheduleEvent } from 'components/organisms/SemesterCalendar/SemesterCalendar';

const eventPropGetter = (event: ScheduleEvent, start: Date, end: Date, isSelected: boolean) => {
  return {
    ...(isSelected && {
      style: {
        backgroundColor: '#000',
      },
    }),
    ...(event.color && {
      style: {
        color: '#1e1d1d',
        backgroundColor: '#ededed',
        borderColor: event.color,
        borderLeft: `4px solid ${event.color}`,
      },
    }),
    ...(event.isDraggable ? { className: 'isDraggable' } : { className: 'nonDraggable' }),
  };
};

export default eventPropGetter;

import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled, css } from '@mui/material/styles';

// Extend Switch styles to make the switch element the same color on both sides.
const StyledSwitch = styled(Switch)(
  ({ theme, color = 'primary' }) => css`
    & .MuiSwitch-switchBase {
      color: ${theme.palette[color !== 'default' ? color : 'primary'].main};
    }

    & .MuiSwitch-track {
      background-color: ${theme.palette[color !== 'default' ? color : 'primary'].main};
    }
  `
);

interface PaymentTypeSwitchProps {
  leftLabel: React.ReactNode;
  rightLabel: React.ReactNode;
}

type Props = PaymentTypeSwitchProps & SwitchProps;

const SwitchWithDoubleLabel = ({ leftLabel, rightLabel, ...props }: Props) => {
  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <Typography sx={{ position: 'relative' }}>{leftLabel}</Typography>
      <StyledSwitch {...props} />
      <Typography sx={{ position: 'relative' }}>{rightLabel}</Typography>
    </Stack>
  );
};

export default SwitchWithDoubleLabel;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthRole } from '../../utils/user/AuthUtils';
import useHasAuthRole from '../../hooks/useHasAuthRole/useHasAuthRole';

interface RequireAuthProps {
  requiredAuthRole?: AuthRole;
  children: JSX.Element;
}

type Props = RequireAuthProps;

const RequireAuth = ({ requiredAuthRole, children }: Props) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  const hasRequiredAuthRole = useHasAuthRole(requiredAuthRole);

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  if (requiredAuthRole && !hasRequiredAuthRole) {
    return <span>Not authorized to view this page</span>;
  }

  return children;
};

export default RequireAuth;

import React from 'react';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useUserContext } from 'contexts/UserContext/UserContext';
import Logo from 'components/atoms/Logo/Logo';
import LogoutButton from 'components/atoms/LogoutButton/LogoutButton';

const RegisterPage = () => {
  const {
    postLogin: { data },
  } = useUserContext();

  return (
    <Container maxWidth='sm'>
      <Box sx={{ py: 3 }}>
        <Stack direction='column' spacing={4} alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
          <Logo size='large' />
          <Outlet />
          <Box sx={{ width: '100%' }}>
            <Divider />
            <Stack direction='row' spacing={2} alignItems='center' justifyContent='center' sx={{ py: 1 }}>
              <Typography sx={{ color: 'text.secondary' }}>
                Not {data?.onboardingState.identityUser?.name || 'you'}?
              </Typography>
              <LogoutButton variant='text' color='secondary' size='small'>
                Log Out
              </LogoutButton>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};

export default RegisterPage;

import React, { useEffect, useMemo } from 'react';
import {
  Calendar as BaseCalendar,
  CalendarProps as BaseCalendarProps,
  Components,
  Event,
  luxonLocalizer,
  Views,
} from 'react-big-calendar';
import { DateTime, Settings, Zone } from 'luxon';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { css, styled } from '@mui/material/styles';
import env from 'config/env';
import CustomToolbar from './CustomToolbar/CustomToolbar';

const defaultTZ = env.datetime.defaultTimeZone;
const browserDefaultTZ = DateTime.local().zoneName;

const DnDCalendar = withDragAndDrop<Event>(BaseCalendar);

const StyledDnDCalendar = styled(DnDCalendar)(
  ({ theme }) => css`
    .rbc-time-view {
      background-color: white;
    }

    .rbc-time-gutter {
      .rbc-time-slot {
        background-color: initial !important;
        border: initial !important;
      }
    }

    .rbc-event-label {
      font-family: ${theme.typography.fontFamily};
      font-size: 0.625rem;
    }
  `
);

const calendarStyle = { height: '100vh' };

interface CalendarProps<TEvent extends object = Event, TResource extends object = object>
  extends Partial<BaseCalendarProps<TEvent, TResource>>,
    Partial<withDragAndDropProps<TEvent, TResource>> {
  timezone?: Zone | string;
}

type Props<TEvent extends object = Event, TResource extends object = object> = CalendarProps<TEvent, TResource>;

const Calendar = <TEvent extends object = Event, TResource extends object = object>({
  timezone = defaultTZ,
  components,
  ...props
}: Props<TEvent, TResource>) => {
  const { getNow, localizer, scrollToTime } = useMemo(() => {
    Settings.defaultZone = timezone;
    return {
      getNow: () => DateTime.local().toJSDate(),
      localizer: luxonLocalizer(DateTime),
      scrollToTime: DateTime.local().set({ hour: 0, minute: 0, second: 0 }).toJSDate(),
    };
  }, [timezone]);

  useEffect(() => {
    return () => {
      if (browserDefaultTZ) {
        Settings.defaultZone = browserDefaultTZ; // reset to browser TZ on unmount
      }
    };
  }, []);

  const calendarComponents = useMemo<Components<TEvent, TResource>>(
    () => ({
      toolbar: CustomToolbar,
      ...components,
    }),
    [components]
  );

  return (
    <StyledDnDCalendar<TEvent, TResource>
      defaultView={Views.WEEK}
      views={['week', 'day']}
      getNow={getNow}
      localizer={localizer}
      scrollToTime={scrollToTime}
      style={calendarStyle}
      components={calendarComponents}
      {...props}
    />
  );
};

export default Calendar;

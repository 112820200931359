import { useUserContext } from '../../contexts/UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getOnboardingStepPath } from '../../utils/user/getOnboardingStepPath';

export default function useOnboardingStepRedirect() {
  const {
    postLogin: { data },
  } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.nextStep) {
      const path = getOnboardingStepPath(data.nextStep);

      if (!path) {
        // No longer requiring an onboarding step, redirect back out.
        navigate('/');
      }
    }
  }, [data?.nextStep, navigate]);
}

import { useLocation } from 'react-router-dom';
import env from 'config/env';

function useIsAdminPage() {
  const location = useLocation();
  const path = `/${env.routing.adminPath}`;

  return location.pathname === path || location.pathname.startsWith(`${path}/`);
}

export default useIsAdminPage;

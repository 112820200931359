import React, { PropsWithChildren } from 'react';
import ContentHeader from 'components/molecules/ContentHeader/ContentHeader';
import ContentContainer from 'components/atoms/ContentContainer/ContentContainer';

type Props = {
  text: string;
};

const HeaderAndContent: React.FC<PropsWithChildren<Props>> = ({ children, text }) => {
  return (
    <>
      <ContentHeader text={text} />
      <ContentContainer>{children}</ContentContainer>
    </>
  );
};

export default HeaderAndContent;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EnrollmentFromQuery, QueriedPaymentCard } from 'types';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useQueryEnrollmentPricing from 'hooks/queries/useQueryEnrollmentPricing/useQueryEnrollmentPricing';
import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';
import ErrorAlert from 'components/molecules/ErrorAlert/ErrorAlert';
import SwitchWithDoubleLabel from 'components/molecules/SwitchWithDoubleLabel/SwitchWithDoubleLabel';
import useMutationCreatePaymentForEnrollment from 'hooks/mutations/useMutationCreatePaymentForEnrollment/useMutationCreatePaymentForEnrollment';
import useQueryAccountBillingDetails from '../../../../hooks/queries/useQueryAccountBillingDetails/useQueryAccountBillingDetails';
import ManagePaymentMethod from '../../../../components/organisms/ManagePaymentMethod/ManagePaymentMethod';
import { BillingState, PaymentType } from '../../../../generated/graphql';
import PricingBreakdown from '../PricingBreakdown/PricingBreakdown';
import FormControlLabel from '@mui/material/FormControlLabel';
import CancellationPolicy from 'pages/EnrollmentConfirm/components/PaymentSetup/CancellationPolicy';
import { useLocation } from 'react-router-dom';

interface PaymentSetupProps {
  enrollment: EnrollmentFromQuery;
}

type Props = PaymentSetupProps;

const PaymentSetup = ({ enrollment }: Props) => {
  const [payInFull, setPayInFull] = useState<boolean>(true);
  const [selectedCard, setSelectedCard] = useState<QueriedPaymentCard | undefined>();
  const [paymentAuthorized, setPaymentAuthorized] = useState<boolean>(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const [createPayment, { data: paymentData, loading: paymentLoading, error: paymentError }] =
    useMutationCreatePaymentForEnrollment();

  // Check the payment status of this enrollment to ensure we're in the right spot.
  const { data, loading, error } = useQueryEnrollmentPricing({
    variables: {
      input: { enrollmentId: enrollment.id },
    },
    context: {
      headers: {
        Authorization: token,
      },
    },
  });

  // Load existing payment details for this account.
  const accountId = enrollment.studentProfile.account?.id;
  const { data: billingData } = useQueryAccountBillingDetails({
    skip: !accountId,
    variables: accountId
      ? {
          accountId,
        }
      : undefined,
    context: {
      headers: {
        Authorization: token,
        enrollmentId: enrollment.id,
      },
    },
  });

  const pricing = data?.enrollmentPricing;

  const allowMonthly = !!pricing?.monthlyPaymentSchedule;

  // Force payInFull to true if monthly payments are not allowed.
  useEffect(() => {
    if (!payInFull && !allowMonthly) {
      setPayInFull(true);
    }
  }, [allowMonthly, payInFull]);

  const handlePayTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayInFull(event.target.checked);
  };

  const payInFullSavePercentText =
    pricing && pricing.payInFullDiscountPercent > 0 ? (
      <Typography
        component='span'
        variant='caption'
        sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, transform: 'translateY(100%)' }}
      >
        (save {pricing.payInFullDiscountPercent}%)
      </Typography>
    ) : (
      ''
    );

  const { totalAmountInCents, payInFullDiscountPercent, monthlyPaymentSchedule } = pricing || {};
  const hasDiscount = payInFullDiscountPercent && payInFullDiscountPercent > 0 && payInFull;
  const discountAmount = useMemo(
    () => (totalAmountInCents ? (hasDiscount ? (payInFullDiscountPercent / 100) * totalAmountInCents : 0) : 0),
    [hasDiscount, payInFullDiscountPercent, totalAmountInCents]
  );

  const totalWithDiscount = totalAmountInCents ? totalAmountInCents - Math.ceil(discountAmount) : 0;

  const initialMonthlyPayment = monthlyPaymentSchedule?.payments[0];
  const amountDueNow = payInFull || !initialMonthlyPayment ? totalWithDiscount : initialMonthlyPayment.totalInCents;
  const customer = billingData?.accountBillingDetails.customer;
  const paymentCards = billingData?.accountBillingDetails.cards || [];

  // Check payment type preference for zelle or check options.
  const requireSquarePayment =
    totalWithDiscount > 0 &&
    enrollment.paymentTypePreference !== PaymentType.PayByCheck &&
    enrollment.paymentTypePreference !== PaymentType.Zelle;

  const handleSubmit = useCallback(() => {
    if (requireSquarePayment && !selectedCard) {
      return;
    }

    (async () => {
      if (customer?.id) {
        await createPayment({
          variables: {
            input: {
              sourceId: selectedCard?.id,
              customerId: customer.id,
              amountInCents: amountDueNow,
              enrollmentId: enrollment.id,
              paymentType: payInFull ? PaymentType.PayInFull : PaymentType.Monthly,
            },
          },
        });
      }
    })();
  }, [amountDueNow, createPayment, customer?.id, enrollment.id, payInFull, requireSquarePayment, selectedCard]);

  const paymentErrorMessage = useMemo(
    () =>
      paymentData?.createPaymentForEnrollment?.errors?.length
        ? paymentData.createPaymentForEnrollment.errors.map((x) => x.detail || x.code).join(', ')
        : undefined,
    [paymentData]
  );

  if (
    (paymentData?.createPaymentForEnrollment?.orderInvoicePairs?.length || 0) > 0 ||
    (!requireSquarePayment && paymentData?.createPaymentForEnrollment) ||
    enrollment.billingStatus === BillingState.Paid
  ) {
    return (
      <Container maxWidth='xs'>
        <Paper sx={{ p: 2 }}>
          <Typography variant='h1' color='black'>
            Payment Complete!
          </Typography>
          <Typography gutterBottom>Thank you, your lesson has been confirmed and payment is processing!</Typography>
          {paymentData?.createPaymentForEnrollment?.orderInvoicePairs?.map((orderInvoicePair, i) => (
            <React.Fragment key={`invoice-${orderInvoicePair.invoice ? orderInvoicePair.invoice.invoiceNumber : i}`}>
              <Typography sx={{ fontWeight: 'bold' }}>{orderInvoicePair?.invoice?.title}</Typography>
              <Typography>Invoice #{orderInvoicePair?.invoice?.invoiceNumber}</Typography>
            </React.Fragment>
          ))}
          <CancellationPolicy />
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth='xs'>
      <Paper sx={{ p: 2 }}>
        <Typography variant='h1' color='black'>
          Payment Setup
        </Typography>
        {pricing && !requireSquarePayment ? (
          <>
            {loading && <LoadingIndicator />}
            {error && <ErrorAlert error={error.message} />}
            {paymentErrorMessage && <ErrorAlert error={paymentErrorMessage} />}
            {paymentError && <ErrorAlert error={paymentError.message} />}
            <Typography gutterBottom>
              You have decided to pay in full with a Check or Zelle. Zelle payments can be sent to sarah@lyriqmusic.com.
              Checks can be dropped off at LYRIQ at any time through our mail slot. Payments must be received before the
              first class. Please pay the indicated amount below.
            </Typography>
            <Typography gutterBottom fontWeight='bold'>
              Click the Submit button at bottom of page to finalize.
            </Typography>
            <Typography variant='h5' component='h2' mb={3} mt={3}>
              Cost Breakdown
            </Typography>
            <PricingBreakdown pricing={pricing} />
          </>
        ) : (
          <>
            <Typography gutterBottom>Set up your payment information now to finalize your lessons.</Typography>
            {loading && <LoadingIndicator />}
            {error && <ErrorAlert error={error.message} />}
            {paymentError && <ErrorAlert error={paymentError.message} />}
            {pricing && (
              <>
                {allowMonthly && (
                  <Box display='flex' justifyContent='center' mb={1}>
                    <SwitchWithDoubleLabel
                      leftLabel={'Monthly'}
                      rightLabel={<>Pay In Full {payInFullSavePercentText}</>}
                      checked={payInFull}
                      onChange={handlePayTypeChange}
                      inputProps={{ 'aria-label': 'Payment Type: Pay in Full or Monthly' }}
                    />
                  </Box>
                )}
                <Typography variant='h5' component='h2' mb={3} mt={3}>
                  Cost Breakdown
                </Typography>
                <PricingBreakdown pricing={pricing} monthly={!payInFull && allowMonthly} />
              </>
            )}
          </>
        )}
      </Paper>
      {pricing && (
        <Paper sx={{ p: 2, mt: 3 }}>
          {requireSquarePayment ? (
            <>
              <Typography variant='h5' component='h2'>
                Payment Method
              </Typography>
              <Box mt={2}>
                {customer?.id && (
                  <ManagePaymentMethod
                    customerId={customer.id}
                    cards={paymentCards}
                    onChange={(card) => setSelectedCard(card)}
                  />
                )}
              </Box>
              {paymentError && <ErrorAlert error={paymentError.message} />}
              <Box mt={2}>
                <FormControlLabel
                  label='I authorize LYRIQ Music School to charge my credit card for agreed upon purchases. I understand that my information will be saved for future transactions on my account.'
                  control={
                    <Checkbox checked={paymentAuthorized} onChange={(e) => setPaymentAuthorized(e.target.checked)} />
                  }
                />
              </Box>
              <CancellationPolicy />
            </>
          ) : (
            <CancellationPolicy />
          )}
          <Box mt={2}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              disabled={requireSquarePayment && (!selectedCard || paymentLoading || !paymentAuthorized)}
              fullWidth
            >
              {paymentLoading ? 'Submitting...' : requireSquarePayment ? 'Submit Payment' : 'Submit'}
            </Button>
          </Box>
        </Paper>
      )}
    </Container>
  );
};

export default PaymentSetup;

import { Availability, DayOfWeek } from 'generated/graphql';
import {
  AvailabilityOptions,
  getAvailabilityDaysOfWeek,
  weekdayMondayIndexMap,
} from 'utils/availability/availabilityUtils';

export function sortDaysOfWeek(daysOfWeek: DayOfWeek[]) {
  const sorted = daysOfWeek.slice();
  sorted.sort((a, b) => weekdayMondayIndexMap[a] - weekdayMondayIndexMap[b]);
  return sorted;
}

export default function formatAvailabilityDays(availability: Availability, opts?: AvailabilityOptions) {
  const daysOfWeek = getAvailabilityDaysOfWeek(availability, opts);

  const sorted = sortDaysOfWeek(daysOfWeek);
  const formatted = sorted.map((day) => {
    const dayText = day.slice(0, 3);
    return `${dayText[0]}${dayText.slice(1).toLowerCase()}`;
  });

  return formatted.join(', ');
}

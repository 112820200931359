import { AppointmentEvent, QueriedAppointment } from 'types';
import colorMapper from 'utils/SemesterCalendar/colorMapper';
import { NO_PREFERENCE_VALUE } from 'components/organisms/InstructorSelectField/InstructorSelectField';
import { getDateTime } from 'utils/dates';

export default function parseEventsFromAppointments(appointments: QueriedAppointment[]) {
  const events: AppointmentEvent[] = [];

  appointments.forEach((appointment) => {
    if (!appointment.startDate || !appointment.endDate) {
      return;
    }

    const colorId = `instructor_${appointment.instructorProfileId?.toString() || NO_PREFERENCE_VALUE}`;

    events.push({
      id: `appointment__${appointment.id}`,
      title: `${appointment.studentProfile?.user?.firstName} ${appointment.studentProfile?.user?.lastName}`,
      start: getDateTime(appointment.startDate).toJSDate(),
      end: getDateTime(appointment.endDate).toJSDate(),
      appointment,
      appointmentId: appointment.id,
      instructorId: appointment.instructorProfileId || undefined,
      resource: appointment.instructorProfileId || NO_PREFERENCE_VALUE,
      studentId: appointment.studentProfileId || undefined,
      skill: appointment.enrollment?.skill || undefined,
      color: appointment.instructorProfile?.calendarColor || colorMapper.pickColor(colorId),
      isDraggable: false,
    });
  });

  return events;
}

import { useMemo } from 'react';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import { UserAccountUtils } from '../../utils/user/accountUtils';
import { Account } from '../../generated/graphql';

function useCurrentUserAccount(): Account | undefined {
  const { user } = useUserContext();

  const accounts = useMemo<Account[]>(() => (user ? UserAccountUtils.getAccounts(user) : []), [user]);

  return accounts[0];
}

export default useCurrentUserAccount;

import { defaultDataIdFromObject, SubscriptionResult, useSubscription } from '@apollo/client';
import {
  EnrollmentSchedulerFragmentFragment,
  EnrollmentSchedulerFragmentFragmentDoc,
  SolverEnrollmentsUpdatedDocument,
  SolverEnrollmentsUpdatedSubscription,
  SolverEnrollmentsUpdatedSubscriptionVariables,
} from '../../../generated/graphql';
import { OnSubscriptionDataOptions, SubscriptionHookOptions } from '@apollo/client/react/types/types';

type Data = SolverEnrollmentsUpdatedSubscription;
type Variables = SolverEnrollmentsUpdatedSubscriptionVariables;

function useSubscriptionSolverEnrollments(
  options?: SubscriptionHookOptions<Data, Variables>
): SubscriptionResult<Data, Variables> {
  return useSubscription<Data, Variables>(SolverEnrollmentsUpdatedDocument, {
    ...options,
    onSubscriptionData: ({ client, subscriptionData }: OnSubscriptionDataOptions<Data>) => {
      // Process the change sets and update the enrollments in the apollo cache.
      const changeSets = subscriptionData.data?.solverEnrollmentsUpdated?.enrollmentChangeSets;
      if (!changeSets) {
        return;
      }

      for (const changeSet of changeSets) {
        const existingEnrollment = client.readFragment({
          id: defaultDataIdFromObject({ __typename: 'Enrollment', id: changeSet.id }),
          fragment: EnrollmentSchedulerFragmentFragmentDoc,
        });

        // If enrollment doesn't exist cache, no need to update it.
        if (!existingEnrollment) {
          // eslint-disable-next-line no-console
          console.warn(`Enrollment ${changeSet.id} doesn't exist in cache`);
          continue;
        }

        // Update the data on the enrollment.
        const modified: EnrollmentSchedulerFragmentFragment = {
          ...existingEnrollment,
          dayOfWeek: changeSet.newValues.dayOfWeek,
          startTime: changeSet.newValues.startTime,
          scheduledInstructorId: changeSet.newValues.scheduledInstructorId,
          scheduledInstructor: changeSet.newValues.scheduledInstructor,
        };

        // eslint-disable-next-line no-console
        console.log(`Updating enrollment ${changeSet.id} in the cache with new values: `, changeSet.newValues);

        // Write the updates into the apollo cache as well.
        client.writeFragment({
          id: client.cache.identify(modified),
          fragment: EnrollmentSchedulerFragmentFragmentDoc,
          data: modified,
        });
      }
    },
  });
}

export default useSubscriptionSolverEnrollments;

import { InstructorAvailability } from 'generated/graphql';
import { BackgroundEvent } from 'types';
import getEventsFromAvailability from './getEventsFromAvailability';
import colorMapper from 'utils/SemesterCalendar/colorMapper';

export default function getInstructorAvailabilityEvents(
  startDate: Date,
  endDate: Date,
  availability: InstructorAvailability[],
  filters: {
    instructorIds?: number[];
    skillIds?: number[];
  } = {}
) {
  const backgroundEvents: BackgroundEvent[] = [];
  const { instructorIds = [], skillIds = [] } = filters;

  availability.forEach((item) => {
    // Apply filters.
    if (instructorIds.length > 0) {
      if (!instructorIds.includes(item.instructor.id)) {
        return;
      }
    }

    if (skillIds.length > 0) {
      if (!item.instructor.skills?.find((skill) => skillIds.includes(skill.id))) {
        return;
      }
    }

    // TODO: handle intersection of exclusion dates.
    if (item.availability?.availabilityItems && item.availability.availabilityItems.length > 0) {
      const availabilityEvents = getEventsFromAvailability(startDate, endDate, item.availability);

      const instrumentsList = item.instructor.skills?.map((x) => x.skillName).join(', ') || '';

      const colorId = `instructor_${item.instructor.id.toString()}`;

      backgroundEvents.push(
        ...availabilityEvents.map((event) => ({
          ...event,
          id: `availability__${item.id}__${event.start.toISOString()}`,
          eventType: 'instructorAvailability',
          instructorId: item.instructor.id,
          resource: item.instructor.id,
          title: `${item.instructor.firstName} ${item.instructor.lastName} Available ${
            instrumentsList ? ` - ${instrumentsList}` : ''
          }`,
          color: item.instructor.calendarColor || colorMapper.pickColor(colorId),
          skills: item.instructor.skills || undefined,
        }))
      );
    }
  });

  return backgroundEvents;
}

import React, { useMemo } from 'react';
import { EnrollmentPricingType } from 'types';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Currency from 'components/atoms/Currency/Currency';
import Stack from '@mui/material/Stack';
import { getDateTime } from 'utils/dates';
import { DateTime } from 'luxon';
import BillingLineItem from '../BillingLineItem/BillingLineItem';
import LineItem from '../LineItem/LineItem';

const totalTypographyProps: TypographyProps = {
  sx: {
    fontSize: '1.2rem',
  },
};

interface PricingBreakdownProps {
  pricing: EnrollmentPricingType;
  monthly?: boolean;
}

type Props = PricingBreakdownProps;

const PricingBreakdown = ({ pricing, monthly = false }: Props) => {
  const {
    totalAmountInCents,
    payInFullDiscountPercent,
    initialFeeInCents,
    monthlyPaymentSchedule,
    lineItems,
    discounts,
  } = pricing;
  const { discountLineItems } = discounts || {};

  const hasDiscount = payInFullDiscountPercent > 0 && !monthly;
  const discountAmount = useMemo(
    () => (hasDiscount ? (payInFullDiscountPercent / 100) * totalAmountInCents : 0),
    [hasDiscount, payInFullDiscountPercent, totalAmountInCents]
  );

  const initialMonthlyPayment = monthlyPaymentSchedule?.payments[0];
  const remainingMonthlyPayments = useMemo(
    () => monthlyPaymentSchedule?.payments.slice(1) || [],
    [monthlyPaymentSchedule?.payments]
  );
  const initialMonthlyDueDate =
    initialMonthlyPayment && !monthlyPaymentSchedule?.requireImmediatePayment
      ? getDateTime(initialMonthlyPayment.date)
          .setZone('UTC')
          .toLocaleString({
            ...DateTime.DATE_SHORT,
            year: '2-digit',
          })
      : undefined;

  return (
    <div>
      <Stack direction='column' spacing={2}>
        {lineItems?.map((lineItem, index) => (
          <BillingLineItem
            key={lineItem.id || lineItem.name || index}
            lineItem={lineItem}
            allDiscounts={discountLineItems}
            divider
          />
        ))}
        {hasDiscount && (
          <LineItem
            title={`Discount (Pay in full, save ${payInFullDiscountPercent}%)`}
            amountInCents={-discountAmount}
            divider
          />
        )}
        <Box>
          <LineItem title={`Subtotal`} amountInCents={totalAmountInCents - discountAmount} sx={{ mb: 1 }} />
          <LineItem
            title={monthly ? 'Semester Total' : 'Total (due now)'}
            amountInCents={totalAmountInCents - discountAmount}
            titleTypographyProps={totalTypographyProps}
            amountTypographyProps={totalTypographyProps}
            bold
          />
        </Box>
        {monthly && initialMonthlyPayment && (
          <Stack direction='column' spacing={1} pt={2}>
            <Typography variant='h5' component='h2' color='secondary'>
              Monthly Payments
            </Typography>
            <Box>
              <Typography variant='overline'>First Month Payment (due {initialMonthlyDueDate || 'now'})</Typography>
              <Typography>
                <strong>
                  <Currency value={initialMonthlyPayment.totalInCents} />
                </strong>
                <br />
                <Typography variant='caption' component='span' fontStyle='italic'>
                  Includes the <Currency value={initialFeeInCents} minFraction={0} /> fee added to the first payment
                </Typography>
              </Typography>
            </Box>
            {remainingMonthlyPayments.length > 0 && (
              <Box>
                <Typography variant='overline'>
                  + {remainingMonthlyPayments.length} Additional Monthly Payments
                </Typography>
                <Typography>
                  <Currency value={monthlyPaymentSchedule.monthlyAmountInCents} /> per month
                  <br />
                  <Typography variant='caption' component='span' fontStyle='italic'>
                    To be billed on:{' '}
                    {remainingMonthlyPayments
                      .map((x) =>
                        getDateTime(x.date)
                          .setZone('UTC')
                          .toLocaleString({
                            ...DateTime.DATE_SHORT,
                            year: '2-digit',
                          })
                      )
                      .join(', ')}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default PricingBreakdown;

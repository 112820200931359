import React, { Dispatch, SetStateAction } from 'react';
import useOnboardingStepRedirect from 'hooks/useOnboardingStepRedirect/useOnboardingStepRedirect';
import AddStudentForm from 'components/organisms/AddStudentsForm/AddStudentForm';

type FuncType = {
  setAddingStudents: Dispatch<SetStateAction<boolean>>;
};

const AddStudentsForm = ({ setAddingStudents }: FuncType) => {
  useOnboardingStepRedirect();

  return <AddStudentForm setAddingStudents={setAddingStudents} />;
};

export default AddStudentsForm;

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const RoundBlackButton = styled(Button)`
  background-color: black;
  border-radius: 1rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  width: 5rem;
  height: 2rem;

  :hover {
    background-color: black;
    color: white;
  }
`;

export default RoundBlackButton;

import React, { useMemo } from 'react';

interface CurrencyProps {
  value: number;
  cents?: boolean;
  minFraction?: number;
  maxFraction?: number;
  options?: Intl.NumberFormatOptions;
}

type Props = CurrencyProps;

const Currency = ({ value, cents = true, minFraction, maxFraction, options = {} }: Props) => {
  const currencyValue = cents ? value / 100 : value;

  const numberFormat = useMemo(() => {
    const opts: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: minFraction,
      maximumFractionDigits: maxFraction,
      ...options,
    };

    return new Intl.NumberFormat('en-us', opts);
  }, [maxFraction, minFraction, options]);

  return <span>{numberFormat.format(currencyValue)}</span>;
};

export default Currency;

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Checkbox, Divider, styled, Typography } from '@mui/material';
import ContentHeaderWrapper from 'components/atoms/ContentHeaderWrapper/ContentHeaderWrapper';
import ContentBodyWrapper from 'components/atoms/ContentBodyWrapper/ContentBodyWrapper';
import Enrollment from 'components/templates/Enrollment/Enrollment';
import theme from 'theme';
import { useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import useMutationCreateEnrollment from 'hooks/mutations/useMutationCreateEnrollment/useMutationCreateEnrollment';
import { AvailabilityItemInput, CreateEnrollmentsInput, DayOfWeek, PackageState, PackageType } from 'generated/graphql';
import { LessonFormat, lessonFormatMap, SignupType } from 'utils/signupDetails';
import useQueryGetPackages from 'hooks/queries/useQueryGetPackages/useQueryGetPackages';
import EnrollmentCancellationPolicy from 'pages/Enrollment/components/EnrollmentCancellationPolicy/EnrollmentCancellationPolicy';
import EnrollmentSickPolicy from 'pages/Enrollment/components/EnrollmentSickPolicy/EnrollmentSickPolicy';
import EnrollmentHeader from 'pages/Enrollment/components/EnrollmentHeader/EnrollmentHeader';
import { getDateTime } from 'utils/dates';
import ErrorAlert from 'components/molecules/ErrorAlert/ErrorAlert';

interface PolicyDetailsProps {
  title: string;
  body: React.ReactNode;
}

const PolicyDivider = styled(Divider)`
  border: solid 0.125rem;
  margin: 0.75rem 0 1.5rem 0;
  border-image: linear-gradient(99deg, ${theme.colors.lightYellow}, ${theme.colors.DarkYellow}) 1;
`;

type Props = PolicyDetailsProps;

const PolicyDetails = ({ title, body }: Props) => (
  <Box sx={{ marginBottom: '1.75rem' }}>
    <Typography variant='h5'>{title}</Typography>
    <PolicyDivider variant='fullWidth' />
    <Typography>{body}</Typography>
  </Box>
);

const Policies = () => {
  const { scheduleId } = useParams();
  const navigate = useNavigate();
  const [checked, setChecked] = useState<boolean>(false);
  const {
    selectedStudents,
    studentEnrollments,
    schedule,
    comments,
    siblingConsideration,
    schedulingPriority,
    paymentPreference,
    phoneNumber,
    resetState,
  } = useEnrollmentContext();
  const { data: packageData } = useQueryGetPackages();
  const [createEnrollments, { error, loading }] = useMutationCreateEnrollment();

  // Ensure there's data ready to submit, if not redirect back to start.
  useEffect(() => {
    if (!selectedStudents?.length || !studentEnrollments?.length) {
      navigate(`/enroll/${scheduleId}`);
    }
    if (!studentEnrollments.some((x) => x.selectedLessons.length || x.selectedClasses.length)) {
      if (selectedStudents?.length) {
        navigate(`/enroll/${scheduleId}/student/${selectedStudents[0].id}/lesson-selection`);
      } else {
        navigate(`/enroll/${scheduleId}`);
      }
    }
  }, [navigate, scheduleId, selectedStudents, studentEnrollments]);

  const onSubmit = useCallback(async () => {
    // Create enrollments for each private lesson and group class selected per student.
    const enrollmentsInput: CreateEnrollmentsInput = { enrollments: [] };
    studentEnrollments?.forEach((enrollment) => {
      const selectedLesson = enrollment.selectedLessons;
      const selectedClasses = enrollment.selectedClasses;
      const selectedTeachers = enrollment.selectedTeachers;
      const selectedAvailability = enrollment.selectedAvailabilities;
      const selectedLessonDetails = enrollment.selectedLessonsSignupDetails;

      const availabilityItems = Object.entries(selectedAvailability).reduce<AvailabilityItemInput[]>(
        (items, [day, availability]) => {
          if (!availability?.length) {
            return items;
          }

          const dayOfWeek = day.toUpperCase() as DayOfWeek;
          const fallbackDate = getDateTime().toFormat('t');
          return items.concat(
            availability
              .filter((x) => !!x.start.time && !!x.end.time)
              .map((x) => ({
                dayOfWeek,
                startTime: getDateTime(x.start.time).toFormat('t') || fallbackDate,
                endTime: getDateTime(x.end.time).toFormat('t') || fallbackDate,
              }))
          );
        },
        []
      );

      for (const lesson of selectedLesson) {
        const skillId = lesson.id;
        const teacher = selectedTeachers.find((teacher) => teacher.skillId === skillId);
        const lessonDetail = selectedLessonDetails.find((detail) => detail.id === skillId);
        const isTrial = lessonDetail?.signupType === SignupType.The4WeekTrial;
        const packageType = PackageType.Lesson;
        const lessonLength = lessonDetail?.sessionLength;
        const onePackage = packageData?.getPackages?.find(
          (pkg) =>
            pkg.appointmentType?.duration === lessonLength &&
            pkg.isTrial === isTrial &&
            pkg.packageType === packageType &&
            pkg.status === PackageState.Active
        );
        const packageId = onePackage?.id;
        const siteIds = lessonDetail?.sites?.map((x) => x.id);
        const enrollInput: CreateEnrollmentsInput['enrollments'][number] = {
          studentId: enrollment.student.profileId,
          skillId: skillId,
          packageId,
          enrollmentDate: new Date().toISOString(),
          scheduleId: schedule?.id,
          preferredInstructorId: teacher?.teacher?.profileId,
          comments: comments,
          phoneNumber,
          availabilityItems,
          lessonType: lessonDetail?.lessonFormat
            ? lessonFormatMap[lessonDetail.lessonFormat as LessonFormat]
            : undefined,
          siblingConsideration,
          schedulingPriority,
          schedulingConsideration: enrollment.schedulingConsideration,
          paymentTypePreference: paymentPreference,
          preferredSiteIds: siteIds,
        };

        enrollmentsInput?.enrollments?.push(enrollInput);
      }

      for (const selectedClass of selectedClasses) {
        const packageId = selectedClass.id;
        const enrollInput: CreateEnrollmentsInput['enrollments'][number] = {
          studentId: enrollment.student.profileId,
          packageId,
          enrollmentDate: new Date().toISOString(),
          scheduleId: schedule?.id,
          comments: comments,
          phoneNumber,
          paymentTypePreference: paymentPreference,
        };

        enrollmentsInput?.enrollments?.push(enrollInput);
      }
    });

    try {
      const result = await createEnrollments({
        variables: {
          createEnrollmentsInput: enrollmentsInput,
        },
      });

      if (result.data?.createEnrollments?.length) {
        resetState();
        navigate(`/enroll/${scheduleId}/confirmation`);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Error creating enrollments:`, err);
    }
  }, [
    comments,
    phoneNumber,
    createEnrollments,
    navigate,
    packageData,
    paymentPreference,
    resetState,
    schedule?.id,
    scheduleId,
    schedulingPriority,
    siblingConsideration,
    studentEnrollments,
  ]);

  return (
    <Enrollment back={{ path: `/enroll/${scheduleId}/summary`, description: 'Sign-up details' }}>
      <EnrollmentHeader />
      <ContentHeaderWrapper>
        <Typography variant={'h1'}>Policy Confirmation</Typography>
      </ContentHeaderWrapper>
      <ContentBodyWrapper>
        <PolicyDetails title={'Cancellation Policy'} body={<EnrollmentCancellationPolicy />} />
        <PolicyDetails title={'Sick Policy'} body={<EnrollmentSickPolicy />} />
        {error && <ErrorAlert error={error.message} />}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1.75rem 0rem' }}>
          <Checkbox checked={checked} onChange={({ target: { checked } }) => setChecked(checked)} />
          <Typography>I have read and understood these policies.</Typography>
        </Box>
        <Button
          fullWidth
          variant='contained'
          disabled={!checked || loading}
          sx={{ textTransform: 'none' }}
          onClick={onSubmit}
        >
          {loading ? 'Saving...' : 'Register!'}
        </Button>
      </ContentBodyWrapper>
    </Enrollment>
  );
};

export default Policies;

import { useUserContext } from 'contexts/UserContext/UserContext';
import { UserRoleUtils } from 'utils/user/roleUtils';
import { useMemo } from 'react';

const useIsAccountHolderAndStudent = (): boolean | undefined => {
  const { user } = useUserContext();
  return useMemo(() => user && UserRoleUtils.isAccountHolderAndStudent(user), [user]);
};

export default useIsAccountHolderAndStudent;

import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import { EnrollmentStatus } from 'generated/graphql';
import { SchedulerFilters } from 'pages/Admin/Scheduler/types';
import useQueryGetSkills from 'hooks/queries/useQueryGetSkills/useQueryGetSkills';
import CloseIconDialog from 'components/molecules/CloseIconDialog/CloseIconDialog';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activeFilters: SchedulerFilters;
  setActiveFilters: React.Dispatch<React.SetStateAction<SchedulerFilters>>;
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const Layout = styled('div')`
  .MuiAutocomplete-root {
    :not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`;

const FiltersDialog: React.FC<Props> = ({ open, setOpen, activeFilters, setActiveFilters }) => {
  const instrumentsResult = useQueryGetSkills();
  const instruments = useMemo(() => instrumentsResult.data?.getSkills || [], [instrumentsResult.data?.getSkills]);

  return (
    <CloseIconDialog open={open} setOpen={setOpen} title='Filter Enrollments'>
      <Layout>
        <Autocomplete
          id='enrollmentStatusFilter'
          multiple
          options={Object.values(EnrollmentStatus)}
          disableCloseOnSelect
          getOptionLabel={(option) => option}
          value={activeFilters.scheduleStatus}
          onChange={(event, value) => {
            setActiveFilters({ ...activeFilters, scheduleStatus: value });
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option}
            </li>
          )}
          style={{ width: 500 }}
          renderInput={(params) => <TextField {...params} label='Enrollment Status' placeholder='' />}
        />
        <Autocomplete
          id='instrumentFilter'
          multiple
          options={instruments.map((instrument) => instrument.skillName)}
          value={activeFilters.instruments}
          disableCloseOnSelect
          getOptionLabel={(instrument) => instrument}
          onChange={(event, value) => {
            setActiveFilters({ ...activeFilters, instruments: value });
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {option}
            </li>
          )}
          style={{ width: 500 }}
          renderInput={(params) => <TextField {...params} label='Instrument(s)' placeholder='' />}
        />
      </Layout>
    </CloseIconDialog>
  );
};

export default FiltersDialog;

import { createTheme, ThemeOptions, Breakpoints, BreakpointsOptions } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

type ThemeColors = {
  blue: string;
  red: string;
  black: string;
  lightBlack: string;
  white: string;
  grey: string;
  greySilver: string;
  cloudy: string;
  cobalt: string;
  pink: string;
  rackley: string;
  enrollText: string;
  lightYellow: string;
  DarkYellow: string;
};
//Allow custom breakpoints
type BreakpointOverrides = {
  xs: true;
  sm: true;
  md: true;
  lg: true;
  xl: true;
};

declare module '@mui/material/styles' {
  // Add custom theme variables.
  interface Theme {
    colors: ThemeColors;
    breakpoint: BreakpointOverrides;
    breakpoints: Breakpoints;
  }

  interface ThemeOptions {
    colors: ThemeColors;
    breakpoint?: BreakpointOverrides;
    breakpoints: BreakpointsOptions;
  }
}

const breakpointValues = {
  xs: 384,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

const colors: ThemeColors = {
  blue: '#6282A8',
  red: '#8D1717',
  black: '#1e1d1d',
  lightBlack: '#00000040',
  white: '#fff',
  grey: '#646464',
  cloudy: '#fefeff',
  cobalt: '#4281fc',
  pink: '#e499ff',
  rackley: '#718EB3',
  enrollText: '#221F20',
  lightYellow: '#E3B43F',
  DarkYellow: '#A76331',
  greySilver: '#5A5A5A',
};

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#E3B43F',
      dark: '#D5AC59',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#718EB3',
      light: '#99ADC7',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(17, 125, 162, 0.5)',
    },
    background: {
      default: '#F5F5F5',
    },
    success: {
      main: '#306B34',
      light: '#83A685',
      dark: '#1D401F',
    },
    warning: {
      main: '#F4B920',
      light: '#F8D579',
      dark: '#926F13',
    },
    error: {
      main: '#8D1717',
    },
  },
  typography: {
    fontFamily: 'Nunito',
    fontSize: 12,
    h1: {
      fontSize: '2.125rem',
      fontWeight: 800,
      fontFamily: 'Nunito',
      lineHeight: 1.35,
      color: '#1e1d1d',
    },
    h2: {
      fontSize: '1rem',
      fontWeight: 800,
      letterSpacing: '0em',
      fontFamily: 'Nunito',
      lineHeight: 1.375,
      color: '#1e1d1d',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 600,
      fontFamily: 'Nunito',
      lineHeight: 1.334,
    },
    h4: {
      fontSize: '1.5rem',
      fontFamily: 'Nunito',
      lineHeight: 1.5,
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.25rem',
      fontFamily: 'Nunito',
      fontWeight: 700,
    },
    h6: {
      fontSize: '1rem',
      fontFamily: 'Nunito',
      fontWeight: 700,
      letterSpacing: '0em',
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontFamily: 'Montserrat',
      letterSpacing: '0.0075em',
      lineHeight: 1.43,
      color: 'rgba(0, 0, 0, 0.6)',
    },
    subtitle2: {
      fontSize: '0.85rem',
      fontFamily: 'Nunito',
      fontWeight: 400,
      lineHeight: 1.4,
      color: '#646464',
      margin: 0,
    },
    body1: {
      letterSpacing: '0em',
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    button: {
      fontSize: '1.125rem',
      letterSpacing: '0.014em',
    },
    caption: {
      fontSize: '0.75rem',
      letterSpacing: '0.021em',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 700,
      letterSpacing: '0.16667em',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#ffffff',
          color: '#353435',
        },
      },
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiDivider: {
      styleOverrides: {
        vertical: {
          borderColor: '#d9d9d9',
        },
      },
    },
  },
  colors,
  breakpoints: {
    values: breakpointValues,
  },
};

const theme = createTheme(themeOptions);

export default theme;

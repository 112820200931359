import React from 'react';
import { useLocation } from 'react-router-dom';
import { LinearProgress, styled } from '@mui/material';

const StyledLinearProgress = styled(LinearProgress)`
  position: fixed;
  bottom: 3.5rem;
  width: 100%;
  height: 1rem;
`;

const enrollmentSteps = [
  'semester-information',
  'lesson-selection',
  'sign-up-details',
  'teacher-selection',
  'timeframe-selection',
  'summary',
  'policies',
  'confirmation',
];

const ENROLL = 'enroll';

const calculateCurrentProgress = (pathname: string) => {
  const [, enroll, scheduleId, page, studentId, studentPage] = pathname.split('/');
  const totalSteps = enrollmentSteps.length;

  const percentage = (number: number) => Math.floor(number * 100);

  if (enroll === ENROLL && !!scheduleId) {
    if (studentId !== undefined) {
      switch (studentPage) {
        case enrollmentSteps[1]:
          return percentage(2 / totalSteps);
        case enrollmentSteps[2]:
          return percentage(3 / totalSteps);
        case enrollmentSteps[3]:
          return percentage(4 / totalSteps);
        case enrollmentSteps[4]:
          return percentage(5 / totalSteps);
        default:
          return undefined;
      }
    }

    switch (page) {
      case enrollmentSteps[0]:
        return percentage(1 / totalSteps);
      case enrollmentSteps[5]:
        return percentage(6 / totalSteps);
      case enrollmentSteps[6]:
        return percentage(7 / totalSteps);
      case enrollmentSteps[7]:
        return percentage(8 / totalSteps);
      default:
        return undefined;
    }
  }
};

const ProgressBar = () => {
  const { pathname } = useLocation();
  const currentProgress = calculateCurrentProgress(pathname);

  return currentProgress !== undefined ? <StyledLinearProgress variant='determinate' value={currentProgress} /> : null;
};

export default ProgressBar;

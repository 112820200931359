import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

interface CheckboxListProps<T = string, IdType = T> {
  value: T[];
  onChange: (newValue: T[]) => void;
  options: T[];
  labelAccessor: (item: T) => React.ReactNode;
  keyAccessor?: (item: T, index: number) => React.Key;
  idAccessor?: (item: T) => IdType;
}

type Props<T = string, IdType = T> = CheckboxListProps<T, IdType>;

function CheckboxList<T = string, IdType = T>({
  value,
  onChange,
  options,
  labelAccessor,
  keyAccessor,
  idAccessor,
}: Props<T, IdType>) {
  const getId = (item: T) => (idAccessor ? idAccessor(item) : item);

  const handleToggle = (selectedValue: T) => () => {
    const id = getId(selectedValue);
    const currentIndex = value.findIndex((valueItem) => getId(valueItem) === id);
    const newChecked = [...value];

    if (currentIndex === -1) {
      newChecked.push(selectedValue);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    onChange(newChecked);
  };

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {options.map((itemValue, index) => {
        const key = keyAccessor ? keyAccessor(itemValue, index) : index;
        const id = getId(itemValue);
        const labelId = `checkbox-list-label-${key}`;

        return (
          <ListItem key={key} disablePadding>
            <ListItemButton role={undefined} onClick={handleToggle(itemValue)} dense>
              <ListItemIcon>
                <Checkbox
                  edge='start'
                  checked={value.some((valueItem) => getId(valueItem) === id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={labelAccessor(itemValue)} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export default CheckboxList;

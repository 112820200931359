import { EnrollmentFromQuery, SchedulerBackgroundEvent } from 'types';

export default function getEnrollmentSchedulerAvailability(enrollment: EnrollmentFromQuery) {
  const backgroundEvents: SchedulerBackgroundEvent[] = [];

  if (enrollment.availability?.availabilityItems && enrollment.availability.availabilityItems.length > 0) {
    backgroundEvents.push(
      ...enrollment.availability.availabilityItems.map((event) => ({
        ...event,
        startTime: event.startTime ?? '00:00:00',
        endTime: event.endTime ?? '23:59:59',
        id: `enrollment_availability__${enrollment.id}__${event.id}`,
        studentId: enrollment.studentProfileId,
        instructorId: enrollment.scheduledInstructorId || enrollment.preferredInstructor?.id,
        title: `${enrollment.studentProfile.user?.firstName} ${enrollment.studentProfile.user?.lastName} Available`,
        skill: enrollment.skill || undefined,
        skills: enrollment.skill ? [enrollment.skill] : [],
      }))
    );
  }

  return backgroundEvents;
}

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetSkillLevelsDocument, GetSkillLevelsQuery, GetSkillLevelsQueryVariables } from 'generated/graphql';

function useQueryGetSkillLevels(
  options?: QueryHookOptions<GetSkillLevelsQuery, GetSkillLevelsQueryVariables>
): QueryResult<GetSkillLevelsQuery, GetSkillLevelsQueryVariables> {
  return useQuery<GetSkillLevelsQuery, GetSkillLevelsQueryVariables>(GetSkillLevelsDocument, {
    ...options,
  });
}

export default useQueryGetSkillLevels;

import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const StudentsPage = () => {
  return (
    <Container>
      <Typography variant='h1'>Students page</Typography>
    </Container>
  );
};

export default StudentsPage;

import { gql, MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  StopScheduleSolverDocument,
  StopScheduleSolverMutation,
  StopScheduleSolverMutationVariables,
} from 'generated/graphql';
import { useMemo } from 'react';

type Data = StopScheduleSolverMutation;
type Variables = StopScheduleSolverMutationVariables;

function useMutationStopScheduleSolver(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      update(cache, result, options) {
        const { solverId } = options.variables?.input || {};

        if (solverId) {
          cache.writeFragment<{ solverStatus?: string }>({
            id: `SolverTimeTable:${solverId}`,
            fragment: gql`
              fragment SolverTimeTableUpdate on SolverTimeTable {
                solverStatus
              }
            `,
            data: {
              solverStatus: 'NOT_SOLVING',
            },
          });
        }
      },
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(StopScheduleSolverDocument, opts);
}

export default useMutationStopScheduleSolver;

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetScheduleSolverSolutionResultsDocument,
  GetScheduleSolverSolutionResultsQuery,
  GetScheduleSolverSolutionResultsQueryVariables,
} from 'generated/graphql';

type Data = GetScheduleSolverSolutionResultsQuery;
type Variables = GetScheduleSolverSolutionResultsQueryVariables;

function useQueryScheduleSolverSolutionResults(
  input?: Variables['input'],
  options?: QueryHookOptions<Data, Variables>
): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(GetScheduleSolverSolutionResultsDocument, {
    ...options,
    skip: !input,
    variables: input
      ? {
          input,
        }
      : undefined,
  });
}

export default useQueryScheduleSolverSolutionResults;

import React, { useCallback, useState } from 'react';
import SquarePaymentForm from '../SquarePaymentForm/SquarePaymentForm';
import useMutationStoreCard from '../../../hooks/mutations/useMutationStoreCard/useMutationStoreCard';
import { PaymentCard } from '../../../generated/graphql';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { QueriedPaymentCard } from '../../../types';
import ErrorAlert from '../../molecules/ErrorAlert/ErrorAlert';
import Box from '@mui/material/Box';

const ADD_NEW = 'add_new';

interface ManagePaymentMethodProps {
  customerId: string;
  cards: QueriedPaymentCard[];
  onChange?: (card?: PaymentCard) => void;
}

type Props = ManagePaymentMethodProps;

const ManagePaymentMethod = ({ customerId, cards, onChange }: Props) => {
  const [storeCard, { loading: storeCardSaving, error: storeCardError }] = useMutationStoreCard();
  const [cardOptions, setCardOptions] = useState<QueriedPaymentCard[]>(cards);
  const [selectValue, setSelectValue] = useState<string>();
  const [showAddCard, setShowAddCard] = useState<boolean>(false);

  const handleCardSelected = useCallback(
    (card?: QueriedPaymentCard) => {
      onChange && onChange(card);
    },
    [onChange]
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<string | undefined>) => {
      const value = event.target.value;
      const card = cards.find((card) => card.id === value);
      handleCardSelected(card);
      setSelectValue(value);
      setShowAddCard(value === ADD_NEW);
    },
    [cards, handleCardSelected]
  );

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id='card-select-label'>Payment Method</InputLabel>
        <Select
          labelId='card-select-label'
          id='card-select'
          value={selectValue || ''}
          label='Payment Method'
          onChange={handleChange}
          disabled={storeCardSaving}
        >
          {cardOptions.map((card) => (
            <MenuItem key={card.id} value={card.id}>
              ****{card.last4} | Expires {card.expMonth}/{card.expYear}
            </MenuItem>
          ))}
          <MenuItem value={ADD_NEW}>Add New</MenuItem>
        </Select>
      </FormControl>

      {storeCardError && <ErrorAlert error={storeCardError.message} />}

      {showAddCard && (
        <Box mt={1}>
          <SquarePaymentForm
            cardTokenizeResponseReceived={async (token) => {
              // TODO (LMA-258): Handle showing an error message if there is one.
              // TODO: (LMA-258): Send the verification token along with the request if there is one (second argument here)
              if (token.token) {
                const result = await storeCard({
                  variables: {
                    input: {
                      sourceId: token.token,
                      card: {
                        customerId,
                      },
                    },
                  },
                });

                const card = result.data?.storeCard.card;

                if (card) {
                  setCardOptions((prev) => [...prev, card]);
                  handleCardSelected(card);
                  setSelectValue(card.id);
                  setShowAddCard(false);
                }
              }
            }}
          >
            Save Card
          </SquarePaymentForm>
        </Box>
      )}
    </div>
  );
};

export default ManagePaymentMethod;

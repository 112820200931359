import React, { createContext, useContext, useMemo } from 'react';
import {
  StudentProfile,
  GetStudentsDocument,
  GetStudentsQuery,
  GetStudentsQueryVariables,
  Exact,
  Account,
} from 'generated/graphql';
import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import useCurrentUserAccount from 'hooks/useCurrentUserAccount/useCurrentUserAccount';
import { ApolloQueryResult } from '@apollo/client/core/types';

type AccountContextState = {
  account: Account | null | undefined;
  students: StudentProfile[] | null | undefined;
  getStudentsRefetch: (
    variables?: Partial<Exact<{ getStudentsId: number }>> | undefined
  ) => Promise<ApolloQueryResult<GetStudentsQuery>>;
};

const AccountContext = createContext<AccountContextState | undefined>(undefined);

const AccountContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const account = useCurrentUserAccount();
  const getStudentsId = account?.id || -1;
  const { data, refetch } = useQuery<GetStudentsQuery, GetStudentsQueryVariables>(GetStudentsDocument, {
    skip: !isAuthenticated,
    variables: { getStudentsId },
  });

  // Memoize the state value to improve context performance.
  const stateValue = useMemo<AccountContextState>(
    () => ({
      account,
      students: data?.getStudents,
      getStudentsRefetch: refetch,
    }),
    [account, data, refetch]
  );

  return <AccountContext.Provider value={stateValue}>{children}</AccountContext.Provider>;
};

const useAccountContext = () => {
  const context = useContext(AccountContext);

  if (context === undefined) {
    throw new Error('useUserContext was used outside of its Provider');
  }

  return context;
};

export { AccountContext, AccountContextProvider, useAccountContext };

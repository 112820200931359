import React, { PropsWithChildren, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ProfileType } from 'generated/graphql';
import useCurrentUserRole from 'hooks/useCurrentUserRole/useCurrentUserRole';
import useCurrentUserAccount from 'hooks/useCurrentUserAccount/useCurrentUserAccount';
import useOnboardingStepRedirect from 'hooks/useOnboardingStepRedirect/useOnboardingStepRedirect';
import EnrollAccountHolderButton from 'components/atoms/EnrollAccountHolderButton/EnrollAccountHolderButton';
import MaxWidthDiv from 'components/atoms/MaxWidthDiv/MaxWidthDiv';
import ErrorAlert from 'components/molecules/ErrorAlert/ErrorAlert';
import AddStudentsForm from 'components/organisms/AddStudentsForm/AddStudentsForm';
import useIsAccountHolderAndStudent from '../../../hooks/useIsAccountHolderAndStudent/useIsAccountHolderAndStudent';

const AddStudents: React.FC<PropsWithChildren> = ({ children }) => {
  useOnboardingStepRedirect();
  const accountHolder = useCurrentUserRole(ProfileType.AccountHolder);
  const account = useCurrentUserAccount();
  const showEnrollSelfButton = !useIsAccountHolderAndStudent();

  const [addingStudents, setAddingStudents] = useState(false);

  if (!account || !accountHolder) {
    return <ErrorAlert error={'Missing account or account holder'} />;
  }

  return (
    <MaxWidthDiv>
      {addingStudents ? (
        <>
          <Typography>Add a new student</Typography>
          <AddStudentsForm setAddingStudents={setAddingStudents} />
        </>
      ) : (
        <>
          {children}
          {showEnrollSelfButton && <EnrollAccountHolderButton />}
          <Button color='primary' variant='contained' fullWidth onClick={() => setAddingStudents(true)}>
            Add a Student
          </Button>
        </>
      )}
    </MaxWidthDiv>
  );
};

export default AddStudents;

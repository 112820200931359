import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SlotInfo, stringOrDate } from 'react-big-calendar';
import env from 'config/env';
import { DayOfWeek, Enrollment, EnrollmentStatus } from 'generated/graphql';
import { useAdminContext } from 'contexts/AdminContext/AdminContext';
import useQueryEnrollmentsOnSchedule from 'hooks/useQueryEnrollmentsOnSchedule/useQueryEnrollmentsOnSchedule';
import useQueryScheduleAvailability from 'hooks/queries/useQueryScheduleAvailability/useQueryScheduleAvailability';
import EnrollmentList from 'components/molecules/EnrollmentList/EnrollmentList';
import SemesterCalendar, { ScheduleEvent } from 'components/organisms/SemesterCalendar/SemesterCalendar';
import { DateTime, WeekdayNumbers } from 'luxon';
import { EnrollmentFromQuery } from 'types';
import useMutationScheduleEnrollment from 'hooks/useMutationScheduleEnrollment/useMutationScheduleEnrollment';
import InstructorSelectField from 'components/organisms/InstructorSelectField/InstructorSelectField';
import Stack from '@mui/material/Stack';
import filterEnrollments from 'utils/SemesterCalendar/filterEnrollments';
import { SchedulerFilters } from 'pages/Admin/Scheduler/types';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { isEnrollmentEvent } from 'utils/SemesterCalendar/isEnrollmentEvent';
import Button from '@mui/material/Button';
import EnrollmentConfirmationsDialog from 'components/organisms/EnrollmentConfirmationsDialog/EnrollmentConfirmationsDialog';
import HeaderPortal from 'components/organisms/HeaderPortal/HeaderPortal';
import SchedulerHeader from 'pages/Admin/Scheduler/components/SchedulerHeader/SchedulerHeader';
import { SchedulingConsiderationsContextProvider } from 'contexts/SchedulingConsiderationsContext/SchedulingConsiderationsContext';
import { ScheduleSolverContextProvider } from 'contexts/ScheduleSolverContext/ScheduleSolverContext';
import RoundBlackButton from 'components/atoms/RoundBlackButton/RoundBlackButton';
import FiltersDialog from 'components/organisms/FiltersDialog/FiltersDialog';
import ScheduleSelectField from 'components/organisms/ScheduleSelectField/ScheduleSelectField';

const dayOfWeekLuxonIndexMap: Record<WeekdayNumbers, DayOfWeek> = {
  1: DayOfWeek.Monday,
  2: DayOfWeek.Tuesday,
  3: DayOfWeek.Wednesday,
  4: DayOfWeek.Thursday,
  5: DayOfWeek.Friday,
  6: DayOfWeek.Saturday,
  7: DayOfWeek.Sunday,
};

function getDayOfWeekForDateTime(dt: DateTime): DayOfWeek {
  return dayOfWeekLuxonIndexMap[dt.weekday as WeekdayNumbers];
}

type QueriedEnrollment = Omit<Enrollment, 'schedule'>;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Scheduler = () => {
  const { selectedSchedule, selectedInstructors, setSelectedInstructors } = useAdminContext();
  const [draggedEvent, setDraggedEvent] = useState<ScheduleEvent | null>();
  const [selectedEnrollment, setSelectedEnrollment] = useState<QueriedEnrollment | undefined>();
  const [viewByInstructor, setViewByInstructor] = useState<boolean>(true);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [filtersDialogOpen, setFiltersDialogOpen] = useState<boolean>(false);
  const [enrollmentFilters, setEnrollmentFilters] = useState<SchedulerFilters>({ scheduleStatus: [] });

  const { data: availabilityData } = useQueryScheduleAvailability(selectedSchedule?.id);
  const { data: enrollmentData, subscribeToEnrollmentUpdates } = useQueryEnrollmentsOnSchedule();

  const [scheduleEnrollment] = useMutationScheduleEnrollment();

  // Subscribe to enrollment updates
  useEffect(() => {
    subscribeToEnrollmentUpdates();
  }, [subscribeToEnrollmentUpdates]);

  const filters = useMemo<SchedulerFilters>(
    () => ({ instructors: selectedInstructors, ...enrollmentFilters }),
    [selectedInstructors, enrollmentFilters]
  );

  const unscheduled = useMemo(
    () =>
      filterEnrollments(
        enrollmentData?.enrollments.filter((v) => v.schedulingStatus === EnrollmentStatus.New) || [],
        filters
      ),
    [filters, enrollmentData?.enrollments]
  );
  const scheduled = useMemo(
    () =>
      filterEnrollments(
        enrollmentData?.enrollments.filter((v) => v.schedulingStatus !== EnrollmentStatus.New) || [],
        filters,
        true
      ),
    [filters, enrollmentData?.enrollments]
  );
  const readyForConfirmation = useMemo(
    () =>
      filterEnrollments(
        enrollmentData?.enrollments || [],
        { scheduleStatus: [EnrollmentStatus.ReadyForConfirmation] },
        true
      ),
    [enrollmentData?.enrollments]
  );

  const handleEnrollmentClick = useCallback((enrollment: EnrollmentFromQuery) => {
    setSelectedEnrollment((prevState) => (prevState?.id !== enrollment?.id ? enrollment : undefined));
  }, []);

  const handleDragStart = useCallback((enrollment: EnrollmentFromQuery) => {
    setDraggedEvent({
      id: 'drag_event',
      title: enrollment.label,
    });
  }, []);

  const handleMoveEvent = useCallback(
    async ({
      event,
      start,
      isAllDay: droppedOnAllDaySlot = false,
      resourceId,
    }: {
      event: ScheduleEvent;
      start: stringOrDate;
      end: stringOrDate;
      isAllDay: boolean;
      resourceId?: number | string;
    }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        window.alert('Cannot drop on all day slot');
        return;
      }

      if (isEnrollmentEvent(event)) {
        const dt = typeof start === 'string' ? DateTime.fromISO(start) : DateTime.fromJSDate(start);
        const { enrollment } = event;

        const targetInstructorId =
          typeof resourceId === 'number'
            ? resourceId
            : enrollment.scheduledInstructorId || enrollment.preferredInstructorId;

        if (targetInstructorId) {
          await scheduleEnrollment({
            variables: {
              input: {
                id: enrollment.id,
                dayOfWeek: getDayOfWeekForDateTime(dt),
                instructorId: targetInstructorId,
                startTime: dt.toFormat('TT'),
              },
            },
          });

          setSelectedEnrollment(undefined);
        } else {
          window.alert('Scheduling enrollments without instructor not yet implemented.');
        }
      }
    },
    [scheduleEnrollment]
  );

  const handleSelectSlot = useCallback(
    async ({ start, resourceId }: SlotInfo) => {
      if (selectedEnrollment) {
        const dt = DateTime.fromJSDate(start);

        const targetInstructorId =
          typeof resourceId === 'number'
            ? resourceId
            : selectedEnrollment.scheduledInstructorId || selectedEnrollment.preferredInstructorId;

        if (targetInstructorId) {
          await scheduleEnrollment({
            variables: {
              input: {
                id: selectedEnrollment.id,
                dayOfWeek: getDayOfWeekForDateTime(dt),
                instructorId: targetInstructorId,
                startTime: dt.toFormat('TT'),
              },
            },
          });

          setSelectedEnrollment(undefined);
        } else {
          window.alert('Scheduling enrollments without instructor not yet implemented.');
        }
      }
    },
    [scheduleEnrollment, selectedEnrollment]
  );

  return (
    <ScheduleSolverContextProvider>
      <SchedulingConsiderationsContextProvider scheduleId={selectedSchedule?.id}>
        <HeaderPortal>
          <SchedulerHeader />
        </HeaderPortal>
        <Grid container columnSpacing={7.25}>
          <Grid item sm={4} md={3} lg={2}>
            <Box sx={{ height: `calc(100vh - ${env.sizing.admin.vhOffset})` }} flexDirection='column' display='flex'>
              <Box flexGrow={0} mb={1} sx={{ minWidth: 200 }}>
                <Stack spacing={2}>
                  <ScheduleSelectField id='scheduler-schedule-select' label='Change schedule' />
                  <InstructorSelectField
                    onChange={setSelectedInstructors}
                    value={selectedInstructors}
                    label='Filter by teacher(s)'
                    placeholder='Teachers'
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={viewByInstructor}
                          onChange={(event, checked) => setViewByInstructor(checked)}
                        />
                      }
                      label='View by Teacher'
                    />
                  </FormGroup>
                  {readyForConfirmation.length > 0 && (
                    <div>
                      {readyForConfirmation.length} Ready for Confirmation
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => setConfirmationDialogOpen(true)}
                      >
                        Send Confirmations
                      </Button>
                      <EnrollmentConfirmationsDialog
                        enrollments={readyForConfirmation}
                        open={confirmationDialogOpen}
                        onClose={() => setConfirmationDialogOpen(false)}
                      />
                    </div>
                  )}
                </Stack>
              </Box>
              <StyledBox flexGrow={0}>
                <Typography variant='h3' component='h2' gutterBottom>
                  Classes to schedule ({unscheduled.length})
                </Typography>
                <RoundBlackButton onClick={() => setFiltersDialogOpen(true)}>Filters</RoundBlackButton>
                <FiltersDialog
                  open={filtersDialogOpen}
                  setOpen={setFiltersDialogOpen}
                  activeFilters={enrollmentFilters}
                  setActiveFilters={setEnrollmentFilters}
                />
              </StyledBox>
              <Box flexGrow={1} py={1} sx={{ overflowY: 'auto' }}>
                {unscheduled && (
                  <EnrollmentList
                    enrollments={unscheduled}
                    selectedId={selectedEnrollment?.id}
                    onClick={handleEnrollmentClick}
                    onDragStart={handleDragStart}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item sm={8} md={9} lg={10}>
            {availabilityData && (
              <SemesterCalendar
                availability={availabilityData?.scheduleAvailability}
                enrollments={scheduled}
                selectedEnrollment={selectedEnrollment}
                selectedInstructors={selectedInstructors}
                draggableAccessor='isDraggable'
                onEventDrop={handleMoveEvent}
                draggedEvent={draggedEvent || undefined}
                onSelectSlot={handleSelectSlot}
                byResource={viewByInstructor}
                selectable
              />
            )}
          </Grid>
        </Grid>
      </SchedulingConsiderationsContextProvider>
    </ScheduleSolverContextProvider>
  );
};

export default Scheduler;

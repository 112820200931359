import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';

interface ErrorAlertProps {
  error: React.ReactNode;
}

type Props = ErrorAlertProps;

const ErrorAlert = ({ error, ...props }: Props) => {
  return (
    <Container>
      <Box sx={{ py: 3 }} {...props}>
        <Alert severity='error'>
          <AlertTitle>An error has occurred</AlertTitle>
          {error}
        </Alert>
      </Box>
    </Container>
  );
};

export default ErrorAlert;

import { Availability, AvailabilityItem, DayOfWeek } from 'generated/graphql';
import { PartialBy } from 'types';

export const allDaysOfWeek = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday,
];

export const weekdayMondayIndexMap = {
  [DayOfWeek.Monday]: 0,
  [DayOfWeek.Tuesday]: 1,
  [DayOfWeek.Wednesday]: 2,
  [DayOfWeek.Thursday]: 3,
  [DayOfWeek.Friday]: 4,
  [DayOfWeek.Saturday]: 5,
  [DayOfWeek.Sunday]: 6,
};
export const dayOfWeekIndexToStringMap: Record<number, DayOfWeek> = {
  0: DayOfWeek.Monday,
  1: DayOfWeek.Tuesday,
  2: DayOfWeek.Wednesday,
  3: DayOfWeek.Thursday,
  4: DayOfWeek.Friday,
  5: DayOfWeek.Saturday,
  6: DayOfWeek.Sunday,
};
export const weekdaySundayIndexMap = {
  [DayOfWeek.Sunday]: 0,
  [DayOfWeek.Monday]: 1,
  [DayOfWeek.Tuesday]: 2,
  [DayOfWeek.Wednesday]: 3,
  [DayOfWeek.Thursday]: 4,
  [DayOfWeek.Friday]: 5,
  [DayOfWeek.Saturday]: 6,
};

export interface AvailabilityOptions {
  locationIds?: number[];
  siteIds?: number[];
  skillIds?: number[];
}

export function filterAvailabilityItems(items: AvailabilityItem[], opts?: AvailabilityOptions) {
  if (!opts) {
    return items;
  }

  return items.filter((availabilityItem) => {
    // If item doesn't have any locations, then we assume it's valid for all locations and therefore skip location
    // checks.
    if (availabilityItem.locations?.length) {
      if (opts.siteIds?.length) {
        const siteIds = opts.siteIds;
        if (!availabilityItem.locations?.some((location) => location.siteId && siteIds.includes(location.siteId))) {
          return false;
        }
      }

      if (opts.locationIds?.length) {
        const locationIds = opts.locationIds;
        if (!availabilityItem.locations?.some((location) => locationIds.includes(location.id))) {
          return false;
        }
      }
    }

    // If item doesn't have any skills assigned, then we assume it's valid for all skills.
    if (availabilityItem.skills?.length && opts.skillIds?.length) {
      const skillIds = opts.skillIds;
      if (!availabilityItem.skills.some((skill) => skillIds.includes(skill.id))) {
        return false;
      }
    }

    return true;
  });
}

export function getAvailabilityDaysOfWeek(availability: Availability, opts?: AvailabilityOptions) {
  if (!availability.availabilityItems?.length) {
    return [];
  }

  const filteredItems: AvailabilityItem[] = opts
    ? filterAvailabilityItems(availability.availabilityItems, opts)
    : availability.availabilityItems;

  return Array.from(new Set(filteredItems.map((x) => x.dayOfWeek)));
}

export function sortAvailabilityItems<T extends PartialBy<AvailabilityItem, 'id'> = PartialBy<AvailabilityItem, 'id'>>(
  items: T[]
): T[] {
  const sorted = items.slice();
  sorted.sort((a, b) => {
    const dayA = a.dayOfWeek;
    const dayB = b.dayOfWeek;
    if (dayA !== dayB) {
      return weekdayMondayIndexMap[dayA] - weekdayMondayIndexMap[dayB];
    }

    const startTimeA = a.startTime ?? '00:00:00';
    const startTimeB = b.startTime ?? '00:00:00';
    const endTimeA = a.endTime ?? '23:59:59';
    const endTimeB = b.endTime ?? '23:59:59';

    if (startTimeA < startTimeB) {
      return -1;
    }

    if (startTimeA > startTimeB) {
      return 1;
    }

    if (endTimeA === endTimeB) {
      return 0;
    }

    return endTimeA < endTimeB ? -1 : 1;
  });
  return sorted;
}

import { EnrollmentFromQuery, NoPreferenceType, QueriedInstructorProfile } from 'types';
import { SchedulerFilters } from 'pages/Admin/Scheduler/types';
import { UserRoleUtils } from 'utils/user/roleUtils';

export default function filterEnrollments(
  enrollments: EnrollmentFromQuery[],
  filters: SchedulerFilters,
  isScheduledList = false
) {
  const lowerSearch = filters.search?.toLowerCase();

  return enrollments.filter((enrollment) => {
    if (
      filters.scheduleStatus &&
      filters.scheduleStatus.length > 0 &&
      !filters.scheduleStatus.includes(enrollment.schedulingStatus)
    ) {
      return false;
    }

    if (
      filters.instructors &&
      filters.instructors.length > 0 &&
      !filters.instructors.find(instructorMatcher(enrollment, isScheduledList))
    ) {
      return false;
    }

    if (
      filters.instruments &&
      filters.instruments.length > 0 &&
      enrollment.skill?.skillName &&
      !filters.instruments.includes(enrollment.skill?.skillName)
    ) {
      return false;
    }

    if (lowerSearch && lowerSearch.trim()) {
      if (enrollment.label?.toLowerCase().includes(lowerSearch)) {
        return true;
      }

      const studentName = UserRoleUtils.getFullName(enrollment.studentProfile.user);
      if (studentName.toLowerCase().includes(lowerSearch)) {
        return true;
      }

      if (enrollment.studentProfile.user?.email?.toLowerCase().includes(lowerSearch)) {
        return true;
      }

      const instructor = enrollment.scheduledInstructor || enrollment.preferredInstructor;
      const instructorName = instructor ? UserRoleUtils.getFullName(instructor.user) : 'You Pick!';
      if (instructorName.toLowerCase().includes(lowerSearch)) {
        return true;
      }

      if (enrollment.skill?.skillName.toLowerCase().includes(lowerSearch)) {
        return true;
      }

      // Match on account holder emails.
      const accountHolderRoles = UserRoleUtils.getAccountHolderRoles(enrollment.studentProfile.account);
      if (accountHolderRoles.some((role) => role.user?.email?.toLowerCase()?.includes(lowerSearch))) {
        return true;
      }

      return false;
    }

    return true;
  });
}

function instructorMatcher(
  enrollment: EnrollmentFromQuery,
  isScheduledList = false
): (x: QueriedInstructorProfile | NoPreferenceType) => boolean {
  return (x: QueriedInstructorProfile | NoPreferenceType) => {
    return typeof x === 'string'
      ? isScheduledList || !enrollment.preferredInstructorId
      : x.profileId === (enrollment.scheduledInstructorId || enrollment.preferredInstructorId);
  };
}

import React, { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';

const Container = styled('div')`
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #fff;
  margin-top: -2rem;
`;

const ContentContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default ContentContainer;

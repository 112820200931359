import React from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CheckMarkLogo from 'assets/checkmark-icon.svg';
import { useAccountContext } from 'contexts/AccountContext/AccountContext';
import theme from 'theme';
import { useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import { StudentProfile } from 'generated/graphql';
import Enrollment from 'components/templates/Enrollment/Enrollment';
import AddNewStudentButton from 'components/molecules/AddNewStudentButton/AddNewStudentButton';
import { getSecondaryColor, STUDENT_COLORS } from 'utils/colors/studentColors';
import hexToRGB from 'utils/colors/hexToRGB';
import ErrorAlert from 'components/molecules/ErrorAlert/ErrorAlert';

const StudentListItem = styled('div')`
  display: flex;
  width: 100%;
  min-height: 3.875rem;
  flex-shrink: 0;

  border-radius: 0.625rem;
  background: ${theme.colors.cloudy};
  box-shadow: 0 0.25rem 0.5rem 0 rgba(66, 129, 252, 0.45);
  margin-bottom: 0.938rem;
  cursor: pointer;

  &.selected {
    position: relative;
    border-radius: 0.875rem;
    border: 0.25rem solid #4281fc;

    img {
      position: absolute;
      top: -0.625rem;
      right: -0.625rem;
    }
  }

  .student-color {
    width: 2rem;
    height: 3.875rem;
    flex-shrink: 0;
    border-radius: 0.625rem 0 0 0.625rem;
  }

  .student-name {
    display: flex;
    width: 100%;
    min-height: 3.75rem;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    padding-left: 0.5rem;
  }
`;

const EnrollmentPage = () => {
  const { selectedStudents, setSelectedStudents, schedule } = useEnrollmentContext();
  const { students } = useAccountContext();

  const isStudentSelected = (studentId: number) => {
    return selectedStudents && selectedStudents.filter((student) => student.id === studentId).length > 0;
  };
  const onSelectStudent = (student: StudentProfile) => {
    if (selectedStudents?.some((s) => s.id === student.id)) {
      setSelectedStudents(selectedStudents.filter((s) => s.id !== student.id));
    } else {
      setSelectedStudents([...(selectedStudents ?? []), student]);
    }
  };

  const { scheduleId: id } = useParams<{ scheduleId: string }>();
  const scheduleId = id ? parseInt(id, 10) : undefined;
  if (!scheduleId) {
    return <ErrorAlert error='Schedule ID missing from URL, page not found.' />;
  }

  return (
    <Enrollment
      next={{
        path: 'semester-information?reviewStudentsInfo=true',
        label: 'Begin Registration',
        disabled: selectedStudents?.length === 0,
      }}
    >
      <Typography variant={'h3'} fontWeight={'normal'} fontSize={'1.5rem'} sx={{ mb: 2 }}>
        {schedule?.name}
      </Typography>
      <Typography variant={'h4'} fontWeight={'normal'} fontSize={'1.25rem'} sx={{ mb: 2 }}>
        Select users to register:
      </Typography>
      <div>
        {students?.map((student) => {
          const isSelected = isStudentSelected(student.id);
          const { firstName, lastName } = student.user || {};
          const studentColor = student?.color || STUDENT_COLORS.purple.primary;
          const studentColorSecondary = hexToRGB(getSecondaryColor(studentColor), '0.72');
          return (
            <StudentListItem
              key={student.userId}
              className={isSelected ? 'selected' : ''}
              onClick={() => onSelectStudent(student)}
            >
              <Box
                className={'student-color'}
                sx={{ background: `linear-gradient(90deg, ${studentColor} 0%, ${studentColorSecondary} 100%)` }}
              />
              <div className={'student-name'}>
                <Typography variant={'subtitle1'} fontWeight={500} fontSize={'1rem'}>
                  {firstName} - {lastName}
                </Typography>
              </div>
              {isSelected && <img src={CheckMarkLogo} alt={student.user?.firstName || 'alt'} />}
            </StudentListItem>
          );
        })}
      </div>
      <AddNewStudentButton scheduleId={scheduleId} />
      <Typography fontSize={'1rem'} fontFamily={'Inter'}>
        Next, you’ll register each student selected. Then, you’ll be provided a summary and asked to confirm before your
        registration is finalized.{' '}
      </Typography>
    </Enrollment>
  );
};

export default EnrollmentPage;

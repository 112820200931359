import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const KEY_PREFIX = 'lyriq.local_storage_state.';
const TIMESTAMP_SUFFIX = '.timestamp';
const STATE_KEY_PARAM = 'sKey';

function getItem<T = unknown>(key: string, ttl?: number): T | null {
  const value = localStorage.getItem(key);

  // If ttl is provided, check if this has expired.
  if (ttl != null) {
    const timestampKey = `${key}${TIMESTAMP_SUFFIX}`;
    const timestampString = localStorage.getItem(timestampKey);
    const timestamp = timestampString ? parseInt(timestampString, 10) : null;
    if (timestamp && new Date().getTime() - timestamp > ttl) {
      // Storage is expired, so remove it and return null.
      removeItem(key);
      return null;
    }
  }

  return value != null ? JSON.parse(value) : value;
}

function setItem(key: string, value?: unknown): void {
  const timestampKey = `${key}${TIMESTAMP_SUFFIX}`;
  if (value != null) {
    localStorage.setItem(key, JSON.stringify(value));
    localStorage.setItem(timestampKey, new Date().getTime().toString());
  } else {
    removeItem(key);
  }
}

function removeItem(key: string) {
  const timestampKey = `${key}${TIMESTAMP_SUFFIX}`;
  localStorage.removeItem(key);
  localStorage.removeItem(timestampKey);
}

type ClearStorage = () => void;

export default function useLocalStorageState<S = undefined>(
  key: string,
  initialState: S | (() => S),
  ttl?: number
): [S, Dispatch<SetStateAction<S>>, ClearStorage] {
  const [search] = useSearchParams();
  const stateKey = search.get(STATE_KEY_PARAM) ? `s__${search.get(STATE_KEY_PARAM)}.` : '';
  const fullKey = `${KEY_PREFIX}${stateKey}${key}`;
  const initialStorageValue = useMemo(() => getItem<S>(fullKey, ttl), [fullKey, ttl]);
  const [initialValue] = useState(initialState);
  const [value, setValue] = useState<S>(initialStorageValue != null ? initialStorageValue : initialState);

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(initialValue)) {
      setItem(fullKey, value);
    } else {
      removeItem(fullKey);
    }
  }, [fullKey, initialValue, value]);

  const clearStorage = useCallback(() => {
    removeItem(fullKey);
    setValue(initialValue);
  }, [fullKey, initialValue]);

  return [value, setValue, clearStorage];
}

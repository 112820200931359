import React, { useMemo } from 'react';
import { parseSolverScoreText } from 'utils/SemesterCalendar/parseSolverScoreText';
import { HardSoftScore } from 'generated/graphql';
import DangerousIcon from '@mui/icons-material/Dangerous';
import VerifiedIcon from '@mui/icons-material/Verified';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SmallBadge from 'components/atoms/SmallBadge/SmallBadge';

interface SolverScoreDisplayProps {
  scoreText?: string;
}

type Props = SolverScoreDisplayProps;

const SolverScoreDisplay = ({ scoreText }: Props) => {
  const score = useMemo<HardSoftScore | undefined>(() => parseSolverScoreText(scoreText), [scoreText]);

  if (!score) {
    return null;
  }

  const hasHard = !!score.hardScore;
  const hasSoft = !!score.softScore;
  const hasInit = !!score.initScore;

  if (hasInit) {
    return null;
  }

  const HardIcon = hasHard ? (score.hardScore > 0 ? VerifiedIcon : DangerousIcon) : undefined;
  const SoftIcon = hasSoft
    ? score.softScore > 0
      ? SentimentVerySatisfiedIcon
      : SentimentVeryDissatisfiedIcon
    : undefined;

  return (
    <>
      {HardIcon && (
        <SmallBadge badgeContent={score.hardScore} color='secondary' overlap='circular'>
          <HardIcon color={score.hardScore > 0 ? 'success' : 'error'} />
        </SmallBadge>
      )}
      {SoftIcon && (
        <SmallBadge badgeContent={score.softScore} color='secondary' overlap='circular'>
          <SoftIcon color={score.softScore > 0 ? 'success' : 'warning'} />
        </SmallBadge>
      )}
    </>
  );
};

export default SolverScoreDisplay;

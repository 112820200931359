import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetAdminAppointmentsDocument,
  GetAdminAppointmentsQuery,
  GetAdminAppointmentsQueryVariables,
} from 'generated/graphql';

type Data = GetAdminAppointmentsQuery;
type Variables = GetAdminAppointmentsQueryVariables;

function useQueryAdminAppointments(options?: QueryHookOptions<Data, Variables>): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(GetAdminAppointmentsDocument, {
    ...options,
  });
}

export default useQueryAdminAppointments;

import React from 'react';
import { MenuItemProps } from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export type SelectOption = { label: string; value: string };

interface MultipleSelectCheckmarksProps {
  id: string;
  label: string;
  placeholder?: string;
  options: SelectOption[];
  value?: string[];
  onChange?: (value: string[]) => void;
  listItemProps?: Partial<MenuItemProps>;
  listItemPropsGetter?: (option: SelectOption) => Partial<MenuItemProps>;
}

type Props = MultipleSelectCheckmarksProps &
  Partial<Omit<AutocompleteProps<SelectOption, true, false, false>, 'onChange' | 'value'>>;

const MultipleSelectCheckmarks = ({
  id,
  label,
  placeholder,
  options,
  onChange,
  value,
  listItemProps,
  listItemPropsGetter,
  ...props
}: Props) => {
  const handleChange = (event: React.SyntheticEvent, newValue?: SelectOption[]) => {
    onChange && onChange(newValue ? newValue.map((val) => val.value) : []);
  };

  return (
    <Autocomplete
      multiple
      id={id}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <Box component='li' {...listItemProps} {...(listItemPropsGetter && listItemPropsGetter(option))} {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.label}
        </Box>
      )}
      {...props}
      value={options.filter((option) => !!value && value.includes(option.value))}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label={label} placeholder={placeholder} />}
    />
  );
};

export default MultipleSelectCheckmarks;

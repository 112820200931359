import React from 'react';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { EnrollmentFromQuery } from 'types';
import { PackageType } from 'generated/graphql';
import IconButton from '@mui/material/IconButton';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ClearIcon from '@mui/icons-material/Clear';

const ListWrapper = styled('div')`
  padding: 1.69rem 1.06rem;
  background: #d9d9d9;
`;

const EnrollmentCard = styled('div')<{ isSelected?: boolean }>(
  ({ isSelected }) => css`
    cursor: pointer;
    background: #fdfdfd;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    max-width: unset;
    width: 100%;
    padding: 0.56rem 0.81rem;
    border: 3px solid transparent;

    ${isSelected
      ? css`
          border-color: #4281fc;
        `
      : ''}
  `
);

const ScheduleButtonWrap = styled('div')`
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
`;

interface EnrollmentListProps {
  enrollments: EnrollmentFromQuery[];
  selectedId?: number;
  onDragStart?: (enrollment: EnrollmentFromQuery) => void;
  onClick?: (enrollment: EnrollmentFromQuery) => void;
  onScheduleClick?: (enrollment: EnrollmentFromQuery) => void;
}

type Props = EnrollmentListProps;

const EnrollmentList = ({ enrollments, selectedId, onClick, onScheduleClick }: Props) => {
  const dialogStates: boolean[] = [];
  const popperStates: boolean[] = [];
  for (let i = 0; i < enrollments.length; i++) {
    dialogStates[i] = false;
    popperStates[i] = false;
  }

  return (
    <ListWrapper>
      <Stack spacing={2} alignItems='center'>
        {enrollments.map((enrollment) => {
          const {
            id,
            skill,
            scheduledInstructor,
            preferredInstructor,
            studentProfile,
            package: enrollmentPackage,
          } = enrollment;

          const isSelected = id === selectedId;
          const instructor = scheduledInstructor || preferredInstructor;

          return (
            <EnrollmentCard key={id} isSelected={isSelected} onClick={onClick && (() => onClick(enrollment))}>
              <Typography sx={{ fontSize: '1rem', fontWeight: 600 }}>
                {studentProfile.user?.firstName} {studentProfile.user?.lastName}
              </Typography>
              {enrollmentPackage.packageType === PackageType.PublicGroupLesson ? (
                <>
                  <Typography variant='body2' mt={1} sx={{ fontWeight: 600 }}>
                    {enrollmentPackage.name}
                  </Typography>
                  {!!enrollmentPackage.teacherDisplayText && (
                    <Typography variant='body2' mt={1}>
                      {enrollmentPackage.teacherDisplayText}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography variant='body2' mt={1} sx={{ fontWeight: 600 }}>
                  {(skill?.skillName || enrollmentPackage.name) && (
                    <Box component='span'>{skill?.skillName || enrollmentPackage.name}</Box>
                  )}
                  {instructor ? ` - ${instructor.user?.firstName} ${instructor.user?.lastName}` : ' - You Pick!'}
                </Typography>
              )}
              {skill?.skillName && (
                <Typography variant='body2' mt={1}>
                  {enrollmentPackage.name}
                </Typography>
              )}
              <ScheduleButtonWrap>
                <IconButton
                  onClick={
                    onScheduleClick
                      ? (e) => {
                          e.stopPropagation();
                          onScheduleClick(enrollment);
                        }
                      : undefined
                  }
                  sx={isSelected ? { color: '#4281fc' } : undefined}
                >
                  {isSelected ? <ClearIcon fontSize='inherit' /> : <AdsClickIcon fontSize='inherit' />}
                </IconButton>
              </ScheduleButtonWrap>
            </EnrollmentCard>
          );
        })}
      </Stack>
    </ListWrapper>
  );
};

export default EnrollmentList;

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ScheduleSolverControls from 'components/organisms/ScheduleSolverControls/ScheduleSolverControls';
import ScheduleSelectField from 'components/organisms/ScheduleSelectField/ScheduleSelectField';
import SchedulerViewControl from 'pages/Admin/NewScheduler/components/SchedulerViewControl/SchedulerViewControl';
import Button from '@mui/material/Button';
import { v4 as uuidv4 } from 'uuid';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const SchedulerHeader = () => {
  const [sKey, setSKey] = useState<string>(() => uuidv4());
  const newUrl = `/manage/new-scheduler?sKey=${sKey}`;

  return (
    <Wrapper>
      <Box sx={{ mr: 2 }}>
        <Button
          variant='outlined'
          color='inherit'
          href={newUrl}
          onClick={() => setSKey(uuidv4())}
          target='_blank'
          size='small'
        >
          Open New Tab
        </Button>
      </Box>
      <Box sx={{ mr: 4 }}>
        <Button
          variant='outlined'
          color='inherit'
          onClick={() => {
            setSKey(uuidv4());
            const width = window.innerWidth * 0.66;
            const height = (width * window.innerHeight) / window.innerWidth;
            // Ratio the height to the width as the user screen ratio
            window.open(
              newUrl,
              '_blank',
              `width=${width}, height=${height}, top=${(window.innerHeight - height) / 2}, left=${
                (window.innerWidth - width) / 2
              }`
            );
          }}
          size='small'
        >
          Open New Window
        </Button>
      </Box>
      <Box sx={{ width: 300, mr: 4 }}>
        <ScheduleSelectField id='scheduler-schedule-select' label='Change schedule' />
      </Box>
      <Box sx={{ mr: 4 }}>
        <SchedulerViewControl />
      </Box>
      <ScheduleSolverControls />
    </Wrapper>
  );
};

export default SchedulerHeader;

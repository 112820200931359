import { styled } from '@mui/material';
import { css } from '@mui/material/styles';

const ContentHeaderWrapper = styled('div')(
  ({ theme }) => css`
    margin: 1.5rem 0.5rem;

    h1 {
      color: ${theme.colors.enrollText};
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  `
);

export default ContentHeaderWrapper;

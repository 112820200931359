import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetSchedulesDocument, GetSchedulesQuery, GetSchedulesQueryVariables } from 'generated/graphql';

function useQueryGetSchedules(
  options?: QueryHookOptions<GetSchedulesQuery, GetSchedulesQueryVariables>
): QueryResult<GetSchedulesQuery, GetSchedulesQueryVariables> {
  return useQuery<GetSchedulesQuery, GetSchedulesQueryVariables>(GetSchedulesDocument, {
    ...options,
  });
}

export default useQueryGetSchedules;

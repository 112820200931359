import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const SIZES = {
  headerHeight: 3.5,
  headerTitleHeight: 1.375,
  rowHeight: 4.1875,
  gutterWidth: 4.375,
  minColumnWidth: 9.5,
  borders: '1px solid #dedede',
  gutterBackground: '#fff',
  headerBackground: '#fff',
  headerTitleBackground: '#d9d9d9',
  slotBackground: '#f7f7f7',
};

export const StyledHeader = styled('div')({
  height: `${SIZES.headerHeight}rem`,
  width: '100%',
  display: 'flex',
  position: 'sticky',
  top: 0,
  zIndex: 100,
});

export const HeaderGutterCell = styled('div')({
  width: `${SIZES.gutterWidth}rem`,
  flexGrow: 0,
  flexShrink: 0,
  border: SIZES.borders,
  position: 'sticky',
  left: 0,
  zIndex: 12,
  background: SIZES.headerBackground,
});

export const HeaderContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: `calc(100% - ${SIZES.gutterWidth}rem)`,
  flexGrow: 1,
  flexShrink: 0,
});

export const HeaderTitle = styled('div')(({ $bgColor }) => ({
  textAlign: 'center',
  height: `${SIZES.headerTitleHeight}rem`,
  background: $bgColor ?? SIZES.headerTitleBackground,
  fontSize: '0.8125rem',
  fontWeight: 600,
}));

export const HeaderCols = styled('div')({ display: 'flex', flexGrow: 1, flexShrink: 0 });

export const HeaderCell = styled(Box)({
  minWidth: `${SIZES.minColumnWidth}rem`,
  flexGrow: 0,
  flexShrink: 0,
  borderBottom: SIZES.borders,
  background: SIZES.headerBackground,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '0.75rem',
});

export const BodyContent = styled('div')({ display: 'flex' });

export const GutterCol = styled('div')({
  width: `${SIZES.gutterWidth}rem`,
  flexGrow: 0,
  flexShrink: 0,
  minHeight: '100%',
  position: 'sticky',
  left: 0,
  background: SIZES.gutterBackground,
  zIndex: 10,
});

export const GutterCell = styled('div')({
  height: `${SIZES.rowHeight}rem`,
  width: '100%',
  borderLeft: SIZES.borders,
  borderRight: SIZES.borders,
  borderBottom: SIZES.borders,
  flexGrow: 0,
  flexShrink: 0,
  background: SIZES.gutterBackground,
  fontSize: '0.8125rem',
  textAlign: 'right',
  padding: '0.25rem 0.38rem 0.25rem 0.2rem',
  zIndex: 10,
});

export const BodyCols = styled('div')({
  width: `calc(100% - ${SIZES.gutterWidth}rem)`,
  position: 'relative',
  display: 'flex',
});

export const BodyCol = styled(Box)({
  minWidth: `${SIZES.minColumnWidth}rem`,
  minHeight: '100%',
  flexGrow: 0,
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

export const BodyCell = styled(Box)({
  height: `${SIZES.rowHeight}rem`,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderLeft: SIZES.borders,
  borderBottom: SIZES.borders,
  flexGrow: 0,
  flexShrink: 0,
  background: SIZES.slotBackground,
});

export const FooterCol = styled(Box)({
  minWidth: `${SIZES.minColumnWidth}rem`,
  flexGrow: 0,
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  bottom: 0,
  zIndex: 100,
});

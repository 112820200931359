import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  CreateEnrollmentsDocument,
  CreateEnrollmentsMutation,
  CreateEnrollmentsMutationVariables,
} from 'generated/graphql';
import { useMemo } from 'react';

type Data = CreateEnrollmentsMutation;
type Variables = CreateEnrollmentsMutationVariables;

function useMutationCreateEnrollment(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(CreateEnrollmentsDocument, opts);
}

export default useMutationCreateEnrollment;

import React, { createContext, useContext, useMemo } from 'react';
import { EnrollmentSchedulingConsiderationsQuery } from 'generated/graphql';
import useQueryEnrollmentSchedulingConsiderations from 'hooks/queries/useQueryEnrollmentSchedulingConsiderations/useQueryEnrollmentSchedulingConsiderations';

type SiblingEnrollmentsDetail =
  EnrollmentSchedulingConsiderationsQuery['enrollmentSchedulingConsiderations']['siblingEnrollments'][number];
type MultipleEnrollmentsDetail =
  EnrollmentSchedulingConsiderationsQuery['enrollmentSchedulingConsiderations']['multipleEnrollments'][number];
type SiblingEnrollmentsMap = Record<number, SiblingEnrollmentsDetail>;
type MultipleEnrollmentsMap = Record<number, MultipleEnrollmentsDetail>;

type SchedulingConsiderationsContextState = {
  loading: boolean;
  error?: Error;
  siblingEnrollmentsMap?: SiblingEnrollmentsMap;
  multipleEnrollmentsMap?: MultipleEnrollmentsMap;
  scheduleId?: number;
};

const SchedulingConsiderationsContext = createContext<SchedulingConsiderationsContextState | undefined>(undefined);

interface SchedulingConsiderationsContextProviderProps {
  scheduleId?: number;
  children: React.ReactNode;
}

type Props = SchedulingConsiderationsContextProviderProps;

const SchedulingConsiderationsContextProvider = ({ scheduleId, children }: Props) => {
  const { data, loading, error } = useQueryEnrollmentSchedulingConsiderations({
    skip: !scheduleId,
    variables: scheduleId
      ? {
          input: {
            scheduleId,
          },
        }
      : undefined,
  });

  const siblingEnrollments = data?.enrollmentSchedulingConsiderations.siblingEnrollments;
  const multipleEnrollments = data?.enrollmentSchedulingConsiderations.multipleEnrollments;

  const siblingEnrollmentsMap = useMemo<SiblingEnrollmentsMap | undefined>(() => {
    if (!siblingEnrollments) {
      return;
    }

    return siblingEnrollments.reduce<SiblingEnrollmentsMap>((mapping, data) => {
      mapping[data.enrollmentId] = data;
      return mapping;
    }, {});
  }, [siblingEnrollments]);

  const multipleEnrollmentsMap = useMemo<MultipleEnrollmentsMap | undefined>(() => {
    if (!multipleEnrollments) {
      return;
    }

    return multipleEnrollments.reduce<MultipleEnrollmentsMap>((mapping, data) => {
      mapping[data.studentProfileId] = data;
      return mapping;
    }, {});
  }, [multipleEnrollments]);

  // Memoize the state value to improve context performance.
  const stateValue = useMemo<SchedulingConsiderationsContextState>(
    () => ({
      siblingEnrollmentsMap,
      multipleEnrollmentsMap,
      loading,
      error,
      scheduleId,
    }),
    [error, loading, multipleEnrollmentsMap, siblingEnrollmentsMap, scheduleId]
  );

  return (
    <SchedulingConsiderationsContext.Provider value={stateValue}>{children}</SchedulingConsiderationsContext.Provider>
  );
};

const useSchedulingConsiderationsContext = () => {
  const context = useContext(SchedulingConsiderationsContext);

  if (context === undefined) {
    throw new Error('useSchedulingConsiderationsContext was used outside of its Provider');
  }

  return context;
};

export { SchedulingConsiderationsContext, SchedulingConsiderationsContextProvider, useSchedulingConsiderationsContext };

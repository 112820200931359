import React, { useCallback, useState } from 'react';
import { EnrollmentFromQuery } from 'types';
import Button, { ButtonProps } from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import useMutationUpdateEnrollmentStatus from 'hooks/mutations/useMutationUpdateEnrollmentStatus/useMutationUpdateEnrollmentStatus';

interface EnrollmentLockToggleProps {
  enrollment: EnrollmentFromQuery;
  buttonProps?: ButtonProps;
}

type Props = EnrollmentLockToggleProps;

const EnrollmentLockToggle = ({ enrollment, buttonProps }: Props) => {
  const [updateEnrollmentStatus, { loading }] = useMutationUpdateEnrollmentStatus();
  const [mouseOver, setMouseOver] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    return updateEnrollmentStatus({
      variables: {
        input: {
          id: enrollment.id,
          scheduleLocked: !enrollment.scheduleLocked,
        },
      },
      optimisticResponse: {
        updateEnrollmentStatus: {
          success: true,
          __typename: 'BaseStatusResult',
        },
      },
    });
  }, [enrollment.id, enrollment.scheduleLocked, updateEnrollmentStatus]);

  const showInverse = mouseOver && !loading;

  const showLocked = (enrollment.scheduleLocked && !showInverse) || (!enrollment.scheduleLocked && showInverse);
  const label = mouseOver
    ? enrollment.scheduleLocked
      ? 'Unlock'
      : 'Lock'
    : enrollment.scheduleLocked
    ? 'Locked'
    : 'Unlocked';

  return (
    <Button
      color='primary'
      size='small'
      {...buttonProps}
      endIcon={showLocked ? <LockIcon /> : <LockOpenIcon />}
      onClick={handleClick}
      disabled={loading}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      variant={showLocked ? 'contained' : 'outlined'}
    >
      {label}
    </Button>
  );
};

export default EnrollmentLockToggle;

import React, { useMemo } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache, split } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';
import { createHttpLink } from '@apollo/client';
import env from 'config/env';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';

const httpLink = createHttpLink({
  uri: env.graphql.apiUrl,
});

interface AuthorizedApolloProviderProps {
  children: React.ReactNode;
}

type Props = AuthorizedApolloProviderProps;

const AuthorizedApolloProvider = ({ children }: Props) => {
  const { getAccessTokenSilently } = useAuth0();

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        return getAccessTokenSilently()
          .then((token) => {
            if (typeof Storage !== 'undefined') {
              localStorage.setItem('token', token);
            }
            // return the headers to the context so httpLink can read them
            return {
              headers: {
                ...headers,
                ...(token ? { authorization: `Bearer ${token}` } : {}),
              },
            };
          })
          .catch(() => {
            return headers;
          });
      }),
    [getAccessTokenSilently]
  );

  const combinedHttpLink = useMemo(() => authLink.concat(httpLink), [authLink]);

  const wsLink = useMemo(() => {
    return new GraphQLWsLink(
      createClient({
        url: env.graphql.subscriptionsUrl,
        connectionParams: async () => {
          const token = await getAccessTokenSilently();

          if (typeof Storage !== 'undefined') {
            localStorage.setItem('token', token);
          }

          return token
            ? {
                authToken: token,
              }
            : {};
        },
      })
    );
  }, [getAccessTokenSilently]);

  const splitLink = useMemo(() => {
    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      combinedHttpLink
    );
  }, [combinedHttpLink, wsLink]);

  const client = useMemo(
    () =>
      new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache({
          typePolicies: {
            SolverEnrollmentSolution: {
              keyFields: ['enrollmentId'],
            },
          },
        }),
      }),
    [splitLink]
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;

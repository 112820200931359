import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetConfigurationsDocument, GetConfigurationsQuery, GetConfigurationsQueryVariables } from 'generated/graphql';

function useQueryGetConfigurations(
  options?: QueryHookOptions<GetConfigurationsQuery, GetConfigurationsQueryVariables>
): QueryResult<GetConfigurationsQuery, GetConfigurationsQueryVariables> {
  return useQuery<GetConfigurationsQuery, GetConfigurationsQueryVariables>(GetConfigurationsDocument, {
    ...options,
  });
}

export default useQueryGetConfigurations;

import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

interface LoadingIndicatorProps {
  sx?: BoxProps['sx'];
}

type Props = LoadingIndicatorProps & Partial<CircularProgressProps>;

const LoadingIndicator = ({ sx, ...props }: Props) => {
  return (
    <Box sx={{ display: 'flex', ...(sx || {}) }}>
      <CircularProgress {...props} />
    </Box>
  );
};

export default LoadingIndicator;

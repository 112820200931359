import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScheduleState } from 'generated/graphql';
import useQueryGetSchedules from 'hooks/queries/useQueryGetSchedules/useQueryGetSchedules';
import useQueryGetConfigurations from 'hooks/queries/useQueryGetConfigurations/useQueryGetConfigurations';
import parse from 'html-react-parser';
import ContentWrapper from 'components/atoms/ContentWrapper/ContentWrapper';
import { Box, styled } from '@mui/material';
import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';

const DEFAULT_EMPTY_STATE_MESSAGE =
  '<p>Enrollment is not available yet, check back soon or visit <a href="https://lyriqmusic.com" target="_blank">lyriqmusic.com</a> for more information.</p>';

const PageWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
`;

const EnrollmentRedirectPage = () => {
  const navigate = useNavigate();
  const ACTIVE_UPCOMING_SCHEDULE_STATUSES = [ScheduleState.Active, ScheduleState.Scheduling, ScheduleState.Open];

  // get schedules with status: [“Open”, “Scheduling”, or “Active”]
  const { data, loading: loadingSchedules } = useQueryGetSchedules({
    variables: {
      input: {
        filters: {
          status: ACTIVE_UPCOMING_SCHEDULE_STATUSES,
        },
      },
    },
  });
  const { data: configurationsData, loading: loadingConfigurations } = useQueryGetConfigurations();

  const schedules = useMemo(() => data?.getSchedules?.results, [data?.getSchedules?.results]);
  const hasSchedules = schedules && schedules.length > 0;
  const target = Date.now();
  let scheduleId = -1;
  let nearest = Infinity;
  hasSchedules &&
    schedules.forEach((sc) => {
      const time = new Date(sc.startDate).getTime();
      const distance = Math.abs(time - target);
      if (distance < nearest) {
        nearest = distance;
        scheduleId = sc.id;
      }
    });

  if (scheduleId !== -1) {
    navigate(`/enroll/${scheduleId}`, { replace: true });
    return null;
  }

  const emptyStateMessage =
    configurationsData?.configurations.find((x) => x.configKey === 'ENROLL_EMPTY_STATE_CONTENT')?.configValue ||
    DEFAULT_EMPTY_STATE_MESSAGE;

  return (
    <PageWrapper>
      {loadingSchedules || loadingConfigurations ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingIndicator />
        </Box>
      ) : (
        <div>{parse(emptyStateMessage)}</div>
      )}
    </PageWrapper>
  );
};

export default EnrollmentRedirectPage;

import { Account, User } from 'generated/graphql';

export class UserAccountUtils {
  static getAccounts(user: User): Account[] {
    const accounts: Account[] = [];

    if (!user.roles) {
      return accounts;
    }

    user.roles.forEach((role) => {
      if (role.account && !accounts.find((account) => account.id === role.account?.id)) {
        accounts.push(role.account);
      }
    });

    return accounts;
  }
}

import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home/HomePage';
import LoginPage from './pages/Login/LoginPage';
import RequireAuth from './requires/RequireAuth/RequireAuth';
import RegisterPage from './pages/Register/RegisterPage';
import RegisterUser from './pages/RegisterUser/RegisterUser';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import Box from '@mui/material/Box';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserContext } from 'contexts/UserContext/UserContext';
import LoadingIndicator from './components/atoms/LoadingIndicator/LoadingIndicator';
import BottomNav from './components/organisms/BottomNav/BottomNav';
import MenuAppBar from './components/organisms/MenuAppBar/MenuAppBar';
import AccountPage from './pages/Account/AccountPage';
import StudentsPage from './pages/Students/StudentsPage';
import StudentHome from './pages/Home/pages/StudentHome/StudentHome';
import SchedulePage from './pages/Schedule/SchedulePage';
import AddStudents from './pages/AddStudents/AddStudents';
import AdminPage from './pages/Admin/AdminPage';
import useIsAdminPage from './hooks/useIsAdminPage/useIsAdminPage';
import Scheduler from './pages/Admin/Scheduler/Scheduler';
import NewScheduler from './pages/Admin/NewScheduler/NewScheduler';
import env from './config/env';
import { AuthRole } from 'utils/user/AuthUtils';
import EnrollmentConfirmPage from 'pages/EnrollmentConfirm/EnrollmentConfirmPage';
import { AccountContextProvider } from 'contexts/AccountContext/AccountContext';
import { EnrollmentContextProvider } from 'contexts/EnrollmentContext/EnrollmentContext';
import ManagerCalendar from 'pages/Admin/ManagerCalendar/ManagerCalendar';
import EnrollmentPage from 'pages/Enrollment/EnrollmentPage';
import AddStudent from 'pages/Enrollment/AddStudent';
import SemesterInformation from 'pages/Enrollment/SemesterInformation';
import LessonSelection from 'pages/Enrollment/LessonSelection';
import SignUpDetails from 'pages/Enrollment/SignUpDetails';
import TeacherSelection from 'pages/Enrollment/TeacherSelection';
import TimeframeSelection from 'pages/Enrollment/TimeframeSelection';
import Summary from 'pages/Enrollment/Summary';
import Policies from 'pages/Enrollment/Policies';
import Confirmation from 'pages/Enrollment/Confirmation';
import ProgressBar from 'pages/Enrollment/ProgressBar';
import EnrollmentRedirectPage from 'pages/Enrollment/EnrollmentRedirectPage';

function App() {
  const { isAuthenticated, isLoading: authLoading } = useAuth0();
  const {
    user,
    postLogin: { loading: postLoginLoading, data },
    isOnboardingLimited,
  } = useUserContext();
  const { onboardingState } = data || {};
  const isAdminPage = useIsAdminPage();

  return (
    <>
      {isAuthenticated && isOnboardingLimited === false && (
        <AccountContextProvider>
          <MenuAppBar />
        </AccountContextProvider>
      )}
      {(authLoading || isAuthenticated) && postLoginLoading && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', py: 4 }}>
          <LoadingIndicator />
        </Box>
      )}
      {/* Adjust for the footer */}
      <div style={{ marginBottom: '3.5rem' }}>
        {(!isAuthenticated || !postLoginLoading) && (
          <>
            <Routes>
              <Route
                path='/'
                element={
                  <RequireAuth>
                    <HomePage />
                  </RequireAuth>
                }
              />
              <Route
                path='/student-home/:id'
                element={
                  <RequireAuth>
                    {onboardingState && user ? (
                      <AccountContextProvider>
                        <StudentHome user={user} onboardingState={onboardingState} />
                      </AccountContextProvider>
                    ) : (
                      <></>
                    )}
                  </RequireAuth>
                }
              />
              <Route
                path='account'
                element={
                  <RequireAuth>
                    <AccountPage />
                  </RequireAuth>
                }
              />
              <Route
                path='students'
                element={
                  <RequireAuth>
                    <StudentsPage />
                  </RequireAuth>
                }
              />
              <Route
                path='schedule'
                element={
                  <RequireAuth>
                    <SchedulePage />
                  </RequireAuth>
                }
              />
              <Route
                path='enroll'
                element={
                  <AccountContextProvider>
                    <EnrollmentContextProvider>
                      <Outlet />
                    </EnrollmentContextProvider>
                  </AccountContextProvider>
                }
              >
                <Route path=':scheduleId' element={<EnrollmentPage />} />
                <Route path=':scheduleId/add-student' element={<AddStudent />} />
                <Route path=':scheduleId/semester-information' element={<SemesterInformation />} />
                <Route path=':scheduleId/student/:studentId/lesson-selection' element={<LessonSelection />} />
                <Route path=':scheduleId/student/:studentId/sign-up-details' element={<SignUpDetails />} />
                <Route path=':scheduleId/student/:studentId/teacher-selection' element={<TeacherSelection />} />
                <Route path=':scheduleId/student/:studentId/timeframe-selection' element={<TimeframeSelection />} />
                <Route path=':scheduleId/summary' element={<Summary />} />
                <Route path=':scheduleId/policies' element={<Policies />} />
                <Route path=':scheduleId/confirmation' element={<Confirmation />} />
                <Route index element={<EnrollmentRedirectPage />} />
              </Route>
              <Route path='enrollment/confirm' element={<EnrollmentConfirmPage />} />
              <Route
                path='register'
                element={
                  <RequireAuth>
                    <RegisterPage />
                  </RequireAuth>
                }
              >
                <Route path='user' element={<RegisterUser />} />
                <Route path='create-account' element={<CreateAccount />} />
                <Route path='verify-email' element={<VerifyEmail />} />
                <Route path='add-students' element={<AddStudents />} />
              </Route>
              <Route path='login' element={<LoginPage />} />
              <Route
                path={env.routing.adminPath}
                element={
                  <RequireAuth requiredAuthRole={AuthRole.LyriqAdmin}>
                    <AdminPage />
                  </RequireAuth>
                }
              >
                <Route path='scheduler' element={<Scheduler />} />
                <Route path='new-scheduler' element={<NewScheduler />} />
                <Route path='calendar' element={<ManagerCalendar />} />
              </Route>
            </Routes>
            <ProgressBar />
          </>
        )}
      </div>
      {isAuthenticated && !isAdminPage && <BottomNav />}
    </>
  );
}

export default App;

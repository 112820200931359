import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import Enrollment from 'components/templates/Enrollment/Enrollment';
import ContentHeaderWrapper from 'components/atoms/ContentHeaderWrapper/ContentHeaderWrapper';
import ContentBodyWrapper from 'components/atoms/ContentBodyWrapper/ContentBodyWrapper';
import HelpText from 'components/atoms/HelpText/HelpText';
import useQueryInstructorProfiles, {
  InstructorProfileData,
} from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import ErrorAlert from 'components/molecules/ErrorAlert/ErrorAlert';
import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';
import theme from 'theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import Badge from '@mui/material/Badge';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import { UserRoleUtils } from 'utils/user/roleUtils';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import parse from 'html-react-parser';
import formatSkills from 'utils/format/formatSkills';
import Stack from '@mui/material/Stack';
import { css, styled } from '@mui/material/styles';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import formatAvailabilityDays from 'utils/format/formatAvailabilityDays';
import EnrollmentHeader from 'pages/Enrollment/components/EnrollmentHeader/EnrollmentHeader';
import striptags from 'striptags';
import { Site } from 'generated/graphql';

const FULL_DESCRIPTION = 'Full Description';
const BRIEF_DESCRIPTION = 'Brief Description';
const AUTO_SHORT_DESCRIPTION_LENGTH = 170;

type SelectedProp = {
  selected: boolean;
};

const CardShadow = styled(Card)`
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
  margin: 16px;
  ${({ selected }: SelectedProp) => `outline: ${selected ? theme.colors.cobalt : 'rgba(0, 0, 0, 0)'} solid 4px;`}
  transition: outline 0.1s ease-out;
`;

const CardClasses = styled(CardShadow)`
  width: 22.5rem;
  max-width: 100%;
`;

type ExpandMoreProps = {
  expand: boolean;
};

const ExpandMore = styled(ExpandMoreIcon)`
  ${({ expand }: ExpandMoreProps) => `
    transform: ${!expand ? 'rotate(0deg)' : 'rotate(180deg)'};
    marginLeft: auto;
    transition: transform 0.4s;
  `}
`;

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    position: 'absolute',
    top: 18,
    right: 18,
    height: '1.75rem',
    width: '1.75rem',
    borderRadius: 16,
    backgroundColor: theme.colors.cobalt,
  },
}));

const NoPreferenceText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.enrollText};
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 143%;
    letter-spacing: 0.01525rem;
  `
);

function filterTeachersBySites(teachers: InstructorProfileData[], sites?: Site[], scheduleId?: number) {
  if (!sites?.length) {
    return teachers;
  }

  const siteIds = sites.map((x) => x.id);

  return teachers.filter((teacher) => {
    const availabilityItems =
      teacher.scheduleAvailability?.find(({ scheduleId: _scheduleId }) => _scheduleId && _scheduleId === scheduleId) ??
      teacher.defaultAvailability;
    const filteredItems = availabilityItems?.availabilityItems?.filter((item) => {
      return (
        !item.locations?.length ||
        item.locations.some((location) => location.siteId && siteIds.includes(location.siteId))
      );
    });

    return !!filteredItems?.length;
  });
}

const TeacherSelection = () => {
  const { selectedStudents, selectTeacher, studentEnrollments } = useEnrollmentContext();
  const navigate = useNavigate();
  const { scheduleId, studentId } = useParams();
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const currentStudent = useMemo(() => {
    if (studentId && selectedStudents && selectedStudents?.length > 0) {
      const studentIndex = selectedStudents?.findIndex((student) => student.id === parseInt(studentId, 10));
      const student = studentIndex > -1 && selectedStudents && selectedStudents[studentIndex];
      return student ? student : undefined;
    }

    return;
  }, [selectedStudents, studentId]);

  if (!studentId || !currentStudent) {
    navigate(`/enroll/${scheduleId}`);
  }

  const enrollment = useMemo(
    () => studentEnrollments.find((x) => x.student.id === currentStudent?.id),
    [currentStudent?.id, studentEnrollments]
  );

  const { selectedLessons } = enrollment || {};

  useEffect(() => {
    if (!selectedLessons?.length) {
      navigate(`/enroll/${scheduleId}/student/${studentId}/lesson-selection`);
    }
  }, [navigate, scheduleId, selectedLessons?.length, studentId]);

  const currentStudentName = currentStudent?.user?.firstName || '';

  // Based on the lessons selected, load teachers.
  const { data, loading, error } = useQueryInstructorProfiles({
    skip: !selectedLessons || !selectedLessons.length,
    variables: selectedLessons
      ? {
          input: {
            filters: {
              skillIds: selectedLessons.map((x) => x.id),
              availableOnly: true,
            },
          },
        }
      : undefined,
  });

  const teachersBySkill: Record<number, InstructorProfileData[]> = useMemo(() => {
    if (!selectedLessons || !data?.instructorProfiles.length) {
      return {};
    }

    return selectedLessons.reduce<Record<number, InstructorProfileData[]>>((grouped, skill) => {
      grouped[skill.id] = data.instructorProfiles.filter((instructor) =>
        instructor.availableSkills?.some((x) => x.id === skill.id)
      );
      return grouped;
    }, {});
  }, [data?.instructorProfiles, selectedLessons]);

  const handleTeacherSelect = useCallback(
    (skillId: number, teacher?: InstructorProfileData, noPreference = false) => {
      if (currentStudent) {
        selectTeacher(currentStudent.id, skillId, teacher, noPreference);
      }
    },
    [currentStudent, selectTeacher]
  );

  const isComplete = useMemo(() => {
    if (!enrollment || !selectedLessons) {
      return false;
    }

    return selectedLessons.every((skill) => enrollment.selectedTeachers.some((x) => x.skillId === skill.id));
  }, [enrollment, selectedLessons]);

  const selectedLessonsSignupDetails = enrollment?.selectedLessonsSignupDetails;

  return (
    <Enrollment
      back={{ path: `/enroll/${scheduleId}/student/${studentId}/sign-up-details`, description: 'Sign-Up Details' }}
      next={{
        path: `/enroll/${scheduleId}/student/${studentId}/timeframe-selection`,
        description: 'Timeframe',
        disabled: !isComplete,
      }}
    >
      <EnrollmentHeader currentStudent={currentStudent} />
      <ContentHeaderWrapper>
        <Typography variant={'h1'}>Who would {currentStudentName} like to work with?</Typography>
      </ContentHeaderWrapper>
      <ContentBodyWrapper>
        <HelpText>
          As a reminder, the selections made here are preferences, not bookings. Lyriq will do its best to match your
          student with their preferences.
        </HelpText>
        {error && <ErrorAlert error={error.message} />}
        {loading && <LoadingIndicator />}
        <Stack direction='column' spacing={4} pt={4}>
          {selectedLessons?.map((skill) => {
            const isNoPreference = enrollment?.selectedTeachers.some((x) => x.skillId === skill.id && x.noPreference);
            const selectedLessonDetails = selectedLessonsSignupDetails?.find((ss) => ss.id === skill.id);
            const filteredTeachers = teachersBySkill[skill.id]
              ? filterTeachersBySites(teachersBySkill[skill.id], selectedLessonDetails?.sites, Number(scheduleId))
              : teachersBySkill[skill.id];

            return (
              <div key={skill.id}>
                <SectionHeader variant={'h2'}>{skill.skillName} Teachers</SectionHeader>
                <Box display='flex' alignItems='flex-start' flexWrap='wrap'>
                  <StyledBadge badgeContent={<CheckIcon />} color='primary' invisible={!isNoPreference}>
                    <CardClasses selected={isNoPreference}>
                      <CardActionArea onClick={() => handleTeacherSelect(skill.id, undefined, true)}>
                        <CardContent>
                          <NoPreferenceText>YOU PICK!</NoPreferenceText>
                        </CardContent>
                      </CardActionArea>
                    </CardClasses>
                  </StyledBadge>

                  {filteredTeachers?.map((teacher) => {
                    const selected = enrollment?.selectedTeachers.some(
                      (x) => x.skillId === skill.id && x.teacher?.id === teacher.id
                    );
                    const expandKey = `${skill.id}__${teacher.id}`;
                    const isExpanded = expanded[expandKey] ?? false;
                    const teacherName = UserRoleUtils.getFullName(teacher.user);

                    const shortDescription =
                      teacher.summary ||
                      (teacher.biography && striptags(teacher.biography).length > AUTO_SHORT_DESCRIPTION_LENGTH
                        ? `${striptags(teacher.biography).substring(0, AUTO_SHORT_DESCRIPTION_LENGTH)}...`
                        : teacher.biography);
                    const hasShortDescription = !!shortDescription;
                    const hasLongDescription = !!teacher.biography && teacher.biography !== shortDescription;
                    const hasAnyDescription = hasShortDescription || hasLongDescription;
                    const availability =
                      teacher.scheduleAvailability?.find(
                        ({ scheduleId: _scheduleId }) => _scheduleId && _scheduleId === Number(scheduleId)
                      ) ?? teacher.defaultAvailability;

                    return (
                      <StyledBadge key={teacher.id} badgeContent={<CheckIcon />} color='primary' invisible={!selected}>
                        <CardClasses selected={selected}>
                          <CardActionArea onClick={() => handleTeacherSelect(skill.id, teacher)}>
                            <CardContent>
                              <Grid container sx={{ marginBottom: hasAnyDescription ? 2 : 0 }}>
                                <Grid item xs={4}>
                                  {teacher.featureImage && (
                                    <CardMedia
                                      component='img'
                                      sx={{ width: 86 }}
                                      image={teacher.featureImage || ''}
                                      alt={`Teacher photo ${teacherName}`}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant='h3'>{teacherName}</Typography>
                                  {availability && (
                                    <Typography fontSize='0.85rem' color={'text.secondary'}>
                                      {formatAvailabilityDays(availability, { skillIds: [skill.id] })}
                                    </Typography>
                                  )}
                                  <Typography fontWeight='bold'>{formatSkills([skill])}</Typography>
                                </Grid>
                              </Grid>
                              {hasAnyDescription && (
                                <>
                                  <Collapse in={!isExpanded} timeout='auto'>
                                    <Typography component='div'>{parse(shortDescription || '')}</Typography>
                                  </Collapse>
                                  {hasLongDescription && (
                                    <Collapse in={isExpanded} timeout='auto'>
                                      <Typography component='div'>{parse(teacher.biography || '')}</Typography>
                                    </Collapse>
                                  )}
                                </>
                              )}
                            </CardContent>
                          </CardActionArea>
                          {hasLongDescription && (
                            <>
                              <Divider />
                              <CardActionArea>
                                <CardContent
                                  onClick={() => setExpanded((prev) => ({ ...prev, [expandKey]: !isExpanded }))}
                                >
                                  <Box display='flex' justifyContent='space-between'>
                                    <Typography>{isExpanded ? BRIEF_DESCRIPTION : FULL_DESCRIPTION}</Typography>
                                    <ExpandMore expand={isExpanded} />
                                  </Box>
                                </CardContent>
                              </CardActionArea>
                            </>
                          )}
                        </CardClasses>
                      </StyledBadge>
                    );
                  })}
                </Box>
              </div>
            );
          })}
        </Stack>
      </ContentBodyWrapper>
    </Enrollment>
  );
};

export default TeacherSelection;

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetSkillsDocument, GetSkillsQuery, GetSkillsQueryVariables } from 'generated/graphql';

function useQueryGetSkills(
  options?: QueryHookOptions<GetSkillsQuery, GetSkillsQueryVariables>
): QueryResult<GetSkillsQuery, GetSkillsQueryVariables> {
  return useQuery<GetSkillsQuery, GetSkillsQueryVariables>(GetSkillsDocument, {
    ...options,
  });
}

export default useQueryGetSkills;

import React, { useCallback, useMemo, useState } from 'react';
import Calendar from 'components/atoms/Calendar/Calendar';
import { CalendarProps, Components, Event, SlotPropGetter, View, Views } from 'react-big-calendar';
import { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { DateTime } from 'luxon';
import env from 'config/env';
import {
  BackgroundEvent,
  EnrollmentBackgroundEvent,
  EnrollmentFromQuery,
  MinMax,
  NoPreferenceType,
  QueriedInstructorProfile,
} from 'types';
import { ScheduleAvailabilityResult } from 'generated/graphql';
import getMinMax from 'utils/SemesterCalendar/getMinMax';
import getInstructorAvailabilityEvents from 'utils/SemesterCalendar/getInstructorAvailabilityEvents';
import parseEventsFromEnrollments from 'utils/SemesterCalendar/parseEventsFromEnrollments';
import getEnrollmentAvailability from 'utils/SemesterCalendar/getEnrollmentAvailability';
import useQueryInstructorProfiles from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import { NO_PREFERENCE_VALUE } from 'components/organisms/InstructorSelectField/InstructorSelectField';
import ResourceHeader from './components/ResourceHeader/ResourceHeader';
import EnrollmentEventCard from 'components/organisms/SemesterCalendar/components/EnrollmentEventCard/EnrollmentEventCard';
import memoize from 'fast-memoize';
import getSlotAvailability, { TIMESLOT_STEP } from 'utils/calendar/getSlotAvailability';
import getInitialRange from 'utils/calendar/getInitialRange';
import slotPropGetterBase from 'utils/calendar/slotPropGetterBase';
import eventPropGetter from 'utils/calendar/eventPropGetter';

function getStartDateForSemester(availability?: ScheduleAvailabilityResult) {
  if (!availability) {
    return DateTime.now().toJSDate();
  }

  const { startDate } = availability;
  const start = DateTime.fromISO(startDate);
  // If Sunday, return this date. Otherwise, go to end of week (Sunday).
  if (start.weekday === 7) {
    return start.toJSDate();
  }

  return start.endOf('week').startOf('day').toJSDate();
}

export interface BaseScheduleEvent extends Event {
  id: string | number;
  isDraggable?: boolean;
  color?: string;
}

export interface ScheduleResource {
  id: string | number;
  title: string;
  color?: string;
  instructorProfile?: QueriedInstructorProfile;
}

export type ScheduleEvent = BaseScheduleEvent;

const calendarComponents: Components<ScheduleEvent, ScheduleResource> = {
  resourceHeader: ResourceHeader,
  event: EnrollmentEventCard,
  // timeSlotWrapper: TimeslotWrapper,
};

const calendarStyle = { height: `calc(100vh - ${env.sizing.admin.vhOffset})` };
const calendarMinMax: MinMax = getMinMax();

const DEFAULT_VIEW = Views.WEEK;

interface SemesterCalendarProps
  extends Partial<CalendarProps<ScheduleEvent, ScheduleResource>>,
    Partial<withDragAndDropProps<ScheduleEvent, ScheduleResource>> {
  availability?: ScheduleAvailabilityResult;
  enrollments?: EnrollmentFromQuery[];
  selectedEnrollment?: EnrollmentFromQuery;
  selectedInstructors?: (QueriedInstructorProfile | NoPreferenceType)[];
  draggedEvent?: ScheduleEvent;
  byResource?: boolean;
}

type Props = SemesterCalendarProps;

const SemesterCalendar = ({
  availability,
  enrollments,
  selectedEnrollment,
  selectedInstructors,
  byResource,
  ...props
}: Props) => {
  const { data: instructorsData } = useQueryInstructorProfiles();
  const instructors = useMemo(() => instructorsData?.instructorProfiles || [], [instructorsData?.instructorProfiles]);
  const defaultDate = useMemo(() => getStartDateForSemester(availability), [availability]);

  const [currentDate, setCurrentDate] = useState<Date>(defaultDate);
  const [currentView, setCurrentView] = useState<View>(props.defaultView || DEFAULT_VIEW);
  const [currentRange, setCurrentRange] = useState<Date[] | { start: Date; end: Date } | undefined>(() =>
    getInitialRange(currentDate, currentView)
  );

  const instructorResources = useMemo<ScheduleResource[]>(() => {
    return [
      ...instructors
        .filter(
          (instructor) =>
            (!selectedInstructors ||
              selectedInstructors.length === 0 ||
              (selectedInstructors.length === 1 && selectedInstructors[0] === NO_PREFERENCE_VALUE) ||
              !!selectedInstructors.find((x) => typeof x !== 'string' && x.profileId === instructor.profileId)) &&
            (!selectedEnrollment ||
              selectedEnrollment.scheduledInstructorId === instructor.profileId ||
              selectedEnrollment.preferredInstructorId === instructor.profileId ||
              (!selectedEnrollment.preferredInstructorId &&
                !!instructor.availableSkills?.find((skill) => skill.id === selectedEnrollment.skillId)))
        )
        .filter((instructor) => {
          if (!availability?.instructorAvailability) {
            return true;
          }

          if (
            selectedInstructors &&
            selectedInstructors.find((x) => typeof x !== 'string' && x.profileId === instructor.profileId)
          ) {
            return true;
          }

          if (
            selectedEnrollment &&
            (selectedEnrollment.scheduledInstructorId === instructor.profileId ||
              selectedEnrollment.preferredInstructorId === instructor.profileId)
          ) {
            return true;
          }

          if (enrollments && !!enrollments.find((x) => x.scheduledInstructorId === instructor.profileId)) {
            return true;
          }

          const avail = availability.instructorAvailability.find((x) => x.instructor.id === instructor.profileId);
          return !!avail && !!avail.availability?.availabilityItems && avail.availability?.availabilityItems.length > 0;
        })
        .map((instructor) => ({
          id: instructor.profileId,
          title: instructor.user
            ? `${instructor.user.firstName} ${instructor.user.lastName}`
            : instructor.profileId.toString(),
          color: instructor.calendarColor || undefined,
          instructorProfile: instructor,
        })),
      { id: NO_PREFERENCE_VALUE, title: 'You Pick!' },
    ];
  }, [availability?.instructorAvailability, enrollments, instructors, selectedEnrollment, selectedInstructors]);

  // Account for filters when enrollment is selected, or other filters are applied.
  const filters = useMemo<{
    instructorIds?: number[];
    skillIds?: number[];
  }>(() => {
    if (!selectedEnrollment) {
      return {
        instructorIds:
          selectedInstructors && selectedInstructors.length > 0
            ? selectedInstructors
                .map((x) => (typeof x === 'string' ? undefined : x.profileId))
                .filter((x): x is number => !!x)
            : undefined,
      };
    }

    return {
      instructorIds:
        selectedEnrollment.scheduledInstructorId || selectedEnrollment.preferredInstructorId
          ? [selectedEnrollment.scheduledInstructorId, selectedEnrollment.preferredInstructorId].filter(
              (x): x is number => !!x
            )
          : undefined,
      skillIds: selectedEnrollment.skillId ? [selectedEnrollment.skillId] : undefined,
    };
  }, [selectedEnrollment, selectedInstructors]);

  const instructorAvailabilityEvents = useMemo<BackgroundEvent[]>(
    () =>
      availability
        ? getInstructorAvailabilityEvents(
            availability.startDate,
            availability.endDate,
            availability.instructorAvailability,
            filters
          )
        : [],
    [availability, filters]
  );

  const slotAvailabilityMap = useMemo(
    () => (currentRange ? getSlotAvailability(currentRange, instructorAvailabilityEvents) : {}),
    [currentRange, instructorAvailabilityEvents]
  );

  const selectedAvailabilityEvents = useMemo<EnrollmentBackgroundEvent[]>(
    () =>
      availability && selectedEnrollment
        ? getEnrollmentAvailability(availability.startDate, availability.endDate, selectedEnrollment)
        : [],
    [availability, selectedEnrollment]
  );

  const backgroundEvents = useMemo<BaseScheduleEvent[]>(
    () => [...selectedAvailabilityEvents],
    [selectedAvailabilityEvents]
  );

  const studentSlotAvailabilityMap = useMemo(
    () => (currentRange ? getSlotAvailability(currentRange, selectedAvailabilityEvents) : {}),
    [currentRange, selectedAvailabilityEvents]
  );

  const events = useMemo<ScheduleEvent[]>(
    () =>
      enrollments && availability
        ? parseEventsFromEnrollments(new Date(availability.startDate), new Date(availability.endDate), enrollments)
        : [],
    [availability, enrollments]
  );

  const slotPropGetter: SlotPropGetter = useMemo(
    () =>
      memoize((date: Date, resourceId: number | string | undefined) => {
        return slotPropGetterBase(slotAvailabilityMap, studentSlotAvailabilityMap, date, resourceId);
      }),
    [slotAvailabilityMap, studentSlotAvailabilityMap]
  );

  const handleNavigate = useCallback((newDate: Date, view: View) => {
    setCurrentDate(newDate);
    setCurrentView(view);
  }, []);

  const handleRangeChange = useCallback((range: Date[] | { start: Date; end: Date }, view?: View) => {
    setCurrentRange(range);
    if (view) {
      setCurrentView(view);
    }
  }, []);

  const handleViewChange = useCallback((view: View) => {
    setCurrentView(view);
  }, []);

  const byResourceProps: Partial<CalendarProps<ScheduleEvent, ScheduleResource>> = useMemo(
    () =>
      byResource
        ? {
            resources: instructorResources,
            resourceAccessor: (event) => event.resource,
            resourceIdAccessor: (resource) => resource.id,
            resourceTitleAccessor: (resource) => resource.title,
          }
        : {},
    [byResource, instructorResources]
  );

  return (
    <Calendar<ScheduleEvent, ScheduleResource>
      style={calendarStyle}
      onNavigate={handleNavigate}
      onRangeChange={handleRangeChange}
      onView={handleViewChange}
      defaultDate={defaultDate}
      defaultView={DEFAULT_VIEW}
      events={events}
      backgroundEvents={backgroundEvents}
      eventPropGetter={eventPropGetter}
      slotPropGetter={slotPropGetter}
      step={TIMESLOT_STEP}
      timeslots={2}
      min={calendarMinMax.min}
      max={calendarMinMax.max}
      resizable={false}
      components={calendarComponents}
      {...byResourceProps}
      {...props}
    />
  );
};

export default SemesterCalendar;

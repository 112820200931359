import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import { useAuth0 } from '@auth0/auth0-react';
import { Link as RouterLink } from 'react-router-dom';
import Banner from 'components/atoms/Banner/Banner';
import Logo from 'components/atoms/Logo/Logo';
import useHasAuthRole from 'hooks/useHasAuthRole/useHasAuthRole';
import { AuthRole } from 'utils/user/AuthUtils';
import Box from '@mui/material/Box';
import EnrollUserButton from 'components/molecules/EnrollUserButton/EnrollUserButton';

function MenuAppBar() {
  const { isAuthenticated, logout } = useAuth0();
  const isAdmin = useHasAuthRole(AuthRole.LyriqAdmin);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <AppBar position='sticky' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Banner>
        <Typography variant='h6' component='div'>
          <Link component={RouterLink} to='/'>
            <Logo size='black' />
          </Link>
        </Typography>
        <Box id='header-portal-root' sx={{ flexGrow: 1 }} />
        {isAuthenticated && (
          <Box sx={{ ml: 'auto' }}>
            <EnrollUserButton />
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={RouterLink} onClick={handleClose} to='/account'>
                My Account
              </MenuItem>
              {isAdmin && (
                <MenuItem component={RouterLink} onClick={handleClose} to='/manage/calendar'>
                  Admin Calendar
                </MenuItem>
              )}
              {isAdmin && (
                <MenuItem component={RouterLink} onClick={handleClose} to='/manage/new-scheduler'>
                  Admin Scheduler
                </MenuItem>
              )}
              {isAdmin && (
                <MenuItem component={RouterLink} onClick={handleClose} to='/manage/scheduler'>
                  Admin Scheduler (old)
                </MenuItem>
              )}
              {isAdmin && (
                // put https://lyriq.caxy.com/admin as link to access page otherwise not display admin
                <MenuItem component={'a'} onClick={handleClose} href='https://lyriq.caxy.com/admin'>
                  Admin
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        )}
      </Banner>
    </AppBar>
  );
}

export default MenuAppBar;

import useQueryEnrollments, { QueryEnrollmentsResult } from 'hooks/queries/useQueryEnrollments/useQueryEnrollments';
import { useAdminContext } from 'contexts/AdminContext/AdminContext';
import { EnrollmentFiltersInput } from '../../generated/graphql';

function useQueryEnrollmentsOnSchedule(
  filters?: Partial<EnrollmentFiltersInput>,
  skip?: boolean
): QueryEnrollmentsResult {
  const { selectedSchedule } = useAdminContext();

  return useQueryEnrollments({
    skip: !selectedSchedule || skip,
    variables: {
      input: {
        filters: selectedSchedule
          ? {
              scheduleId: selectedSchedule.id,
              ...(filters || {}),
            }
          : undefined,
      },
    },
  });
}

export default useQueryEnrollmentsOnSchedule;

import { gql, MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  ConfirmEnrollmentDocument,
  ConfirmEnrollmentMutation,
  ConfirmEnrollmentMutationVariables,
  EnrollmentStatus,
} from 'generated/graphql';
import { useMemo } from 'react';

type Data = ConfirmEnrollmentMutation;
type Variables = ConfirmEnrollmentMutationVariables;

function useMutationConfirmEnrollment(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      update(cache, result, options) {
        const id = options.variables?.id;

        cache.writeFragment<{ schedulingStatus: EnrollmentStatus }>({
          id: `Enrollment:${id}`,
          fragment: gql`
            fragment EnrollmentWithStatus on Enrollment {
              schedulingStatus
            }
          `,
          data: {
            schedulingStatus: EnrollmentStatus.UserConfirmed,
          },
        });
      },
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(ConfirmEnrollmentDocument, opts);
}

export default useMutationConfirmEnrollment;

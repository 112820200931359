import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { StoreCardDocument, StoreCardMutation, StoreCardMutationVariables } from 'generated/graphql';

type Data = StoreCardMutation;
type Variables = StoreCardMutationVariables;

function useMutationStoreCard(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  return useMutation<Data, Variables>(StoreCardDocument, options);
}

export default useMutationStoreCard;

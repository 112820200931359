import { ProfileType } from '../../utils/user/roleUtils';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import useUserRole from '../useUserRole/useUserRole';
import { Role } from '../../generated/graphql';

function useCurrentUserRole<T extends Role>(profileType: ProfileType): undefined | T {
  const { user } = useUserContext();

  return useUserRole<T>(profileType, user);
}

export default useCurrentUserRole;

import React from 'react';
import Button from '@mui/material/Button';
import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import { AddStudentDocument, AddStudentMutation, AddStudentMutationVariables } from 'generated/graphql';
import { useUserContext } from 'contexts/UserContext/UserContext';
import { useAccountContext } from 'contexts/AccountContext/AccountContext';

type StudentInfoData = {
  accountId: number;
  firstName: string;
  lastName: string;
  email?: string;
  userId: number;
};

const EnrollAccountHolderButton = () => {
  const {
    user,
    postLogin: { refetch },
  } = useUserContext();
  const { getStudentsRefetch } = useAccountContext();
  const [addStudent, { loading, error }] = useMutation<AddStudentMutation, AddStudentMutationVariables>(
    AddStudentDocument
  );

  // Unfortunately we have to duplicate code here instead of abstracting it to a reusable hook
  // because react hooks cannot be used inside of callbacks (i.e. onClick)
  const submitStudentData = async (data: StudentInfoData) => {
    const result = await addStudent({
      variables: {
        input: {
          ...data,
          sendInvite: false,
        },
      },
    });

    if (result.data?.addStudent) {
      await refetch();
      await getStudentsRefetch();
    }
  };

  const buttonDisabled = loading && !error;

  return (
    <>
      {error && (
        <Alert color='error' sx={{ my: 2 }}>
          {error.message}
        </Alert>
      )}
      <Button
        disabled={buttonDisabled}
        color='primary'
        variant='contained'
        sx={{ marginBottom: '1rem' }}
        fullWidth
        onClick={async () => {
          const accountId = user && user.roles && user.roles[0]?.account?.id;
          if (user && accountId) {
            const accountHolderStudentInfo = {
              accountId,
              firstName: user.firstName || '',
              lastName: user.lastName || '',
              email: user.email || '',
              userId: user.id,
            };
            await submitStudentData(accountHolderStudentInfo);
          }
        }}
      >
        {!buttonDisabled && 'Enroll myself as a student'}
        {buttonDisabled && 'Enrolling...'}
      </Button>
    </>
  );
};

export default EnrollAccountHolderButton;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import formatISO9075 from 'date-fns/formatISO9075';
import startOfToday from 'date-fns/startOfToday';
import add from 'date-fns/add';
import parseISO from 'date-fns/parseISO';
import { styled } from '@mui/material/styles';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { EnrollmentFromQuery } from 'types';
import {
  LessonType,
  SiblingSchedulePreference,
  SchedulingPreference,
  UpdateEnrollmentDocument,
  UpdateEnrollmentMutation,
  UpdateEnrollmentMutationVariables,
} from 'generated/graphql';
import useQueryGetSkills from 'hooks/queries/useQueryGetSkills/useQueryGetSkills';
import useQueryInstructorProfiles from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import useQueryGetPackages from 'hooks/queries/useQueryGetPackages/useQueryGetPackages';

const StyledTimePickers = styled('div')`
  .MuiTextField-root {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

type Props = {
  enrollment: EnrollmentFromQuery;
  showEditDialog: boolean;
  setShowEditDialog: React.Dispatch<React.SetStateAction<boolean>> | React.Dispatch<boolean>;
};

export const EnrollmentCardEditForm: React.FC<Props> = ({ enrollment, showEditDialog, setShowEditDialog }) => {
  const { id } = enrollment;
  const firstName = enrollment.studentProfile.user?.firstName;
  const lastName = enrollment.studentProfile.user?.lastName;
  const userId = enrollment.studentProfile.user?.id;
  const instrument = enrollment.skill?.id;
  const packageName = enrollment.package.id;
  const { startTime, endTime } = enrollment;
  const preferredInstructor = enrollment.preferredInstructor?.profileId || '';
  const scheduledInstructor = enrollment.scheduledInstructor?.profileId || '';
  const { lessonType, siblingConsideration, schedulingConsideration, enrollmentDate } = enrollment;

  const startTimeStamps = startTime?.split(':') || [];
  const endTimeStamps = endTime?.split(':') || [];
  const initStartTime = startTime
    ? add(startOfToday(), { hours: parseInt(startTimeStamps[0], 10), minutes: parseInt(startTimeStamps[1], 10) })
    : startTime;
  const initEndTime = endTime
    ? add(startOfToday(), { hours: parseInt(endTimeStamps[0], 10), minutes: parseInt(endTimeStamps[1], 10) })
    : endTime;
  const [lessonStartTime, setLessonStartTime] = useState<Date | string | null | undefined>(initStartTime);
  const [lessonEndTime, setLessonEndTime] = useState<Date | string | null | undefined>(initEndTime);
  const [studentEnrollmentDate, setStudentEnrollmentDate] = useState<Date | null>(parseISO(enrollmentDate));

  const [updateEnrollment] = useMutation<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables>(
    UpdateEnrollmentDocument
  );

  const skills = useQueryGetSkills();
  const skillsData = skills.data?.getSkills;
  const instructors = useQueryInstructorProfiles();
  const instructorsData = instructors.data?.instructorProfiles;
  const packages = useQueryGetPackages();
  const packageData = packages.data?.getPackages;

  const validationSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    instrument: yup.string().required('Instrument is required'),
    packageName: yup.string().required('Package Name is required'),
    siblingConsideration: yup.string().required('Sibling Consideration is required'),
    schedulingConsideration: yup.string().required('Scheduling Consideration is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      instrument,
      packageName,
      preferredInstructor,
      scheduledInstructor,
      lessonType,
      siblingConsideration,
      schedulingConsideration,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const submitStartTime = startTime
        ? formatISO9075(lessonStartTime as Date, { representation: 'time' })
        : startTime;
      const submitEndTime = endTime ? formatISO9075(lessonEndTime as Date, { representation: 'time' }) : endTime;
      await updateEnrollment({
        variables: {
          updateEnrollmentInput: {
            id,
            enrollment: {
              skillId: values.instrument,
              packageId: values.packageName,
              startTime: submitStartTime,
              endTime: submitEndTime,
              preferredInstructorId: parseInt(values.preferredInstructor.toString(), 10),
              scheduledInstructorId: parseInt(values.scheduledInstructor.toString(), 10),
              lessonType: values.lessonType,
              siblingConsideration: values.siblingConsideration,
              schedulingConsideration: values.schedulingConsideration,
              enrollmentDate: formatISO9075(studentEnrollmentDate as Date, { representation: 'date' }),
            },
            user: {
              id: userId || 0,
              firstName: values.firstName,
              lastName: values.lastName,
            },
          },
        },
      });
    },
  });

  return (
    <Dialog open={showEditDialog} onClose={() => setShowEditDialog(false)}>
      <DialogTitle>Edit Student Enrollment</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            fullWidth
            id='firstName'
            name='firstName'
            label='First Name'
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            sx={{ my: 2 }}
          />
          <TextField
            fullWidth
            id='lastName'
            name='lastName'
            label='Last Name'
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            sx={{ my: 2 }}
          />
          <InputLabel>Instrument</InputLabel>
          <Select
            sx={{ my: 2 }}
            fullWidth
            value={formik.values.instrument}
            id='instrument'
            name='instrument'
            onChange={formik.handleChange}
          >
            {skillsData?.map((skill) => {
              return (
                <MenuItem key={skill.id} value={skill.id}>
                  {skill.skillName}
                </MenuItem>
              );
            })}
          </Select>
          <InputLabel>Package Name</InputLabel>
          <Select
            sx={{ my: 2 }}
            fullWidth
            value={formik.values.packageName}
            id='packageName'
            name='packageName'
            onChange={formik.handleChange}
          >
            {packageData?.map((pkg) => {
              return (
                <MenuItem key={pkg.id} value={pkg.id}>
                  {pkg.name}
                </MenuItem>
              );
            })}
          </Select>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledTimePickers>
              <TimePicker
                label='Lesson Start Time'
                value={lessonStartTime}
                onChange={(value) => setLessonStartTime(value)}
              ></TimePicker>
              <TimePicker
                label='Lesson End Time'
                value={lessonEndTime}
                onChange={(value) => setLessonEndTime(value)}
              ></TimePicker>
              <DatePicker
                label='Enrollment Start Date'
                onChange={(value) => setStudentEnrollmentDate(value)}
                value={studentEnrollmentDate}
              ></DatePicker>
            </StyledTimePickers>
          </LocalizationProvider>
          <InputLabel>Preferred Teacher</InputLabel>
          <Select
            sx={{ my: 2 }}
            fullWidth
            value={formik.values.preferredInstructor}
            id='preferredInstructor'
            name='preferredInstructor'
            onChange={formik.handleChange}
          >
            {instructorsData?.map((instructor) => {
              return (
                <MenuItem key={instructor.profileId} value={instructor.profileId}>
                  {instructor.user?.firstName} {instructor.user?.lastName}
                </MenuItem>
              );
            })}
          </Select>
          <InputLabel>Scheduled Teacher</InputLabel>
          <Select
            sx={{ my: 2 }}
            fullWidth
            value={formik.values.scheduledInstructor}
            id='scheduledInstructor'
            name='scheduledInstructor'
            onChange={formik.handleChange}
          >
            {instructorsData?.map((instructor) => {
              return (
                <MenuItem key={instructor.profileId} value={instructor.profileId}>
                  {instructor.user?.firstName} {instructor.user?.lastName}
                </MenuItem>
              );
            })}
          </Select>
          <InputLabel>Lesson Type</InputLabel>
          <Select
            sx={{ my: 2 }}
            fullWidth
            value={formik.values.lessonType}
            id='lessonType'
            name='lessonType'
            onChange={formik.handleChange}
          >
            {Object.values(LessonType).map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
          <InputLabel>Sibling Consideration</InputLabel>
          <Select
            sx={{ my: 2 }}
            fullWidth
            value={formik.values.siblingConsideration}
            id='siblingConsideration'
            name='siblingConsideration'
            onChange={formik.handleChange}
          >
            {Object.values(SiblingSchedulePreference).map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
          <InputLabel>Scheduling Consideration</InputLabel>
          <Select
            sx={{ my: 2 }}
            fullWidth
            value={formik.values.schedulingConsideration}
            id='schedulingConsideration'
            name='schedulingConsideration'
            onChange={formik.handleChange}
          >
            {Object.values(SchedulingPreference).map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
          <Button
            sx={{ marginBottom: '1rem' }}
            color='primary'
            variant='contained'
            fullWidth
            type='submit'
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
          <Button
            sx={{ marginBottom: '1rem' }}
            color='primary'
            variant='contained'
            fullWidth
            onClick={() => setShowEditDialog(false)}
          >
            Close
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EnrollmentCardEditForm;

import React from 'react';
import { Event, NavigateAction, ToolbarProps, View, Navigate, Messages } from 'react-big-calendar';

type Props<TEvent extends object = Event, TResource extends object = object> = ToolbarProps<TEvent, TResource>;

const CustomToolbar = <TEvent extends object = Event, TResource extends object = object>(
  props: Props<TEvent, TResource>
) => {
  const navigate = (action: NavigateAction) => {
    props.onNavigate(action);
  };

  const doView = (view: View) => {
    props.onView(view);
  };

  const viewNamesGroup = (messages: Messages) => {
    const viewNames = props.views;
    const view = props.view;

    if (Array.isArray(viewNames) && viewNames.length > 1) {
      return viewNames.map((name) => (
        <button
          type='button'
          key={name}
          className={view === name ? 'rbc-active' : undefined}
          onClick={doView.bind(null, name)}
        >
          {messages[name]}
        </button>
      ));
    }
  };

  const {
    localizer: { messages },
    label,
  } = props;

  return (
    <div className='rbc-toolbar'>
      <span className='rbc-btn-group'>
        <button type='button' onClick={navigate.bind(null, Navigate.TODAY)}>
          {messages.today}
        </button>
        <button type='button' onClick={navigate.bind(null, Navigate.PREVIOUS)}>
          {messages.previous}
        </button>
        <button type='button' onClick={navigate.bind(null, Navigate.NEXT)}>
          {messages.next}
        </button>
      </span>

      <span className='rbc-toolbar-label'>{label}</span>

      <span className='rbc-btn-group'>{viewNamesGroup(messages)}</span>
    </div>
  );
};

export default CustomToolbar;

import React from 'react';
import Typography from '@mui/material/Typography';

const EnrollmentCancellationPolicy = () => {
  return (
    <>
      <Typography gutterBottom>
        If you need to cancel a lesson you must call or text{' '}
        <a href='tel:7732708284' target='_blank' rel='noreferrer'>
          (773) 270-8284
        </a>{' '}
        or email{' '}
        <a href='mailto:info@lyriqmusic.com' target='_blank' rel='noreferrer'>
          info@lyriqmusic.com
        </a>{' '}
        24-hours in advance of your lesson. If you need to drop out of the semester a full month&apos;s notice must be
        given.
      </Typography>
      <Typography gutterBottom>
        If you have cancelled a lesson with 24-hour advance notice you are allowed to make up that lesson. There is a is
        a dedicated makeup week at the end of each semester. NOTE: Only ONE make-up per semester is allowed.
      </Typography>
      <Typography gutterBottom>
        In the event your teacher is unable to teach due to illness or personal leave, LYRIQ reserves the right to
        provide a substitute teacher or offer a reschedule that works for the client. If a substitute or reschedule
        cannot be arranged, the client will receive a credit for the lesson. If the client does not desire to have a
        substitute, the lesson will be considered a cancellation by the client.
      </Typography>
    </>
  );
};

export default EnrollmentCancellationPolicy;

import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import UpdateStudentForm from 'components/organisms/AddStudentsForm/UpdateStudentForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

type Props = {
  backPath?: string;
};

const PromptUserAccountModal = ({ backPath }: Props) => {
  const [queryParams] = useSearchParams();
  const reviewStudentsInfo = queryParams.get('reviewStudentsInfo');
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = (cancel?: boolean) => {
    queryParams.delete('reviewStudentsInfo');
    if (backPath && cancel) {
      navigate(backPath);
    }
    setOpen(false);
    navigate(queryParams.toString());
  };

  return (
    <Dialog
      open={open && !!reviewStudentsInfo}
      scroll='paper'
      fullScreen={fullScreen}
      aria-labelledby='update-students-dialog-title'
      aria-describedby='update-students-dialog-description'
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <UpdateStudentForm onCancel={handleClose} />
      </LocalizationProvider>
    </Dialog>
  );
};
export default PromptUserAccountModal;

import React, { useState } from 'react';
import { EventProps } from 'react-big-calendar';
import { ScheduleEvent } from 'components/organisms/SemesterCalendar/SemesterCalendar';
import { isEnrollmentEvent } from 'utils/SemesterCalendar/isEnrollmentEvent';
import Typography from '@mui/material/Typography';
import EnrollmentCardPopper from 'components/molecules/EnrollmentCardPopper/EnrollmentCardPopper';
import LockClockIcon from '@mui/icons-material/LockClock';
import Stack from '@mui/material/Stack';
import EnrollmentStatusIcon from 'components/atoms/EnrollmentStatusIcon/EnrollmentStatusIcon';
import Box from '@mui/material/Box';
import useSiblingEnrollmentsDetail from 'hooks/useSiblingEnrollmentsDetail/useSiblingEnrollmentsDetail';
import useMultipleEnrollmentsDetail from 'hooks/useMultipleEnrollmentsDetail/useMultipleEnrollmentsDetail';
import LetterAvatarWithBadge from 'components/organisms/SemesterCalendar/components/LetterAvatarWithBadge/LetterAvatarWithBadge';
import useEnrollmentSolutionResult from 'components/organisms/SemesterCalendar/hooks/useEnrollmentSolutionResult/useEnrollmentSolutionResult';
import SolverScoreDisplay from 'components/molecules/SolverScoreDisplay/SolverScoreDisplay';
import EnrollmentCardEditForm from 'components/molecules/EnrollmentCardEditForm/EnrollmentCardEditForm';

type Props = EventProps<ScheduleEvent>;

const EnrollmentEventCard = ({ title, event }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setPopperOpen] = React.useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);

  const enrollment = isEnrollmentEvent(event) ? event.enrollment : undefined;
  const enrollmentId = enrollment?.id;
  const studentProfileId = enrollment?.studentProfileId;

  const solutionResult = useEnrollmentSolutionResult(enrollmentId);

  const siblingEnrollments = useSiblingEnrollmentsDetail(enrollmentId);
  const multipleEnrollments = useMultipleEnrollmentsDetail(studentProfileId);

  const togglePopover = (event: React.MouseEvent<HTMLDivElement>, open: boolean) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
    setPopperOpen(open);
  };

  if (!enrollment) {
    return <>{title}</>;
  }

  const { skill, scheduledInstructor, preferredInstructor, scheduleLocked } = enrollment;

  const instructor = scheduledInstructor || preferredInstructor;

  const notPreferredInstructor = preferredInstructor && preferredInstructor.id !== instructor?.id;

  const accountHolderName =
    siblingEnrollments &&
    `${siblingEnrollments.accountHolderFirstNames[0]} ${siblingEnrollments.accountHolderLastNames[0]}`;

  return (
    <Box role='button' tabIndex={0} onClick={(event) => togglePopover(event, !open)} sx={{ height: '100%' }}>
      <Stack direction='column' sx={{ height: '100%' }}>
        <Typography variant='body2' fontWeight='bold' noWrap>
          {title}
        </Typography>
        <Typography variant='body2' noWrap>
          {skill?.skillName || 'No Skill Selected'}
          {instructor ? ` - ${instructor.user?.lastName}${notPreferredInstructor ? '**' : ''}` : 'No Teacher'}
        </Typography>
        <Box mt={'auto'}>
          <Stack direction='row' alignItems='center' spacing={1} flexWrap='nowrap'>
            <EnrollmentStatusIcon status={enrollment.schedulingStatus} iconProps={{ fontSize: 'small' }} />
            {scheduleLocked && <LockClockIcon fontSize='small' />}
            {multipleEnrollments && (
              <LetterAvatarWithBadge
                badgeContent={multipleEnrollments.enrollmentIds.length}
                name={title.toUpperCase()}
              />
            )}
            {solutionResult && !!solutionResult.score && <SolverScoreDisplay scoreText={solutionResult.score} />}
            {siblingEnrollments && accountHolderName && (
              <Box flex='1 0 auto' display='flex' flexDirection='row' alignItems='center'>
                <LetterAvatarWithBadge
                  badgeContent={siblingEnrollments.siblingEnrollmentIds.length}
                  name={accountHolderName.toUpperCase()}
                  badgeProps={{ sx: { ml: 'auto' } }}
                />
              </Box>
            )}
          </Stack>
        </Box>
      </Stack>
      {open && (
        <EnrollmentCardPopper
          anchorEl={anchorEl}
          open={open}
          enrollment={enrollment}
          setShowEditDialog={setShowEditDialog}
        />
      )}
      {showEditDialog && (
        <EnrollmentCardEditForm
          enrollment={enrollment}
          showEditDialog={showEditDialog}
          setShowEditDialog={setShowEditDialog}
        />
      )}
    </Box>
  );
};

export default React.memo(EnrollmentEventCard);

import React from 'react';
import { css, styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

type BarColor = 'secondary' | 'primary';

const colors: Record<BarColor, { start: string; end: string }> = {
  primary: { start: '#E499FF', end: '#8572BB' },
  secondary: { start: '#E3B43F', end: '#A76331' },
};

const Header = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.enrollText};
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `
);

interface SectionHeaderProps {
  variant?: TypographyProps['variant'];
  children: React.ReactNode;
  barColor?: BarColor;
}

type Props = SectionHeaderProps;

const SectionHeader = ({ variant, barColor = 'primary', children }: Props) => {
  const barColors = colors[barColor] ?? colors.primary;

  return (
    <Stack direction='column' spacing={1.5} mb={2}>
      <Header variant={variant || 'h2'}>{children}</Header>
      <div style={{ width: '100%', height: 3, position: 'relative' }}>
        <Box
          sx={{
            width: '100%',
            height: 3,
            left: 0,
            top: 0,
            position: 'absolute',
            background: `linear-gradient(90deg, ${barColors.start} 0%, ${barColors.end} 100%)`,
          }}
        />
      </div>
    </Stack>
  );
};

export default SectionHeader;

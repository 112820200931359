import React from 'react';
import Typography from '@mui/material/Typography';

const EnrollmentSickPolicy = () => {
  return (
    <>
      <Typography gutterBottom>
        LYRIQ reserves the right to ask its students to wear a mask if they are exhibiting cold symptoms. Please do not
        attend lessons if you are sick or did not attend school/work that day because of an illness. LYRIQ can always
        accommodate virtual lessons in case of illness.
      </Typography>
    </>
  );
};

export default EnrollmentSickPolicy;

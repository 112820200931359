import React, { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import {
  OnboardingStepEnum,
  PostLoginDocument,
  PostLoginQuery,
  PostLoginQueryVariables,
  PostLoginResult,
  User,
} from '../../generated/graphql';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getOnboardingStepPath } from '../../utils/user/getOnboardingStepPath';
import { ApolloQueryResult } from '@apollo/client/core/types';

/**
 * List of steps that are considered onboarding.
 * The main nav and bottom nav aren't available during these steps.
 */
const limitedOnboardingSteps: OnboardingStepEnum[] = [
  OnboardingStepEnum.VerifyEmail,
  OnboardingStepEnum.Register,
  OnboardingStepEnum.JoinAccount,
  // TODO: CreateAccount?
];

function isLimitedOnboardingStep(step?: OnboardingStepEnum) {
  if (!step) {
    return false;
  }

  return limitedOnboardingSteps.includes(step);
}

type DataResult<T, TData, TVariables> = {
  data?: T;
  loading: boolean;
  error?: ApolloError;
  refetch(variables?: Partial<TVariables>): Promise<ApolloQueryResult<TData>>;
};

type UserContextState = {
  postLogin: DataResult<PostLoginResult, PostLoginQuery, PostLoginQueryVariables>;
  isOnboardingLimited?: boolean;
  user?: User;
  loading: boolean;
  error?: Error;
};

const UserContext = createContext<UserContextState | undefined>(undefined);

interface UserContextProviderProps {
  children: React.ReactNode;
}

type Props = UserContextProviderProps;

const UserContextProvider = ({ children }: Props) => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const isForcedStep = useRef<boolean>(false);

  const {
    data: postLoginResult,
    loading: postLoginLoading,
    error: postLoginError,
    refetch: postLoginRefetch,
  } = useQuery<PostLoginQuery, PostLoginQueryVariables>(PostLoginDocument, { skip: !isAuthenticated });

  const user = useMemo<User | undefined>(
    () => postLoginResult?.postLogin.onboardingState.user || undefined,
    [postLoginResult?.postLogin.onboardingState.user]
  );

  useEffect(() => {
    if (postLoginResult?.postLogin.nextStep) {
      const path = getOnboardingStepPath(postLoginResult?.postLogin.nextStep);

      if (path) {
        isForcedStep.current = true;
        navigate(path, { replace: true });
      } else {
        // If we were previously on a forced step, navigate away from it.
        isForcedStep.current = false;
      }
    }
  }, [navigate, postLoginResult?.postLogin.nextStep]);

  // Memoize the state value to improve context performance.
  const stateValue = useMemo<UserContextState>(
    () => ({
      postLogin: {
        data: postLoginResult?.postLogin,
        loading: postLoginLoading,
        error: postLoginError,
        refetch: postLoginRefetch,
      },
      user,
      isOnboardingLimited: postLoginResult?.postLogin.nextStep
        ? isLimitedOnboardingStep(postLoginResult?.postLogin.nextStep)
        : undefined,
      loading: postLoginLoading,
      error: postLoginError,
    }),
    [postLoginError, postLoginLoading, postLoginRefetch, postLoginResult?.postLogin, user]
  );

  return <UserContext.Provider value={stateValue}>{children}</UserContext.Provider>;
};

const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext was used outside of its Provider');
  }

  return context;
};

export { UserContext, UserContextProvider, useUserContext };

import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import theme from 'theme';
import parse from 'html-react-parser';
import { PackagesStatus, PackagesType, SkillCategory } from 'generated/graphql';
import {
  Badge,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import { useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import useQueryGetSkills from 'hooks/queries/useQueryGetSkills/useQueryGetSkills';
import useQueryGetPackages from 'hooks/queries/useQueryGetPackages/useQueryGetPackages';
import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';
import ContentHeaderWrapper from 'components/atoms/ContentHeaderWrapper/ContentHeaderWrapper';
import ContentBodyWrapper from 'components/atoms/ContentBodyWrapper/ContentBodyWrapper';
import Enrollment from 'components/templates/Enrollment/Enrollment';
import EnrollmentHeader from 'pages/Enrollment/components/EnrollmentHeader/EnrollmentHeader';
import striptags from 'striptags';

const FULL_DESCRIPTION = 'Full Description';
const BRIEF_DESCRIPTION = 'Brief Description';
const AUTO_SHORT_DESCRIPTION_LENGTH = 170;

enum LessonSelectionType {
  PRIVATE_LESSONS = 'Private Lessons',
  GROUP_CLASSES = 'Group Classes',
}

const CardWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.5rem 0;
`;

type SelectedProp = {
  selected: boolean;
};

const CardShadow = styled(Card)`
  box-shadow: 0px 0.25rem 0.5rem 0px rgba(0, 0, 0, 0.5);
  margin: 0.5rem 0.75rem;
  ${({ selected }: SelectedProp) => `outline: ${selected ? theme.colors.cobalt : 'rgba(0, 0, 0, 0)'} solid 0.25rem;`}
  transition: outline 0.1s ease-out;
`;

const CardLesson = styled(CardShadow)`
  width: 190px;
  max-height: 80px;
`;

const CardClasses = styled(CardShadow)`
  width: 430px;
`;

type ExpandMoreProps = {
  expand: boolean;
};

const ExpandMore = styled(ExpandMoreIcon)`
  ${({ expand }: ExpandMoreProps) => `
    transform: ${!expand ? 'rotate(0deg)' : 'rotate(180deg)'};
    marginLeft: auto;
    transition: transform 0.4s;
  `}
`;

type CardBackgroundProps = {
  image?: string;
};

const CardBackground = styled(CardContent)`
  background-image: linear-gradient(270deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 50%),
    url(${({ image }: CardBackgroundProps) => image});
  background-repeat: no-repeat;
  background-size: cover;
  height: 80px;
  color: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    position: 'absolute',
    top: 10,
    right: 12,
    height: '1.75rem',
    width: '1.75rem',
    borderRadius: 16,
    backgroundColor: theme.colors.cobalt,
    zIndex: 0,
  },
}));

const LessonSelection = () => {
  const { studentEnrollments, selectedStudents, selectLesson, selectClass } = useEnrollmentContext();
  const navigate = useNavigate();
  const { scheduleId, studentId } = useParams();
  const [lessonType, setLessonType] = useState<LessonSelectionType>(LessonSelectionType.PRIVATE_LESSONS);
  const [expanded, setExpanded] = useState<Record<number, boolean>>({});
  const { data: skillData, loading: skillLoading } = useQueryGetSkills({
    variables: {
      input: {
        filters: { skillCategories: [SkillCategory.Instrument], hideFromRegistration: false },
      },
    },
  });
  const { data: packageData, loading: packageLoading } = useQueryGetPackages({
    variables: {
      input: {
        filters: { status: [PackagesStatus.Active], packageType: [PackagesType.PublicGroupLesson] },
      },
    },
  });
  const sortedPackages = useMemo(() => {
    const packages = packageData?.getPackages?.slice() || [];
    packages.sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
    return packages;
  }, [packageData?.getPackages]);

  const { currentStudent, nextStudent, previousStudent } = useMemo(() => {
    if (studentId && selectedStudents && selectedStudents?.length > 0) {
      const studentIndex = selectedStudents?.findIndex((student) => student.id === parseInt(studentId, 10));
      const student = studentIndex > -1 && selectedStudents && selectedStudents[studentIndex];
      const nextStudent = selectedStudents[studentIndex + 1];
      const previousStudent = selectedStudents[studentIndex - 1];

      return {
        currentStudent: student ? student : undefined,
        nextStudent: nextStudent ? nextStudent : undefined,
        previousStudent: previousStudent ? previousStudent : undefined,
      };
    }

    return {
      currentStudent: undefined,
      nextStudent: undefined,
      previousStudent: undefined,
    };
  }, [selectedStudents, studentId]);

  if (!studentId || !currentStudent) {
    navigate(`/enroll/${scheduleId}`);
  }

  const currentStudentName = currentStudent?.user?.firstName || '';

  const enrollment = useMemo(
    () => studentEnrollments.find((x) => x.student.id === currentStudent?.id),
    [currentStudent?.id, studentEnrollments]
  );

  const { selectedLessons, selectedClasses } = enrollment || {};

  const lessonSelected = selectedLessons && selectedLessons.length > 0;
  const classSelected = selectedClasses && selectedClasses.length > 0;

  const nextButton = useMemo(
    () => ({
      ...(!lessonSelected && classSelected
        ? nextStudent?.id
          ? {
              path: `/enroll/${scheduleId}/student/${nextStudent.id}/lesson-selection`,
              description: `Schedule Next: ${nextStudent.user?.firstName}`,
            }
          : {
              path: `/enroll/${scheduleId}/summary`,
              description: 'Summary',
            }
        : {
            path: `/enroll/${scheduleId}/student/${studentId}/sign-up-details`,
            description: 'Sign-Up Details',
          }),
      disabled: !lessonSelected && !classSelected,
    }),
    [classSelected, lessonSelected, nextStudent, scheduleId, studentId]
  );

  const backButton = useMemo(
    () => ({
      ...(previousStudent?.id
        ? {
            path: `/enroll/${scheduleId}/student/${previousStudent.id}/timeframe-selection`,
            description: `${previousStudent.user?.firstName ? previousStudent.user?.firstName : 'Previous Student'} ${
              previousStudent.user?.lastName ? previousStudent.user?.lastName : ''
            }`,
          }
        : {
            path: `/enroll/${scheduleId}/semester-information`,
            description: 'Semester Info',
          }),
    }),
    [scheduleId, previousStudent]
  );

  return (
    <Enrollment back={backButton} next={nextButton}>
      <EnrollmentHeader currentStudent={currentStudent} />
      <ContentHeaderWrapper>
        <Typography variant={'h1'}>{`What lessons does ${currentStudentName} want to take?`}</Typography>
      </ContentHeaderWrapper>
      <ContentBodyWrapper>
        <Tabs value={lessonType} onChange={(event, value) => setLessonType(value)} variant='fullWidth'>
          <Tab label={LessonSelectionType.PRIVATE_LESSONS} value={LessonSelectionType.PRIVATE_LESSONS} />
          <Tab label={LessonSelectionType.GROUP_CLASSES} value={LessonSelectionType.GROUP_CLASSES} />
        </Tabs>
        {lessonType === LessonSelectionType.PRIVATE_LESSONS && (
          <CardWrapper>
            {skillLoading && <LoadingIndicator />}
            {skillData &&
              skillData.getSkills?.map((skill) => {
                const selected = selectedLessons?.find(({ id }) => id === skill.id) !== undefined;
                return (
                  <StyledBadge
                    key={`lesson-selection-skill-${skill.id}`}
                    badgeContent={<CheckIcon />}
                    color='primary'
                    invisible={!selected}
                  >
                    <CardLesson selected={selected} sx={{ borderRadius: '16px' }}>
                      <CardActionArea onClick={() => selectLesson(Number(currentStudent?.id), skill)}>
                        <CardBackground image={skill.skillImageUrl}>
                          <Typography variant='h5' fontWeight={600}>
                            {skill.skillName}
                          </Typography>
                        </CardBackground>
                      </CardActionArea>
                    </CardLesson>
                  </StyledBadge>
                );
              })}
          </CardWrapper>
        )}
        {lessonType === LessonSelectionType.GROUP_CLASSES && (
          <CardWrapper>
            {packageLoading && <LoadingIndicator />}
            {!!sortedPackages.length &&
              sortedPackages.map((lessonPackage) => {
                const selected = selectedClasses?.find(({ id }) => id === lessonPackage.id) !== undefined;
                const expandKey = lessonPackage.id;
                const isExpanded = expanded[expandKey] ?? false;
                const shortDescription =
                  lessonPackage.shortDescription ||
                  (lessonPackage.longDescription &&
                  striptags(lessonPackage.longDescription).length > AUTO_SHORT_DESCRIPTION_LENGTH
                    ? `${striptags(lessonPackage.longDescription).substring(0, AUTO_SHORT_DESCRIPTION_LENGTH)}...`
                    : lessonPackage.longDescription);
                const hasShortDescription = !!shortDescription;
                const hasLongDescription =
                  !!lessonPackage.longDescription && lessonPackage.longDescription !== shortDescription;
                const hasAnyDescription = hasShortDescription || hasLongDescription;

                return (
                  <StyledBadge
                    key={`lesson-selection-package-${lessonPackage.id}`}
                    badgeContent={<CheckIcon />}
                    color='primary'
                    invisible={!selected}
                  >
                    <CardClasses selected={selected}>
                      <CardActionArea onClick={() => selectClass(Number(currentStudent?.id), lessonPackage)}>
                        <CardContent>
                          <Grid container sx={{ marginBottom: hasAnyDescription ? 2 : 0 }}>
                            {lessonPackage.packageImageUrl && (
                              <Grid xs={4}>
                                <CardMedia
                                  component='img'
                                  sx={{ width: 86 }}
                                  image={lessonPackage.packageImageUrl || ''}
                                  alt='Class Picture'
                                />
                              </Grid>
                            )}
                            <Grid container xs={lessonPackage.packageImageUrl ? 8 : 12} flexDirection='column'>
                              <Typography variant='h3'>{lessonPackage.name}</Typography>
                              {lessonPackage.subtitle && (
                                <Typography fontSize='0.85rem' color={'text.secondary'}>
                                  {lessonPackage.subtitle}
                                </Typography>
                              )}
                              {lessonPackage.teacherDisplayText && (
                                <Typography fontWeight='bold'>{lessonPackage.teacherDisplayText}</Typography>
                              )}
                            </Grid>
                          </Grid>
                          {hasAnyDescription && (
                            <>
                              <Collapse in={!isExpanded} timeout='auto'>
                                <Typography>{parse(shortDescription || '')}</Typography>
                              </Collapse>
                              {hasLongDescription && (
                                <Collapse in={isExpanded} timeout='auto'>
                                  <Typography>{parse(lessonPackage.longDescription || '')}</Typography>
                                </Collapse>
                              )}
                            </>
                          )}
                        </CardContent>
                      </CardActionArea>
                      {hasLongDescription && (
                        <>
                          <Divider />
                          <CardActionArea>
                            <CardContent onClick={() => setExpanded((prev) => ({ ...prev, [expandKey]: !isExpanded }))}>
                              <Box display='flex' justifyContent='space-between'>
                                <Typography>{isExpanded ? BRIEF_DESCRIPTION : FULL_DESCRIPTION}</Typography>
                                <ExpandMore expand={isExpanded} />
                              </Box>
                            </CardContent>
                          </CardActionArea>
                        </>
                      )}
                    </CardClasses>
                  </StyledBadge>
                );
              })}
          </CardWrapper>
        )}
      </ContentBodyWrapper>
    </Enrollment>
  );
};

export default LessonSelection;

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  GetAppointmentsByStudentIdDocument,
  GetAppointmentsByStudentIdQuery,
  GetAppointmentsByStudentIdQueryVariables,
} from 'generated/graphql';

function useQueryGetAppointmentsByStudentId(
  options?: QueryHookOptions<GetAppointmentsByStudentIdQuery, GetAppointmentsByStudentIdQueryVariables>
): QueryResult<GetAppointmentsByStudentIdQuery, GetAppointmentsByStudentIdQueryVariables> {
  return useQuery<GetAppointmentsByStudentIdQuery, GetAppointmentsByStudentIdQueryVariables>(
    GetAppointmentsByStudentIdDocument,
    {
      ...options,
    }
  );
}

export default useQueryGetAppointmentsByStudentId;

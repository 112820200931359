import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CancellationPolicy = () => {
  return (
    <Box mt={2}>
      <Typography variant='h5' component='h2' mb={3} mt={3}>
        Cancellations & Makeups
      </Typography>
      <Typography gutterBottom>
        If you need to cancel a lesson you must call or text (773) 270-8284 or email info@lyriqmusic.com 24-hours in
        advance of your lesson. If you need to drop out of the semester a full month&apos;s notice must be given.
      </Typography>
      <Typography gutterBottom>
        If you have cancelled a lesson with 24-hour advance notice you are allowed to make up that lesson. There is a is
        a dedicated makeup week at the end of each semester. NOTE: Only ONE make-up per semester is allowed.
      </Typography>
      <Typography gutterBottom>
        In the event your teacher is unable to teach due to illness or personal leave, LYRIQ reserves the right to
        provide a substitute teacher or offer a reschedule that works for the client. If a substitute or reschedule
        cannot be arranged, the client will receive a credit for the lesson. If the client does not desire to have a
        substitute, the lesson will be considered a cancellation by the client.
      </Typography>
    </Box>
  );
};

export default CancellationPolicy;

import React, { useCallback, useEffect, useMemo } from 'react';
import useQueryInstructorProfiles from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import MultipleSelectCheckmarks, {
  SelectOption,
} from 'components/molecules/MultipleSelectCheckmarks/MultipleSelectCheckmarks';
import { NoPreferenceType, QueriedInstructorProfile } from 'types';
import colorMapper, { ColorMapper } from 'utils/SemesterCalendar/colorMapper';
import Chip from '@mui/material/Chip';

export const NO_PREFERENCE_VALUE = 'NO_PREFERENCE';

type InstructorProfile = QueriedInstructorProfile;

type InstructorValue = InstructorProfile | NoPreferenceType;

interface InstructorSelectFieldProps {
  id?: string;
  label: string;
  placeholder?: string;
  value?: InstructorValue[];
  onChange: (value: InstructorValue[]) => void;
  skillIds?: number[];
}

type Props = InstructorSelectFieldProps;

const InstructorSelectField = ({ id, label, value, onChange, placeholder, skillIds }: Props) => {
  const { data: instructorsData } = useQueryInstructorProfiles({
    variables: skillIds
      ? {
          input: {
            filters: {
              skillIds: skillIds,
              availableOnly: true,
            },
          },
        }
      : undefined,
  });
  const instructors = useMemo(() => instructorsData?.instructorProfiles || [], [instructorsData?.instructorProfiles]);

  const options = useMemo(
    () => [
      { label: 'You Pick!', value: NO_PREFERENCE_VALUE },
      ...instructors
        .map((instructor) => ({
          label: instructor.user
            ? `${instructor.user.firstName} ${instructor.user.lastName}`
            : instructor.profileId.toString(),
          value: instructor.profileId.toString(),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ],
    [instructors]
  );
  const idValues = useMemo(
    () =>
      value
        ? value.map((instructor) => (typeof instructor === 'string' ? instructor : instructor.profileId.toString()))
        : [],
    [value]
  );

  const handleChange = useCallback(
    (value: string[]) => {
      const newValue = value
        .map((id) =>
          id === NO_PREFERENCE_VALUE
            ? NO_PREFERENCE_VALUE
            : instructors.find((instructor) => instructor.profileId.toString() === id)
        )
        .filter((x): x is InstructorProfile | NoPreferenceType => !!x);
      onChange && onChange(newValue);
    },
    [instructors, onChange]
  );

  const listItemPropsGetter = useCallback((option: { label: string; value: string }) => {
    return {
      sx: {
        borderRight: `5px solid ${colorMapper.pickColor(`instructor_${option.value}`)}`,
      },
    };
  }, []);

  useEffect(() => {
    if (skillIds && skillIds.length > 0) {
      onChange(instructors);
    }
  }, [instructors, onChange, skillIds]);

  return (
    <MultipleSelectCheckmarks
      id={id || 'instructor-select-field'}
      label={label}
      placeholder={placeholder}
      value={idValues}
      onChange={handleChange}
      options={options}
      listItemPropsGetter={listItemPropsGetter}
      renderTags={(value: readonly SelectOption[], getTagProps) =>
        value.map((option: SelectOption, index: number) => {
          const instructorColor = colorMapper.pickColor(`instructor_${option.value}`);
          return (
            <Chip
              variant='filled'
              label={option.label}
              {...getTagProps({ index })}
              key={option.value}
              sx={{
                backgroundColor: instructorColor,
                color: ColorMapper.getContrastYIQ(instructorColor),
              }}
            />
          );
        })
      }
    />
  );
};

export default InstructorSelectField;

import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoginButton from 'components/atoms/LoginButton/LoginButton';
import Logo from 'components/atoms/Logo/Logo';

type LocationStateProps = {
  from?: Location;
};

const hasFromProp = (state: unknown): state is LocationStateProps => {
  // You use `as` here, but only so typescript doesn't yell at you while you access the property
  // specifically to test if it is there.
  return (state as LocationStateProps).from !== undefined;
};

const LoginPage = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  const locationState = location.state;
  const from = locationState && hasFromProp(locationState) ? locationState.from?.pathname || '/' : '/';

  useEffect(() => {
    if (!isAuthenticated) {
      // TODO: We're setting this, but we're never reading it. Implement post-login redirect in future work.
      localStorage.setItem('POST_LOGIN_REDIRECT_TO', from);
    }
  }, [from, isAuthenticated]);

  if (isAuthenticated) {
    return <Navigate to='/' replace />;
  }

  return (
    <Container sx={{ height: '100vh' }}>
      <Stack direction='column' spacing={4} alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
        <Logo size='large' />
        <Typography variant='h1' color='black'>
          Login or Sign Up
        </Typography>
        <LoginButton />
      </Stack>
    </Container>
  );
};

export default LoginPage;

import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import theme from 'theme';
import { Button, styled, Tab, Tabs, Typography } from '@mui/material';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AvailabilityProps, DateEnums, useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import ContentHeaderWrapper from 'components/atoms/ContentHeaderWrapper/ContentHeaderWrapper';
import ContentBodyWrapper from 'components/atoms/ContentBodyWrapper/ContentBodyWrapper';
import Enrollment from 'components/templates/Enrollment/Enrollment';
import EnrollmentHeader from 'pages/Enrollment/components/EnrollmentHeader/EnrollmentHeader';
import TeacherSelectionReminder from './components/TeacherSelectionReminder/TeacherSelectionReminder';
import TimeRangeSlider from './components/TimeRangeSlider/TimeRangeSlider';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';

const weekdayDefaultValue = { start: '2:00 PM', end: '10:00 PM' };
const weekendDefaultValue = { start: '9:00 AM', end: '4:00 PM' };
const initialValueForDay: Record<DateEnums, { start: string; end: string }> = {
  Monday: weekdayDefaultValue,
  Tuesday: weekdayDefaultValue,
  Wednesday: weekdayDefaultValue,
  Thursday: weekdayDefaultValue,
  Friday: weekdayDefaultValue,
  Saturday: weekendDefaultValue,
  Sunday: weekendDefaultValue,
};

enum WeekendTab {
  WEEKDAYS = 'Weekdays',
  WEEKENDS = 'Weekends',
}

const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
const weekends = ['Saturday', 'Sunday'];

const CenterDiv = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 1.313rem;
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
  color: ${theme.colors.black};
  text-transform: none;
`;

const TeacherSelectionReminderContainer = styled(ContentBodyWrapper)`
  margin: 1rem 0.25rem;
  border: solid ${theme.palette.secondary.light} 0.125rem;
  border-radius: 1rem;
`;

type SelectedProps = {
  date: string;
  value: string;
  hasEntries?: boolean;
};

const DateTab = styled(Tab)`
  max-height: 3rem;
  border-bottom: solid ${theme.palette.secondary.light};
  border-right: solid ${theme.palette.secondary.light};
  text-transform: none;
  ${({ date, value }: SelectedProps) =>
    date === value &&
    `
    border: 3px solid #1976D2;
    font-weight: bold;
    font-size: 1.4rem;
    color: #1976D2;
  `}

  ${({ hasEntries }: SelectedProps) =>
    hasEntries &&
    `
    font-weight: bold;
    color: #1976D2;
  `}
`;

const WeekdayTab = styled(DateTab)`
  min-width: 5.313rem;
`;

const WeekEndTab = styled(DateTab)`
  min-width: 13.188rem;
`;

const StyledTypography = styled(Typography)`
  margin-top: 1rem;
`;

const getInitialAvailability = (date: DateEnums) => ({
  id: uuidv4(),
  start: {
    time: DateTime.fromFormat(initialValueForDay[date].start, 'h:mm a').toJSDate(),
    editable: false,
  },
  end: {
    time: DateTime.fromFormat(initialValueForDay[date].end, 'h:mm a').toJSDate(),
    editable: false,
  },
});

const TimeframeSelection = () => {
  const { selectedStudents, studentEnrollments, selectAvailability } = useEnrollmentContext();
  const navigate = useNavigate();
  const { scheduleId, studentId } = useParams();
  const [weekendTab, setWeekendTab] = useState<WeekendTab>(WeekendTab.WEEKDAYS);
  const [date, setDate] = useState<DateEnums>(DateEnums.MONDAY);

  const { currentStudent, nextStudent } = useMemo(() => {
    if (studentId && selectedStudents && selectedStudents?.length > 0) {
      const studentIndex = selectedStudents?.findIndex((student) => student.id === parseInt(studentId, 10));
      const student = studentIndex > -1 && selectedStudents && selectedStudents[studentIndex];
      const nextStudent = selectedStudents[studentIndex + 1];

      return {
        currentStudent: student ? student : undefined,
        nextStudent: nextStudent ? nextStudent : undefined,
      };
    }

    return {
      currentStudent: undefined,
      nextStudent: undefined,
    };
  }, [selectedStudents, studentId]);

  if (!studentId || !currentStudent) {
    navigate(`/enroll/${scheduleId}`);
  }

  const currentStudentName = currentStudent?.user?.firstName || '';
  const nextStudentId = nextStudent?.id;
  const nextStudentName = nextStudent?.user?.firstName || '';

  const enrollment = useMemo(
    () => studentEnrollments.find((x) => x.student.id === currentStudent?.id),
    [currentStudent?.id, studentEnrollments]
  );

  const {
    selectedLessons,
    selectedTeachers,
    selectedAvailabilities = {},
    selectedLessonsSignupDetails,
  } = enrollment || {};

  // NOTE: Timeslot is for all lessons for now, get longest lesson length for now
  const minimalLessonLength = useMemo(
    () =>
      selectedLessonsSignupDetails?.reduce((accum, { sessionLength }) => Math.max(accum, sessionLength || 0), 0) || 0,
    [selectedLessonsSignupDetails]
  );

  // noinspection SuspiciousTypeOfGuard
  const completedTimeSetFilter = (timeframe: AvailabilityProps[]) =>
    timeframe
      .filter(({ start, end }) => !!start?.time && !!end?.time)
      .map((availability) => ({
        ...availability,
        id: availability.id || uuidv4(),
        start: {
          ...availability.start,
          time:
            typeof availability.start?.time === 'string' ? new Date(availability.start.time) : availability.start?.time,
        },
        end: {
          ...availability.end,
          time: typeof availability.end?.time === 'string' ? new Date(availability.end.time) : availability.end?.time,
        },
      }));

  const selectedTimeSet = useMemo(() => {
    const selectedTimeSetAll = selectedAvailabilities[date] || [];
    return completedTimeSetFilter(selectedTimeSetAll);
  }, [selectedAvailabilities, date]);

  const isTimeFrameSelected = useMemo(
    () =>
      Object.values(selectedAvailabilities).reduce(
        (accum, timeFrame) => accum || completedTimeSetFilter(timeFrame).length > 0,
        false
      ),
    [selectedAvailabilities]
  );

  // If nextStudentId is null or undefined, this is the last student. Proceed to the end.
  const handleNextClick = () => {
    nextStudentId
      ? navigate(`/enroll/${scheduleId}/student/${nextStudentId}/lesson-selection`)
      : navigate(`/enroll/${scheduleId}/summary`);
  };

  const baseTimePickerProps = useMemo(
    () => ({
      selectedAvailabilities,
      currentStudent,
      date,
      minimalLessonLength,
    }),
    [currentStudent, date, minimalLessonLength, selectedAvailabilities]
  );

  if (!currentStudent) {
    return null;
  }

  return (
    <Enrollment back={{ path: `/enroll/${scheduleId}/student/${studentId}/teacher-selection`, description: 'Teacher' }}>
      <EnrollmentHeader currentStudent={currentStudent} />
      <ContentHeaderWrapper>
        <Typography variant={'h1'}>{`What timeframe is ${currentStudentName} available for lessons?`}</Typography>
      </ContentHeaderWrapper>
      <ContentBodyWrapper>
        <Typography>
          Enter all {currentStudentName}’s availability for each day they are available for lessons below. The start and
          end times represent the timeframe they are available on a particular day of the week. Please do not put in a
          single half-hour if you have more flexibility. Lyriq will do its best to pair you with your preferred teacher.
          However, if that is not possible we will offer other options that will work within the availability you
          provide.
        </Typography>
        <TeacherSelectionReminder selectedLessons={selectedLessons} selectedTeachers={selectedTeachers} />
        <StyledTypography>
          Click the day(s) you would like lessons. Specify your availability by moving the start and end time sliders
          for each day (e.g., Monday 3:30-7:30pm)
        </StyledTypography>
        <Tabs
          value={weekendTab}
          onChange={(event, value) => {
            setWeekendTab(value);
            const newDate = value === WeekendTab.WEEKDAYS ? DateEnums.MONDAY : DateEnums.SATURDAY;
            setDate(newDate);
          }}
          variant='fullWidth'
          sx={{ mt: 2 }}
        >
          <Tab
            label={
              <>
                <Typography variant='h5' sx={{ textTransform: 'none' }}>
                  {WeekendTab.WEEKDAYS}
                </Typography>
              </>
            }
            value={WeekendTab.WEEKDAYS}
            sx={{ paddingBottom: 0.5 }}
          />
          <Tab
            label={
              <>
                <Typography variant='h5' sx={{ textTransform: 'none' }}>
                  {WeekendTab.WEEKENDS}
                </Typography>
              </>
            }
            value={WeekendTab.WEEKENDS}
            sx={{ paddingBottom: 0.5 }}
          />
        </Tabs>
        <TeacherSelectionReminderContainer>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Tabs
              variant='fullWidth'
              onChange={(event, value) => {
                setDate(value);
              }}
              sx={{ borderRadius: '16px 16px 0px 0px' }}
            >
              {weekendTab === WeekendTab.WEEKDAYS
                ? weekdays.map((day) => (
                    <WeekdayTab
                      key={`weekday-picker-${day}`}
                      label={day.substring(0, 3)}
                      value={day}
                      date={date}
                      hasEntries={!!completedTimeSetFilter(selectedAvailabilities[day as DateEnums] || []).length}
                    />
                  ))
                : weekends.map((day) => (
                    <WeekEndTab
                      fullWidth
                      key={`weekend-picker-${day}`}
                      label={day.substring(0, 3)}
                      value={day}
                      date={date}
                      hasEntries={!!completedTimeSetFilter(selectedAvailabilities[day as DateEnums] || []).length}
                    />
                  ))}
            </Tabs>
            <ContentBodyWrapper>
              {selectedTimeSet.length > 0 &&
                selectedTimeSet.map((value, idx) => (
                  <Box key={value.id} sx={{ my: 2 }}>
                    <TimeRangeSlider {...baseTimePickerProps} id={value.id} />
                    {idx + 1 < selectedTimeSet.length && <Divider sx={{ mt: 2 }} />}
                  </Box>
                ))}
            </ContentBodyWrapper>
          </LocalizationProvider>
          {!selectedTimeSet.length && (
            <CenterDiv sx={{ mt: 2 }}>
              <StyledButton
                variant='text'
                startIcon={<MoreTimeIcon />}
                onClick={() => {
                  selectAvailability(Number(currentStudent?.id), date, getInitialAvailability(date));
                }}
              >
                <Typography fontSize={'1rem'}>Add availability on {date}</Typography>
              </StyledButton>
            </CenterDiv>
          )}
          {selectedTimeSet.length > 0 && (
            <CenterDiv>
              <StyledButton
                variant='text'
                startIcon={<MoreTimeIcon />}
                onClick={() => {
                  selectAvailability(Number(currentStudent?.id), date, getInitialAvailability(date));
                }}
              >
                <Typography fontSize={'1rem'}>Add additional availability?</Typography>
              </StyledButton>
            </CenterDiv>
          )}
        </TeacherSelectionReminderContainer>
        <CenterDiv>
          <Button
            variant='contained'
            color='secondary'
            onClick={handleNextClick}
            disabled={!isTimeFrameSelected}
            fullWidth
          >
            Save {currentStudentName ?? ''} and {nextStudentId ? `continue to ${nextStudentName}` : 'view summary'}
          </Button>
        </CenterDiv>
      </ContentBodyWrapper>
    </Enrollment>
  );
};

export default TimeframeSelection;

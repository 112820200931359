import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { CallToActionsDocument, CallToActionsQuery } from 'generated/graphql';

function useQueryGetCallToActions(options?: QueryHookOptions<CallToActionsQuery>): QueryResult<CallToActionsQuery> {
  return useQuery<CallToActionsQuery>(CallToActionsDocument, {
    ...options,
  });
}

export default useQueryGetCallToActions;

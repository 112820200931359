import React, { useCallback } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useAdminContext } from 'contexts/AdminContext/AdminContext';
import { SchedulerView } from 'components/organisms/NewSemesterCalendar/components/SchedulingCalendar/SchedulingCalendar';
import Stack from '@mui/material/Stack';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { DayOfWeek } from 'generated/graphql';
import { dayOfWeekIndexToStringMap, weekdayMondayIndexMap } from 'utils/availability/availabilityUtils';
import { sortDaysOfWeek } from 'utils/format/formatAvailabilityDays';

const SchedulerViewControl = () => {
  const { currentView, setCurrentView, daysOfWeek, setDaysOfWeek } = useAdminContext();

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: SchedulerView | null) => {
    if (newView !== null) {
      setCurrentView(newView);
      if (newView === 'day' && daysOfWeek && daysOfWeek.length > 1) {
        setDaysOfWeek([daysOfWeek[0]]);
      }
    }
  };

  const handleDaysOfWeekChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newDaysOfWeek: DayOfWeek[]) => {
      if (newDaysOfWeek.length) {
        setDaysOfWeek(sortDaysOfWeek(newDaysOfWeek));
      }
    },
    [setDaysOfWeek]
  );

  const handleBackClick = useCallback(() => {
    const [dayOfWeek] = daysOfWeek || [DayOfWeek.Monday];
    if (!dayOfWeek) {
      setDaysOfWeek([DayOfWeek.Monday]);
    }

    const currentIndex = weekdayMondayIndexMap[dayOfWeek];
    const newDayOfWeek = currentIndex > 0 ? dayOfWeekIndexToStringMap[currentIndex - 1] : DayOfWeek.Sunday;
    setDaysOfWeek([newDayOfWeek]);
  }, [daysOfWeek, setDaysOfWeek]);

  const handleNextClick = useCallback(() => {
    const [dayOfWeek] = daysOfWeek || [DayOfWeek.Monday];
    if (!dayOfWeek) {
      setDaysOfWeek([DayOfWeek.Monday]);
    }

    const currentIndex = weekdayMondayIndexMap[dayOfWeek];
    const newDayOfWeek = currentIndex === 6 ? DayOfWeek.Monday : dayOfWeekIndexToStringMap[currentIndex + 1];
    setDaysOfWeek([newDayOfWeek]);
  }, [daysOfWeek, setDaysOfWeek]);

  return (
    <Stack direction='row' spacing={2}>
      {currentView === 'day' && (
        <ButtonGroup
          variant='contained'
          color='secondary'
          aria-label='Previous and Next day of week controls'
          size='small'
        >
          <Button onClick={handleBackClick}>Back</Button>
          <Button onClick={handleNextClick}>Next</Button>
        </ButtonGroup>
      )}
      {currentView === 'custom_days' && (
        <ToggleButtonGroup value={daysOfWeek} onChange={handleDaysOfWeekChange} aria-label='days of week' size='small'>
          <ToggleButton value={DayOfWeek.Monday} aria-label='Monday'>
            Mon
          </ToggleButton>
          <ToggleButton value={DayOfWeek.Tuesday} aria-label='Tuesday'>
            Tue
          </ToggleButton>
          <ToggleButton value={DayOfWeek.Wednesday} aria-label='Wednesday'>
            Wed
          </ToggleButton>
          <ToggleButton value={DayOfWeek.Thursday} aria-label='Thursday'>
            Thu
          </ToggleButton>
          <ToggleButton value={DayOfWeek.Friday} aria-label='Friday'>
            Fri
          </ToggleButton>
          <ToggleButton value={DayOfWeek.Saturday} aria-label='Saturday'>
            Sat
          </ToggleButton>
          <ToggleButton value={DayOfWeek.Sunday} aria-label='Sunday'>
            Sun
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      <ToggleButtonGroup
        value={currentView}
        exclusive
        onChange={handleViewChange}
        aria-label='text alignment'
        size='small'
      >
        <ToggleButton value='day' aria-label='View by day' size='small'>
          Day
        </ToggleButton>
        <ToggleButton value='week' aria-label='View by week' size='small'>
          Week
        </ToggleButton>
        <ToggleButton value='custom_days' aria-label='View by custom set of days' size='small'>
          Custom
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default SchedulerViewControl;

import React from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import ContentHeaderWrapper from 'components/atoms/ContentHeaderWrapper/ContentHeaderWrapper';
import ContentBodyWrapper from 'components/atoms/ContentBodyWrapper/ContentBodyWrapper';
import Enrollment from 'components/templates/Enrollment/Enrollment';
import EnrollmentHeader from 'pages/Enrollment/components/EnrollmentHeader/EnrollmentHeader';
import PromptUserAccountModal from 'pages/Enrollment/PromptUserAccountModal';

const SemesterInformation = () => {
  const { scheduleId } = useParams();
  const { selectedStudents, schedule } = useEnrollmentContext();
  const backPath = `/enroll/${scheduleId}`;

  return (
    <Enrollment
      back={{ path: backPath, description: 'Student Selection' }}
      next={{
        path: `/enroll/${scheduleId}/student/${
          selectedStudents && selectedStudents.length && selectedStudents[0].id
        }/lesson-selection`,
        description: 'Lesson Selection',
      }}
    >
      <EnrollmentHeader />
      <ContentHeaderWrapper>
        <Typography variant={'h1'}>Semester Information</Typography>
      </ContentHeaderWrapper>
      <ContentBodyWrapper>
        <Typography variant='body2' sx={{ margin: '2.5rem 0rem' }}>
          Please review the information below for this semester. You can access this information again at any time
          during the registration process by tapping the{' '}
          <InfoOutlinedIcon fontSize='small' sx={{ position: 'relative', top: '0.2rem' }} /> icon in the upper-right of
          your screen.
        </Typography>
        {schedule?.semesterInformation && <Typography>{parse(schedule?.semesterInformation)}</Typography>}
      </ContentBodyWrapper>
      <PromptUserAccountModal backPath={backPath} />
    </Enrollment>
  );
};

export default SemesterInformation;

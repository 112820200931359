import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  AccountBillingDetailsDocument,
  AccountBillingDetailsQuery,
  AccountBillingDetailsQueryVariables,
} from 'generated/graphql';

type Data = AccountBillingDetailsQuery;
type Variables = AccountBillingDetailsQueryVariables;

function useQueryAccountBillingDetails(options?: QueryHookOptions<Data, Variables>): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(AccountBillingDetailsDocument, {
    ...options,
  });
}

export default useQueryAccountBillingDetails;

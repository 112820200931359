import React from 'react';
import { CreditCard, PaymentForm, PaymentFormProps } from 'react-square-web-payments-sdk';
import env from 'config/env';
import * as Square from '@square/web-sdk';

interface SquarePaymentFormProps extends Partial<PaymentFormProps> {
  createVerificationDetails?: () => Square.ChargeVerifyBuyerDetails | Square.StoreVerifyBuyerDetails;
  cardTokenizeResponseReceived: (
    props: Square.TokenResult,
    verifiedBuyer?: Square.VerifyBuyerResponseDetails | null
  ) => void;
}

type Props = SquarePaymentFormProps;

const SquarePaymentForm = ({ cardTokenizeResponseReceived, createVerificationDetails, children, ...props }: Props) => {
  return (
    <PaymentForm
      /**
       * Identifies the calling form with a verified application ID generated from
       * the Square Application Dashboard.
       */
      applicationId={env.square.applicationId}
      /**
       * Invoked when payment form receives the result of a tokenize generation
       * request. The result will be a valid credit card or wallet token, or an error.
       */
      cardTokenizeResponseReceived={cardTokenizeResponseReceived}
      /**
       * This function enable the Strong Customer Authentication (SCA) flow
       *
       * We strongly recommend use this function to verify the buyer and reduce
       * the chance of fraudulent transactions.
       */
      createVerificationDetails={createVerificationDetails}
      /**
       * Identifies the location of the merchant that is taking the payment.
       * Obtained from the Square Application Dashboard - Locations tab.
       */
      locationId={env.square.locationId}
      {...props}
    >
      <CreditCard>{children}</CreditCard>
    </PaymentForm>
  );
};

export default SquarePaymentForm;

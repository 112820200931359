import React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import majorGrid from './Image/major-grid.png';

const PageTitle = styled('h3')`
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
`;

const StyledImage = styled('img')`
  margin-top: 1rem;
`;

const StyledDiv = styled('div')`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: 5rem;
  margin-top: 2rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const SchedulePage = () => {
  return (
    <Container>
      <PageTitle>Lesson Notes</PageTitle>
      <h2>July 28th Lesson Notes</h2>
      <p>
        Amet egestas diam amet vitae vestibulum auctor tristique. Id consequat tempus nunc elementum placerat enim.
        Parturient purus varius a molestie nisl enim, ut velit. Euismod neque dui accumsan pulvinar sed vel.
        Pellentesque nisi sed suspendisse in semper habitant. Sit cursus hendrerit quis mi magna. Id nulla lectus velit
        sit viverra integer convallis penatibus.
      </p>
      <p>Nec id fames nulla risus malesuada nulla. Sit habitasse sed netus hendrerit habitant.</p>
      <Container>
        <StyledImage src={majorGrid} alt='grid charts' />
      </Container>
      <StyledDiv>
        <iframe title='video' frameBorder='0' allowFullScreen src='https://www.youtube.com/embed/pviTtNIery8'></iframe>
      </StyledDiv>
    </Container>
  );
};

export default SchedulePage;

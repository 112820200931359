import { Skill } from 'generated/graphql';

export default function formatSkills(skills?: Pick<Skill, 'skillName'>[] | null) {
  if (!skills || !skills.length) {
    return '';
  }

  // Sort by name first
  const skillNames = skills.map((x) => x.skillName);
  skillNames.sort();

  return skillNames.join(', ');
}

import React from 'react';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { EnrollmentFromQuery } from 'types';
import BoxShadowContainer from 'components/atoms/BoxShadowContainer/BoxShadowContainer';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import EnrollmentStatusControl from 'components/molecules/EnrollmentStatusControl/EnrollmentStatusControl';
import EnrollmentLockToggle from 'components/molecules/EnrollmentLockToggle/EnrollmentLockToggle';
import useMutationUnscheduleEnrollment from 'hooks/useMutationUnscheduleEnrollment/useMutationUnscheduleEnrollment';
import { EnrollmentStatus, BillingState } from 'generated/graphql';

const StyledPopper = styled(Popper)`
  max-width: 50rem;
  max-height: 50rem;
  border-radius: 1.25rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0 0.3rem 0.9rem;
  z-index: 100;
`;

type Props = {
  anchorEl?: HTMLElement | null;
  open: boolean;
  enrollment: EnrollmentFromQuery;
  setShowEditDialog: React.Dispatch<React.SetStateAction<boolean>> | React.Dispatch<boolean>;
};

function canBeUnscheduled(enrollment: EnrollmentFromQuery) {
  return (
    ![EnrollmentStatus.PendingConfirmation, EnrollmentStatus.UserConfirmed, EnrollmentStatus.Approved].includes(
      enrollment.schedulingStatus
    ) && enrollment.billingStatus !== BillingState.Paid
  );
}

function isLocked(enrollment: EnrollmentFromQuery) {
  const lockedStatuses = [
    EnrollmentStatus.PendingConfirmation,
    EnrollmentStatus.UserConfirmed,
    EnrollmentStatus.Approved,
  ];

  return lockedStatuses.includes(enrollment.schedulingStatus);
}

const EnrollmentCardPopper: React.FC<Props> = ({ anchorEl, open, enrollment, setShowEditDialog }) => {
  const studentName = `${enrollment.studentProfile.user?.firstName} ${enrollment.studentProfile.user?.lastName}`;
  const instrument = enrollment.skill?.skillName;
  const packageName = enrollment.package.name;
  const { startTime, endTime } = enrollment;
  const lessonDuration =
    startTime && endTime
      ? // @ts-ignore - ignoring because typescript is not allowing date subtraction
        Math.abs(new Date(`01/01/2000 ${startTime}`) - new Date(`01/01/2000 ${endTime}`)) / 60000
      : 'N / A';
  const preferredTeacher = enrollment.preferredInstructor
    ? `${enrollment.preferredInstructor.user?.firstName} ${enrollment.preferredInstructor.user?.lastName}`
    : 'None';
  const scheduledTeacher = enrollment.scheduledInstructor
    ? `${enrollment.scheduledInstructor.user?.firstName} ${enrollment.scheduledInstructor.user?.lastName}`
    : 'None';
  const { schedulingStatus, lessonType, siblingConsideration, schedulingConsideration, enrollmentDate, billingStatus } =
    enrollment;
  const studentAvailability = enrollment.availability?.availabilityItems;

  const [unscheduleEnrollment] = useMutationUnscheduleEnrollment();
  const handleUnscheduleClick = async () =>
    await unscheduleEnrollment({
      variables: {
        input: {
          id: enrollment.id,
        },
      },
    });

  const enrollmentCanBeUnscheduled = canBeUnscheduled(enrollment);
  const editDisabled = isLocked(enrollment) || enrollment.scheduleLocked;

  return (
    <StyledPopper open={open} anchorEl={anchorEl} popperOptions={{ placement: 'right-end' }}>
      <BoxShadowContainer sx={{ margin: 0 }}>
        <Stack direction='row' spacing={2} alignItems='center' justifyContent='flex-end' mb={1}>
          <Button
            color='primary'
            variant='contained'
            size='small'
            onClick={() => setShowEditDialog(true)}
            disabled={editDisabled}
          >
            Edit
          </Button>
          {enrollmentCanBeUnscheduled && (
            <Button
              color='primary'
              variant='contained'
              size='small'
              onClick={handleUnscheduleClick}
              disabled={editDisabled}
            >
              Unschedule
            </Button>
          )}
          <Box sx={{ minWidth: '7rem' }}>
            <EnrollmentLockToggle enrollment={enrollment} buttonProps={{ fullWidth: true }} />
          </Box>
        </Stack>
        <Divider light sx={{ mb: 1 }} />
        <Typography>Student Name: {studentName}</Typography>
        <Typography>Instrument: {instrument}</Typography>
        <Typography>Package Name: {packageName}</Typography>
        <Typography>Lesson Duration: {lessonDuration} minutes</Typography>
        <Typography>Preferred Teacher: {preferredTeacher}</Typography>
        <Typography>Scheduled Teacher: {scheduledTeacher}</Typography>
        {studentAvailability ? (
          studentAvailability.map((availability, i) => {
            const { dayOfWeek, startTime, endTime } = availability;
            return (
              <Typography key={availability.id}>
                Availability {i + 1}: {dayOfWeek[0] + dayOfWeek.slice(1).toLowerCase()} {startTime} - {endTime}
              </Typography>
            );
          })
        ) : (
          <Typography>Availability: Not Provided</Typography>
        )}
        <Typography>Scheduling Status: {schedulingStatus}</Typography>
        <Typography>Lesson Type: {lessonType}</Typography>
        <Typography>Sibling Consideration: {siblingConsideration}</Typography>
        <Typography>Scheduling Consideration: {schedulingConsideration}</Typography>
        <Typography>Enrollment Date: {enrollmentDate}</Typography>
        <Typography>Billing Status: {billingStatus}</Typography>
        <Divider light sx={{ mt: 1 }} />
        <EnrollmentStatusControl enrollment={enrollment} />
      </BoxShadowContainer>
    </StyledPopper>
  );
};

export default EnrollmentCardPopper;

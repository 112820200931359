import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import theme from 'theme';
import { useNavigate } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';

type Props = {
  scheduleId: string | number;
};

const CenterDiv = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 1.313rem;
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
  color: ${theme.colors.black};
  text-transform: none;
`;

export const AddNewStudentButton: React.FC<Props> = ({ scheduleId }) => {
  const navigate = useNavigate();
  const handleAddNewStudentClick = () => navigate(`/enroll/${scheduleId}/add-student`);

  return (
    <CenterDiv>
      <StyledButton variant='text' startIcon={<PersonAddIcon />} onClick={handleAddNewStudentClick}>
        <Typography fontSize={'1rem'}>Add a new Student?</Typography>
      </StyledButton>
    </CenterDiv>
  );
};

export default AddNewStudentButton;

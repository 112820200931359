import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AvailabilityItem, DayOfWeek } from 'generated/graphql';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import Stack from '@mui/material/Stack';
import AvailabilitySlider, {
  deserializeValue,
  minMaxForDay,
} from 'components/molecules/AvailabilitySlider/AvailabilitySlider';
import { TimeRange } from 'components/molecules/TimeRangeSliderField/TimeRangeSliderField';
import { allDaysOfWeek, sortAvailabilityItems } from 'utils/availability/availabilityUtils';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grow from '@mui/material/Grow';

export type AvailabilityItemWithUuid = Omit<AvailabilityItem, 'id'> & { __uuid?: string; id?: AvailabilityItem['id'] };

interface AvailabilityInputProps {
  value: AvailabilityItemWithUuid[];
  onChange?: (newValue: AvailabilityItemWithUuid[]) => void;
}

type Props = AvailabilityInputProps;

const AvailabilityInput = ({ value, onChange }: Props) => {
  const [showDaySelect, setShowDaySelect] = useState(false);

  useEffect(() => {
    if (!onChange) {
      return;
    }

    let hasUpdate = false;
    const updated = value.map((item) => {
      if (!item.__uuid) {
        hasUpdate = true;
        return {
          ...item,
          __uuid: item.id?.toString() ?? uuidv4(),
        };
      }

      return item;
    });

    if (hasUpdate) {
      onChange(updated);
    }
  }, [value, onChange]);

  const handleAddNew = useCallback(
    (dayOfWeek: DayOfWeek) => {
      const minMax = deserializeValue(minMaxForDay[dayOfWeek]);
      onChange &&
        onChange(
          sortAvailabilityItems([
            ...value,
            {
              __uuid: uuidv4(),
              dayOfWeek,
              startTime: minMax.start,
              endTime: minMax.end,
            },
          ])
        );
      setShowDaySelect(false);
    },
    [onChange, value]
  );

  const handleChange = useCallback(
    (item: AvailabilityItemWithUuid, newValue: TimeRange) => {
      onChange &&
        onChange(
          value.map((x) => {
            if (item.__uuid ? x.__uuid === item.__uuid : x.id === item.id) {
              return {
                ...x,
                startTime: newValue.start,
                endTime: newValue.end,
              };
            }

            return x;
          })
        );
    },
    [onChange, value]
  );

  const handleDelete = useCallback(
    (item: AvailabilityItemWithUuid) => {
      onChange && onChange(value.filter((x) => (item.__uuid ? x.__uuid !== item.__uuid : x.id !== item.id)));
    },
    [onChange, value]
  );

  const items = useMemo(
    () =>
      value.map((item) => ({
        ...item,
        timeValue: item.startTime && item.endTime ? { start: item.startTime, end: item.endTime } : undefined,
      })),
    [value]
  );

  return (
    <Stack direction='column' spacing={2}>
      {items.map((item) => (
        <Grid container key={item.__uuid}>
          <Grid item xs={12}>
            {item.dayOfWeek}
          </Grid>
          <Grid item xs={12}>
            <AvailabilitySlider
              dayOfWeek={item.dayOfWeek}
              value={item.timeValue}
              onChange={(newValue) => handleChange(item, newValue)}
              onDelete={() => handleDelete(item)}
              labelProps={{
                variant: 'body1',
                color: (theme) => theme.palette.common.black,
              }}
            />
          </Grid>
        </Grid>
      ))}
      <Box sx={{ display: 'flex', flexWrap: 'no-wrap', justifyContent: 'center', minHeight: '2rem' }}>
        {!showDaySelect && (
          <Button
            variant='text'
            startIcon={<MoreTimeIcon />}
            onClick={() => setShowDaySelect(true)}
            size='small'
            sx={{ color: 'black' }}
          >
            <Typography fontSize={'1rem'}>Add availability</Typography>
          </Button>
        )}
        {showDaySelect && (
          <Grow in={showDaySelect} appear>
            <ButtonGroup variant='outlined' aria-label='Day of week selector button group' size='small'>
              {allDaysOfWeek.map((dayOfWeek) => (
                <Button key={dayOfWeek} onClick={() => handleAddNew(dayOfWeek)}>
                  {dayOfWeek.slice(0, 3)}
                </Button>
              ))}
            </ButtonGroup>
          </Grow>
        )}
      </Box>
    </Stack>
  );
};

export default AvailabilityInput;

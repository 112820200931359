import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import theme from 'theme';
import useCurrentUserAccount from 'hooks/useCurrentUserAccount/useCurrentUserAccount';
import useQueryGetSkillLevels from 'hooks/queries/useQueryGetSkillLevels/useQueryGetSkillLevels';
import { useUserContext } from 'contexts/UserContext/UserContext';
import { useAccountContext } from 'contexts/AccountContext/AccountContext';
import { AddStudentUserDocument, AddStudentUserMutation, AddStudentUserMutationVariables } from 'generated/graphql';
import { CirclePicker, ColorResult } from 'react-color';
import { getPrimaryStudentColors, getRandomStudentColor } from 'utils/colors/studentColors';

export const SubmitButton = styled(Button)`
  background-color: ${theme.colors.rackley};
  margin-left: 1rem;
  border: none;
  border-radius: 0;
  text-transform: none;

  :hover {
    background-color: ${theme.colors.rackley};
  }
`;
export const CancelButton = styled(Button)`
  color: ${theme.colors.black};
  margin-right: 1rem;
  border: none;
  border-radius: 0;
  text-transform: none;
`;

const validationSchema = yup.object({
  accountId: yup.number().required('Account ID is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email(),
});

interface AddStudentFormProps {
  setAddingStudents?: Dispatch<SetStateAction<boolean>>;
  onComplete?: () => void;
  onCancel?: () => void;
}

type Props = AddStudentFormProps;

const AddStudentForm = ({ setAddingStudents, onComplete, onCancel }: Props) => {
  const colorOptions = useMemo(() => getPrimaryStudentColors(), []);
  const [studentColor, setStudentColor] = useState(getRandomStudentColor().primary);
  const handleColorChange = (newColor: ColorResult) => {
    setStudentColor(newColor.hex);
  };
  const {
    postLogin: { refetch },
  } = useUserContext();
  const account = useCurrentUserAccount();
  const { getStudentsRefetch } = useAccountContext();

  const [addStudentUser, { error }] = useMutation<AddStudentUserMutation, AddStudentUserMutationVariables>(
    AddStudentUserDocument
  );

  const { data } = useQueryGetSkillLevels();
  const skillLevels = data?.getSkillLevels;

  const [skillLevel, setSkillLevel] = useState(skillLevels && skillLevels[0]?.id.toString());

  const formik = useFormik({
    initialValues: {
      accountId: account?.id,
      firstName: '',
      lastName: '',
      email: '',
      birthMonth: '',
      birthYear: '',
      school: '',
      color: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (account) {
        const result = await addStudentUser({
          variables: {
            input: {
              accountId: account.id,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              sendInvite: false,
              birthMonth: parseInt(values.birthMonth, 10),
              birthYear: parseInt(values.birthYear, 10),
              school: values.school,
              competenceLevel: skillLevel,
              availability: {},
              color: studentColor,
            },
          },
        });

        if (result.data?.addStudentUser) {
          await refetch();
          await getStudentsRefetch();
        }
        setAddingStudents && setAddingStudents(false);
        onComplete && onComplete();
      }
    },
  });
  const onCancelClick = () => {
    setAddingStudents && setAddingStudents(false);
    onCancel && onCancel();
  };

  return (
    <>
      {error && (
        <Alert color='error' sx={{ my: 2 }}>
          {error.message}
        </Alert>
      )}
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id='firstName'
          name='firstName'
          label='First Name'
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          variant='standard'
          sx={{ my: 2 }}
          required
        />
        <TextField
          fullWidth
          id='lastName'
          name='lastName'
          label='Last Name'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          variant='standard'
          sx={{ my: 2 }}
          required
        />
        <Box sx={{ display: 'flex' }}>
          <TextField
            fullWidth
            id='birthMonth'
            name='birthMonth'
            label='Birth Month'
            value={formik.values.birthMonth}
            onChange={formik.handleChange}
            error={formik.touched.birthMonth && Boolean(formik.errors.birthMonth)}
            helperText={formik.touched.birthMonth && formik.errors.birthMonth}
            variant='standard'
            sx={{ my: 2, mr: 1 }}
            required
          />
          <TextField
            fullWidth
            id='birthYear'
            name='birthYear'
            label='Birth Year'
            value={formik.values.birthYear}
            onChange={formik.handleChange}
            error={formik.touched.birthYear && Boolean(formik.errors.birthYear)}
            helperText={formik.touched.birthYear && formik.errors.birthYear}
            variant='standard'
            required
            sx={{ my: 2, ml: 1 }}
            col-6
          />
        </Box>
        <TextField
          fullWidth
          id='email'
          type='email'
          name='email'
          label='Student Email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={
            (formik.touched.email && formik.errors.email) ||
            "Optional if you'd like the student to have their own account"
          }
          variant='standard'
          sx={{ my: 2 }}
        />
        <TextField
          fullWidth
          id='school'
          name='school'
          label='School'
          value={formik.values.school}
          onChange={formik.handleChange}
          error={formik.touched.school && Boolean(formik.errors.school)}
          helperText={formik.touched.school && formik.errors.school}
          variant='standard'
          sx={{ my: 2 }}
        />
        <InputLabel>Skill Level</InputLabel>
        <Select
          sx={{ my: 2 }}
          fullWidth
          value={skillLevel || skillLevels?.[0]?.id || ''}
          onChange={(event) => {
            const skillLevel = event?.target.value.toString();
            setSkillLevel(skillLevel);
          }}
          variant='standard'
        >
          {skillLevels?.map((level) => {
            return (
              <MenuItem key={level.id} value={level.id}>
                {level.levelName}
              </MenuItem>
            );
          })}
        </Select>
        <Box mt={2}>
          <InputLabel>Student Color</InputLabel>
          <Box mt={1} mb={3}>
            <CirclePicker color={studentColor} onChange={handleColorChange} colors={colorOptions} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <CancelButton color='primary' variant='text' fullWidth onClick={onCancelClick}>
            Cancel
          </CancelButton>
          <SubmitButton
            color='primary'
            variant='contained'
            fullWidth
            type='submit'
            disabled={formik.isSubmitting}
            sx={{ ml: 1, backgroundColor: '#718EB3' }}
          >
            {formik.isSubmitting ? 'Submitting...' : 'Add student'}
          </SubmitButton>
        </Box>
      </form>
    </>
  );
};

export default AddStudentForm;

import { gql, MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  DeclineEnrollmentDocument,
  DeclineEnrollmentMutation,
  DeclineEnrollmentMutationVariables,
  EnrollmentStatus,
} from 'generated/graphql';
import { useMemo } from 'react';

type Data = DeclineEnrollmentMutation;
type Variables = DeclineEnrollmentMutationVariables;

function useMutationDeclineEnrollment(options?: MutationHookOptions<Data, Variables>): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      update(cache, result, options) {
        const { id } = options.variables?.input || {};

        cache.writeFragment<{ schedulingStatus: EnrollmentStatus }>({
          id: `Enrollment:${id}`,
          fragment: gql`
            fragment EnrollmentWithStatus on Enrollment {
              schedulingStatus
            }
          `,
          data: {
            schedulingStatus: EnrollmentStatus.UserDeclined,
          },
        });
      },
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(DeclineEnrollmentDocument, opts);
}

export default useMutationDeclineEnrollment;

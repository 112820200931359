import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { AdminSchedulesDocument, AdminSchedulesQuery, AdminSchedulesQueryVariables } from 'generated/graphql';
import useHasAuthRole from '../../useHasAuthRole/useHasAuthRole';
import { AuthRole } from '../../../utils/user/AuthUtils';

function useQueryAdminSchedules(
  options?: QueryHookOptions<AdminSchedulesQuery, AdminSchedulesQueryVariables>
): QueryResult<AdminSchedulesQuery, AdminSchedulesQueryVariables> {
  const hasAuthRole = useHasAuthRole(AuthRole.LyriqAdmin);

  return useQuery<AdminSchedulesQuery, AdminSchedulesQueryVariables>(AdminSchedulesDocument, {
    skip: !hasAuthRole,
    ...options,
  });
}

export default useQueryAdminSchedules;

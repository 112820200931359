import React from 'react';
import Typography from '@mui/material/Typography';
import BoxShadowContainer from 'components/atoms/BoxShadowContainer/BoxShadowContainer';

type Props = {
  firstName: string;
  lastName: string;
};

const StudentInfoCard: React.FC<Props> = ({ firstName, lastName }) => {
  return (
    <BoxShadowContainer>
      <Typography variant={'h3'}>
        {firstName} {lastName}
      </Typography>
    </BoxShadowContainer>
  );
};

export default StudentInfoCard;

import { ColorMapper } from 'utils/SemesterCalendar/colorMapper';

export default function getRepeatingLinearGradient(colors: string[], opacity = 0.25): string {
  const stripeSize = 0.625;
  const gradient: string[] = ['to right'];
  colors.forEach((color, index) => {
    const start = index * stripeSize;
    const end = start + stripeSize;
    const alphaColor = ColorMapper.setOpacity(color, opacity);

    gradient.push(`${alphaColor}${start > 0 ? ` ${start}rem` : ''}`);
    gradient.push(`${alphaColor} ${end}rem`);
  });

  return `repeating-linear-gradient(${gradient.join(', ')})`;
}

import React from 'react';
import useOnboardingStepRedirect from 'hooks/useOnboardingStepRedirect/useOnboardingStepRedirect';

const CreateAccount = () => {
  useOnboardingStepRedirect();

  return <div>Create account</div>;
};

export default CreateAccount;

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  EnrollmentSchedulerFragmentFragmentDoc,
  EnrollmentsDocument,
  EnrollmentsQuery,
  EnrollmentsQueryVariables,
  EnrollmentUpdatedDocument,
  EnrollmentUpdatedSubscription,
  EnrollmentUpdatedSubscriptionVariables,
} from 'generated/graphql';
import { AuthRole } from 'utils/user/AuthUtils';
import useHasAuthRole from 'hooks/useHasAuthRole/useHasAuthRole';
import omit from 'lodash.omit';

type Data = EnrollmentsQuery;
type Variables = EnrollmentsQueryVariables;
type SubscriptionData = EnrollmentUpdatedSubscription;
type SubscriptionVariables = EnrollmentUpdatedSubscriptionVariables;

export type QueryEnrollmentsResult = QueryResult<Data, Variables> & { subscribeToEnrollmentUpdates: () => void };

function useQueryEnrollments(options?: QueryHookOptions<Data, Variables>): QueryEnrollmentsResult {
  const hasAuthRole = useHasAuthRole(AuthRole.LyriqAdmin);

  const { subscribeToMore, client, ...result } = useQuery<Data, Variables>(EnrollmentsDocument, {
    skip: !hasAuthRole,
    ...options,
  });

  return {
    ...result,
    client,
    subscribeToMore,
    subscribeToEnrollmentUpdates: () => {
      subscribeToMore<SubscriptionData, SubscriptionVariables>({
        document: EnrollmentUpdatedDocument,
        variables: options?.variables?.input
          ? {
              input: {
                filters: options?.variables?.input.filters,
              },
            }
          : undefined,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data?.enrollmentUpdated) {
            return prev;
          }

          const updatedEnrollment = subscriptionData.data.enrollmentUpdated;

          return {
            ...prev,
            enrollments: prev.enrollments.map((enrollment) => {
              if (enrollment.id !== updatedEnrollment.id) {
                return enrollment;
              }

              // Update the data on the enrollment, ignoring any fields that are empty.
              const updates = Object.fromEntries(Object.entries(updatedEnrollment).filter(([, v]) => v != null));

              const modified = {
                ...enrollment,
                ...omit(updates, ['__typename']),
              };

              // Write the updates into the apollo cache as well.
              client.writeFragment({
                id: client.cache.identify(modified),
                fragment: EnrollmentSchedulerFragmentFragmentDoc,
                data: modified,
              });

              return modified;
            }),
          };
        },
      });
    },
  };
}

export default useQueryEnrollments;

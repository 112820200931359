import React from 'react';
import { EnrollmentStatus } from 'generated/graphql';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CheckIcon from '@mui/icons-material/Check';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';

const icons: Record<EnrollmentStatus, React.ElementType> = {
  [EnrollmentStatus.Approved]: CheckIcon,
  [EnrollmentStatus.AutoScheduled]: HistoryToggleOffIcon,
  [EnrollmentStatus.Expired]: EventBusyIcon,
  [EnrollmentStatus.Inactive]: UpdateDisabledIcon,
  [EnrollmentStatus.New]: FiberNewIcon,
  [EnrollmentStatus.PendingConfirmation]: ScheduleSendIcon,
  [EnrollmentStatus.ReadyForConfirmation]: PendingActionsIcon,
  [EnrollmentStatus.Scheduled]: ScheduleIcon,
  [EnrollmentStatus.UserConfirmed]: EventAvailableIcon,
  [EnrollmentStatus.UserDeclined]: CancelScheduleSendIcon,
};

const statusLabels: Record<EnrollmentStatus, React.ReactNode> = {
  [EnrollmentStatus.Approved]: 'Approved',
  [EnrollmentStatus.AutoScheduled]: 'Auto-Scheduled',
  [EnrollmentStatus.Expired]: 'Expired',
  [EnrollmentStatus.Inactive]: 'Inactive',
  [EnrollmentStatus.New]: 'New',
  [EnrollmentStatus.PendingConfirmation]: 'Pending Confirmation',
  [EnrollmentStatus.ReadyForConfirmation]: 'Ready For Confirmation',
  [EnrollmentStatus.Scheduled]: 'Scheduled',
  [EnrollmentStatus.UserConfirmed]: 'User Confirmed',
  [EnrollmentStatus.UserDeclined]: 'User Declined',
};

interface EnrollmentStatusIconProps {
  status: EnrollmentStatus;
  iconProps?: SvgIconProps;
}

type Props = EnrollmentStatusIconProps;

const EnrollmentStatusIcon = ({ status, iconProps }: Props) => {
  const IconComponent = icons[status];

  if (!IconComponent) {
    return null;
  }

  const label = statusLabels[status] || status;

  return (
    <Tooltip title={label}>
      <IconComponent {...iconProps} />
    </Tooltip>
  );
};

export default EnrollmentStatusIcon;

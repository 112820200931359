import React from 'react';
import BackgroundLetterAvatar from 'components/molecules/BackgroundLetterAvatar/BackgroundLetterAvatar';
import { BadgeProps } from '@mui/material/Badge';
import { AvatarProps } from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import SmallBadge from 'components/atoms/SmallBadge/SmallBadge';

const StyledLetterAvatar = styled(BackgroundLetterAvatar)`
  width: 17px;
  height: 17px;
  font-size: 0.6rem;
`;

interface LetterAvatarWithBadgeProps {
  name: string;
  badgeContent?: React.ReactNode;
  badgeProps?: BadgeProps;
  avatarProps?: AvatarProps;
}

type Props = LetterAvatarWithBadgeProps;

const LetterAvatarWithBadge = ({ name, badgeContent, badgeProps, avatarProps }: Props) => {
  return (
    <SmallBadge badgeContent={badgeContent} {...badgeProps}>
      <StyledLetterAvatar name={name} {...avatarProps} />
    </SmallBadge>
  );
};

export default LetterAvatarWithBadge;

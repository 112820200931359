import React, { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';

type Props = {
  imgUrl: string;
};

const ImgDiv = styled('div', { shouldForwardProp: (prop: PropertyKey) => prop !== 'imgUrl' })<{ imgUrl: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props: Props) => {
    return `url(${props.imgUrl}) no-repeat top left`;
  }};
  background-size: cover;
  width: 100%;
  height: 45vw;
  max-height: 27rem;
`;

const BackgroundImageDiv: React.FC<PropsWithChildren<Props>> = ({ children, imgUrl }) => {
  return <ImgDiv imgUrl={imgUrl}>{children}</ImgDiv>;
};

export default BackgroundImageDiv;

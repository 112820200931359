import { gql, MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  StartScheduleSolverDocument,
  StartScheduleSolverMutation,
  StartScheduleSolverMutationVariables,
} from 'generated/graphql';
import { useMemo } from 'react';

type Data = StartScheduleSolverMutation;
type Variables = StartScheduleSolverMutationVariables;

function useMutationStartScheduleSolver(
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      update(cache, result) {
        const { solverId } = result.data?.startScheduleSolver || {};

        if (solverId) {
          cache.writeFragment<{ solverStatus?: string }>({
            id: `SolverTimeTable:${solverId}`,
            fragment: gql`
              fragment SolverTimeTableUpdate on SolverTimeTable {
                solverStatus
              }
            `,
            data: {
              solverStatus: 'SOLVING_SCHEDULED',
            },
          });
        }
      },
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(StartScheduleSolverDocument, opts);
}

export default useMutationStartScheduleSolver;

import { useAuth0 } from '@auth0/auth0-react';
import { AuthRole, AuthUtils } from 'utils/user/AuthUtils';

function useHasAuthRole(role?: AuthRole) {
  const { isAuthenticated, user } = useAuth0();

  return role && isAuthenticated && user && AuthUtils.hasAuthRole(user, role);
}

export default useHasAuthRole;

import { OnboardingStepEnum } from '../../generated/graphql';

export function getOnboardingStepPath(onboardingStep: OnboardingStepEnum): string | undefined {
  switch (onboardingStep) {
    case OnboardingStepEnum.AddStudents:
      return '/register/add-students';
    case OnboardingStepEnum.CreateAccount:
      return '/register/create-account';
    case OnboardingStepEnum.JoinAccount:
      return '/register/join-account';
    case OnboardingStepEnum.Register:
      return '/register/user';
    case OnboardingStepEnum.VerifyEmail:
      return '/register/verify-email';
    case OnboardingStepEnum.None:
      return undefined;
    default:
      return undefined;
  }
}

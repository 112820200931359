import { styled } from '@mui/material/styles';
import theme from 'theme';

const ContentWrapper = styled('div')`
  padding: 1.75rem 1rem;
  background-color: ${theme.colors.white};
  min-height: calc(100vh - 112px);

  @media (min-width: 600px) {
    min-height: calc(100vh - 120px);
  }
`;

export default ContentWrapper;

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetScheduleDocument, GetScheduleQueryVariables, GetScheduleQuery } from 'generated/graphql';

function useQueryGetSchedule(
  options?: QueryHookOptions<GetScheduleQuery, GetScheduleQueryVariables>
): QueryResult<GetScheduleQuery, GetScheduleQueryVariables> {
  return useQuery<GetScheduleQuery, GetScheduleQueryVariables>(GetScheduleDocument, {
    ...options,
  });
}

export default useQueryGetSchedule;

import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import LockClockIcon from '@mui/icons-material/LockClock';
import Stack from '@mui/material/Stack';
import EnrollmentStatusIcon from 'components/atoms/EnrollmentStatusIcon/EnrollmentStatusIcon';
import Box from '@mui/material/Box';
import useSiblingEnrollmentsDetail from 'hooks/useSiblingEnrollmentsDetail/useSiblingEnrollmentsDetail';
import useMultipleEnrollmentsDetail from 'hooks/useMultipleEnrollmentsDetail/useMultipleEnrollmentsDetail';
import LetterAvatarWithBadge from 'components/organisms/NewSemesterCalendar/components/LetterAvatarWithBadge/LetterAvatarWithBadge';
import useEnrollmentSolutionResult from 'components/organisms/NewSemesterCalendar/hooks/useEnrollmentSolutionResult/useEnrollmentSolutionResult';
import SolverScoreDisplay from 'components/molecules/SolverScoreDisplay/SolverScoreDisplay';
import { EnrollmentFromQuery } from 'types';
import colorMapper from 'utils/SemesterCalendar/colorMapper';
import { NO_PREFERENCE_VALUE } from 'components/organisms/InstructorSelectField/InstructorSelectField';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAdminContext } from 'contexts/AdminContext/AdminContext';

interface EnrollmentEventCardProps<T extends EnrollmentFromQuery = EnrollmentFromQuery> {
  enrollment: T;
  onClick?: (event: T, e: React.SyntheticEvent<HTMLElement>) => void;
}

type Props<T extends EnrollmentFromQuery = EnrollmentFromQuery> = EnrollmentEventCardProps<T>;

const EnrollmentEventCard = <T extends EnrollmentFromQuery = EnrollmentFromQuery>({
  enrollment,
  onClick,
}: Props<T>) => {
  const enrollmentId = enrollment.id;
  const studentProfileId = enrollment.studentProfileId;

  const { setSelectedEnrollment } = useAdminContext();

  const solutionResult = useEnrollmentSolutionResult(enrollmentId);
  const siblingEnrollments = useSiblingEnrollmentsDetail(enrollmentId);
  const multipleEnrollments = useMultipleEnrollmentsDetail(studentProfileId);

  const { skill, scheduledInstructor, preferredInstructor, scheduleLocked } = enrollment;
  const instructor = scheduledInstructor || preferredInstructor;
  const notPreferredInstructor = preferredInstructor && preferredInstructor.id !== instructor?.id;
  const accountHolderName =
    siblingEnrollments &&
    `${siblingEnrollments.accountHolderFirstNames[0]} ${siblingEnrollments.accountHolderLastNames[0]}`;
  const title = `${enrollment.studentProfile.user?.firstName} ${enrollment.studentProfile.user?.lastName}`;

  // Get the color for the card based on the instructor, or fallback to a unique color based on ID.
  const instructorId = enrollment.scheduledInstructorId || enrollment.preferredInstructorId || undefined;
  const colorId = `instructor_${instructorId?.toString() || NO_PREFERENCE_VALUE}`;
  const eventColor =
    enrollment.scheduledInstructor?.calendarColor ||
    enrollment.preferredInstructor?.calendarColor ||
    colorMapper.pickColor(colorId);

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleMoveClick = useCallback(() => {
    handleClose();
    setSelectedEnrollment(enrollment);
  }, [enrollment, setSelectedEnrollment]);

  return (
    <Box onContextMenu={handleContextMenu} sx={{ height: '100%' }}>
      <Box
        role='button'
        tabIndex={0}
        onClick={onClick ? (e) => onClick(enrollment, e) : undefined}
        sx={{
          height: '100%',
          backgroundColor: eventColor,
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.25)',
          overflow: 'hidden',
          borderRadius: '0.5rem',
          padding: '0.13rem 0 0 0.31rem',
        }}
      >
        <Box
          sx={{
            height: '100%',
            background: '#FDFDFD',
            borderRadius: '0.5rem 0.3125rem',
            padding: '0.31rem 0.27rem',
          }}
        >
          <Stack direction='column' sx={{ height: '100%' }}>
            <Typography variant='body2' fontWeight='bold' noWrap>
              {title}
            </Typography>
            <Typography variant='body2' noWrap>
              {skill?.skillName || 'No Skill Selected'}
              {instructor ? ` - ${instructor.user?.lastName}${notPreferredInstructor ? '**' : ''}` : 'No Teacher'}
            </Typography>
            <Box mt={'auto'}>
              <Stack direction='row' alignItems='center' spacing={1} flexWrap='nowrap'>
                <EnrollmentStatusIcon status={enrollment.schedulingStatus} iconProps={{ fontSize: 'small' }} />
                {scheduleLocked && <LockClockIcon fontSize='small' />}
                {multipleEnrollments && (
                  <LetterAvatarWithBadge
                    badgeContent={multipleEnrollments.enrollmentIds.length}
                    name={title.toUpperCase()}
                  />
                )}
                {solutionResult && !!solutionResult.score && <SolverScoreDisplay scoreText={solutionResult.score} />}
                {siblingEnrollments && accountHolderName && (
                  <Box flex='1 0 auto' display='flex' flexDirection='row' alignItems='center'>
                    <LetterAvatarWithBadge
                      badgeContent={siblingEnrollments.siblingEnrollmentIds.length}
                      name={accountHolderName.toUpperCase()}
                      badgeProps={{ sx: { ml: 'auto' } }}
                    />
                  </Box>
                )}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem onClick={handleMoveClick}>Move / Reschedule</MenuItem>
        <MenuItem onClick={handleClose}>Close menu</MenuItem>
      </Menu>
    </Box>
  );
};

export default React.memo(EnrollmentEventCard) as typeof EnrollmentEventCard;

import React from 'react';
import { ProfileHomeProps } from 'types';
import { useAccountContext } from 'contexts/AccountContext/AccountContext';
import RawLink from 'components/atoms/RawLink/RawLink';
import StudentInfoCard from 'components/molecules/StudentInfoCard/StudentInfoCard';
import EmbeddedAddStudentsForm from 'components/organisms/EmbeddedAddStudentsForm/EmbeddedAddStudentsForm';
import HeaderAndContent from 'components/templates/HeaderAndContent/HeaderAndContent';

const AccountHolderHome = ({ user }: ProfileHomeProps) => {
  const { students } = useAccountContext();
  return (
    <>
      <HeaderAndContent text={`Hi ${user.firstName}`}>
        <EmbeddedAddStudentsForm>
          {students?.map((student) => {
            return (
              <RawLink key={student.id} to={`/student-home/${student.id}`}>
                <StudentInfoCard firstName={student.user?.firstName || ''} lastName={student.user?.lastName || ''} />
              </RawLink>
            );
          })}
        </EmbeddedAddStudentsForm>
      </HeaderAndContent>
    </>
  );
};

export default AccountHolderHome;

import React from 'react';
import { styled } from '@mui/material/styles';
import Badge, { BadgeProps } from '@mui/material/Badge';

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    height: 12px;
    width: 12px;
    min-width: 12px;
    font-size: 0.5rem;
  }
`;

type Props = BadgeProps;

const SmallBadge = ({ children, ...props }: Props) => {
  return (
    <StyledBadge
      color='secondary'
      max={9}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    >
      {children}
    </StyledBadge>
  );
};

export default SmallBadge;

import React, { PropsWithChildren } from 'react';
import { styled, ThemeOptions } from '@mui/material/styles';

type StyleProps = {
  theme: ThemeOptions;
};

const Container = styled('div')`
  position: absolute;
  top: 1rem;
  left: 0.75rem;
  display: flex;
  flex-direction: column;
  text-shadow: 0.125rem 0.125rem 0.0625rem white;
  color: ${({ theme }: StyleProps) => theme.colors.grey};

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    line-height: 2em;
  }

  .ql-size-huge {
    font-size: 4.5rem;
  }

  .ql-size-large {
    font-size: 3.5rem;
  }

  .ql-size-small {
    font-size: 0.75rem;
  }
`;

export const CTAText: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container>
      <p>{children}</p>
    </Container>
  );
};

export default CTAText;

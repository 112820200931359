import React from 'react';
import { SolverEnrollmentsUpdate } from 'generated/graphql';
import { getDateTime } from 'utils/dates';
import { DateTime } from 'luxon';

interface SolverUpdateDetailsProps {
  data: SolverEnrollmentsUpdate;
}

type Props = SolverUpdateDetailsProps;

const SolverUpdateDetails = ({ data }: Props) => {
  return <div>{getDateTime(data.solutionDateTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</div>;
};

export default SolverUpdateDetails;

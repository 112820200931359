import React, { useCallback, useState } from 'react';
import { css, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import HeaderPortal from 'components/organisms/HeaderPortal/HeaderPortal';
import { StudentProfile } from 'generated/graphql';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoTwoTone';
import { useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import parse from 'html-react-parser';
import DialogTitleWithClose from 'components/molecules/DialogTitleWithClose/DialogTitleWithClose';

const Wrapper = styled(Box)<{ $hideInfo?: boolean }>(
  ({ $hideInfo }) => css`
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 ${$hideInfo ? '1rem' : '2.8rem'};
  `
);

const ActiveStudentText = styled(Typography)`
  color: white;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
`;

interface EnrollmentHeaderProps {
  currentStudent?: StudentProfile;
  hideInfo?: boolean;
}

type Props = EnrollmentHeaderProps;

const EnrollmentHeader = ({ currentStudent, hideInfo = false }: Props) => {
  const { schedule } = useEnrollmentContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClickOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const semesterInfo = schedule?.semesterInformation;

  return (
    <HeaderPortal>
      <Wrapper $hideInfo={hideInfo || !semesterInfo}>
        {currentStudent?.user && <ActiveStudentText>{currentStudent.user.firstName}</ActiveStudentText>}
        {!hideInfo && semesterInfo && (
          <>
            <IconButton
              onClick={handleClickOpen}
              size='large'
              aria-label='semester information'
              aria-controls='semester-information-dialog'
              aria-haspopup='true'
              color='inherit'
              edge='end'
              sx={{ position: 'absolute', right: '1rem' }}
            >
              <InfoIcon sx={{ 'path[opacity]': { fill: 'rgba(255, 255, 255)', opacity: 0.7 } }} />
            </IconButton>
            <Dialog
              id='semester-information-dialog'
              fullScreen={fullScreen}
              open={dialogOpen}
              onClose={handleClose}
              aria-labelledby='schedule-information-dialog-title'
            >
              <DialogTitleWithClose id='schedule-information-dialog-title' onClose={handleClose}>
                Semester Information
              </DialogTitleWithClose>
              <DialogContent>
                <DialogContentText>
                  <Typography>{parse(semesterInfo)}</Typography>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </>
        )}
      </Wrapper>
    </HeaderPortal>
  );
};

export default EnrollmentHeader;

import { MutationTuple, useMutation } from '@apollo/client';
import {
  ScheduleEnrollmentDocument,
  ScheduleEnrollmentMutation,
  ScheduleEnrollmentMutationVariables,
} from 'generated/graphql';

function useMutationScheduleEnrollment(): MutationTuple<
  ScheduleEnrollmentMutation,
  ScheduleEnrollmentMutationVariables
> {
  return useMutation<ScheduleEnrollmentMutation, ScheduleEnrollmentMutationVariables>(ScheduleEnrollmentDocument);
}

export default useMutationScheduleEnrollment;

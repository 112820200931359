import React, { useCallback, useEffect } from 'react';
import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useAdminContext } from 'contexts/AdminContext/AdminContext';
import useQueryScheduleSolverStatus from 'hooks/queries/useQueryScheduleSolverStatus/useQueryScheduleSolverStatus';
import useMutationStartScheduleSolver from 'hooks/mutations/useMutationStartScheduleSolver/useMutationStartScheduleSolver';
import useMutationStopScheduleSolver from 'hooks/mutations/useMutationStopScheduleSolver/useMutationStopScheduleSolver';
import { SolverStatus } from 'types';
import Tooltip from '@mui/material/Tooltip';
import { useScheduleSolverContext } from 'contexts/ScheduleSolverContext/ScheduleSolverContext';
import useSubscriptionSolverEnrollments from '../../../hooks/subscriptions/useSubscriptionSolverEnrollments/useSubscriptionSolverEnrollments';
import SolverUpdateDetails from './SolverUpdateDetails/SolverUpdateDetails';

const POLLING_INTERVAL = 30000;

const ScheduleSolverControls = () => {
  const { selectedSchedule } = useAdminContext();
  const { currentSolverId, setCurrentSolverId } = useScheduleSolverContext();
  const [buttonOpen, setButtonOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const { data: scheduleSolverStatusData, loading: solverStatusLoading } = useQueryScheduleSolverStatus(
    selectedSchedule ? { scheduleId: selectedSchedule?.id, solverId: currentSolverId } : undefined,
    { pollInterval: POLLING_INTERVAL }
  );
  const solverStatus = scheduleSolverStatusData?.getScheduleSolverStatus;

  // Subscribe to solver updates to apply updates to apollo cache in bulk.
  const { data: solverUpdatesData } = useSubscriptionSolverEnrollments();

  useEffect(() => {
    if (solverStatus?.id) {
      setCurrentSolverId(solverStatus?.id);
    }
  }, [setCurrentSolverId, solverStatus?.id]);

  const [startSolver] = useMutationStartScheduleSolver();
  const [stopSolver] = useMutationStopScheduleSolver();

  const handleToggle = () => {
    setButtonOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setButtonOpen(false);
  };

  const handleStartClick = useCallback(
    async (event: React.MouseEvent, forceNew?: boolean) => {
      if (!selectedSchedule) {
        return;
      }

      const result = await startSolver({
        variables: {
          input: {
            scheduleId: selectedSchedule.id,
            solverId: forceNew ? undefined : currentSolverId || solverStatus?.id,
            forceNew,
          },
        },
        optimisticResponse: forceNew
          ? undefined
          : {
              startScheduleSolver: {
                success: true,
                solverId: currentSolverId || solverStatus?.id,
                error: null,
                __typename: 'StartScheduleSolverResult',
              },
            },
      });

      const newId = result.data?.startScheduleSolver.solverId;

      if (newId && newId !== currentSolverId) {
        setCurrentSolverId(newId);
      }
    },
    [currentSolverId, selectedSchedule, setCurrentSolverId, solverStatus?.id, startSolver]
  );

  const handleResetAndStartClick = useCallback(
    async (event: React.MouseEvent) => {
      setButtonOpen(false);
      await handleStartClick(event, true);
    },
    [handleStartClick]
  );

  const handleStopClick = useCallback(async () => {
    if (!selectedSchedule) {
      return;
    }

    await stopSolver({
      variables: {
        input: { scheduleId: selectedSchedule.id, solverId: solverStatus?.id },
      },
      optimisticResponse: {
        stopScheduleSolver: {
          success: true,
          error: null,
          __typename: 'BaseStatusResult',
        },
      },
    });
  }, [selectedSchedule, solverStatus?.id, stopSolver]);

  const isSolving = solverStatus?.solverStatus === SolverStatus.SOLVING_ACTIVE;
  const isPending = solverStatus?.solverStatus === SolverStatus.SOLVING_SCHEDULED;

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      {solverStatusLoading && !solverStatus && <LoadingIndicator size='1rem' />}
      <>
        <ButtonGroup
          variant='contained'
          color={isSolving ? 'error' : 'secondary'}
          ref={anchorRef}
          aria-label='split button'
        >
          <Button
            onClick={isSolving ? handleStopClick : handleStartClick}
            disabled={isPending}
            variant='contained'
            size='small'
          >
            {isSolving ? 'Stop solving' : isPending ? 'Pending...' : 'Start Solver'}
          </Button>
          <Button
            size='small'
            aria-controls={buttonOpen ? 'split-button-menu' : undefined}
            aria-expanded={buttonOpen ? 'true' : undefined}
            aria-label='select merge strategy'
            aria-haspopup='menu'
            disabled={isPending}
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={buttonOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id='split-button-menu' autoFocusItem dense>
                    <MenuItem onClick={handleResetAndStartClick} disabled={isSolving} dense>
                      Reset and Start Solver
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
      {solverStatus && (
        <Tooltip title={`Solution ID: ${currentSolverId}`} arrow>
          <Typography variant='body1'>
            <span>AI Score: {solverStatus.score}</span>
          </Typography>
        </Tooltip>
      )}
      {solverUpdatesData?.solverEnrollmentsUpdated && (
        <SolverUpdateDetails data={solverUpdatesData.solverEnrollmentsUpdated} />
      )}
    </Stack>
  );
};

export default ScheduleSolverControls;

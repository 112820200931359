import React, { createContext, useContext, useMemo, useState } from 'react';
import { SolverEnrollmentSolution, SolverSolutionResults } from 'generated/graphql';
import useQueryScheduleSolverSolutionResults from 'hooks/queries/useQueryScheduleSolverSolutionResults/useQueryScheduleSolverSolutionResults';
import { useAdminContext } from 'contexts/AdminContext/AdminContext';

type ScheduleSolverContextState = {
  loading: boolean;
  error?: Error;
  solutionResults?: SolverSolutionResults;
  enrollmentSolutionMap?: Record<number, SolverEnrollmentSolution>;
  currentSolverId?: string;
  setCurrentSolverId: (id?: string) => void;
};

const ScheduleSolverContext = createContext<ScheduleSolverContextState | undefined>(undefined);

interface ScheduleSolverContextProviderProps {
  children: React.ReactNode;
}

type Props = ScheduleSolverContextProviderProps;

const ScheduleSolverContextProvider = ({ children }: Props) => {
  const { selectedSchedule } = useAdminContext();
  const [currentSolverId, setCurrentSolverId] = useState<string | undefined>();

  const { data, loading, error } = useQueryScheduleSolverSolutionResults(
    selectedSchedule && currentSolverId ? { scheduleId: selectedSchedule?.id, solverId: currentSolverId } : undefined,
    {
      skip: !selectedSchedule || !currentSolverId,
    }
  );

  const enrollmentSolutions = data?.getScheduleSolverStatus?.solutionResults?.enrollmentSolutions;

  const enrollmentSolutionMap = useMemo<Record<number, SolverEnrollmentSolution> | undefined>(() => {
    if (!enrollmentSolutions) {
      return;
    }

    return enrollmentSolutions.reduce<Record<number, SolverEnrollmentSolution>>((all, solution) => {
      all[solution.enrollmentId] = solution;
      return all;
    }, {});
  }, [enrollmentSolutions]);

  // Memoize the state value to improve context performance.
  const stateValue = useMemo<ScheduleSolverContextState>(
    () => ({
      loading,
      error,
      solutionResults: data?.getScheduleSolverStatus?.solutionResults || undefined,
      enrollmentSolutionMap,
      currentSolverId,
      setCurrentSolverId,
    }),
    [currentSolverId, data?.getScheduleSolverStatus?.solutionResults, enrollmentSolutionMap, error, loading]
  );

  return <ScheduleSolverContext.Provider value={stateValue}>{children}</ScheduleSolverContext.Provider>;
};

const useScheduleSolverContext = () => {
  const context = useContext(ScheduleSolverContext);

  if (context === undefined) {
    throw new Error('useScheduleSolverContext was used outside of its Provider');
  }

  return context;
};

export { ScheduleSolverContext, ScheduleSolverContextProvider, useScheduleSolverContext };

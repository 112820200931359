import React from 'react';
import { styled, css } from '@mui/material/styles';
import { switchProp } from 'styled-tools';
import imgLogo from './logo.png';
import imgLogoLarge from './lyriq-logo-large.png';
import imgLogoBlack from './lyriq-logo-black.png';

const LogoWrapper = styled('div')`
  ${switchProp('$size', {
    normal: css`
      height: 2.5rem;
    `,
    large: css`
      width: 12rem;
      max-width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    `,
    black: css`
      height: 2.5rem;
    `,
  })}
`;

const logoSizes = {
  normal: imgLogo,
  large: imgLogoLarge,
  black: imgLogoBlack,
};

interface LogoProps {
  size?: 'normal' | 'large' | 'black';
}

type Props = LogoProps;

const Logo = ({ size = 'normal' }: Props) => {
  if (!logoSizes[size]) {
    throw new Error(`Unsupported size in Logo component: ${size}`);
  }

  return (
    <LogoWrapper $size={size}>
      <img src={logoSizes[size]} alt='Lyriq Music School' height='40' />
    </LogoWrapper>
  );
};

export default Logo;

import React from 'react';
import { styled } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import EventNoteIcon from '@mui/icons-material/EventNote';
import SettingsIcon from '@mui/icons-material/Settings';
import Paper from '@mui/material/Paper';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext/UserContext';

const NavBar = styled(BottomNavigation)`
  background-color: #221f20;
`;

const NavLink = styled(BottomNavigationAction)`
  color: white;

  svg {
    width: 1.55em;
    height: 1.55em;
    padding: 0.25rem;
  }

  &&&.Mui-selected {
    color: black;

    svg {
      background-color: white;
      border-radius: 2rem;
    }
  }
`;

const BottomNav = () => {
  const location = useLocation();
  const {
    postLogin: { loading },
    isOnboardingLimited,
  } = useUserContext();

  if (loading || isOnboardingLimited) {
    return null;
  }

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <NavBar showLabels value={location.pathname}>
        <NavLink component={Link} to='/' value='/' icon={<HomeIcon />} />
        <NavLink component={Link} to='/students' value='/students' icon={<EventNoteIcon />} />
        <NavLink component={Link} to='/account' value='/account' icon={<SettingsIcon />} />
      </NavBar>
    </Paper>
  );
};

export default BottomNav;

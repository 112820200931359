import React, { useMemo } from 'react';
import { DiscountLineItem, LineItemWithAppliedDiscounts } from 'generated/graphql';
import LineItem from '../LineItem/LineItem';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const INSET_SPACER = 5;

const discountTypographyProps: TypographyProps = {
  sx: {
    ml: INSET_SPACER,
  },
};

interface BillingLineItemProps {
  lineItem: LineItemWithAppliedDiscounts;
  allDiscounts?: DiscountLineItem[];
  helpText?: React.ReactNode;
  divider?: boolean;
}

type Props = BillingLineItemProps;

const BillingLineItem = ({ lineItem, allDiscounts, helpText, divider = false }: Props) => {
  const discounts = useMemo<DiscountLineItem[]>(
    () => allDiscounts?.filter((x) => x.uid && (lineItem.appliedDiscounts || []).includes(x.uid)) || [],
    [allDiscounts, lineItem.appliedDiscounts]
  );

  return (
    <>
      <LineItem
        title={lineItem.name}
        subtitle={lineItem.quantity > 1 ? `Total for ${lineItem.quantity} lessons in the semester` : undefined}
        helpText={helpText}
        amountInCents={lineItem.basePricePerItemInCents * lineItem.quantity}
        divider={divider && discounts.length === 0}
        bold
      />
      {discounts.length > 0 && (
        <Box>
          <Divider variant='inset' textAlign='left' sx={{ mb: 2, ml: INSET_SPACER }} light>
            <Typography variant='overline'>Line Item Discounts</Typography>
          </Divider>
          {discounts.map((discount) => (
            <LineItem
              key={discount.uid || discount.name}
              title={discount.name}
              subtitle={discount.description}
              amountInCents={-1 * (discount.appliedAmountInCents || 0) * lineItem.quantity}
              titleTypographyProps={discountTypographyProps}
              subtitleTypographyProps={discountTypographyProps}
              helpTextTypographyProps={discountTypographyProps}
              divider={divider}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default BillingLineItem;

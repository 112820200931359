import { getFirstDayOfWeek } from 'utils/dates';
import { DateTime } from 'luxon';
import { SchedulerView } from 'components/organisms/NewSemesterCalendar/components/SchedulingCalendar/SchedulingCalendar';
import { View } from 'react-big-calendar';

export default function getInitialRange(date: Date, view: SchedulerView | View): Date[] | { start: Date; end: Date } {
  if (view === 'day') {
    return [date];
  }

  if (view === 'week' || view === 'custom_days') {
    const range: Date[] = [];
    let dateCursor = getFirstDayOfWeek(date);
    for (let i = 0; i < 7; i++) {
      range.push(dateCursor);
      dateCursor = new Date(dateCursor);
      dateCursor.setDate(dateCursor.getDate() + 1);
    }

    return range;
  }

  if (view === 'month') {
    const dt = DateTime.fromJSDate(date);
    return {
      start: dt.startOf('month').toJSDate(),
      end: dt.endOf('month').endOf('day').toJSDate(),
    };
  }

  // Default to a month.
  const dt = DateTime.fromJSDate(date);
  return {
    start: dt.startOf('month').toJSDate(),
    end: dt.endOf('month').endOf('day').toJSDate(),
  };
}

export function getStartEndDatesFromRange(range: Date[] | { start: Date; end: Date }): { start: Date; end: Date } {
  if (Array.isArray(range)) {
    const first = range[0];
    const last = range[range.length - 1];

    return { start: first, end: last };
  }

  return range;
}

import { SchedulerBackgroundEvent } from 'types';
import { DateTime, Interval } from 'luxon';
import { SchedulerSlotAvailabilityMap } from 'utils/calendar/types';
import { parseTimeString } from 'utils/dates';
import getMinMax from 'utils/SemesterCalendar/getMinMax';
import { allDaysOfWeek } from 'utils/availability/availabilityUtils';
import { DayOfWeek } from 'generated/graphql';

export const TIMESLOT_STEP = 15;
const defaultMinMax = getMinMax();

export function getTimeslotKey(dayOfWeek: DayOfWeek, time: string) {
  return `${dayOfWeek}__${time}`;
}

export default function getSchedulerSlotAvailability(
  instructorAvailabilityEvents: SchedulerBackgroundEvent[],
  step = TIMESLOT_STEP,
  min = defaultMinMax.min,
  max = defaultMinMax.max
): SchedulerSlotAvailabilityMap {
  const availabilityIntervals = instructorAvailabilityEvents.map((event) => {
    return {
      event,
      interval: Interval.fromDateTimes(parseTimeString(event.startTime), parseTimeString(event.endTime)),
    };
  });

  const mapping: Record<string, { event: SchedulerBackgroundEvent; isFirst: boolean; isLast: boolean }[]> = {};

  for (const dayOfWeek of allDaysOfWeek) {
    let cursor = DateTime.fromJSDate(min);
    const end = DateTime.fromJSDate(max);
    while (cursor <= end) {
      const next = cursor.plus({ minute: step });
      const slotInterval = Interval.fromDateTimes(cursor, next);
      const matching = availabilityIntervals.filter(
        (x) => x.event.dayOfWeek === dayOfWeek && x.interval.overlaps(slotInterval)
      );

      if (matching.length > 0) {
        const start = cursor.toFormat('TT');
        const nextTime = next.toFormat('TT');
        mapping[getTimeslotKey(dayOfWeek, cursor.toFormat('TT'))] = matching.map((x) => ({
          event: x.event,
          isFirst: x.event.startTime >= start,
          isLast: x.event.endTime <= nextTime,
        }));
      }

      cursor = next;
    }
  }

  return mapping;
}

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  if (isAuthenticated || isLoading) {
    return null;
  }

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'stretch', md: 'center' }}
      justifyContent={{ xs: 'flex-start', md: 'center' }}
      spacing={2}
      sx={{ width: '100%' }}
    >
      <Button
        variant='contained'
        color='secondary'
        size='large'
        onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'login' } })}
      >
        Log In
      </Button>
      <Button
        variant='contained'
        color='secondary'
        size='large'
        onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}
      >
        Sign Up
      </Button>
    </Stack>
  );
};

export default LoginButton;

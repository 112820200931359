import React from 'react';
import Container from '@mui/material/Container';
import { useUserContext } from 'contexts/UserContext/UserContext';
import { AccountContextProvider } from 'contexts/AccountContext/AccountContext';
import { OnboardingState } from 'generated/graphql';
import AccountHolderHome from './pages/AccountHolderHome/AccountHolderHome';
import InstructorHome from './pages/InstructorHome/InstructorHome';
import StudentHome from './pages/StudentHome/StudentHome';
import ErrorAlert from 'components/molecules/ErrorAlert/ErrorAlert';

function getHomeComponent(onboardingState: OnboardingState) {
  if (onboardingState.isAccountHolder) {
    return AccountHolderHome;
  }

  if (onboardingState.isInstructor) {
    return InstructorHome;
  }

  if (onboardingState.isStudent) {
    return StudentHome;
  }
}

const HomePage = () => {
  const {
    user,
    error,
    postLogin: { data },
  } = useUserContext();
  const { onboardingState } = data || {};

  if (error) {
    return <ErrorAlert error={error.message} />;
  }

  if (!onboardingState || !user) {
    return null;
  }

  const HomeComponent = getHomeComponent(onboardingState);
  if (!HomeComponent) {
    return <div>Not implemented</div>;
  }
  return (
    <Container sx={{ backgroundColor: '#fff', padding: '0 !important' }}>
      <AccountContextProvider>
        <HomeComponent user={user} onboardingState={onboardingState} />
      </AccountContextProvider>
    </Container>
  );
};

export default HomePage;

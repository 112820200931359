import React from 'react';
import Typography from '@mui/material/Typography';
import EmbeddedAddStudentsForm from 'components/organisms/EmbeddedAddStudentsForm/EmbeddedAddStudentsForm';
import { AccountContextProvider } from 'contexts/AccountContext/AccountContext';

const AddStudents = () => {
  return (
    <AccountContextProvider>
      <EmbeddedAddStudentsForm>
        <Typography>
          <Typography>{`Let's Get Started!`}</Typography>
        </Typography>
      </EmbeddedAddStudentsForm>
    </AccountContextProvider>
  );
};

export default AddStudents;

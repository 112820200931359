import { DateTime } from 'luxon';
import { MinMax } from 'types';
import { AvailabilityItem } from '../../generated/graphql';

const DEFAULT_CALENDAR_MIN = DateTime.fromFormat('08:00:00', 'TT').toJSDate();
const DEFAULT_CALENDAR_MAX = DateTime.fromFormat('22:00:00', 'TT').toJSDate();
const DEFAULT_CALENDAR_MIN_MAX = {
  min: DEFAULT_CALENDAR_MIN,
  max: DEFAULT_CALENDAR_MAX,
};

const WEEKDAY_DEFAULT: Required<MinMax> = {
  min: DateTime.fromFormat('14:00:00', 'TT').toJSDate(),
  max: DateTime.fromFormat('22:00:00', 'TT').toJSDate(),
};

const DEFAULT_MIN_MAX_BY_DAY: Record<number, Required<MinMax>> = {
  // Sunday = 0
  0: {
    min: DateTime.fromFormat('08:00:00', 'TT').toJSDate(),
    max: DateTime.fromFormat('15:00:00', 'TT').toJSDate(),
  },
  1: WEEKDAY_DEFAULT,
  2: WEEKDAY_DEFAULT,
  3: WEEKDAY_DEFAULT,
  4: WEEKDAY_DEFAULT,
  5: WEEKDAY_DEFAULT,
  6: {
    min: DateTime.fromFormat('08:00:00', 'TT').toJSDate(),
    max: DateTime.fromFormat('16:00:00', 'TT').toJSDate(),
  },
};

export default function getMinMax(
  range?: { start: Date; end: Date },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  availabilityItems?: AvailabilityItem[]
): Required<MinMax> {
  // TODO: implement logic using schedule availabilityItems.
  if (!range || !range.start) {
    return DEFAULT_CALENDAR_MIN_MAX;
  }

  // Check if single-day.
  if (!range.end || range.start.toLocaleDateString() === range.end.toLocaleDateString()) {
    return DEFAULT_MIN_MAX_BY_DAY[range.start.getDay()];
  }

  // For now, return default when view is more than 1 day.
  return DEFAULT_CALENDAR_MIN_MAX;
}

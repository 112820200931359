import React from 'react';
import { Skill } from 'generated/graphql';
import { LessonTeacherSelection } from 'contexts/EnrollmentContext/EnrollmentContext';
import { sortDaysOfWeek } from 'utils/format/formatAvailabilityDays';
import theme from 'theme';
import ContentBodyWrapper from 'components/atoms/ContentBodyWrapper/ContentBodyWrapper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { useParams } from 'react-router-dom';

const getWeekDayAbbreviated = (day: string) => {
  switch (day) {
    case 'MONDAY':
      return 'Mon';
    case 'TUESDAY':
      return 'Tue';
    case 'WEDNESDAY':
      return 'Wed';
    case 'THURSDAY':
      return 'Thu';
    case 'FRIDAY':
      return 'Fri';
    case 'SATURDAY':
      return 'Sat';
    case 'SUNDAY':
      return 'Sun';
    default:
      return '';
  }
};

const availabilitiesFormatter = (availabilities: string[] | undefined | null) => {
  if (!availabilities) {
    return 'none of dates';
  }
  availabilities = availabilities.map((av) => getWeekDayAbbreviated(av));

  if (availabilities.length > 1) {
    const lastIndex = availabilities.length - 1;
    availabilities = [...availabilities.slice(0, lastIndex), `and ${availabilities[lastIndex]}`];
  }

  return `on ${availabilities.join(', ')}`;
};

const TeacherSelectionReminderContainer = styled(ContentBodyWrapper)`
  margin: 1rem 0.25rem;
  border: solid ${theme.palette.secondary.light} 0.125rem;
  border-radius: 1rem;
`;

interface TeacherSelectionReminderProps {
  selectedLessons?: Skill[];
  selectedTeachers?: LessonTeacherSelection[];
}

type Props = TeacherSelectionReminderProps;

const TeacherSelectionReminder = ({ selectedLessons, selectedTeachers }: Props) => {
  const { scheduleId } = useParams();

  if (!selectedTeachers || !selectedTeachers.some((x) => !x.noPreference && !!x.teacher)) {
    return null;
  }

  return (
    <TeacherSelectionReminderContainer>
      <Typography variant='subtitle2' fontWeight={600} sx={{ margin: '0.75rem', marginBottom: 0 }}>
        Teacher Selection Reminder:
      </Typography>
      <List>
        {selectedTeachers
          .filter((x) => !x.noPreference && !!x.teacher)
          .map(
            ({
              skillId,
              teacher: {
                id: teacherId,
                scheduleAvailability,
                defaultAvailability,
                user,
                featureImage,
                profileType,
              } = {},
            }) => {
              const userFirstname = user?.firstName;
              const { skillName } = selectedLessons?.find(({ id }) => id === skillId) || {};
              const availability =
                scheduleAvailability?.find(
                  ({ scheduleId: _scheduleId }) => _scheduleId && _scheduleId === Number(scheduleId)
                ) ?? defaultAvailability;
              const availabilityItems = availability?.availabilityItems;
              return (
                <ListItem key={`timeframe-selection-teacher-${teacherId}-${skillId}`} sx={{ padding: '0.2rem 1rem' }}>
                  <ListItemAvatar>
                    <Avatar
                      variant='rounded'
                      alt={`${profileType}-${userFirstname}`}
                      src={featureImage || ''}
                      sx={{ width: 48, height: 48 }}
                    />
                  </ListItemAvatar>
                  <ListItemText>
                    {`${userFirstname} (${skillName}) is available`}{' '}
                    {availabilityItems?.length && (
                      <span style={{ fontWeight: 'bold' }}>
                        {` ${availabilitiesFormatter(
                          sortDaysOfWeek(availabilityItems.map(({ dayOfWeek }) => dayOfWeek))
                        )}`}
                        .
                      </span>
                    )}
                  </ListItemText>
                </ListItem>
              );
            }
          )}
      </List>
    </TeacherSelectionReminderContainer>
  );
};

export default TeacherSelectionReminder;

import React from 'react';
import { ResourceHeaderProps } from 'react-big-calendar';
import { ScheduleResource } from 'components/organisms/NewSemesterCalendar/NewSemesterCalendar';
import Box from '@mui/material/Box';
import colorMapper, { ColorMapper } from 'utils/SemesterCalendar/colorMapper';

type Props = ResourceHeaderProps<ScheduleResource>;

const ResourceHeader = ({ label, resource }: Props) => {
  const colorId = `instructor_${resource.id}`;
  const resourceColor = resource.color || colorMapper.pickColor(colorId);

  return <Box sx={{ backgroundColor: resourceColor, color: ColorMapper.getContrastYIQ(resourceColor) }}>{label}</Box>;
};

export default React.memo(ResourceHeader);

import React, { createContext, Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { DayOfWeek, Schedule } from 'generated/graphql';
import { EnrollmentFromQuery, NoPreferenceType, QueriedInstructorProfile } from 'types';
import useLocalStorageState from 'hooks/useLocalStorageState/useLocalStorageState';
import { SchedulerView } from 'components/organisms/NewSemesterCalendar/components/SchedulingCalendar/SchedulingCalendar';

type AdminContextState = {
  loading: boolean;
  error?: Error;
  selectedSchedule?: Schedule;
  setSelectedSchedule: (schedule?: Schedule) => void;
  selectedInstructors?: (QueriedInstructorProfile | NoPreferenceType)[];
  setSelectedInstructors: (instructors: (QueriedInstructorProfile | NoPreferenceType)[]) => void;
  currentView: SchedulerView;
  setCurrentView: Dispatch<SetStateAction<SchedulerView>>;
  daysOfWeek?: DayOfWeek[];
  setDaysOfWeek: Dispatch<SetStateAction<DayOfWeek[]>>;
  selectedEnrollment?: EnrollmentFromQuery;
  setSelectedEnrollment: Dispatch<SetStateAction<EnrollmentFromQuery | undefined>>;
};

const AdminContext = createContext<AdminContextState | undefined>(undefined);

interface AdminContextProviderProps {
  children: React.ReactNode;
}

type Props = AdminContextProviderProps;

const AdminContextProvider = ({ children }: Props) => {
  const [selectedSchedule, setSelectedSchedule] = useLocalStorageState<Schedule | undefined>(
    'admin.schedule',
    undefined
  );
  const [selectedInstructors, setSelectedInstructors] = useLocalStorageState<
    (QueriedInstructorProfile | NoPreferenceType)[]
  >('admin.selectedInstructors', []);
  const [currentView, setCurrentView] = useLocalStorageState<SchedulerView>('admin.currentView', 'week');
  const [daysOfWeek, setDaysOfWeek] = useLocalStorageState<DayOfWeek[]>('admin.daysOfWeek', [DayOfWeek.Monday]);
  const [selectedEnrollment, setSelectedEnrollment] = useState<EnrollmentFromQuery | undefined>();

  // Memoize the state value to improve context performance.
  const stateValue = useMemo<AdminContextState>(
    () => ({
      selectedSchedule,
      setSelectedSchedule,
      selectedInstructors,
      setSelectedInstructors,
      currentView,
      setCurrentView,
      daysOfWeek,
      setDaysOfWeek,
      selectedEnrollment,
      setSelectedEnrollment,
      loading: false,
      error: undefined,
    }),
    [
      currentView,
      daysOfWeek,
      selectedEnrollment,
      selectedInstructors,
      selectedSchedule,
      setCurrentView,
      setDaysOfWeek,
      setSelectedInstructors,
      setSelectedSchedule,
    ]
  );

  return <AdminContext.Provider value={stateValue}>{children}</AdminContext.Provider>;
};

const useAdminContext = () => {
  const context = useContext(AdminContext);

  if (context === undefined) {
    throw new Error('useAdminContext was used outside of its Provider');
  }

  return context;
};

export { AdminContext, AdminContextProvider, useAdminContext };

import { MutationTuple, useMutation } from '@apollo/client';
import {
  UpdateAppointmentDocument,
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables,
} from 'generated/graphql';

function useMutationUpdateAppointment(): MutationTuple<UpdateAppointmentMutation, UpdateAppointmentMutationVariables> {
  return useMutation<UpdateAppointmentMutation, UpdateAppointmentMutationVariables>(UpdateAppointmentDocument);
}

export default useMutationUpdateAppointment;

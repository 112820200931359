import React from 'react';
// @ts-ignore
import BaseTimeRangeSlider from 'react-time-range-slider';
import theme from 'theme';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';

export type TimeRange = {
  start: string;
  end: string;
};

interface TimeRangeSliderFieldProps {
  disabled?: boolean;
  draggableTrack?: boolean;
  minValue?: string;
  maxValue?: string;
  format?: 12 | 24;
  step?: number;
  value?: TimeRange;
  name?: string;
  onChange?: (time: TimeRange) => void;
  onChangeComplete?: () => void;
  labelProps?: TypographyProps;
}

type Props = TimeRangeSliderFieldProps;

const TimeRangeSliderField = ({ value, labelProps, ...props }: Props) => {
  return (
    <Box px={2}>
      <Typography variant='h3' color={theme.colors.rackley} textAlign='center' {...labelProps}>
        {value?.start} - {value?.end}
      </Typography>
      <BaseTimeRangeSlider
        disabled={false}
        draggableTrack={false}
        format={12}
        name={'time_range'}
        step={15}
        value={value}
        {...props}
      />
    </Box>
  );
};

export default TimeRangeSliderField;

import { gql, MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  EnrollmentStatus,
  UpdateEnrollmentStatusDocument,
  UpdateEnrollmentStatusMutation,
  UpdateEnrollmentStatusMutationVariables,
} from 'generated/graphql';
import { useMemo } from 'react';

type Data = UpdateEnrollmentStatusMutation;
type Variables = UpdateEnrollmentStatusMutationVariables;

function useMutationUpdateEnrollmentStatus(
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      update(cache, result, options) {
        const { scheduleLocked, status, id } = options.variables?.input || {};

        cache.writeFragment<{ schedulingStatus?: EnrollmentStatus; scheduleLocked?: boolean }>({
          id: `Enrollment:${id}`,
          fragment: gql`
            fragment EnrollmentWithStatus on Enrollment {
              schedulingStatus
              scheduleLocked
            }
          `,
          data: {
            scheduleLocked: scheduleLocked != null ? scheduleLocked : undefined,
            schedulingStatus: status != null ? status : undefined,
          },
        });
      },
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(UpdateEnrollmentStatusDocument, opts);
}

export default useMutationUpdateEnrollmentStatus;

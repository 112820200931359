import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  PaymentType,
  Schedule,
  SchedulingPreference,
  SchedulingPriority,
  SiblingSchedulePreference,
  Skill,
  StudentProfile,
  User,
} from 'generated/graphql';
import { InstructorProfileData } from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import { PackageData } from 'hooks/queries/useQueryGetPackages/useQueryGetPackages';
import { SkillSignupDetails } from 'utils/signupDetails';
import useLocalStorageState from 'hooks/useLocalStorageState/useLocalStorageState';
import { useParams } from 'react-router-dom';
import useQueryGetSchedule from 'hooks/queries/useQueryGetSchedule/useQueryGetSchedule';
import ErrorAlert from 'components/molecules/ErrorAlert/ErrorAlert';
import { v4 as uuidv4 } from 'uuid';
import { useUserContext } from 'contexts/UserContext/UserContext';

const LOCAL_STORAGE_STATE_TTL_MS = 3600000; // 1 hour in milliseconds

function studentEnrollmentUpdater(studentId: number, data: Partial<StudentEnrollment>) {
  return (prevState: StudentEnrollment[]) =>
    prevState.map((enrollment) => {
      if (enrollment.student.id !== studentId) {
        return enrollment;
      }

      return {
        ...enrollment,
        ...data,
      };
    });
}

function getInitialPhoneNumber(user?: User): string {
  if (!user?.phoneInfos?.length) {
    return '';
  }

  const phoneInfo = user.phoneInfos.find((x) => x.phonePurpose === 'Primary') ?? user.phoneInfos[0];

  return phoneInfo.number || '';
}

export interface LessonTeacherSelection {
  skillId: number;
  teacher?: InstructorProfileData;
  noPreference?: boolean;
}

export enum DateEnums {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday',
}

export type AvailabilityProps = {
  id: string;
  start: {
    time?: Date;
    editable: boolean;
  };
  end: {
    time?: Date;
    editable: boolean;
  };
};

export type Dates = {
  [value in DateEnums]?: AvailabilityProps[];
};

export interface StudentEnrollment {
  student: StudentProfile;
  selectedLessons: Skill[];
  selectedClasses: PackageData[];
  selectedTeachers: LessonTeacherSelection[];
  selectedAvailabilities: Dates;
  selectedLessonsSignupDetails: SkillSignupDetails[];
  schedulingConsideration?: SchedulingPreference;
}

type EnrollmentContextState = {
  selectedStudents: StudentProfile[] | null | undefined;
  setSelectedStudents: Dispatch<SetStateAction<StudentProfile[]>>;
  schedule?: Schedule;
  setSchedule: Dispatch<SetStateAction<Schedule | undefined>>;
  studentEnrollments: StudentEnrollment[];
  selectTeacher: (studentId: number, skillId: number, teacher?: InstructorProfileData, noPreference?: boolean) => void;
  selectLesson: (studentId: number, skill: Skill) => void;
  selectClass: (studentId: number, lessonPackage: PackageData) => void;
  selectAvailability: (studentId: number, date: DateEnums, availability: AvailabilityProps, id?: string) => void;
  removeAvailability: (studentId: number, date: DateEnums, id: string) => void;
  selectLessonSignupDetails: (studentId: number, skillSignupDetails: SkillSignupDetails) => void;
  siblingConsideration: SiblingSchedulePreference;
  setSiblingConsideration: Dispatch<SetStateAction<SiblingSchedulePreference>>;
  schedulingPriority: SchedulingPriority;
  setSchedulingPriority: Dispatch<SetStateAction<SchedulingPriority>>;
  comments: string;
  setComments: Dispatch<SetStateAction<string>>;
  paymentPreference: PaymentType;
  setPaymentPreference: Dispatch<SetStateAction<PaymentType>>;
  setStudentSchedulingConsideration: (studentId: number, value: SchedulingPreference) => void;
  phoneNumber?: string;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  resetState: () => void;
};

const EnrollmentContext = createContext<EnrollmentContextState | undefined>(undefined);

interface EnrollmentContextProviderProps {
  children: React.ReactNode;
}

type Props = EnrollmentContextProviderProps;

const EnrollmentContextProvider = ({ children }: Props) => {
  const { user } = useUserContext();
  const [schedule, setSchedule] = useState<Schedule>();
  const [selectedStudents, setSelectedStudents, clearSelectedStudents] = useLocalStorageState<StudentProfile[]>(
    'selectedStudents',
    [],
    LOCAL_STORAGE_STATE_TTL_MS
  );
  const [studentEnrollments, setStudentEnrollments, clearStudentEnrollments] = useLocalStorageState<
    StudentEnrollment[]
  >('studentEnrollments', [], LOCAL_STORAGE_STATE_TTL_MS);
  const [siblingConsideration, setSiblingConsideration, clearSiblingConsideration] =
    useLocalStorageState<SiblingSchedulePreference>(
      'siblingConsideration',
      SiblingSchedulePreference.NoPreference,
      LOCAL_STORAGE_STATE_TTL_MS
    );
  const [schedulingPriority, setSchedulingPriority, clearSchedulingPriority] = useLocalStorageState<SchedulingPriority>(
    'schedulingPriority',
    SchedulingPriority.NoPreference,
    LOCAL_STORAGE_STATE_TTL_MS
  );
  const [comments, setComments, clearComments] = useLocalStorageState<string>(
    'comments',
    '',
    LOCAL_STORAGE_STATE_TTL_MS
  );
  const [phoneNumber, setPhoneNumber, clearPhoneNumber] = useLocalStorageState<string>(
    'phoneNumber',
    getInitialPhoneNumber(user),
    LOCAL_STORAGE_STATE_TTL_MS
  );
  const [paymentPreference, setPaymentPreference, clearPaymentPreference] = useLocalStorageState<PaymentType>(
    'paymentPreference',
    PaymentType.PayInFull,
    LOCAL_STORAGE_STATE_TTL_MS
  );

  // Load the schedule from the URL.
  const { scheduleId: id } = useParams<{ scheduleId: string }>();
  const scheduleId = id ? parseInt(id, 10) : undefined;
  const { data: scheduleResult } = useQueryGetSchedule({
    skip: !scheduleId,
    variables: scheduleId ? { scheduleId } : undefined,
  });
  const scheduleData = scheduleResult?.getSchedule?.schedule;

  useEffect(() => {
    if (scheduleData) {
      setSchedule(scheduleData);
    }
  }, [scheduleData, setSchedule]);

  // Keep studentEnrollments in sync with selectedStudents.
  useEffect(() => {
    const enrollmentsToAdd = selectedStudents.filter(
      (student) => !studentEnrollments.find((x) => x.student.id === student.id)
    );
    const studentIdsToRemove = studentEnrollments
      .filter((enrollment) => !selectedStudents.find((x) => x.id === enrollment.student.id))
      .map((x) => x.student.id);

    if (enrollmentsToAdd.length || studentIdsToRemove.length) {
      setStudentEnrollments((prev) => [
        ...prev.filter((x) => !studentIdsToRemove.includes(x.student.id)),
        ...enrollmentsToAdd.map((student) => ({
          student,
          selectedLessons: [],
          selectedClasses: [],
          selectedTeachers: [],
          selectedAvailabilities: {},
          selectedLessonsSignupDetails: [],
        })),
      ]);
    }
  }, [selectedStudents, setStudentEnrollments, studentEnrollments]);

  const resetState = useCallback(() => {
    clearSelectedStudents();
    clearStudentEnrollments();
    clearSiblingConsideration();
    clearSchedulingPriority();
    clearComments();
    clearPaymentPreference();
    clearPhoneNumber();
  }, [
    clearComments,
    clearPaymentPreference,
    clearSchedulingPriority,
    clearSelectedStudents,
    clearSiblingConsideration,
    clearStudentEnrollments,
    clearPhoneNumber,
  ]);

  const selectTeacher = useCallback(
    (studentId: number, skillId: number, teacher?: InstructorProfileData, noPreference = false) => {
      const enrollment = studentEnrollments.find((x) => x.student.id === studentId);
      if (!enrollment) {
        return;
      }

      const wasSelected = !!enrollment.selectedTeachers.find(
        (x) => x.skillId === skillId && ((!teacher && x.noPreference && noPreference) || x.teacher?.id === teacher?.id)
      );

      // If teacher was previously selected, deselect them.
      if (wasSelected) {
        setStudentEnrollments(
          studentEnrollmentUpdater(studentId, {
            selectedTeachers: enrollment.selectedTeachers.filter(
              (x) => x.skillId !== skillId || x.teacher?.id !== teacher?.id || x.noPreference !== noPreference
            ),
          })
        );
      } else {
        // Otherwise add selection for the teacher.
        setStudentEnrollments(
          studentEnrollmentUpdater(studentId, {
            selectedTeachers: [
              // Filter selected teachers to remove a teacher selected for this skill, if there is one.
              ...enrollment.selectedTeachers.filter((x) => x.skillId !== skillId),
              { skillId, teacher, noPreference },
            ],
          })
        );
      }
    },
    [setStudentEnrollments, studentEnrollments]
  );

  const selectLesson = useCallback(
    (studentId: number, skill: Skill) => {
      const enrollment = studentEnrollments.find((x) => x.student.id === studentId);
      if (!enrollment) {
        return;
      }

      const { selectedLessons } = enrollment;
      if (selectedLessons?.some((s) => s.id === skill.id)) {
        setStudentEnrollments(
          studentEnrollmentUpdater(studentId, {
            selectedLessons: selectedLessons.filter((s) => s.id !== skill.id),
          })
        );
      } else {
        setStudentEnrollments(
          studentEnrollmentUpdater(studentId, {
            selectedLessons: [...(selectedLessons ?? []), skill],
          })
        );
      }
    },
    [setStudentEnrollments, studentEnrollments]
  );

  const setStudentSchedulingConsideration = useCallback(
    (studentId: number, value: SchedulingPreference) => {
      const enrollment = studentEnrollments.find((x) => x.student.id === studentId);
      if (!enrollment) {
        return;
      }

      const { schedulingConsideration } = enrollment;
      if (value !== schedulingConsideration) {
        setStudentEnrollments(
          studentEnrollmentUpdater(studentId, {
            schedulingConsideration: value,
          })
        );
      }
    },
    [setStudentEnrollments, studentEnrollments]
  );

  const selectLessonSignupDetails = useCallback(
    (studentId: number, skillSignupDetails: SkillSignupDetails) => {
      const enrollment = studentEnrollments.find((x) => x.student.id === studentId);
      if (!enrollment) {
        return;
      }

      const { selectedLessonsSignupDetails } = enrollment;
      const filteredSelectedLessonsDetails = selectedLessonsSignupDetails?.filter(
        (s) => s.id !== skillSignupDetails.id
      );
      setStudentEnrollments(
        studentEnrollmentUpdater(studentId, {
          selectedLessonsSignupDetails: [...(filteredSelectedLessonsDetails ?? []), skillSignupDetails],
        })
      );
    },
    [setStudentEnrollments, studentEnrollments]
  );

  const selectClass = useCallback(
    (studentId: number, lessonPackage: PackageData) => {
      const enrollment = studentEnrollments.find((x) => x.student.id === studentId);
      if (!enrollment) {
        return;
      }

      const { selectedClasses } = enrollment;
      if (selectedClasses?.some((s) => s.id === lessonPackage.id)) {
        setStudentEnrollments(
          studentEnrollmentUpdater(studentId, {
            selectedClasses: selectedClasses.filter((s) => s.id !== lessonPackage.id),
          })
        );
      } else {
        setStudentEnrollments(
          studentEnrollmentUpdater(studentId, {
            selectedClasses: [...(selectedClasses ?? []), lessonPackage],
          })
        );
      }
    },
    [setStudentEnrollments, studentEnrollments]
  );

  const selectAvailability = useCallback(
    (studentId: number, date: DateEnums, availability: AvailabilityProps, id?: string) => {
      const enrollment = studentEnrollments.find((x) => x.student.id === studentId);
      if (!enrollment) {
        return;
      }

      const { selectedAvailabilities = {} } = enrollment;
      const availabilities = selectedAvailabilities[date];

      if (availabilities) {
        if (id !== undefined) {
          setStudentEnrollments(
            studentEnrollmentUpdater(studentId, {
              selectedAvailabilities: {
                ...selectedAvailabilities,
                [date]: availabilities.map((timeslots) => (timeslots.id === id ? availability : timeslots)),
              },
            })
          );
        } else {
          setStudentEnrollments(
            studentEnrollmentUpdater(studentId, {
              selectedAvailabilities: {
                ...selectedAvailabilities,
                [date]: [...availabilities, { ...availability, id: availability.id || uuidv4() }],
              },
            })
          );
        }
      } else {
        setStudentEnrollments(
          studentEnrollmentUpdater(studentId, {
            selectedAvailabilities: {
              ...selectedAvailabilities,
              [date]: [{ ...availability, id: availability.id || uuidv4() }],
            },
          })
        );
      }
    },
    [setStudentEnrollments, studentEnrollments]
  );

  const removeAvailability = useCallback(
    (studentId: number, date: DateEnums, id: string) => {
      const enrollment = studentEnrollments.find((x) => x.student.id === studentId);
      if (!enrollment) {
        return;
      }

      const { selectedAvailabilities = {} } = enrollment;
      const availabilities = selectedAvailabilities[date];

      if (availabilities) {
        setStudentEnrollments(
          studentEnrollmentUpdater(studentId, {
            selectedAvailabilities: {
              ...selectedAvailabilities,
              [date]: availabilities.filter((timeslots) => timeslots.id !== id),
            },
          })
        );
      }
    },
    [setStudentEnrollments, studentEnrollments]
  );

  const stateValue = useMemo<EnrollmentContextState>(
    () => ({
      selectedStudents,
      setSelectedStudents,
      schedule,
      setSchedule,
      studentEnrollments,
      selectTeacher,
      selectLesson,
      selectClass,
      selectAvailability,
      removeAvailability,
      selectLessonSignupDetails,
      siblingConsideration,
      setSiblingConsideration,
      schedulingPriority,
      setSchedulingPriority,
      comments,
      setComments,
      paymentPreference,
      setPaymentPreference,
      setStudentSchedulingConsideration,
      phoneNumber,
      setPhoneNumber,
      resetState,
    }),
    [
      selectedStudents,
      setSelectedStudents,
      schedule,
      studentEnrollments,
      selectTeacher,
      selectLesson,
      selectClass,
      selectAvailability,
      removeAvailability,
      selectLessonSignupDetails,
      siblingConsideration,
      setSiblingConsideration,
      schedulingPriority,
      setSchedulingPriority,
      comments,
      setComments,
      paymentPreference,
      setPaymentPreference,
      setStudentSchedulingConsideration,
      phoneNumber,
      setPhoneNumber,
      resetState,
    ]
  );

  if (id && !scheduleId) {
    return <ErrorAlert error='Schedule ID is missing from url, page not found' />;
  }

  return <EnrollmentContext.Provider value={stateValue}>{children}</EnrollmentContext.Provider>;
};

const useEnrollmentContext = () => {
  const context = useContext(EnrollmentContext);

  if (context === undefined) {
    throw new Error('useEnrollmentContext was used outside of its Provider.');
  }

  return context;
};

export { EnrollmentContext, EnrollmentContextProvider, useEnrollmentContext };

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { EnrollmentPricingDocument, EnrollmentPricingQuery, EnrollmentPricingQueryVariables } from 'generated/graphql';

type Data = EnrollmentPricingQuery;
type Variables = EnrollmentPricingQueryVariables;

function useQueryEnrollmentPricing(options?: QueryHookOptions<Data, Variables>): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(EnrollmentPricingDocument, {
    ...options,
  });
}

export default useQueryEnrollmentPricing;

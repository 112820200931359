const SEQUENCE_1 = [0x8ae234, 0xfce94f, 0x729fcf, 0xe9b96e, 0xad7fa8];
const SEQUENCE_2 = [0x73d216, 0xedd400, 0x3465a4, 0xc17d11, 0x75507b];

export class ColorMapper {
  colorMap = new Map<string, string>();
  nextColorCount = 0;

  public pickColor(object: string) {
    let color = this.colorMap.get(object);
    if (color !== undefined) {
      return color;
    }
    color = this.nextColor();
    this.colorMap.set(object, color);
    return color;
  }

  private nextColor() {
    let color;
    const colorIndex = this.nextColorCount % SEQUENCE_1.length;
    let shadeIndex = Math.floor(this.nextColorCount / SEQUENCE_1.length);
    if (shadeIndex === 0) {
      color = SEQUENCE_1[colorIndex];
    } else if (shadeIndex === 1) {
      color = SEQUENCE_2[colorIndex];
    } else {
      shadeIndex -= 3;
      const floorColor = SEQUENCE_2[colorIndex];
      const ceilColor = SEQUENCE_1[colorIndex];
      let base = Math.floor(shadeIndex / 2 + 1);
      let divisor = 2;
      while (base >= divisor) {
        divisor *= 2;
      }
      base = base * 2 - divisor + 1;
      const shadePercentage = base / divisor;
      color = this.buildPercentageColor(floorColor, ceilColor, shadePercentage);
    }
    this.nextColorCount++;
    return `#${color.toString(16)}`;
  }

  private buildPercentageColor(floorColor: number, ceilColor: number, shadePercentage: number): number {
    const red =
      ((floorColor & 0xff0000) + Math.floor(shadePercentage * ((ceilColor & 0xff0000) - (floorColor & 0xff0000)))) &
      0xff0000;
    const green =
      ((floorColor & 0x00ff00) + Math.floor(shadePercentage * ((ceilColor & 0x00ff00) - (floorColor & 0x00ff00)))) &
      0x00ff00;
    const blue =
      ((floorColor & 0x0000ff) + Math.floor(shadePercentage * ((ceilColor & 0x0000ff) - (floorColor & 0x0000ff)))) &
      0x0000ff;
    return red | green | blue;
  }

  static setOpacity(hex: string, alpha: number) {
    return `${hex}${Math.floor(alpha * 255)
      .toString(16)
      .padStart(2, '0')}`;
  }

  static getContrastYIQ(hexcolor: string) {
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  }

  public static stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
}

const colorMapper = new ColorMapper();

export default colorMapper;

import { DayOfWeek } from 'generated/graphql';
import { RRule } from 'rrule';

export const rruleWeekdayMap = {
  [DayOfWeek.Sunday]: RRule.SU,
  [DayOfWeek.Monday]: RRule.MO,
  [DayOfWeek.Tuesday]: RRule.TU,
  [DayOfWeek.Wednesday]: RRule.WE,
  [DayOfWeek.Thursday]: RRule.TH,
  [DayOfWeek.Friday]: RRule.FR,
  [DayOfWeek.Saturday]: RRule.SA,
};

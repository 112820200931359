import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddStudentForm from 'components/organisms/AddStudentsForm/AddStudentForm';
import ContentWrapper from 'components/atoms/ContentWrapper/ContentWrapper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const AddStudentWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
`;

const AddStudent = () => {
  const navigate = useNavigate();
  const { scheduleId } = useParams();

  const handleCancelClick = () => navigate(`/enroll/${scheduleId}`);
  const handleAddStudentClick = () => navigate(`/enroll/${scheduleId}`);

  return (
    <AddStudentWrapper>
      <Typography variant={'h4'}>Add a new student</Typography>
      <AddStudentForm onComplete={handleAddStudentClick} onCancel={handleCancelClick} />
    </AddStudentWrapper>
  );
};

export default AddStudent;

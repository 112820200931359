import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  ScheduleAvailabilityDocument,
  ScheduleAvailabilityQuery,
  ScheduleAvailabilityQueryVariables,
} from 'generated/graphql';
import useHasAuthRole from '../../useHasAuthRole/useHasAuthRole';
import { AuthRole } from '../../../utils/user/AuthUtils';

function useQueryScheduleAvailability(
  scheduleId?: number,
  options?: QueryHookOptions<ScheduleAvailabilityQuery, ScheduleAvailabilityQueryVariables>
): QueryResult<ScheduleAvailabilityQuery, ScheduleAvailabilityQueryVariables> {
  const hasAuthRole = useHasAuthRole(AuthRole.LyriqAdmin);

  return useQuery<ScheduleAvailabilityQuery, ScheduleAvailabilityQueryVariables>(ScheduleAvailabilityDocument, {
    skip: !hasAuthRole || !scheduleId,
    ...options,
    variables: {
      input: {
        ...(options?.variables?.input || {}),
        scheduleId: scheduleId || 0,
      },
    },
  });
}

export default useQueryScheduleAvailability;

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  EnrollmentSchedulingConsiderationsQuery,
  EnrollmentSchedulingConsiderationsQueryVariables,
  EnrollmentSchedulingConsiderationsDocument,
} from 'generated/graphql';
import { AuthRole } from 'utils/user/AuthUtils';
import useHasAuthRole from 'hooks/useHasAuthRole/useHasAuthRole';

type Data = EnrollmentSchedulingConsiderationsQuery;
type Variables = EnrollmentSchedulingConsiderationsQueryVariables;
type Result = QueryResult<Data, Variables>;

function useQueryEnrollmentSchedulingConsiderations(options?: QueryHookOptions<Data, Variables>): Result {
  const hasAuthRole = useHasAuthRole(AuthRole.LyriqAdmin);

  return useQuery<Data, Variables>(EnrollmentSchedulingConsiderationsDocument, {
    ...options,
    skip: !hasAuthRole || !!options?.skip,
  });
}

export default useQueryEnrollmentSchedulingConsiderations;

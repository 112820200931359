import React from 'react';
import Box from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Currency from 'components/atoms/Currency/Currency';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { SxProps } from '@mui/material';

interface LineItemProps {
  title: React.ReactNode;
  amountInCents: number;
  subtitle?: React.ReactNode;
  helpText?: React.ReactNode;
  bold?: boolean;
  header?: React.ReactNode;
  divider?: boolean;
  sx?: SxProps;
  titleTypographyProps?: TypographyProps;
  amountTypographyProps?: TypographyProps;
  subtitleTypographyProps?: TypographyProps;
  helpTextTypographyProps?: TypographyProps;
  headerTypographyProps?: TypographyProps;
}

type Props = LineItemProps;

const LineItem = ({
  title,
  subtitle,
  amountInCents,
  helpText,
  header,
  titleTypographyProps,
  amountTypographyProps,
  subtitleTypographyProps,
  helpTextTypographyProps,
  headerTypographyProps,
  sx,
  bold = false,
  divider = false,
}: Props) => {
  return (
    <Box sx={sx}>
      {!!header && (
        <Typography variant='overline' {...headerTypographyProps}>
          {header}
        </Typography>
      )}
      <Stack direction='row' justifyContent='space-between' alignItems='top' spacing={2}>
        <Box>
          <Typography fontWeight={bold ? 'bold' : undefined} {...titleTypographyProps}>
            {title}
          </Typography>
          {!!subtitle && (
            <Typography variant='caption' component='span' {...subtitleTypographyProps}>
              {subtitle}
            </Typography>
          )}
          {helpText && (
            <>
              <br />
              <Typography variant='caption' component='span' fontStyle='italic' {...helpTextTypographyProps}>
                {helpText}
              </Typography>
            </>
          )}
        </Box>
        <Typography fontWeight={bold ? 'bold' : undefined} textAlign='right' {...amountTypographyProps}>
          <Currency value={amountInCents} minFraction={2} maxFraction={2} />
        </Typography>
      </Stack>
      {divider ? <Divider sx={{ mt: 2 }} light /> : null}
    </Box>
  );
};

export default LineItem;

import getEventsFromAvailability from './getEventsFromAvailability';
import { EnrollmentBackgroundEvent, EnrollmentFromQuery } from 'types';

export default function getEnrollmentAvailability(startDate: Date, endDate: Date, enrollment: EnrollmentFromQuery) {
  const backgroundEvents: EnrollmentBackgroundEvent[] = [];

  // TODO: handle intersection of exclusion dates.
  if (enrollment.availability?.availabilityItems && enrollment.availability.availabilityItems.length > 0) {
    const availabilityEvents = getEventsFromAvailability(startDate, endDate, enrollment.availability);

    backgroundEvents.push(
      ...availabilityEvents.map((event) => ({
        ...event,
        id: `enrollment_availability__${enrollment.id}__${event.start.toISOString()}`,
        studentId: enrollment.studentProfileId,
        instructorId: enrollment.scheduledInstructorId || enrollment.preferredInstructor?.id,
        title: `${enrollment.studentProfile.user?.firstName} ${enrollment.studentProfile.user?.lastName} Available`,
        skill: enrollment.skill || undefined,
      }))
    );
  }

  return backgroundEvents;
}

import React, { PropsWithChildren } from 'react';
import { Box, styled } from '@mui/material';
import ContentWrapper from 'components/atoms/ContentWrapper/ContentWrapper';
import Navigation, { Props as NavigationProps } from 'components/molecules/Navigation/Navigation';

const EnrollmentPageWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
`;

const Enrollment: React.FC<PropsWithChildren<NavigationProps>> = ({ children, ...props }) => (
  <EnrollmentPageWrapper>
    <Box sx={{ marginBottom: '1.5rem' }}>{children}</Box>
    <Navigation {...props} />
  </EnrollmentPageWrapper>
);

export default Enrollment;

import React, { useCallback, useMemo, useState } from 'react';
import Calendar from 'components/atoms/Calendar/Calendar';
import { ScheduleEvent, ScheduleResource } from 'components/organisms/SemesterCalendar/SemesterCalendar';
import env from 'config/env';
import { BackgroundEvent, MinMax, NoPreferenceType, QueriedInstructorProfile } from 'types';
import getMinMax from 'utils/SemesterCalendar/getMinMax';
import { CalendarProps, Components, SlotPropGetter, View, Views } from 'react-big-calendar';
import { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { ScheduleAvailabilityResult } from 'generated/graphql';
import useQueryInstructorProfiles from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import { DateTime } from 'luxon';
import { NO_PREFERENCE_VALUE } from 'components/organisms/InstructorSelectField/InstructorSelectField';
import getInstructorAvailabilityEvents from 'utils/SemesterCalendar/getInstructorAvailabilityEvents';
import memoize from 'fast-memoize';
import getInitialRange, { getStartEndDatesFromRange } from 'utils/calendar/getInitialRange';
import getSlotAvailability, { TIMESLOT_STEP } from 'utils/calendar/getSlotAvailability';
import slotPropGetterBase from 'utils/calendar/slotPropGetterBase';
import eventPropGetter from 'utils/calendar/eventPropGetter';
import parseEventsFromAppointments from 'utils/SemesterCalendar/parseEventsFromAppointments';
import useQueryAdminAppointments from 'hooks/queries/useQueryAdminAppointments/useQueryAdminAppointments';
import { getDateTime } from 'utils/dates';
import AppointmentEventCard from '../AppointmentEventCard/AppointmentEventCard';

const calendarStyle = { height: `calc(100vh - ${env.sizing.admin.vhOffset})` };
const DEFAULT_VIEW = Views.DAY;
const REFETCH_POLL_INTERVAL = 15000;

interface AppointmentCalendarProps
  extends Partial<CalendarProps<ScheduleEvent, ScheduleResource>>,
    Partial<withDragAndDropProps<ScheduleEvent, ScheduleResource>> {
  availability?: ScheduleAvailabilityResult;
  selectedInstructors?: (QueriedInstructorProfile | NoPreferenceType)[];
  draggedEvent?: ScheduleEvent;
  byResource?: boolean;
}

type Props = AppointmentCalendarProps;

const AppointmentCalendar = ({ availability, selectedInstructors, byResource, ...props }: Props) => {
  const { data: instructorsData } = useQueryInstructorProfiles();
  const instructors = useMemo(() => instructorsData?.instructorProfiles || [], [instructorsData?.instructorProfiles]);
  const defaultDate = useMemo(() => DateTime.fromISO('2023-01-09').toJSDate(), []);

  const [currentDate, setCurrentDate] = useState<Date>(defaultDate);
  const [currentView, setCurrentView] = useState<View>(props.defaultView || DEFAULT_VIEW);
  const [currentRange, setCurrentRange] = useState<Date[] | { start: Date; end: Date } | undefined>(() =>
    getInitialRange(currentDate, currentView)
  );

  const startEndDates = useMemo(
    () => getStartEndDatesFromRange(currentRange || getInitialRange(currentDate, currentView)),
    [currentDate, currentRange, currentView]
  );

  const queryStartDate = getDateTime(startEndDates.start).toISO();
  const queryEndDate = getDateTime(startEndDates.end).toISO();

  const { data: appointmentsData } = useQueryAdminAppointments({
    variables:
      queryStartDate && queryEndDate
        ? {
            input: {
              startDate: queryStartDate,
              endDate: queryEndDate,
            },
          }
        : undefined,
    pollInterval: REFETCH_POLL_INTERVAL,
  });

  const calendarMinMax: MinMax = useMemo(
    () => getMinMax(startEndDates, availability?.scheduleAvailabilityItems),
    [availability?.scheduleAvailabilityItems, startEndDates]
  );

  const appointments = useMemo(() => appointmentsData?.appointments || [], [appointmentsData?.appointments]);

  const instructorResources = useMemo<ScheduleResource[]>(() => {
    return [
      ...instructors
        .filter(
          (instructor) =>
            !selectedInstructors ||
            selectedInstructors.length === 0 ||
            (selectedInstructors.length === 1 && selectedInstructors[0] === NO_PREFERENCE_VALUE) ||
            !!selectedInstructors.find((x) => typeof x !== 'string' && x.profileId === instructor.profileId)
        )
        // Exclude any teachers that don't have appointments assigned to them.
        .filter((instructor) => {
          return appointments.some((appointment) => appointment.instructorProfileId === instructor.profileId);
        })
        .map((instructor) => ({
          id: instructor.profileId,
          title: instructor.user
            ? `${instructor.user.firstName} ${instructor.user.lastName}`
            : instructor.profileId.toString(),
          color: instructor.calendarColor || undefined,
          instructorProfile: instructor,
        })),
    ];
  }, [instructors, selectedInstructors, appointments]);

  // Account for filters when enrollment is selected, or other filters are applied.
  const filters = useMemo<{
    instructorIds?: number[];
  }>(() => {
    return {
      instructorIds:
        selectedInstructors && selectedInstructors.length > 0
          ? selectedInstructors
              .map((x) => (typeof x === 'string' ? undefined : x.profileId))
              .filter((x): x is number => !!x)
          : undefined,
    };
  }, [selectedInstructors]);

  const instructorAvailabilityEvents = useMemo<BackgroundEvent[]>(
    () =>
      availability
        ? getInstructorAvailabilityEvents(
            availability.startDate,
            availability.endDate,
            availability.instructorAvailability,
            filters
          )
        : [],
    [availability, filters]
  );

  const slotAvailabilityMap = useMemo(
    () => (currentRange ? getSlotAvailability(currentRange, instructorAvailabilityEvents) : {}),
    [currentRange, instructorAvailabilityEvents]
  );

  // TODO: Do we want to show these for appointments based on enrollment?
  // const selectedAvailabilityEvents = useMemo<EnrollmentBackgroundEvent[]>(
  //   () =>
  //     availability && selectedEnrollment
  //       ? getEnrollmentAvailability(availability.startDate, availability.endDate, selectedEnrollment)
  //       : [],
  //   [availability, selectedEnrollment]
  // );
  // const backgroundEvents = useMemo<BaseScheduleEvent[]>(
  //   () => [...selectedAvailabilityEvents],
  //   [selectedAvailabilityEvents]
  // );
  // const studentSlotAvailabilityMap = useMemo(
  //   () => (currentRange ? getSlotAvailability(currentRange, selectedAvailabilityEvents) : {}),
  //   [currentRange, selectedAvailabilityEvents]
  // );

  const events = useMemo<ScheduleEvent[]>(
    () => (appointments && availability ? parseEventsFromAppointments(appointments) : []),
    [availability, appointments]
  );

  const slotPropGetter: SlotPropGetter = useMemo(
    () =>
      memoize((date: Date, resourceId: number | string | undefined) => {
        return slotPropGetterBase(slotAvailabilityMap, {}, date, resourceId);
      }),
    [slotAvailabilityMap]
  );

  const handleNavigate = useCallback((newDate: Date, view: View) => {
    setCurrentDate(newDate);
    setCurrentView(view);
  }, []);

  const handleRangeChange = useCallback((range: Date[] | { start: Date; end: Date }, view?: View) => {
    setCurrentRange(range);
    if (view) {
      setCurrentView(view);
    }
  }, []);

  const handleViewChange = useCallback((view: View) => {
    setCurrentView(view);
  }, []);

  const byResourceProps: Partial<CalendarProps<ScheduleEvent, ScheduleResource>> = useMemo(
    () =>
      byResource
        ? {
            resources: instructorResources,
            resourceAccessor: (event) => event.resource,
            resourceIdAccessor: (resource) => resource.id,
            resourceTitleAccessor: (resource) => resource.title,
          }
        : {},
    [byResource, instructorResources]
  );

  const calendarComponents: Components<ScheduleEvent, ScheduleResource> = {
    event: AppointmentEventCard,
  };

  return (
    <>
      <Calendar<ScheduleEvent, ScheduleResource>
        style={calendarStyle}
        onNavigate={handleNavigate}
        onRangeChange={handleRangeChange}
        onView={handleViewChange}
        defaultDate={defaultDate}
        defaultView={DEFAULT_VIEW}
        events={events}
        eventPropGetter={eventPropGetter}
        slotPropGetter={slotPropGetter}
        step={TIMESLOT_STEP}
        timeslots={1}
        min={calendarMinMax.min}
        max={calendarMinMax.max}
        resizable={false}
        dayLayoutAlgorithm='overlap'
        components={calendarComponents}
        {...byResourceProps}
        {...props}
      />
    </>
  );
};

export default AppointmentCalendar;

import { AvailabilityItem, InstructorAvailability } from 'generated/graphql';
import { SchedulerBackgroundEvent } from 'types';
import colorMapper from 'utils/SemesterCalendar/colorMapper';
import { filterAvailabilityItems } from 'utils/availability/availabilityUtils';

export default function getInstructorSchedulerAvailability(
  availability: InstructorAvailability[],
  filters: {
    instructorIds?: number[];
    skillIds?: number[];
    siteIds?: number[];
    locationIds?: number[];
  } = {}
) {
  const backgroundEvents: SchedulerBackgroundEvent[] = [];
  const { instructorIds = [], skillIds = [] } = filters;

  availability.forEach((item) => {
    // Apply filters.
    if (instructorIds.length > 0) {
      if (!instructorIds.includes(item.instructor.id)) {
        return;
      }
    }

    if (skillIds.length > 0) {
      if (!item.instructor.skills?.find((skill) => skillIds.includes(skill.id))) {
        return;
      }
    }

    // TODO: handle intersection of exclusion dates.
    if (item.availability?.availabilityItems && item.availability.availabilityItems.length > 0) {
      const filteredItems = filterAvailabilityItems(item.availability.availabilityItems, filters);

      const instrumentsList = item.instructor.skills?.map((x) => x.skillName).join(', ') || '';

      const colorId = `instructor_${item.instructor.id.toString()}`;

      backgroundEvents.push(
        ...filteredItems.map((event) => {
          const instrumentList = getInstrumentsList(event, instrumentsList);
          return {
            ...event,
            startTime: event.startTime ?? '00:00:00',
            endTime: event.endTime ?? '23:59:59',
            id: `availability__${item.id}__${event.id}`,
            eventType: 'instructorAvailability',
            instructorId: item.instructor.id,
            resource: item.instructor.id,
            title: `${item.instructor.firstName} ${item.instructor.lastName}${
              instrumentList ? ` (${instrumentList})` : ''
            }`,
            color: item.instructor.calendarColor || colorMapper.pickColor(colorId),
            skills: item.instructor.skills || undefined,
          };
        })
      );
    }
  });

  return backgroundEvents;
}

function getInstrumentsList(item: AvailabilityItem, defaultValue?: string) {
  return item.skills?.map((x) => x.skillName).join(', ') || defaultValue;
}

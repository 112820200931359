import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useMutation } from '@apollo/client';
import {
  ProfileType,
  RegisterUserDocument,
  RegisterUserMutation,
  RegisterUserMutationVariables,
} from 'generated/graphql';
import Alert from '@mui/material/Alert';
import useOnboardingStepRedirect from 'hooks/useOnboardingStepRedirect/useOnboardingStepRedirect';
import { useUserContext } from 'contexts/UserContext/UserContext';

const validationSchema = yup.object({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  phoneNumber: yup
    .string()
    .trim()
    .matches(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im, 'Phone number is not valid')
    .required('Phone Number required'),
  profileType: yup.mixed<ProfileType>().oneOf(Object.values(ProfileType)).required('User type is required'),
});

const RegisterUser = () => {
  useOnboardingStepRedirect();
  const {
    postLogin: { data, refetch },
  } = useUserContext();
  const [registerUser, { error }] = useMutation<RegisterUserMutation, RegisterUserMutationVariables>(
    RegisterUserDocument
  );

  const formik = useFormik({
    initialValues: {
      firstName: data?.onboardingState.identityUser?.given_name || '',
      lastName: data?.onboardingState.identityUser?.family_name || '',
      phoneNumber: '',
      profileType: ProfileType.AccountHolder,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const result = await registerUser({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            profileType: values.profileType || ProfileType.AccountHolder,
          },
        },
      });

      if (result.data?.registerUser) {
        await refetch();
      }
    },
  });

  return (
    <div>
      {error && (
        <Alert color='error' sx={{ my: 2 }}>
          {error.message}
        </Alert>
      )}
      <Typography>To finish creating your account, please enter your name.</Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id='firstName'
          name='firstName'
          label='First Name'
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          sx={{ my: 2 }}
          required
        />
        <TextField
          fullWidth
          id='lastName'
          name='lastName'
          label='Last Name'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          sx={{ my: 2 }}
          required
        />
        <TextField
          fullWidth
          id='phoneNumber'
          name='phoneNumber'
          label='Phone Number'
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          sx={{ my: 2 }}
          required
        />
        <Button color='primary' variant='contained' fullWidth type='submit' disabled={formik.isSubmitting}>
          {formik.isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </form>
    </div>
  );
};

export default RegisterUser;

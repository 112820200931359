import { rruleWeekdayMap } from './constants';
import { DateTime } from 'luxon';
import { RRule } from 'rrule';
import { Availability } from 'generated/graphql';

export type AvailabilityEvent = {
  start: Date;
  end: Date;
};

function getEventsFromAvailability(startDate: Date, endDate: Date, availability: Availability): AvailabilityEvent[] {
  // TODO: handle intersection of exclusion dates.

  if (!availability.availabilityItems) {
    return [];
  }

  const events: AvailabilityEvent[] = [];

  availability.availabilityItems.forEach((availabilityItem) => {
    const weekday = rruleWeekdayMap[availabilityItem.dayOfWeek];
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (!weekday) {
      throw new Error(`Invalid dayOfWeek value on availability item: "${availabilityItem.dayOfWeek}"`);
    }

    if (availabilityItem.startTime) {
      const startTime = DateTime.fromFormat(availabilityItem.startTime, 'TT');
      start.setHours(startTime.hour, startTime.minute, startTime.second);
    }

    if (availabilityItem.endTime) {
      const endTime = DateTime.fromFormat(availabilityItem.endTime, 'TT');
      end.setHours(endTime.hour, endTime.minute, endTime.second);
    }

    // For each instance of this day of week within the date range, we'll create an event.
    // We set up a rrule instance to be able to generate all the instances we need to create.
    const rule = new RRule({
      freq: RRule.WEEKLY,
      interval: 1,
      byweekday: [weekday],
      dtstart: start,
      until: end,
    });

    const allInstances = rule.all();
    allInstances.forEach((instance) => {
      const endDateTime = new Date(instance);
      const endTime = DateTime.fromFormat(availabilityItem.endTime ? availabilityItem.endTime : '23:59:59', 'TT');
      endDateTime.setHours(endTime.hour, endTime.minute, endTime.second);

      events.push({
        start: instance,
        end: endDateTime,
      });
    });
  });

  return events;
}

export default getEventsFromAvailability;

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { EnrollmentDocument, EnrollmentQuery, EnrollmentQueryVariables } from 'generated/graphql';

type Data = EnrollmentQuery;
type Variables = EnrollmentQueryVariables;

function useQueryEnrollment(options?: QueryHookOptions<Data, Variables>): QueryResult<Data, Variables> {
  return useQuery<Data, Variables>(EnrollmentDocument, {
    ...options,
  });
}

export default useQueryEnrollment;

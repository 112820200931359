import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

type Props = TypographyProps;

const HelpText = ({ children, ...props }: Props) => {
  return (
    <Typography
      fontFamily='Inter'
      {...props}
      sx={{
        color: (theme) => theme.colors.enrollText,
        ...props.sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default HelpText;

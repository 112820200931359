import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button, { ButtonProps } from '@mui/material/Button';

interface LogoutButtonProps {
  children?: React.ReactNode;
}

type Props = LogoutButtonProps & Partial<ButtonProps>;

const LogoutButton = ({ children, ...props }: Props) => {
  const { logout, isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Button {...props} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
      {children || 'Log Out'}
    </Button>
  );
};

export default LogoutButton;

import { EnrollmentFromQuery, WithRequired } from 'types';
import { DayOfWeek } from 'generated/graphql';
import { weekdayMondayIndexMap } from 'utils/availability/availabilityUtils';
import { DateTime, Interval } from 'luxon';
import { SIZES } from 'components/organisms/NewSemesterCalendar/components/SchedulingCalendar/SchedulingCalendar.styled';

const DEFAULT_CALENDAR_MIN = DateTime.fromFormat('08:00:00', 'TT').toJSDate();
const DEFAULT_CALENDAR_MAX = DateTime.fromFormat('22:00:00', 'TT').toJSDate();

const MIN_CARD_WIDTH = '48.66666667%';

const leftOffsets: Record<number, (position: number) => number | string> = {
  0: () => 0,
  1: () => 0,
  2: (position: number) => `${position * 33}%`,
  3: (position: number) => `${position * 26}%`,
  4: (position: number) => `${position * 17}%`,
  5: (position: number) => `${position}rem`,
  6: (position: number) => `${position * 0.81}rem`,
  7: (position: number) => `${position * 0.69}rem`,
  8: (position: number) => `${position * 0.5}rem`,
  9: (position: number) => `${position * 0.5}rem`,
};

const cardWidths: Record<number, string> = {
  1: '100%',
  2: '66%',
  3: '47%',
};

const fallbackOffsetFunc = (position: number) => `${position * 0.41}rem`;

function getLeftOffset(overlapCount = 0, leftPosition = 0) {
  if (leftPosition === 0) {
    return 0;
  }

  const offsetFunc = leftOffsets[overlapCount] ?? fallbackOffsetFunc;

  return offsetFunc(leftPosition);
}

function getTimeslotsForEvent(startTime: string, endTime: string, timeslots: number, step: number) {
  let current = DateTime.fromFormat(startTime, 'TT');
  const end = DateTime.fromFormat(endTime, 'TT');
  const slots: string[] = [];

  while (current < end) {
    for (let i = 0; i < timeslots; i++) {
      slots.push(current.toFormat('TT'));
      current = current.plus({ minute: step });
    }
  }

  return slots;
}

export function getEventsByDay<TEvent extends EnrollmentFromQuery = EnrollmentFromQuery>(
  currentDays: DayOfWeek[],
  sortedEvents: EnrollmentFromQuery[],
  min: Date,
  max: Date,
  timeslots: number,
  step: number
) {
  const totalMinutes = ((max || DEFAULT_CALENDAR_MAX).getTime() - (min || DEFAULT_CALENDAR_MIN).getTime()) / 1000 / 60;

  return currentDays.reduce<
    Record<
      string,
      {
        enrollment: WithRequired<TEvent, 'startTime' | 'endTime' | 'dayOfWeek'>;
        leftPosition?: number;
        styles?: { top?: string; height?: string; left?: string | number; zIndex?: number };
      }[]
    >
  >((byDay, dayOfWeek) => {
    const dayIndex = weekdayMondayIndexMap[dayOfWeek];
    const filteredEvents = sortedEvents.filter(
      (enrollment): enrollment is WithRequired<TEvent, 'startTime' | 'endTime' | 'dayOfWeek'> => {
        if (enrollment.dayOfWeek == null || !enrollment.startTime || !enrollment.endTime) {
          return false;
        }
        return enrollment.dayOfWeek === dayIndex;
      }
    );

    const offsets: { id: number; startTime: string; endTime: string; offset: number }[] = [];
    const overlapCounts: Record<number, number> = {};
    const timeslotEvents: Record<string, EnrollmentFromQuery[]> = {};
    const eventSlots: Record<number, string[]> = {};

    byDay[dayOfWeek] = filteredEvents.map((event, index) => {
      const { startTime, endTime } = event;
      if (!startTime || !endTime) {
        return { enrollment: event };
      }
      const startDt = DateTime.fromFormat(startTime, 'TT');
      const offset = (startDt.toMillis() - (min || DEFAULT_CALENDAR_MIN).getTime()) / 1000 / 60;
      const percentFromTop = (offset / totalMinutes) * 100;
      const heightPerStep = SIZES.rowHeight / timeslots;
      let minutes = event.package?.appointmentType?.duration;
      if (!minutes) {
        minutes = Interval.fromDateTimes(startDt, DateTime.fromFormat(endTime, 'TT')).length('minutes');
      }
      const durationInSteps = minutes / step;

      const slots = getTimeslotsForEvent(startTime, endTime, timeslots, step);
      eventSlots[event.id] = slots;
      slots.forEach((slot) => {
        if (!timeslotEvents[slot]) {
          timeslotEvents[slot] = [];
        }
        timeslotEvents[slot].push(event);
      });

      const priorOverlapping =
        index > 0
          ? offsets.filter((priorEvent) => {
              return (
                priorEvent.startTime &&
                priorEvent.endTime &&
                priorEvent.startTime < endTime &&
                priorEvent.endTime > startTime
              );
            })
          : [];
      const overlappingOffsets = priorOverlapping.map((x) => x.offset);
      let lastMultipleCount = 0;
      while (overlappingOffsets.includes(lastMultipleCount)) {
        lastMultipleCount++;
      }

      offsets.push({ id: event.id, offset: lastMultipleCount, endTime, startTime });

      // Increment overlap counts on prior overlapping.
      priorOverlapping.forEach(({ id }) => {
        overlapCounts[id] = (overlapCounts[id] ?? 0) + 1;
      });

      overlapCounts[event.id] = priorOverlapping.length;

      return {
        enrollment: event,
        leftPosition: lastMultipleCount,
        styles: {
          top: `${percentFromTop}%`,
          height: `${durationInSteps * heightPerStep}rem`,
          left: lastMultipleCount > 0 ? `${lastMultipleCount}rem` : 0,
          zIndex: lastMultipleCount > 0 ? lastMultipleCount : undefined,
        },
      };
    });

    byDay[dayOfWeek] = byDay[dayOfWeek].map((event) => {
      const overlapCount = overlapCounts[event.enrollment.id];
      const slots = eventSlots[event.enrollment.id] ?? [];
      const maxOverlap = Math.max(0, ...slots.map((slot) => timeslotEvents[slot]?.length ?? 0));
      if (maxOverlap === 0 && overlapCount > 0) {
        throw new Error(`Unexpected this! ${maxOverlap} vs ${overlapCount}`);
      }
      if (maxOverlap > 1) {
        return {
          ...event,
          styles: {
            ...event.styles,
            width: cardWidths[maxOverlap] ?? MIN_CARD_WIDTH,
            left: getLeftOffset(maxOverlap, event.leftPosition),
          },
        };
      }

      return event;
    });

    return byDay;
  }, {});
}

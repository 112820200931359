import React, { useEffect, useState } from 'react';
import { DayOfWeek } from 'generated/graphql';
import { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TimeRangeSliderField, { TimeRange } from 'components/molecules/TimeRangeSliderField/TimeRangeSliderField';

const OptionButtons = styled(Box)`
  display: flex;
  justify-content: end;
`;

const weekdayMinMax = { start: '2:00 PM', end: '10:00 PM' };
const weekendMinMax = { start: '9:00 AM', end: '4:00 PM' };

const weekdayDefaultValue = { start: '2:00 PM', end: '10:00 PM' };
const weekendDefaultValue = { start: '9:00 AM', end: '4:00 PM' };

const defaultValueForDay: Record<DayOfWeek, { start: string; end: string }> = {
  [DayOfWeek.Monday]: weekdayDefaultValue,
  [DayOfWeek.Tuesday]: weekdayDefaultValue,
  [DayOfWeek.Wednesday]: weekdayDefaultValue,
  [DayOfWeek.Thursday]: weekdayDefaultValue,
  [DayOfWeek.Friday]: weekdayDefaultValue,
  [DayOfWeek.Saturday]: weekendDefaultValue,
  [DayOfWeek.Sunday]: weekendDefaultValue,
};

export const minMaxForDay: Record<DayOfWeek, { start: string; end: string }> = {
  [DayOfWeek.Monday]: weekdayMinMax,
  [DayOfWeek.Tuesday]: weekdayMinMax,
  [DayOfWeek.Wednesday]: weekdayMinMax,
  [DayOfWeek.Thursday]: weekdayMinMax,
  [DayOfWeek.Friday]: weekdayMinMax,
  [DayOfWeek.Saturday]: weekendMinMax,
  [DayOfWeek.Sunday]: weekendMinMax,
};

export function serializeValue(value: TimeRange): TimeRange {
  return {
    start: DateTime.fromFormat(value.start, 'TT').toFormat('h:mm a'),
    end: DateTime.fromFormat(value.end, 'TT').toFormat('h:mm a'),
  };
}

export function deserializeValue(value: TimeRange): TimeRange {
  return {
    start: DateTime.fromFormat(value.start, 'h:mm a').toFormat('TT'),
    end: DateTime.fromFormat(value.end, 'h:mm a').toFormat('TT'),
  };
}

interface AvailabilitySliderProps {
  dayOfWeek: DayOfWeek;
  value?: TimeRange;
  onChange?: (time: TimeRange) => void;
  onDelete?: () => void;
  labelProps?: TypographyProps;
}

type Props = AvailabilitySliderProps;

const AvailabilitySlider = ({ dayOfWeek, value, onChange, onDelete, ...props }: Props) => {
  const [timeValue, setTimeValue] = useState(() => {
    if (value?.start && value.end) {
      return serializeValue(value);
    }

    return defaultValueForDay[dayOfWeek];
  });

  const timeChangeHandler = (time: { start: string; end: string }) => {
    setTimeValue(time);
  };

  const changeCompleteHandler = () => {
    onChange && onChange(deserializeValue(timeValue));
  };

  useEffect(() => {
    if (value) {
      setTimeValue(serializeValue(value));
    }
  }, [value]);

  const minMax = minMaxForDay[dayOfWeek];

  return (
    <Box px={2}>
      <TimeRangeSliderField
        disabled={false}
        draggableTrack={false}
        format={12}
        minValue={minMax.start}
        maxValue={minMax.end}
        name={'time_range'}
        onChangeComplete={changeCompleteHandler}
        onChange={timeChangeHandler}
        step={15}
        value={timeValue}
        {...props}
      />
      {onDelete && (
        <OptionButtons>
          <Button color='error' size='small' onClick={onDelete}>
            Remove
          </Button>
        </OptionButtons>
      )}
    </Box>
  );
};

export default AvailabilitySlider;

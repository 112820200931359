import React, { useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import theme from 'theme';
import { useAccountContext } from 'contexts/AccountContext/AccountContext';
import { CancelButton } from 'components/organisms/AddStudentsForm/AddStudentForm';
import { useEnrollmentContext } from 'contexts/EnrollmentContext/EnrollmentContext';
import useMutationUpdateStudents from 'hooks/mutations/useMutationUpdateStudents/useMutationUpdateStudents';
import dayjs from 'dayjs';
import { useUserContext } from 'contexts/UserContext/UserContext';
import useMutationUpdateUsers from 'hooks/mutations/useMutationUpdateUsers/useMutationUpdateUsers';
import { Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export const SubmitButton = styled(Button)`
  background-color: ${theme.colors.rackley};
  margin-left: 1rem;
  border: none;
  border-radius: 0;
  text-transform: none;

  :hover {
    background-color: ${theme.colors.rackley};
  }
`;

const StudentListContainer = styled('div')`
  margin-bottom: 2rem;
`;

const StudentListItem = styled('div')`
  margin-bottom: 2rem;
`;

type Props = {
  onCancel?: (cancel?: boolean) => void;
};

const phoneNumberValidator = (phoneNumber: string) =>
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phoneNumber);

function UpdateStudentForm({ onCancel }: Props) {
  const { user } = useUserContext();
  const { selectedStudents, setSelectedStudents, setPhoneNumber } = useEnrollmentContext();
  const [studentsInput, setStudentsInput] = useState(selectedStudents ?? []);
  const { account } = useAccountContext();
  const {
    postLogin: { refetch },
  } = useUserContext();
  const userPhoneNumber = user?.phoneInfos?.find(({ phonePurpose }) => phonePurpose === 'Primary')?.number;
  const [accountHolder, setAccountHolder] = useState({
    id: user?.id,
    accountId: account?.id,
    accountName: account?.accountName,
    phoneNumber: userPhoneNumber,
  });
  const [updateStudents] = useMutationUpdateStudents();
  const [updateUsers] = useMutationUpdateUsers();

  const formik = useFormik({
    initialValues: {
      accountId: accountHolder.accountId,
      accountName: accountHolder.accountName,
      phone: accountHolder.phoneNumber,
      students: selectedStudents,
    },
    enableReinitialize: true,
    onSubmit: async () => {
      if (account) {
        if (
          accountHolder.id &&
          (accountHolder.accountName !== account?.accountName || accountHolder.phoneNumber !== userPhoneNumber)
        ) {
          const { data } = await updateUsers({
            variables: {
              input: {
                users: [
                  {
                    id: accountHolder.id,
                    ...(accountHolder.accountName !== account?.accountName && {
                      accountId: accountHolder.accountId,
                      accountName: accountHolder.accountName,
                    }),
                    ...(accountHolder.phoneNumber !== userPhoneNumber && {
                      phoneInfos: [
                        {
                          number: accountHolder.phoneNumber,
                        },
                      ],
                    }),
                  },
                ],
              },
            },
          });
          if (data?.updateUsers.success) {
            if (accountHolder.phoneNumber) {
              setPhoneNumber(accountHolder.phoneNumber);
            }
            if (accountHolder.accountName !== account?.accountName) {
              refetch();
            }
          }
        }
        const { data } = await updateStudents({
          variables: {
            input: {
              students: studentsInput?.map((studentInput) => ({
                id: studentInput.id,
                userId: studentInput.userId,
                ...(Number(studentInput.birthMonth) && {
                  birthMonth: Number(studentInput.birthMonth),
                }),
                ...(Number(studentInput.birthYear) && {
                  birthYear: Number(studentInput.birthYear),
                }),
                ...(studentInput.user?.firstName && {
                  firstName: studentInput.user?.firstName,
                }),
                ...(studentInput.user?.lastName && {
                  lastName: studentInput.user?.lastName,
                }),
                ...(studentInput.user?.phoneInfos && {
                  phoneInfos: studentInput.user?.phoneInfos.map(({ number }) => ({ number })),
                }),
              })),
            },
          },
        });
        if (data?.updateStudents) {
          setSelectedStudents(data?.updateStudents);
        }
      }
      onCancel && onCancel();
    },
  });

  const onStudentFieldsChange = (studentId: number, value: string | { [key: string]: string }[], name: string) => {
    if (studentsInput) {
      setStudentsInput(
        studentsInput.map((student) => {
          return student.id === studentId
            ? {
                ...student,
                [name]: value,
                ...(student.user && {
                  user: {
                    ...student.user,
                    [name]: value,
                  },
                }),
              }
            : student;
        })
      );
    }
  };

  const onStudentBirthdayChange = (studentId: number, date: { month?: number; year?: number }) => {
    if (studentsInput) {
      setStudentsInput(
        studentsInput.map((student) => {
          return student.id === studentId
            ? {
                ...student,
                birthMonth: date.month,
                birthYear: date.year,
              }
            : student;
        })
      );
    }
  };

  const onCancelClick = (cancel?: boolean) => {
    onCancel && onCancel(cancel);
  };

  const isPreFilled = useMemo(
    () =>
      selectedStudents?.reduce(
        (accum, student) =>
          accum &&
          !!student.user?.firstName &&
          !!student.user?.lastName &&
          !!student?.birthMonth &&
          !!student?.birthYear,
        true
      ) &&
      !!account?.accountName &&
      phoneNumberValidator(userPhoneNumber ?? ''),
    [selectedStudents, account, userPhoneNumber]
  );

  const isSubmittable = useMemo(
    () =>
      studentsInput?.reduce(
        (accum, student) =>
          accum &&
          !!student.user?.firstName &&
          !!student.user?.lastName &&
          !!student?.birthMonth &&
          !!student?.birthYear,
        true
      ) &&
      !!accountHolder.accountName &&
      phoneNumberValidator(accountHolder.phoneNumber ?? ''),
    [studentsInput, accountHolder]
  );

  return (
    <>
      <DialogTitle id='update-students-dialog-title'>Review and update {account?.accountName} info</DialogTitle>
      <DialogContent>
        <form>
          <TextField
            fullWidth
            id='accountName'
            name='accountName'
            label='Account Name'
            value={formik.values.accountName}
            onChange={({ target }) => {
              setAccountHolder({
                ...accountHolder,
                accountName: target.value,
              });
            }}
            error={!formik.values.accountName}
            variant='standard'
            sx={{ my: 2 }}
            required
          />
          <TextField
            fullWidth
            id='phone'
            name='phone'
            label="Account Holder's Phone Number"
            value={formik.values.phone}
            onChange={({ target }) => {
              setAccountHolder({
                ...accountHolder,
                phoneNumber: target.value,
              });
            }}
            error={!phoneNumberValidator(formik.values.phone ?? '')}
            variant='standard'
            sx={{ my: 2 }}
            required
          />
          <h5>List of students:</h5>
          <StudentListContainer>
            {formik.values.students &&
              formik.values.students.map((student, idx) => {
                const accountHolder = student.user?.id === user?.id;
                return (
                  <StudentListItem key={student.id}>
                    {accountHolder && <Typography>Account Holder</Typography>}
                    <Box sx={{ display: 'flex' }}>
                      <TextField
                        fullWidth
                        name='firstName'
                        label='First Name'
                        defaultValue={student.user?.firstName}
                        onChange={({ target }) => onStudentFieldsChange(student.id, target.value, target.name)}
                        error={!studentsInput[idx].user?.firstName}
                        variant='standard'
                        sx={{ my: 2, mr: 1 }}
                        required
                      />
                      <TextField
                        fullWidth
                        name='lastName'
                        label='Last Name'
                        defaultValue={student.user?.lastName}
                        onChange={({ target }) => onStudentFieldsChange(student.id, target.value, target.name)}
                        error={!studentsInput[idx].user?.lastName}
                        variant='standard'
                        sx={{ my: 2, ml: 1 }}
                        col-6
                        required
                      />
                    </Box>
                    {!accountHolder && (
                      <TextField
                        fullWidth
                        id='phone'
                        name='phoneInfos'
                        label="Student's Phone Number"
                        defaultValue={student.user?.phoneInfos && student.user?.phoneInfos[0]?.number}
                        onChange={({ target }) =>
                          onStudentFieldsChange(student.id, [{ number: target.value }], target.name)
                        }
                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                        variant='standard'
                        sx={{ my: 2 }}
                      />
                    )}
                    <Box marginTop={2}>
                      <DatePicker
                        label='Birth Month and Year'
                        views={['month', 'year']}
                        format='MM/YYYY'
                        maxDate={dayjs(new Date())}
                        defaultValue={dayjs(`${student?.birthYear}-${student?.birthMonth}`)}
                        onChange={(date) => {
                          const month = date?.month();
                          const year = date?.year();
                          onStudentBirthdayChange(student.id, {
                            month: month ? month + 1 : undefined,
                            year: year ? year : undefined,
                          });
                        }}
                      />
                    </Box>
                  </StudentListItem>
                );
              })}
          </StudentListContainer>
        </form>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex' }}>
          <CancelButton color='primary' variant='text' fullWidth onClick={() => onCancelClick(!isPreFilled)}>
            Close
          </CancelButton>
          <SubmitButton
            color='primary'
            variant='contained'
            fullWidth
            type='submit'
            disabled={formik.isSubmitting || !isSubmittable}
            sx={{ ml: 1, backgroundColor: '#718EB3' }}
            onClick={formik.handleSubmit}
          >
            {formik.isSubmitting ? 'Updating...' : 'Update'}
          </SubmitButton>
        </Box>
      </DialogActions>
    </>
  );
}

export default UpdateStudentForm;

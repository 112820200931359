import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import {
  InstructorProfilesDocument,
  InstructorProfilesQuery,
  InstructorProfilesQueryVariables,
} from 'generated/graphql';

export type InstructorProfileData = InstructorProfilesQuery['instructorProfiles'][number];

function useQueryInstructorProfiles(
  options?: QueryHookOptions<InstructorProfilesQuery, InstructorProfilesQueryVariables>
): QueryResult<InstructorProfilesQuery, InstructorProfilesQueryVariables> {
  return useQuery<InstructorProfilesQuery, InstructorProfilesQueryVariables>(InstructorProfilesDocument, {
    ...options,
  });
}

export default useQueryInstructorProfiles;

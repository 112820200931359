import React, { useMemo } from 'react';
import { CalendarProps as BaseCalendarProps } from 'react-big-calendar';
import { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { DateTime, Zone } from 'luxon';
import Box, { BoxProps } from '@mui/material/Box';
import { getDateTime } from 'utils/dates';
import { DayOfWeek } from 'generated/graphql';
import {
  BodyCell,
  BodyCol,
  BodyCols,
  BodyContent,
  GutterCell,
  GutterCol,
  HeaderCell,
  HeaderCols,
  HeaderContent,
  HeaderGutterCell,
  HeaderTitle,
  StyledHeader,
} from './SchedulingCalendar.styled';
import { EnrollmentFromQuery } from 'types';
import EnrollmentEventCard from 'components/organisms/NewSemesterCalendar/components/EnrollmentEventCard/EnrollmentEventCard';
import { getEventsByDay } from 'components/organisms/NewSemesterCalendar/utils/getEventsByDay';
import { sortEnrollments } from 'components/organisms/NewSemesterCalendar/utils/sortEnrollments';
import { InstructorProfileData } from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import { NO_PREFERENCE_VALUE } from 'components/organisms/InstructorSelectField/InstructorSelectField';
import colorMapper from 'utils/SemesterCalendar/colorMapper';

const DEFAULT_CALENDAR_MIN = DateTime.fromFormat('08:00:00', 'TT').toJSDate();
const DEFAULT_CALENDAR_MAX = DateTime.fromFormat('22:00:00', 'TT').toJSDate();

export type SchedulerView = 'week' | 'work_week' | 'day' | 'custom_days';

export type SchedulerSlotPropGetter = (
  dayOfWeek: DayOfWeek,
  time: string,
  resourceId?: number | string
) => Partial<BoxProps>;

export interface SchedulerSlotInfo {
  dayOfWeek: DayOfWeek;
  startTime: string;
  action: 'select' | 'click' | 'doubleClick';
  resourceId?: number | string | undefined;
}

export interface Event {
  id: string | number;
  allDay?: boolean | undefined;
  title?: React.ReactNode | undefined;
  start?: Date;
  end?: Date;
  resource?: unknown;
}

type TimeSlotGroup = {
  groupStart: DateTime;
  innerSlots: DateTime[];
};

function getTimeSlots(step: number, timeslots: number, min: Date, max: Date): TimeSlotGroup[] {
  let current = getDateTime(min);
  const end = getDateTime(max);
  const slots: TimeSlotGroup[] = [];

  while (current < end) {
    const groupStart = current;
    const innerSlots: DateTime[] = [];
    for (let i = 0; i < timeslots; i++) {
      innerSlots.push(current);
      current = current.plus({ minute: step });
    }

    slots.push({ groupStart, innerSlots });
  }

  return slots;
}

function filterEnrollments(enrollments: EnrollmentFromQuery[], instructor?: InstructorProfileData) {
  if (!instructor) {
    return enrollments;
  }

  return enrollments.filter((enrollment) => enrollment.scheduledInstructorId === instructor.profileId);
}

export interface SchedulingCalendarProps<
  TEvent extends EnrollmentFromQuery = EnrollmentFromQuery,
  TResource extends object = object
> extends Partial<
      Omit<BaseCalendarProps<TEvent, TResource>, 'defaultView' | 'view' | 'slotPropGetter' | 'onSelectSlot'>
    >,
    Partial<Omit<withDragAndDropProps<TEvent, TResource>, 'defaultView' | 'view'>> {
  timezone?: Zone | string;
  title?: string;
  instructor?: InstructorProfileData;
  daysOfWeek?: DayOfWeek[];
  view?: SchedulerView;
  defaultView?: SchedulerView;
  slotPropGetter?: SchedulerSlotPropGetter;
  onSelectSlot?: (slotInfo: SchedulerSlotInfo) => void;
}

type Props<
  TEvent extends EnrollmentFromQuery = EnrollmentFromQuery,
  TResource extends object = object
> = SchedulingCalendarProps<TEvent, TResource>;

const SchedulingCalendar = <
  TEvent extends EnrollmentFromQuery = EnrollmentFromQuery,
  TResource extends object = object
>({
  events,
  view,
  min = DEFAULT_CALENDAR_MIN,
  max = DEFAULT_CALENDAR_MAX,
  timeslots = 2,
  step = 15,
  defaultView = 'week',
  selected,
  onSelectEvent,
  title = 'Full Calendar View',
  instructor,
  daysOfWeek,
  slotPropGetter,
  onSelectSlot,
  ...props
}: Props<TEvent, TResource>) => {
  const slotGroups = useMemo(() => getTimeSlots(step, timeslots, min, max), [max, min, step, timeslots]);
  const currentView = view ?? defaultView;
  const currentDays = useMemo(
    () =>
      currentView === 'week'
        ? [
            DayOfWeek.Monday,
            DayOfWeek.Tuesday,
            DayOfWeek.Wednesday,
            DayOfWeek.Thursday,
            DayOfWeek.Friday,
            DayOfWeek.Saturday,
            DayOfWeek.Sunday,
          ]
        : daysOfWeek?.length
        ? daysOfWeek
        : [DayOfWeek.Monday],
    [currentView, daysOfWeek]
  );
  const dayCount = currentDays.length;
  const sortedEvents = useMemo(
    () => sortEnrollments(filterEnrollments(events || [], instructor)),
    [events, instructor]
  );

  const eventsByDay = useMemo(() => {
    return getEventsByDay<TEvent>(currentDays, sortedEvents, min, max, timeslots, step);
  }, [currentDays, max, min, sortedEvents, step, timeslots]);

  const colorId = `instructor_${instructor?.profileId.toString() || NO_PREFERENCE_VALUE}`;
  const calendarColor = instructor ? instructor.calendarColor || colorMapper.pickColor(colorId) : undefined;

  return (
    <div>
      <Box sx={{ height: '100%', width: '100%', overflow: 'auto', position: 'relative' }} style={props.style}>
        <StyledHeader>
          <HeaderGutterCell></HeaderGutterCell>
          <HeaderContent>
            <HeaderTitle $bgColor={calendarColor}>
              {instructor
                ? instructor.user
                  ? `${instructor.user.firstName} ${instructor.user.lastName}`
                  : instructor.profileId.toString()
                : title}
            </HeaderTitle>
            <HeaderCols>
              {currentDays.map((dayOfWeek, columnIndex) => (
                <HeaderCell
                  key={dayOfWeek}
                  sx={{
                    width: `${100 / dayCount}%`,
                    borderLeft: columnIndex === 0 ? 'none' : undefined,
                  }}
                >
                  {`${dayOfWeek[0].toUpperCase()}${dayOfWeek.slice(1).toLowerCase()}`}
                </HeaderCell>
              ))}
            </HeaderCols>
          </HeaderContent>
        </StyledHeader>
        <BodyContent>
          <GutterCol>
            {slotGroups.map((slotGroup) => (
              <GutterCell key={slotGroup.groupStart.toISO()}>{slotGroup.groupStart.toFormat('t')}</GutterCell>
            ))}
          </GutterCol>
          <BodyCols>
            {currentDays.map((dayOfWeek, columnIndex) => {
              const dayEvents = eventsByDay[dayOfWeek] || [];

              return (
                <BodyCol key={dayOfWeek} sx={{ width: `${100 / dayCount}%` }}>
                  {slotGroups.map((slotGroup) => (
                    <BodyCell
                      key={slotGroup.groupStart.toISO()}
                      sx={{ borderLeft: columnIndex === 0 ? 'none' : undefined }}
                    >
                      {slotGroup.innerSlots.map((innerSlot) => {
                        const slotProps = slotPropGetter?.(dayOfWeek, innerSlot.toFormat('TT'), instructor?.profileId);
                        return (
                          <Box
                            key={innerSlot.toISO()}
                            {...slotProps}
                            sx={{
                              ...slotProps?.sx,
                              flex: '1 0',
                              ...(onSelectSlot
                                ? {
                                    zIndex: 65,
                                    '&:hover': {
                                      backgroundColor: '#BBBBBB',
                                    },
                                  }
                                : undefined),
                            }}
                            onClick={
                              onSelectSlot
                                ? () =>
                                    onSelectSlot({
                                      action: 'click',
                                      dayOfWeek,
                                      startTime: innerSlot.toFormat('TT'),
                                      resourceId: instructor?.profileId,
                                    })
                                : undefined
                            }
                          ></Box>
                        );
                      })}
                    </BodyCell>
                  ))}
                  <Box sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                    {dayEvents.map(({ enrollment: event, styles }) => {
                      if (!event.startTime || !event.endTime) {
                        return null;
                      }

                      return (
                        <Box
                          key={event.id}
                          sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            cursor: 'pointer',
                            ...styles,
                            zIndex: selected && selected.id === event.id ? 40 : styles?.zIndex,
                            '&:hover': {
                              zIndex: 60,
                            },
                          }}
                        >
                          <EnrollmentEventCard enrollment={event} onClick={onSelectEvent} />
                        </Box>
                      );
                    })}
                  </Box>
                </BodyCol>
              );
            })}
          </BodyCols>
        </BodyContent>
      </Box>
    </div>
  );
};

export default SchedulingCalendar;

import React from 'react';
import { styled } from '@mui/material/styles';
import BackgroundImageDiv from 'components/atoms/BackgroundImageDiv/BackgroundImageDiv';
import CTAButton from 'components/atoms/CTAButton/CTAButton';
import CTAText from 'components/molecules/CTAText/CTAText';
import { CallToAction } from 'generated/graphql';
import env from 'config/env';
import parse from 'html-react-parser';

type Props = {
  onBottom?: boolean;
  callToAction: CallToAction;
};

const Container = styled('div', { shouldForwardProp: (prop: PropertyKey) => prop !== 'onBottom' })<{
  onBottom: boolean;
}>`
  margin-bottom: ${({ onBottom }: Props) => (onBottom ? '0' : '2rem')};
  margin-top: ${({ onBottom }: Props) => (onBottom ? '2rem' : '0')};
  width: 100%;
`;

const ButtonContainer = styled('div')`
  button,
  a {
    margin-left: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
`;

const FullBleedCTA: React.FC<Props> = ({ onBottom = false, callToAction }) => {
  const backgroundImageUrl = callToAction.backgroundImageS3Url
    ? `https://${env.app.s3Bucket}.s3-${env.app.s3Region}.amazonaws.com/${callToAction.backgroundImageS3Url}`
    : '';

  return (
    <Container onBottom={onBottom}>
      <BackgroundImageDiv imgUrl={backgroundImageUrl}>
        <CTAText>{parse(callToAction.title)}</CTAText>
        <h1>{callToAction?.subtitle}</h1>
        <div>{parse(callToAction?.body || '')}</div>
        <ButtonContainer>
          {callToAction.primaryButtonUrl && (
            <CTAButton href={callToAction.primaryButtonUrl} target='_blank'>
              {callToAction.primaryButtonText}
            </CTAButton>
          )}
          {callToAction?.secondaryButtonUrl && (
            <CTAButton href={callToAction?.secondaryButtonUrl} target='_blank'>
              {callToAction?.secondaryButtonText}
            </CTAButton>
          )}
        </ButtonContainer>
      </BackgroundImageDiv>
    </Container>
  );
};

export default FullBleedCTA;

import {
  Skill,
  OnboardingState,
  User,
  Enrollment,
  ScheduleItem,
  InstructorProfilesQuery,
  EnrollmentPricingQuery,
  AccountBillingDetailsQuery,
  GetAdminAppointmentsQuery,
  DayOfWeek,
} from 'generated/graphql';

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type EnrollmentFromQuery = Omit<Enrollment, 'schedule'>;

export type QueriedInstructorProfile = InstructorProfilesQuery['instructorProfiles'][number];

export type QueriedPaymentCard = AccountBillingDetailsQuery['accountBillingDetails']['cards'][number];

export type EnrollmentPricingType = EnrollmentPricingQuery['enrollmentPricing'];

export type QueriedAppointment = GetAdminAppointmentsQuery['appointments'][number];

export type NoPreferenceType = 'NO_PREFERENCE';

export type MinMax = {
  min?: Date;
  max?: Date;
};

export type BackgroundEvent = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  instructorId?: number;
  skills?: Skill[];
  color?: string;
  resource?: number;
};

export type SchedulerBackgroundEvent = {
  id: string;
  title?: string;
  dayOfWeek: DayOfWeek;
  startTime: string;
  endTime: string;
  instructorId?: number;
  skills?: Skill[];
  siteIds?: number;
  color?: string;
  resource?: number;
};

export type EnrollmentBackgroundEvent = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  studentId?: number;
  instructorId?: number;
  skill?: Skill;
  color?: string;
};

export interface EnrollmentEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  enrollment: EnrollmentFromQuery;
  enrollmentId: number;
  scheduleItem: ScheduleItem;
  instructorId?: number;
  studentId?: number;
  skill?: Skill;
  color?: string;
  isDraggable?: boolean;
  resource?: number | NoPreferenceType;
}

export interface AppointmentEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  appointment: QueriedAppointment;
  appointmentId: number;
  instructorId?: number;
  studentId?: number;
  skill?: Skill;
  color?: string;
  isDraggable?: boolean;
  resource?: number | NoPreferenceType;
}

export type ProfileHomeProps = {
  user: User;
  onboardingState: OnboardingState;
};

export enum SolverStatus {
  SOLVING_SCHEDULED = 'SOLVING_SCHEDULED',
  SOLVING_ACTIVE = 'SOLVING_ACTIVE',
  NOT_SOLVING = 'NOT_SOLVING',
}

import React from 'react';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import { AdminContextProvider } from 'contexts/AdminContext/AdminContext';

const AdminPage = () => {
  return (
    <AdminContextProvider>
      <Container maxWidth={false}>
        <Box py={3}>
          <Outlet />
        </Box>
      </Container>
    </AdminContextProvider>
  );
};

export default AdminPage;

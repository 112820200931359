import { SlotAvailabilityMap } from 'utils/calendar/types';
import { ColorMapper } from 'utils/SemesterCalendar/colorMapper';

export default function slotPropGetterBase(
  slotAvailabilityMap: SlotAvailabilityMap,
  studentSlotAvailabilityMap: SlotAvailabilityMap,
  date: Date,
  resourceId?: number | string
) {
  // Find all availability that covers this slot.
  const key = date.toISOString();

  const matches = slotAvailabilityMap[key]?.filter(({ event }) => !resourceId || event.resource === resourceId) || [];
  const studentMatches = studentSlotAvailabilityMap[key] || [];

  const bgColor = matches.find(({ event }) => !!event.color)?.event.color;
  const isFirstColor = matches.find(({ event, isFirst }) => isFirst && !!event.color)?.event.color;
  const isLastColor = matches.find(({ event, isLast }) => isLast && !!event.color)?.event.color;

  const isFirstStudentMatch = studentMatches.find(({ isFirst }) => isFirst);
  const isLastStudentMatch = studentMatches.find(({ isLast }) => isLast);

  return {
    ...(matches.length === 0 && {
      style: {
        backgroundColor: '#f7f7f7',
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: 'none',
      },
    }),
    ...(matches.length > 0 && {
      style: {
        backgroundColor: bgColor ? ColorMapper.setOpacity(bgColor, 0.25) : undefined,
        borderLeft: `1px solid ${bgColor}`,
        borderRight: `1px solid ${bgColor}`,
        borderTop: isFirstColor ? `1px solid ${bgColor}` : undefined,
        borderBottom: isLastColor ? `1px solid ${bgColor}` : undefined,
      },
    }),
    ...(studentMatches.length > 0 && {
      style: {
        backgroundColor: bgColor ? ColorMapper.setOpacity(bgColor, 0.25) : '#cfcfcf',
        borderLeft: `3px solid ${bgColor || '#aaa'}`,
        borderRight: `3px solid ${bgColor || '#aaa'}`,
        borderTop: isFirstStudentMatch ? `3px solid ${bgColor || '#aaa'}` : 'none',
        borderBottom: isLastStudentMatch ? `3px solid ${bgColor || '#aaa'}` : 'none',
        boxShadow: 'inset 0 0 5rem rgba(0, 0, 0, 0.2)',
      },
    }),
  };
}

import React, { useMemo, useState } from 'react';
import { Components } from 'react-big-calendar';
import { DateTime } from 'luxon';
import env from 'config/env';
import {
  EnrollmentFromQuery,
  MinMax,
  NoPreferenceType,
  QueriedInstructorProfile,
  SchedulerBackgroundEvent,
} from 'types';
import { DayOfWeek, ScheduleAvailabilityResult } from 'generated/graphql';
import getMinMax from 'utils/SemesterCalendar/getMinMax';
import useQueryInstructorProfiles from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import { NO_PREFERENCE_VALUE } from 'components/organisms/InstructorSelectField/InstructorSelectField';
import ResourceHeader from './components/ResourceHeader/ResourceHeader';
import memoize from 'fast-memoize';
import { TIMESLOT_STEP } from 'utils/calendar/getSlotAvailability';
import getInitialRange from 'utils/calendar/getInitialRange';
import SchedulingCalendar, {
  Event,
  SchedulerSlotPropGetter,
  SchedulerView,
  SchedulingCalendarProps,
} from 'components/organisms/NewSemesterCalendar/components/SchedulingCalendar/SchedulingCalendar';
import eventPropGetter from 'utils/calendar/eventPropGetter';
import Grid from '@mui/material/Grid';
import getInstructorSchedulerAvailability from 'utils/availability/getInstructorSchedulerAvailability';
import getSchedulerSlotAvailability from 'utils/calendar/getSchedulerSlotAvailability';
import schedulerSlotPropGetterBase from 'utils/calendar/schedulerSlotPropGetterBase';
import { SchedulerFilters } from 'pages/Admin/NewScheduler/types';
import getEnrollmentSchedulerAvailability from 'utils/availability/getEnrollmentSchedulerAvailability';
import MultipleSchedulingCalendar from './components/SchedulingCalendar/MultipleSchedulingCalender';

function getStartDateForSemester(availability?: ScheduleAvailabilityResult) {
  if (!availability) {
    return DateTime.now().toJSDate();
  }

  const { startDate } = availability;
  const start = DateTime.fromISO(startDate);
  // If Sunday, return this date. Otherwise, go to end of week (Sunday).
  if (start.weekday === 7) {
    return start.toJSDate();
  }

  return start.endOf('week').startOf('day').toJSDate();
}

export interface BaseScheduleEvent extends Event {
  id: string | number;
  isDraggable?: boolean;
  color?: string;
}

export interface ScheduleResource {
  id: string | number;
  title: string;
  color?: string;
  instructorProfile?: QueriedInstructorProfile;
}

export type ScheduleEvent = EnrollmentFromQuery;

const calendarComponents: Components<ScheduleEvent, ScheduleResource> = {
  resourceHeader: ResourceHeader,
};

const calendarStyle = { height: `calc(100vh - ${env.sizing.admin.vhOffset})` };
const calendarMinMax: MinMax = getMinMax();

const DEFAULT_VIEW: SchedulerView = 'week';

interface SemesterCalendarProps extends Partial<SchedulingCalendarProps<ScheduleEvent, ScheduleResource>> {
  availability?: ScheduleAvailabilityResult;
  enrollments?: EnrollmentFromQuery[];
  selectedEnrollment?: EnrollmentFromQuery;
  selectedInstructors?: (QueriedInstructorProfile | NoPreferenceType)[];
  selectedFilters?: SchedulerFilters;
  draggedEvent?: ScheduleEvent;
  byResource?: boolean;
}

type Props = SemesterCalendarProps;

const NewSemesterCalendar = ({
  availability,
  enrollments,
  selectedEnrollment,
  selectedInstructors,
  selectedFilters,
  byResource,
  ...props
}: Props) => {
  const { data: instructorsData } = useQueryInstructorProfiles();
  const instructors = useMemo(() => instructorsData?.instructorProfiles || [], [instructorsData?.instructorProfiles]);
  const defaultDate = useMemo(() => getStartDateForSemester(availability), [availability]);

  const [currentDate] = useState<Date>(defaultDate);
  const [currentView] = useState<SchedulerView>(props.defaultView || DEFAULT_VIEW);
  const [currentRange] = useState<Date[] | { start: Date; end: Date } | undefined>(() =>
    getInitialRange(currentDate, currentView)
  );

  const instructorResources = useMemo<ScheduleResource[]>(() => {
    return [
      ...instructors
        .filter(
          (instructor) =>
            (!selectedInstructors ||
              selectedInstructors.length === 0 ||
              (selectedInstructors.length === 1 && selectedInstructors[0] === NO_PREFERENCE_VALUE) ||
              !!selectedInstructors.find((x) => typeof x !== 'string' && x.profileId === instructor.profileId)) &&
            (!selectedEnrollment ||
              selectedEnrollment.scheduledInstructorId === instructor.profileId ||
              selectedEnrollment.preferredInstructorId === instructor.profileId ||
              (!selectedEnrollment.preferredInstructorId &&
                !!instructor.availableSkills?.find((skill) => skill.id === selectedEnrollment.skillId)))
        )
        .filter((instructor) => {
          if (!availability?.instructorAvailability) {
            return true;
          }

          if (
            selectedInstructors &&
            selectedInstructors.find((x) => typeof x !== 'string' && x.profileId === instructor.profileId)
          ) {
            return true;
          }

          if (
            selectedEnrollment &&
            (selectedEnrollment.scheduledInstructorId === instructor.profileId ||
              selectedEnrollment.preferredInstructorId === instructor.profileId)
          ) {
            return true;
          }

          if (enrollments && !!enrollments.find((x) => x.scheduledInstructorId === instructor.profileId)) {
            return true;
          }

          const avail = availability.instructorAvailability.find((x) => x.instructor.id === instructor.profileId);
          return !!avail && !!avail.availability?.availabilityItems && avail.availability?.availabilityItems.length > 0;
        })
        .map((instructor) => ({
          id: instructor.profileId,
          title: instructor.user
            ? `${instructor.user.firstName} ${instructor.user.lastName}`
            : instructor.profileId.toString(),
          color: instructor.calendarColor || undefined,
          instructorProfile: instructor,
        })),
      { id: NO_PREFERENCE_VALUE, title: 'You Pick!' },
    ];
  }, [availability?.instructorAvailability, enrollments, instructors, selectedEnrollment, selectedInstructors]);

  // Account for filters when enrollment is selected, or other filters are applied.
  const filters = useMemo<{
    instructorIds?: number[];
    skillIds?: number[];
    siteIds?: number[];
  }>(() => {
    const selectedInstructorIds =
      selectedInstructors && selectedInstructors.length > 0
        ? selectedInstructors
            .map((x) => (typeof x === 'string' ? undefined : x.profileId))
            .filter((x): x is number => !!x)
        : undefined;

    if (!selectedEnrollment) {
      return {
        instructorIds: selectedInstructorIds,
        skillIds: selectedFilters?.skillIds,
      };
    }

    return {
      instructorIds:
        selectedEnrollment.scheduledInstructorId || selectedEnrollment.preferredInstructorId
          ? [selectedEnrollment.scheduledInstructorId, selectedEnrollment.preferredInstructorId]
              .filter((x): x is number => !!x)
              .concat(selectedInstructorIds || [])
          : undefined,
      skillIds: selectedEnrollment.skillId ? [selectedEnrollment.skillId] : undefined,
    };
  }, [selectedEnrollment, selectedFilters?.skillIds, selectedInstructors]);

  const instructorAvailabilityEvents = useMemo<SchedulerBackgroundEvent[]>(
    () => (availability ? getInstructorSchedulerAvailability(availability.instructorAvailability, filters) : []),
    [availability, filters]
  );

  const slotAvailabilityMap = useMemo(
    () => getSchedulerSlotAvailability(instructorAvailabilityEvents),
    [instructorAvailabilityEvents]
  );

  const selectedAvailabilityEvents = useMemo<SchedulerBackgroundEvent[]>(
    () => (availability && selectedEnrollment ? getEnrollmentSchedulerAvailability(selectedEnrollment) : []),
    [availability, selectedEnrollment]
  );

  const studentSlotAvailabilityMap = useMemo(
    () => (currentRange ? getSchedulerSlotAvailability(selectedAvailabilityEvents) : {}),
    [currentRange, selectedAvailabilityEvents]
  );

  const slotPropGetter: SchedulerSlotPropGetter = useMemo(
    () =>
      memoize((dayOfWeek: DayOfWeek, time: string, resourceId: number | string | undefined) => {
        return schedulerSlotPropGetterBase(
          slotAvailabilityMap,
          studentSlotAvailabilityMap,
          dayOfWeek,
          time,
          resourceId
        );
      }),
    [slotAvailabilityMap, studentSlotAvailabilityMap]
  );

  const byResourceProps: Partial<SchedulingCalendarProps<ScheduleEvent, ScheduleResource>> = useMemo(
    () =>
      byResource
        ? {
            resources: instructorResources,
            resourceAccessor: (event) => event.scheduledInstructor || event.preferredInstructor,
            resourceIdAccessor: (resource) => resource.id,
            resourceTitleAccessor: (resource) => resource.title,
          }
        : {},
    [byResource, instructorResources]
  );

  const calendarInstructors = useMemo(
    () =>
      selectedInstructors?.length
        ? selectedInstructors.filter((x): x is QueriedInstructorProfile => x !== NO_PREFERENCE_VALUE)
        : [],
    [selectedInstructors]
  );

  if (calendarInstructors.length > 0) {
    return (
      <Grid container>
        <MultipleSchedulingCalendar
          style={calendarStyle}
          events={enrollments}
          instructors={calendarInstructors}
          defaultDate={defaultDate}
          defaultView={DEFAULT_VIEW}
          eventPropGetter={eventPropGetter}
          slotPropGetter={slotPropGetter}
          step={TIMESLOT_STEP}
          timeslots={2}
          min={calendarMinMax.min}
          max={calendarMinMax.max}
          resizable={false}
          components={calendarComponents}
          {...byResourceProps}
          {...props}
        />
      </Grid>
    );
  }

  return (
    <SchedulingCalendar<ScheduleEvent, ScheduleResource>
      style={calendarStyle}
      defaultDate={defaultDate}
      defaultView={DEFAULT_VIEW}
      events={enrollments}
      // backgroundEvents={backgroundEvents}
      eventPropGetter={eventPropGetter}
      slotPropGetter={slotPropGetter}
      step={TIMESLOT_STEP}
      timeslots={2}
      min={calendarMinMax.min}
      max={calendarMinMax.max}
      resizable={false}
      components={calendarComponents}
      instructor={
        selectedInstructors?.length && selectedInstructors[0] !== NO_PREFERENCE_VALUE
          ? selectedInstructors[0]
          : undefined
      }
      {...byResourceProps}
      {...props}
    />
  );
};

export default NewSemesterCalendar;

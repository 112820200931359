import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { css, styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useAccountContext } from 'contexts/AccountContext/AccountContext';
import bannerImageGuitar from './banner-image-guitar.png';
import hexToRGB from 'utils/colors/hexToRGB';
import { getSecondaryColor } from 'utils/colors/studentColors';

type StyleProps = {
  color?: string;
  secondaryColor?: string;
};

const DEFAULT_GOLD_COLOR = '#E3B43F';
const DEFAULT_SECONDARY_COLOR = 'rgba(227, 132, 63, 0.72)';

const StyledBanner = styled(Toolbar)(
  ({ theme }) => css`
    background-image: linear-gradient(
        90deg,
        ${({ color }: StyleProps) => color || DEFAULT_GOLD_COLOR} 0%,
        ${({ secondaryColor }: StyleProps) => secondaryColor || DEFAULT_SECONDARY_COLOR} 100%
      ),
      url(${bannerImageGuitar});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top left;

    ${theme.breakpoints.up('lg')} {
      background-image: linear-gradient(
        90deg,
        ${({ color }: StyleProps) => color || DEFAULT_GOLD_COLOR} 0%,
        ${({ secondaryColor }: StyleProps) => secondaryColor || DEFAULT_SECONDARY_COLOR} 100%
      );
    }
  `
);

const Banner: React.FC<PropsWithChildren> = ({ children }) => {
  const { students } = useAccountContext();
  const { pathname } = useLocation();

  // NOTE: validate path matches to /enroll/:scheduleId/student/:studentId/page-name which is a student specific pages
  const studentSpecificPageValidation = new RegExp(/^\/enroll\/[\d]+\/student\/[\d]+\/[a-z/\-/]+$/);
  const isStudentSpecificPage = studentSpecificPageValidation.test(pathname);

  // NOTE: pathname matches the pattern above, student id is always in 4th index.
  const studentId = pathname.split('/')[4];
  const { color } = students?.find(({ id }) => id === Number(studentId)) || {};

  const bannerColor = (isStudentSpecificPage && color) || '';
  const secondaryColor = bannerColor ? hexToRGB(getSecondaryColor(bannerColor), '0.72') : undefined;

  return (
    <StyledBanner color={bannerColor} secondaryColor={secondaryColor}>
      {children}
    </StyledBanner>
  );
};

export default Banner;

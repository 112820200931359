import { useScheduleSolverContext } from 'contexts/ScheduleSolverContext/ScheduleSolverContext';
import { useMemo } from 'react';
import { SolverEnrollmentSolution } from 'generated/graphql';

function useEnrollmentSolutionResult(enrollmentId?: number): SolverEnrollmentSolution | undefined {
  const { enrollmentSolutionMap } = useScheduleSolverContext();

  return useMemo(() => {
    if (!enrollmentId) {
      return;
    }

    return enrollmentSolutionMap?.[enrollmentId];
  }, [enrollmentId, enrollmentSolutionMap]);
}

export default useEnrollmentSolutionResult;

import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ScheduleSolverControls from 'components/organisms/ScheduleSolverControls/ScheduleSolverControls';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const SchedulerHeader = () => {
  return (
    <Wrapper>
      <ScheduleSolverControls />
    </Wrapper>
  );
};

export default SchedulerHeader;

import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useMutation } from '@apollo/client';
import Stack from '@mui/material/Stack';
import { useUserContext } from 'contexts/UserContext/UserContext';
import useOnboardingStepRedirect from 'hooks/useOnboardingStepRedirect/useOnboardingStepRedirect';
import {
  OnboardingStepEnum,
  ResendEmailVerificationDocument,
  ResendEmailVerificationMutation,
  ResendEmailVerificationMutationVariables,
} from 'generated/graphql';
import ErrorAlert from 'components/molecules/ErrorAlert/ErrorAlert';

const REFETCH_POLL_INTERVAL = 10000;

const VerifyEmail = () => {
  useOnboardingStepRedirect();
  const {
    postLogin: { refetch },
  } = useUserContext();
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [displayResendMessage, setDisplayResendMessage] = useState<boolean>(false);
  const [checkingContinue, setCheckingContinue] = useState<boolean>(false);
  const [resendEmailVerification, { error: resendError, loading: resendLoading }] = useMutation<
    ResendEmailVerificationMutation,
    ResendEmailVerificationMutationVariables
  >(ResendEmailVerificationDocument);

  // Set up a polling interval to refetch the data to watch for the email update.
  useEffect(() => {
    let timeoutId: number | undefined;

    const doPoll = () => {
      refetch();
      timeoutId = window.setTimeout(doPoll, REFETCH_POLL_INTERVAL);
    };

    timeoutId = window.setTimeout(doPoll, REFETCH_POLL_INTERVAL);

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [refetch]);

  const handleContinueClick = useCallback(async () => {
    setCheckingContinue(true);

    // Clear alert is there is one.
    if (displayAlert) {
      setDisplayAlert(false);
    }
    if (displayResendMessage) {
      setDisplayResendMessage(false);
    }

    const { data } = await refetch();

    setCheckingContinue(false);

    // If email still needs to be verified, display an alert message.
    if (data.postLogin.nextStep === OnboardingStepEnum.VerifyEmail) {
      setDisplayAlert(true);
    }
  }, [displayAlert, displayResendMessage, refetch]);

  const handleResendClick = useCallback(async () => {
    await resendEmailVerification();

    if (displayAlert) {
      setDisplayAlert(false);
    }
    setDisplayResendMessage(true);
  }, [displayAlert, resendEmailVerification]);

  return (
    <Stack direction='column' spacing={2}>
      <Typography variant='h1'>Email Verification</Typography>
      <Typography>
        Please verify your email by clicking the link in the verification email sent to your email address.
      </Typography>
      <Button variant='contained' onClick={handleContinueClick} disabled={checkingContinue}>
        {checkingContinue ? 'Checking...' : 'Continue'}
      </Button>
      {displayAlert && (
        <Alert
          severity='error'
          action={
            <Button color='inherit' size='small' onClick={handleResendClick} disabled={resendLoading}>
              {resendLoading ? 'SENDING...' : 'RESEND'}
            </Button>
          }
        >
          <AlertTitle>Email verification required</AlertTitle>
          Check your spam folder, or request a new verification email.
        </Alert>
      )}
      {displayResendMessage && !displayAlert && (
        <Alert severity='info' onClose={() => setDisplayResendMessage(false)}>
          <AlertTitle>Email verification resent</AlertTitle>A new email has been sent and should arrive in your inbox in
          a few minutes.
        </Alert>
      )}
      {resendError && <ErrorAlert error={resendError.message} />}
    </Stack>
  );
};

export default VerifyEmail;

import { SchedulerSlotAvailabilityMap } from 'utils/calendar/types';
import { ColorMapper } from 'utils/SemesterCalendar/colorMapper';
import { DayOfWeek } from 'generated/graphql';
import { getTimeslotKey } from 'utils/calendar/getSchedulerSlotAvailability';
import getRepeatingLinearGradient from 'utils/colors/getRepeatingLinearGradient';
import { BoxProps } from '@mui/material/Box';

export default function schedulerSlotPropGetterBase(
  slotAvailabilityMap: SchedulerSlotAvailabilityMap,
  studentSlotAvailabilityMap: SchedulerSlotAvailabilityMap,
  dayOfWeek: DayOfWeek,
  time: string,
  resourceId?: number | string
): Partial<BoxProps> {
  // Find all availability that covers this slot.
  const key = getTimeslotKey(dayOfWeek, time);

  const matches = slotAvailabilityMap[key]?.filter(({ event }) => !resourceId || event.resource === resourceId) || [];
  const studentMatches = studentSlotAvailabilityMap[key] || [];

  const bgColor = matches.find(({ event }) => !!event.color)?.event.color;
  const isFirstColor = matches.find(({ event, isFirst }) => isFirst && !!event.color)?.event.color;
  const isLastColor = matches.find(({ event, isLast }) => isLast && !!event.color)?.event.color;

  const isFirstStudentMatch = studentMatches.find(({ isFirst }) => isFirst);
  const isLastStudentMatch = studentMatches.find(({ isLast }) => isLast);

  const bgColors = matches.map((x) => x.event.color).filter((color): color is string => !!color);
  let matchesBackground = bgColor ? ColorMapper.setOpacity(bgColor, 0.25) : undefined;
  if (bgColors.length > 1) {
    matchesBackground = getRepeatingLinearGradient([...Array.from(new Set(bgColors))]);
  }

  return {
    ...(matches.length === 0 && {
      sx: {
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: 'none',
        borderBottom: 'none',
      },
    }),
    ...(matches.length > 0 && {
      sx: {
        position: 'relative',
        background: matchesBackground,
        borderLeft: `1px solid ${bgColor}`,
        borderRight: `1px solid ${bgColor}`,
        borderTop: isFirstColor ? `1px solid ${isFirstColor}` : undefined,
        borderBottom: isLastColor ? `1px solid ${isLastColor}` : undefined,
      },
    }),
    ...(studentMatches.length > 0 && {
      sx: {
        backgroundColor: bgColor ? ColorMapper.setOpacity(bgColor, 0.25) : '#cfcfcf',
        borderLeft: `3px solid ${bgColor || '#aaa'}`,
        borderRight: `3px solid ${bgColor || '#aaa'}`,
        borderTop: isFirstStudentMatch ? `3px solid ${bgColor || '#aaa'}` : 'none',
        borderBottom: isLastStudentMatch ? `3px solid ${bgColor || '#aaa'}` : 'none',
        boxShadow: 'inset 0 0 5rem rgba(0, 0, 0, 0.2)',
      },
    }),
  };
}

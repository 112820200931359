import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetLocationsDocument, GetLocationsQuery, GetLocationsQueryVariables } from 'generated/graphql';

function useQueryGetLocations(
  options?: QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>
): QueryResult<GetLocationsQuery, GetLocationsQueryVariables> {
  return useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, {
    ...options,
  });
}

export default useQueryGetLocations;

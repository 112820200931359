import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { EnrollmentFromQuery } from 'types';
import { dayOfWeekIndexToStringMap, sortAvailabilityItems } from 'utils/availability/availabilityUtils';
import { DateTime } from 'luxon';
import useSiblingEnrollmentsDetail from 'hooks/useSiblingEnrollmentsDetail/useSiblingEnrollmentsDetail';
import useMultipleEnrollmentsDetail from 'hooks/useMultipleEnrollmentsDetail/useMultipleEnrollmentsDetail';
import useQueryEnrollmentsOnSchedule from 'hooks/useQueryEnrollmentsOnSchedule/useQueryEnrollmentsOnSchedule';
import useQueryGetLocations from 'hooks/queries/useQueryGetLocations/useQueryGetLocations';
import { UserRoleUtils } from 'utils/user/roleUtils';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ProfileType } from 'generated/graphql';

interface Props {
  enrollment: EnrollmentFromQuery;
  switchEnrollment?: (enrollment: EnrollmentFromQuery) => void;
}

export const EnrollmentDrawerView = ({ enrollment, switchEnrollment }: Props) => {
  const studentAvailability = useMemo(
    () => sortAvailabilityItems(enrollment.availability?.availabilityItems || []),
    [enrollment.availability?.availabilityItems]
  );
  const enrollmentId = enrollment?.id;
  const studentProfileId = enrollment?.studentProfileId;
  const siblingEnrollments = useSiblingEnrollmentsDetail(enrollmentId);
  const multipleEnrollments = useMultipleEnrollmentsDetail(studentProfileId);
  const { data } = useQueryGetLocations();

  const hasSiblingEnrollments = !!siblingEnrollments?.siblingEnrollmentIds?.length;
  const hasMultipleEnrollments = !!multipleEnrollments?.enrollmentIds?.length;

  const { data: siblingEnrollmentData } = useQueryEnrollmentsOnSchedule(
    {
      ids: siblingEnrollments?.siblingEnrollmentIds,
    },
    !siblingEnrollments?.siblingEnrollmentIds?.length
  );

  const hasScheduledLocation = enrollment.scheduledLocationId
    ? data?.getLocations?.find((location) => location.id === enrollment.scheduledLocationId)
    : null;

  const hasParent = enrollment.studentProfile.account?.roles?.reduce((accum, role) =>
    role.profileType === ProfileType.AccountHolder ? role : accum
  );

  return (
    <Grid container spacing={2}>
      {(hasSiblingEnrollments || hasMultipleEnrollments) && (
        <>
          {hasSiblingEnrollments && (
            <Grid item xs={hasMultipleEnrollments ? 6 : 12}>
              <Typography variant='overline' display='block' gutterBottom>
                Siblings with Lessons
              </Typography>
              <Typography>{siblingEnrollments?.siblingEnrollmentIds?.length || 0} Lessons by Siblings</Typography>
              {!!siblingEnrollmentData?.enrollments?.length && (
                <Stack direction='column' spacing={1} alignItems='flex-start'>
                  {siblingEnrollmentData.enrollments.map((siblingEnrollment) => (
                    <Button
                      variant='text'
                      color='secondary'
                      size='small'
                      key={siblingEnrollment.id}
                      onClick={switchEnrollment ? () => switchEnrollment(siblingEnrollment) : undefined}
                    >
                      {UserRoleUtils.getFullName(siblingEnrollment.studentProfile.user)} -{' '}
                      {siblingEnrollment.skill?.skillName || siblingEnrollment.package.name}
                    </Button>
                  ))}
                </Stack>
              )}
            </Grid>
          )}
          {hasMultipleEnrollments && (
            <Grid item xs={hasSiblingEnrollments ? 6 : 12}>
              <Typography variant='overline' display='block' gutterBottom>
                # of lessons
              </Typography>
              <Typography>{multipleEnrollments?.enrollmentIds?.length || 0} Lessons</Typography>
            </Grid>
          )}
        </>
      )}
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Status
        </Typography>
        <Typography>{enrollment?.schedulingStatus}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Scheduled Day of Week
        </Typography>
        <Typography>
          {enrollment.dayOfWeek != null ? dayOfWeekIndexToStringMap[enrollment.dayOfWeek] : '---'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Start Time
        </Typography>
        <Typography>
          {enrollment.startTime ? DateTime.fromFormat(enrollment.startTime, 'TT').toFormat('h:mm a') : '---'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          End Time
        </Typography>
        <Typography>
          {enrollment.endTime ? DateTime.fromFormat(enrollment.endTime, 'TT').toFormat('h:mm a') : '---'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Scheduled Instructor
        </Typography>
        <Typography>
          {enrollment?.scheduledInstructor
            ? `${enrollment?.scheduledInstructor?.user?.firstName} ${enrollment?.scheduledInstructor?.user?.lastName}`
            : '---'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Preferred Instructor
        </Typography>
        <Typography>
          {enrollment?.preferredInstructor
            ? `${enrollment?.preferredInstructor?.user?.firstName} ${enrollment?.preferredInstructor?.user?.lastName}`
            : 'YOU PICK!'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Lesson Type
        </Typography>
        <Typography>{enrollment?.lessonType}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          LOCATION
        </Typography>
        {hasScheduledLocation ? (
          <Typography>
            {hasScheduledLocation.address || hasScheduledLocation.locationIdentifier} -{' '}
            {hasScheduledLocation?.site?.siteName || hasScheduledLocation?.site?.siteIdentifier}
          </Typography>
        ) : (
          '---'
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant='overline' display='block' gutterBottom>
          Notes
        </Typography>
        <Typography>{enrollment?.notes}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Payment Preference
        </Typography>
        <Typography>{enrollment?.paymentTypePreference}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Phone Number
        </Typography>
        <Typography>
          {enrollment?.phoneNumber ? (
            <a href={`tel:+1${enrollment.phoneNumber}`} target='_blank' rel='noreferrer'>
              {enrollment?.phoneNumber}
            </a>
          ) : (
            '---'
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='overline' display='block' gutterBottom>
          Parent Name
        </Typography>
        <Typography>
          {hasParent?.user ? `${hasParent?.user?.firstName} ${hasParent?.user?.lastName}` : '---'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='overline' display='block' gutterBottom>
          Parent Email
        </Typography>
        <Typography>{hasParent?.user?.email || '---'}</Typography>
      </Grid>

      {/* Availability */}
      <Grid item xs={12}>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='overline' display='block' gutterBottom>
          Availability
        </Typography>
        {studentAvailability?.length ? (
          studentAvailability.map((availability) => {
            const { dayOfWeek, startTime, endTime } = availability;
            return (
              <Typography key={availability.id}>
                {dayOfWeek[0] + dayOfWeek.slice(1).toLowerCase()}{' '}
                {startTime ? DateTime.fromFormat(startTime, 'TT').toFormat('h:mm a') : ''} -{' '}
                {endTime ? DateTime.fromFormat(endTime, 'TT').toFormat('h:mm a') : ''}
              </Typography>
            );
          })
        ) : (
          <Typography>Availability: Not Provided</Typography>
        )}
      </Grid>

      {/* Scheduling Preferences */}
      <Grid item xs={12}>
        <Divider light sx={{ mb: 2 }} />
        <Typography variant='h2'>Scheduling Preferences</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Student Scheduling Preference
        </Typography>
        <Typography>{enrollment?.schedulingConsideration}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Schedule with siblings/family?
        </Typography>
        <Typography>{enrollment?.siblingConsideration}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          What&apos;s the higher priority?
        </Typography>
        <Typography>{enrollment?.schedulingPriority}</Typography>
      </Grid>

      {/* Student */}
      <Grid item xs={12}>
        <Divider light sx={{ mb: 2 }} />
        <Typography variant='h2'>Student</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Name
        </Typography>
        <Typography>
          {enrollment?.studentProfile?.user?.firstName} {enrollment?.studentProfile?.user?.lastName}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Student Email
        </Typography>
        <Typography>{enrollment?.studentProfile?.user?.email || '---'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Birth Year
        </Typography>
        <Typography>{enrollment?.studentProfile?.birthYear || '---'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Birth Month
        </Typography>
        <Typography>{enrollment?.studentProfile?.birthMonth || '---'}</Typography>
      </Grid>
    </Grid>
  );
};

import { useSchedulingConsiderationsContext } from 'contexts/SchedulingConsiderationsContext/SchedulingConsiderationsContext';
import { useMemo } from 'react';

function useSiblingEnrollmentsDetail(enrollmentId?: number) {
  const { siblingEnrollmentsMap } = useSchedulingConsiderationsContext();

  return useMemo(
    () => (enrollmentId ? siblingEnrollmentsMap?.[enrollmentId] : undefined),
    [enrollmentId, siblingEnrollmentsMap]
  );
}

export default useSiblingEnrollmentsDetail;

import { styled, ThemeOptions } from '@mui/material/styles';
import Button from '@mui/material/Button';

type StyleProps = {
  theme: ThemeOptions;
};

const CTAButton = styled(Button)`
  background-color: black;
  padding: 0.25rem;
  color: white;

  ${({ theme }: StyleProps) => theme.breakpoints.down && theme.breakpoints.down('md')} {
    font-size: 1rem;
  }

  ${({ theme }: StyleProps) => theme.breakpoints.down && theme.breakpoints.down('sm')} {
    font-size: 0.75rem;
  }

  ${({ theme }: StyleProps) => theme.breakpoints.down && theme.breakpoints.down('xs')} {
    font-size: 0.625rem;
  }

  :hover {
    background-color: #2b2d2f;
  }
`;

export default CTAButton;

const env = {
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'auth.lyriq.caxy.com',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '49lbnK5JRd1EterfvW1mPLQFdXcskAkz',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://caxy-lyriq.us.auth0.com/api/v2/',
    scope: 'read:current_user update:current_user_metadata openid profile email',
    useRefreshTokens: true,
    maxAge: 1000 * 60 * 60 * 24 * 90, // 90 days
  },
  square: {
    applicationId: process.env.REACT_APP_SQUARE_APPLICATION_ID || 'sandbox-sq0idb-RQnjvFpb6mSaS4xITt7FVw',
    locationId: process.env.REACT_APP_SQUARE_LOCATION_ID || 'LK00SB58K4BYT',
  },
  admin: {
    baseUrl: process.env.REACT_APP_ADMIN_URL || 'http://localhost:4000/admin',
  },
  graphql: {
    apiUrl: process.env.REACT_APP_GRAPHQL_API || 'http://localhost:8000/graphql',
    subscriptionsUrl:
      process.env.REACT_APP_GRAPHQL_SUBSCRIPTIONS_URL ||
      process.env.REACT_APP_GRAPHQL_API ||
      'http://localhost:8000/graphql',
  },
  datetime: {
    defaultTimeZone: 'America/Chicago',
  },
  routing: {
    adminPath: 'manage',
  },
  sizing: {
    admin: {
      vhOffset: '8rem',
    },
  },
  app: {
    s3Bucket: process.env.REACT_APP_S3_BUCKET,
    s3Region: process.env.REACT_APP_S3_REGION,
  },
};

export default env;

import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { useUserContext } from 'contexts/UserContext/UserContext';
import { ProfileType } from 'utils/user/roleUtils';
import useCurrentUserRole from 'hooks/useCurrentUserRole/useCurrentUserRole';
import useCurrentUserAccount from 'hooks/useCurrentUserAccount/useCurrentUserAccount';

const AccountPage = () => {
  const { user, loading, error } = useUserContext();

  const accountHolder = useCurrentUserRole(ProfileType.AccountHolder);
  const account = useCurrentUserAccount();

  if (loading) {
    return <div>Loading ...</div>;
  }

  if (error) {
    return <div>Oops! An error occurred: {error.message}</div>;
  }

  if (!user) {
    return null;
  }

  const fullName = `${user.firstName} ${user.lastName}`.trim();

  // If not an account holder, we'll return a read-only version.

  return (
    <Container>
      <Box sx={{ py: 3 }}>
        <Typography variant='h1'>Your Profile</Typography>
        {user.pictureUrl && <img src={user.pictureUrl} alt={fullName} />}
        <Typography variant='h2'>{fullName}</Typography>
        <p>{user.email}</p>
      </Box>
      <Box sx={{ py: 3 }}>
        <Typography variant='h1'>My Account</Typography>
        {!account && (
          <Box sx={{ py: 4 }}>
            <Alert color='warning'>You are not associated to an account at the moment. Please contact support.</Alert>
          </Box>
        )}
        {account && (
          <Box py={2}>
            <Grid container>
              <Grid item xs={6} md={2}>
                <Typography fontWeight={700}>Account Name:</Typography>
              </Grid>
              <Grid item xs={6} md={10}>
                <Typography>{account.accountName}</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography fontWeight={700}>Account Number:</Typography>
              </Grid>
              <Grid item xs={6} md={10}>
                <Typography>{account.accountNumber}</Typography>
              </Grid>
              {accountHolder && (
                <>
                  <Grid item xs={6} md={2}>
                    <Typography fontWeight={700}>Your Role:</Typography>
                  </Grid>
                  <Grid item xs={6} md={10}>
                    <Typography>Account Holder</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AccountPage;

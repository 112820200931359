import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { GetPackagesDocument, GetPackagesQuery, GetPackagesQueryVariables } from 'generated/graphql';

export type PackageData = NonNullable<GetPackagesQuery['getPackages']>[number];

function useQueryGetPackages(
  options?: QueryHookOptions<GetPackagesQuery, GetPackagesQueryVariables>
): QueryResult<GetPackagesQuery, GetPackagesQueryVariables> {
  return useQuery<GetPackagesQuery, GetPackagesQueryVariables>(GetPackagesDocument, {
    ...options,
  });
}

export default useQueryGetPackages;

import { EnrollmentStatus, ScheduleItem, ScheduleItemState, ScheduleItemType } from 'generated/graphql';
import { RRule, RRuleSet } from 'rrule';
import { DateTime } from 'luxon';
import { EnrollmentEvent, EnrollmentFromQuery } from 'types';
import colorMapper from 'utils/SemesterCalendar/colorMapper';
import { NO_PREFERENCE_VALUE } from 'components/organisms/InstructorSelectField/InstructorSelectField';
import { fixRRuleDates, getDateTime } from '../dates';
import env from '../../config/env';

const DEFAULT_EVENT_DURATION_SECONDS = 1800;

function getEventsFromRRule(
  rule: RRule | RRuleSet,
  enrollment: EnrollmentFromQuery,
  scheduleItem: ScheduleItem,
  dateRange?: { startDate: Date; endDate: Date }
) {
  const events: EnrollmentEvent[] = [];
  const lockedStatuses = [
    EnrollmentStatus.PendingConfirmation,
    EnrollmentStatus.UserConfirmed,
    EnrollmentStatus.Approved,
  ];

  const allInstances = dateRange ? rule.between(dateRange.startDate, dateRange.endDate, true) : rule.all();
  fixRRuleDates(allInstances).forEach((instance) => {
    const endDateTime = DateTime.fromJSDate(instance).plus({
      seconds: scheduleItem.duration || DEFAULT_EVENT_DURATION_SECONDS,
    });

    const instructorId = enrollment.scheduledInstructorId || enrollment.preferredInstructorId || undefined;

    const colorId = `instructor_${instructorId?.toString() || NO_PREFERENCE_VALUE}`;

    const isLocked = lockedStatuses.includes(enrollment.schedulingStatus) || enrollment.scheduleLocked;

    events.push({
      id: `enrollment__${enrollment.id}__${instance.toISOString()}`,
      title: `${enrollment.studentProfile.user?.firstName} ${enrollment.studentProfile.user?.lastName}`,
      start: instance,
      end: endDateTime.toJSDate(),
      enrollment,
      enrollmentId: enrollment.id,
      scheduleItem,
      instructorId,
      resource: enrollment.scheduledInstructorId || enrollment.preferredInstructorId || NO_PREFERENCE_VALUE,
      studentId: enrollment.studentProfileId,
      skill: enrollment.skill || undefined,
      color:
        enrollment.scheduledInstructor?.calendarColor ||
        enrollment.preferredInstructor?.calendarColor ||
        colorMapper.pickColor(colorId),
      isDraggable: !isLocked,
    });
  });

  return events;
}

export default function parseEventsFromEnrollments(
  startDate: Date,
  endDate: Date,
  enrollments: EnrollmentFromQuery[],
  zone = env.datetime.defaultTimeZone
) {
  const events: EnrollmentEvent[] = [];

  enrollments.forEach((enrollment) => {
    if (enrollment.dayOfWeek == null || !enrollment.startTime || !enrollment.endTime) {
      return;
    }

    // TODO: Determine how group classes will show on the calendar.
    // TODO: Handle exclusion dates?
    if (enrollment.startTime && enrollment.endTime) {
      // Use shallow scheduling fields (set from the AI optaplanner)
      const startTime = /^\d+:\d+:\d+/.test(enrollment.startTime)
        ? DateTime.fromFormat(enrollment.startTime, 'TT', { zone }).setZone(zone)
        : getDateTime(enrollment.startTime, { zone }).setZone(zone, { keepLocalTime: true });

      const start = getDateTime(startDate, { zone }).setZone(zone).toJSDate();
      const end = getDateTime(endDate, { zone }).setZone(zone).toJSDate();

      start.setHours(startTime.hour, startTime.minute, startTime.second);

      let endTime: DateTime;
      const minutes = enrollment.package?.appointmentType?.duration;
      if (minutes) {
        endTime = getDateTime(end, {
          zone,
        })
          .setZone(zone)
          .set({
            hour: startTime.hour,
            minute: startTime.minute,
            second: startTime.second,
          })
          .plus({ minutes });
      } else {
        endTime = DateTime.fromFormat(enrollment.endTime, 'TT', { zone });
      }

      end.setHours(endTime.hour, endTime.minute, endTime.second);

      const rrule = new RRule({
        freq: RRule.WEEKLY,
        // Enrollments have `enrollment.scheduleInterval` field for this, however for the admin scheduler page
        // we want to still show the enrollment taking up the timeslot so we leave this as 1.
        interval: 1,
        byweekday: [enrollment.dayOfWeek],
        // We convert to UTC but keep local time.
        // When retrieving dates from the rrule instance later, we do the same back to our timezone.
        dtstart: DateTime.fromJSDate(start).toUTC(undefined, { keepLocalTime: true }).toJSDate(),
        until: DateTime.fromJSDate(end).toUTC(undefined, { keepLocalTime: true }).toJSDate(),
      });

      const scheduleItem: ScheduleItem = {
        id: 0,
        duration: enrollment.package.appointmentType?.duration && enrollment.package.appointmentType.duration * 60,
        endDate: endDate,
        isFullDay: false,
        recurrenceRule: rrule.toString(),
        scheduleItemState: ScheduleItemState.InApproval,
        scheduleItemType: ScheduleItemType.Lesson,
        startDate: startDate,
        startTime: enrollment.startTime,
      };

      events.push(...getEventsFromRRule(rrule, enrollment, scheduleItem, { startDate, endDate }));
    }
  });

  return events;
}

import { EnrollmentFromQuery } from 'types';

export function sortEnrollments(enrollments: EnrollmentFromQuery[]) {
  const sorted = enrollments.slice();
  sorted.sort((a, b) => {
    if (a.dayOfWeek !== b.dayOfWeek) {
      return (a.dayOfWeek ?? -1) - (b.dayOfWeek ?? -1);
    }

    const startTimeA = a.startTime ?? '00:00:00';
    const startTimeB = b.startTime ?? '00:00:00';

    const endTimeA = a.endTime ?? '00:30:00';
    const endTimeB = b.endTime ?? '00:30:00';

    if (startTimeA < startTimeB) {
      return -1;
    }

    if (startTimeA > startTimeB) {
      return 1;
    }

    if (endTimeA === endTimeB) {
      return 0;
    }

    // Sort by end time opposite of how we sort start time, so that longer enrollments are sorted first.
    return endTimeA < endTimeB ? 1 : -1;
  });

  return sorted;
}

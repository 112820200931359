import { BackgroundEvent } from 'types';
import { DateTime, Interval } from 'luxon';
import { CalendarRange, SlotAvailabilityMap } from 'utils/calendar/types';

export const TIMESLOT_STEP = 15;

export default function getSlotAvailability(
  range: CalendarRange,
  instructorAvailabilityEvents: BackgroundEvent[]
): SlotAvailabilityMap {
  if (!Array.isArray(range) || range.length === 0) {
    return {};
  }

  const availabilityIntervals = instructorAvailabilityEvents.map((event) => {
    return {
      event,
      interval: Interval.fromDateTimes(event.start, event.end),
    };
  });

  const mapping: Record<string, { event: BackgroundEvent; isFirst: boolean; isLast: boolean }[]> = {};

  let cursor = DateTime.fromJSDate(range[0]);
  const end = DateTime.fromJSDate(range[range.length - 1]).endOf('day');
  while (cursor <= end) {
    const next = cursor.plus({ minute: TIMESLOT_STEP });
    const slotInterval = Interval.fromDateTimes(cursor, next);
    const matching = availabilityIntervals.filter((x) => x.interval.overlaps(slotInterval));

    if (matching.length > 0) {
      const start = cursor;
      mapping[cursor.toJSDate().toISOString()] = matching.map((x) => ({
        event: x.event,
        isFirst: DateTime.fromJSDate(x.event.start) >= start,
        isLast: DateTime.fromJSDate(x.event.end) <= next,
      }));
    }

    cursor = next;
  }

  return mapping;
}

import { gql, MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  EnrollmentStatus,
  SendEnrollmentConfirmationsDocument,
  SendEnrollmentConfirmationsMutation,
  SendEnrollmentConfirmationsMutationVariables,
} from 'generated/graphql';
import { useMemo } from 'react';

type Data = SendEnrollmentConfirmationsMutation;
type Variables = SendEnrollmentConfirmationsMutationVariables;

function useMutationSendEnrollmentConfirmations(
  options?: MutationHookOptions<Data, Variables>
): MutationTuple<Data, Variables> {
  const opts = useMemo<MutationHookOptions<Data, Variables>>(
    () => ({
      update(cache, result, options) {
        const { enrollmentIds } = options.variables?.input || {};

        enrollmentIds?.forEach((id) => {
          cache.writeFragment<{ schedulingStatus?: EnrollmentStatus }>({
            id: `Enrollment:${id}`,
            fragment: gql`
              fragment EnrollmentWithStatus on Enrollment {
                schedulingStatus
              }
            `,
            data: {
              schedulingStatus: EnrollmentStatus.PendingConfirmation,
            },
          });
        });
      },
      ...options,
    }),
    [options]
  );

  return useMutation<Data, Variables>(SendEnrollmentConfirmationsDocument, opts);
}

export default useMutationSendEnrollmentConfirmations;

import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AppointmentCalendar from 'pages/Admin/ManagerCalendar/components/AppointmentCalendar/AppointmentCalendar';
import { useAdminContext } from 'contexts/AdminContext/AdminContext';
import useQueryScheduleAvailability from 'hooks/queries/useQueryScheduleAvailability/useQueryScheduleAvailability';
import ScheduleSelectField from 'components/organisms/ScheduleSelectField/ScheduleSelectField';
import InstructorSelectField from 'components/organisms/InstructorSelectField/InstructorSelectField';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import HeaderPortal from 'components/organisms/HeaderPortal/HeaderPortal';
import Button from '@mui/material/Button';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop: string) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  overflowX: 'auto',
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
}));

const ManagerCalendar = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const { selectedSchedule, selectedInstructors, setSelectedInstructors } = useAdminContext();
  const [viewByInstructor, setViewByInstructor] = useState<boolean>(true);

  const { data: availabilityData } = useQueryScheduleAvailability(selectedSchedule?.id);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <HeaderPortal>
        <Box sx={{ ml: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            size='small'
            color='primary'
            variant='contained'
            href={`http://${process.env.REACT_APP_API_DOMAIN}/file/appointments-report?scheduleId=${selectedSchedule?.id}`}
            target='_blank'
          >
            Export Appointments for Schedule
          </Button>
        </Box>
      </HeaderPortal>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='persistent'
        anchor='left'
        open={open}
      >
        <Toolbar />
        <Box sx={{ padding: '2rem 1rem 1rem' }}>
          <Stack direction='column' spacing={2}>
            <ScheduleSelectField label={'Change schedule'} />
            <InstructorSelectField
              onChange={setSelectedInstructors}
              value={selectedInstructors}
              label='Filter by teacher(s)'
              placeholder='Teachers'
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch checked={viewByInstructor} onChange={(event, checked) => setViewByInstructor(checked)} />
                }
                label='View by Teacher'
              />
            </FormGroup>
          </Stack>
        </Box>
        <Box sx={{ mt: 'auto' }}>
          <Divider />
          <DrawerFooter>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerFooter>
        </Box>
      </Drawer>
      <Main open={open}>
        {availabilityData && (
          <AppointmentCalendar
            availability={availabilityData?.scheduleAvailability}
            selectedInstructors={selectedInstructors}
            byResource={viewByInstructor}
          />
        )}
      </Main>
    </Box>
  );
};

export default ManagerCalendar;

import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment/moment';
import Divider from '@mui/material/Divider';
import Currency from 'components/atoms/Currency/Currency';
import { ScheduleItemAvailability } from 'generated/graphql';
import { QueriedAppointment } from 'types';

function getDatesList(dates: ScheduleItemAvailability[]): string {
  return dates
    .map((date) =>
      date.startDate === date.endDate
        ? moment(date.startDate).format('MMM D, yyyy')
        : `${moment(date.startDate).format('MMM D, yyyy')} - ${moment(date.endDate).format('MMM D, yyyy')}`
    )
    .join(', ');
}

interface Props {
  appointment: QueriedAppointment | undefined;
}

export const AppointmentDrawerView = ({ appointment }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Date
        </Typography>
        <Typography>{moment(appointment?.startDate).format('MMM D YYYY')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Status
        </Typography>
        <Typography>{appointment?.status}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Start Time
        </Typography>
        <Typography>{moment(appointment?.startDate).format('h:mm a')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          End Time
        </Typography>
        <Typography>{moment(appointment?.endDate).format('h:mm a')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Instructor
        </Typography>
        <Typography>
          {appointment?.instructorProfile?.user?.firstName} {appointment?.instructorProfile?.user?.lastName}
        </Typography>
      </Grid>
      {appointment?.substituteProfile && (
        <Grid item xs={6}>
          <Typography variant='overline' display='block' gutterBottom>
            Substitute
          </Typography>
          <Typography>
            {appointment?.substituteProfile?.user?.firstName} {appointment?.substituteProfile?.user?.lastName}
          </Typography>
        </Grid>
      )}
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Lesson Type
        </Typography>
        <Typography>{appointment?.lessonType}</Typography>
      </Grid>

      {/* Student */}
      <Grid item xs={12}>
        <Typography variant='h2'>Student</Typography>
        <Divider light sx={{ mt: 1 }} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Name
        </Typography>
        <Typography>
          {appointment?.studentProfile?.user?.firstName} {appointment?.studentProfile?.user?.lastName}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Email
        </Typography>
        <Typography>{appointment?.studentProfile?.user?.email || '---'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Student Status
        </Typography>
        <Typography>{appointment?.studentProfile?.user?.status?.toUpperCase()}</Typography>
      </Grid>

      {/* Schedule */}
      <Grid item xs={12}>
        <Typography variant='h2'>Schedule</Typography>
        <Divider light sx={{ mt: 1 }} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Name
        </Typography>
        <Typography>{appointment?.schedule?.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Schedule Status
        </Typography>
        <Typography>{appointment?.schedule?.status?.toUpperCase()}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Start Date
        </Typography>
        <Typography>{appointment?.schedule?.startDate}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          End Date
        </Typography>
        <Typography>{appointment?.schedule?.endDate}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Exclusion Dates
        </Typography>
        <Typography>{getDatesList(appointment?.schedule?.exclusionDates || [])}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Makeup Dates
        </Typography>
        <Typography>{getDatesList(appointment?.schedule?.makeupDates || [])}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Early Signup Cutoff Date
        </Typography>
        <Typography>{appointment?.schedule?.earlySignupCutoffDate}</Typography>
      </Grid>

      {/* Package */}
      <Grid item xs={12}>
        <Typography variant='h2'>Package</Typography>
        <Divider light sx={{ mt: 1 }} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Name
        </Typography>
        <Typography>{appointment?.package?.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Category
        </Typography>
        <Typography>{appointment?.package?.category?.name || '---'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Initial Fee
        </Typography>
        <Currency value={appointment?.package?.initialFeeInCents || 0} minFraction={0} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Price Per Item
        </Typography>
        <Currency value={appointment?.package?.pricePerItemInCents || 0} minFraction={0} />
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Max Occurrences
        </Typography>
        <Typography>{appointment?.package?.maxOccurrences || '---'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Trial Occurrences
        </Typography>
        <Typography>{appointment?.package?.trialOccurrences || '---'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Parent Package
        </Typography>
        <Typography>{appointment?.package?.parentPackage?.name || '---'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Weekly
        </Typography>
        <Typography>{appointment?.package?.isWeekly ? 'Yes' : 'No'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Trial
        </Typography>
        <Typography>{appointment?.package?.isTrial ? 'Yes' : 'No'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Publicly Purchasable
        </Typography>
        <Typography>{appointment?.package?.isPubliclyPurchasable ? 'Yes' : 'No'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Schedule Bound
        </Typography>
        <Typography>{appointment?.package?.isScheduleBound ? 'Yes' : 'No'}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Package Type
        </Typography>
        <Typography>{appointment?.package?.packageType}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='overline' display='block' gutterBottom>
          Status
        </Typography>
        <Typography>{appointment?.package?.status?.toUpperCase()}</Typography>
      </Grid>
    </Grid>
  );
};
